/* eslint-disable @typescript-eslint/no-var-requires */
require("core-js/stable")
require("regenerator-runtime/runtime")

// for more info, see https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill

if (typeof Promise === "undefined") {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require("promise/lib/rejection-tracking").enable()
  window.Promise = require("promise/lib/es6-extensions.js")
}

// Make sure we're in a Browser-like environment before importing polyfills
// This prevents `fetch()` from being imported in a Node test environment
// if (typeof window !== 'undefined') {
//   // fetch() polyfill for making API calls.
//   require('whatwg-fetch');
// }

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require("object-assign")

// Support for...of (a commonly used syntax feature that requires Symbols)
require("core-js/features/symbol")
// Support iterable spread (...Set, ...Map)
require("core-js/features/array/from")
// // In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// // We don't polyfill it in the browser--this is user's responsibility.
// if (process.env.NODE_ENV === 'test') {
//   require('raf').polyfill(global);
// }
require("url-search-params-polyfill")
// var Symbol = require('es6-symbol/polyfill');

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, "includes", {
    value: function (valueToFind, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // 3. If len is 0, return false.
      if (len === 0) {
        return false
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

      function sameValueZero(x, y) {
        return x === y || (typeof x === "number" && typeof y === "number" && isNaN(x) && isNaN(y))
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(valueToFind, elementK) is true, return true.
        if (sameValueZero(o[k], valueToFind)) {
          return true
        }
        // c. Increase k by 1.
        k++
      }

      // 8. Return false
      return false
    },
  })
}

var debouncedTimeoutId = 0
function inputIsFocused() {
  return !!document.activeElement && document.activeElement.tagName == "INPUT"
}
function focusInput() {
  debouncedTimeoutId = 0
  if (inputIsFocused()) {
    document.activeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
  }
}
function onFocusIn() {
  if (!debouncedTimeoutId && inputIsFocused()) {
    debouncedTimeoutId = window.setTimeout(focusInput, 200)
  }
}

if (typeof navigator !== "undefined" && /Android/i.test(navigator.userAgent)) {
  document.addEventListener("focusin", onFocusIn, { passive: true })
}
