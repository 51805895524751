import { useQuery } from "@apollo/client"
import React from "react"
import { AssetSrcQuery, AssetSrcQueryVariables } from "../../__generated__/AssetSrcQuery"
import { ASSET_SRC_QUERY } from "../utils/central-queries"
import CircleSvg from "./icons/Circle"
import { withoutDomain } from "../../common/url-utils"
import styled from "styled-components"
import { logoReplacementPath } from "../../common/constants"
// import useComponentId from "../hooks/useComponentId"

interface IInlineAsset {
  fullPath: string
  id?: string
  className?: string
  alt?: string
  isUrl?: boolean
}

const ImageAsset = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
`

const imageRegexp = /image/
const svgRegexp = /svg/

const Asset = React.memo((props: IInlineAsset) => {
  const { fullPath: fullPathWithDomain, ...rest } = props
  const fullPath = withoutDomain(fullPathWithDomain)
  // const svgDefsIdPrefix = useComponentId()
  const assetQuery = useQuery<AssetSrcQuery, AssetSrcQueryVariables>(ASSET_SRC_QUERY, { variables: { fullPath } })
  if (assetQuery.loading) {
    return (
      <span {...rest}>
        <CircleSvg color="#ffffff22" />
      </span>
    )
  }
  if (assetQuery.data?.asset.src) {
    const contentType = assetQuery.data.asset.contentType
    const isSvg = svgRegexp.test(contentType)
    const src = assetQuery.data.asset.src
    // const src = makeSvgIdsUniq(assetQuery.data.asset.src, svgDefsIdPrefix)
    if (imageRegexp.test(contentType) && !isSvg) {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <img {...rest} src={`data:${assetQuery.data.asset.contentType};base64,${src}`} />
    }
    return <span {...rest} dangerouslySetInnerHTML={{ __html: src }} />
  }
  return <span {...rest}>{`[Asset missing: '${fullPath}']`}</span>
})

const InlineAsset = React.memo((props: IInlineAsset) => {
  const { fullPath: fullPathWithDomain, alt, isUrl, ...rest } = props
  const fullPath = withoutDomain(fullPathWithDomain)
  const url = logoReplacementPath[fullPath]
  // const svgDefsIdPrefix = useComponentId()
  if (url || isUrl) {
    return <ImageAsset {...rest} src={url ?? fullPath} alt={alt} />
  }
  return <Asset {...props} />
})

export default InlineAsset
