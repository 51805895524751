import { AsyncRouteProps, CtxBase, InitialProps, TRouteData } from "../../routes.d"
import { findMatchedRoute, isAsyncComponent, loadRouteComponent } from "./async-route-utils"

export async function loadInitialProps(routes: AsyncRouteProps[], pathname: string, ctx: CtxBase) {
  const { match, route } = findMatchedRoute(routes, pathname)
  const matchedComponent = (route && route.component) || undefined
  if (route) {
    await loadRouteComponent(route)
  }
  const routeName = route?.name
  const initialPropsData: TRouteData =
    matchedComponent && match && isAsyncComponent(matchedComponent) ? await matchedComponent.getInitialProps({ match, ...ctx }) : undefined

  return {
    routeName,
    match: matchedComponent,
    data: initialPropsData,
  } as InitialProps
}
