import * as React from "react"
import { Redirect } from "react-router"
import { canUseDom } from "../utils/misc-utils"
import Status from "./Status"

const BadProdRoute = ({ currentPathname, to = "", status = 307 }) => {
  const prodPathFor =
    // currentPathname.includes("football/pickem") && "fantasy/games/football/playoff-pickem-pool/" ||
    // currentPathname.includes("college-basketball/conference-tournament/bracket") && "college-basketball/ncaa-tournament/conference-brackets/games/" ||
    "fantasy"
  const redirectPath = to || `https://www.cbssports.com/${prodPathFor}`
  if (/www.cbssports.com/.test(redirectPath) && canUseDom) {
    window.location.href = redirectPath
  }
  return (
    <Status code={status}>
      <Redirect to={redirectPath} />
    </Status>
  )
}
export default BadProdRoute
