import React, { FC, HTMLAttributes, useCallback, useState, useEffect, useRef } from "react"
import styled from "styled-components"

const HorizontalShadowScrollRoot = styled.div`
  position: relative;

  .scroll__content-wrapper {
    overflow: auto hidden;
    display: flex;
  }

  .scroll__content {
    flex: 1;
  }

  .scroll__off {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1.5rem;
  }

  .scroll__off--left {
    left: 0;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
  }

  .scroll__off--right {
    right: 0;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
  }
`

interface HorizontalShadowScrollProps extends HTMLAttributes<HTMLDivElement> {
  offLeftElementClassName?: string
  offRightElementClassName?: string
}

const HorizontalShadowScroll: FC<HorizontalShadowScrollProps> = ({
  children,
  offLeftElementClassName = "",
  offRightElementClassName = "",
  ...rest
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [showScroll, setShowScroll] = useState<boolean>(false)
  const [scrollPercent, setScrollPercent] = useState<number>(0)

  const processScroll = useCallback(() => {
    if (scrollRef.current) {
      const { clientWidth, scrollWidth, scrollLeft } = scrollRef.current
      const totalScroll = scrollWidth - clientWidth
      const percent = (scrollLeft / totalScroll) * 100
      setShowScroll(totalScroll > 0)
      setScrollPercent(percent)
    }
  }, [])

  useEffect(() => {
    processScroll()
    window.addEventListener("resize", processScroll, false)
    return () => {
      window.removeEventListener("resize", processScroll)
    }
  }, [processScroll])

  return (
    <HorizontalShadowScrollRoot {...rest}>
      <div className="scroll__content-wrapper" onScroll={processScroll} ref={scrollRef}>
        <div className="scroll__content">{children}</div>
      </div>
      {showScroll && scrollPercent > 0 && <div className={`scroll__off scroll__off--left ${offLeftElementClassName}`}></div>}
      {showScroll && scrollPercent < 100 && <div className={`scroll__off scroll__off--right ${offRightElementClassName}`}></div>}
    </HorizontalShadowScrollRoot>
  )
}

export default HorizontalShadowScroll
