import * as React from "react"
import { urlFromSpoeAttr } from "../../../../common/url-utils"
import A from "../../../components/A"
import Dropdown from "../../../components/Dropdown"
import NavAvatarSvg from "../../../components/icons/NavAvatar"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import { useQuery } from "@apollo/client"
import { CurrentUserSpoeQuery } from "../../../../__generated__/CurrentUserSpoeQuery"
import { CURRENT_USER_SPOE_QUERY } from "../../queries"
import {
  Container,
  MyTeamsComponentBottom,
  MyTeamsComponentBottomRow,
  MyTeamsContainer,
  MyTeamsRow,
  MyTeamsRowInfo,
  MyTeamsRowLeague,
  MyTeamsRowLogo,
  MyTeamsRowName,
  MyTeamsSport,
  MyTeamsSportTitle,
  NavSideDropdown,
  NavSideItemAccount,
  SpoeDropdownNoLeagueBtn,
  SpoeDropdownToggler,
  SpoeDropdownTogglerAvatar,
  SpoeDropdownTogglerCurrentLeagueInfo,
  SpoeDropdownWrapper,
} from "../styles/SiteNavSide.styles"
import { LayoutProps } from "../typings"
import { emptyArray } from "../../../../common/misc-utils"
import { useLocation } from "react-router-dom"
import constants from "../../../../common/constants"
import { getAuthSearch } from "../../../utils/url-utils"

interface ISpoe {
  lineOne: string
  lineTwo: string
  sportName: string
  icon: string
  url: string
  id: number
}
interface ISpoeSport {
  sportName: string
  spoes: ISpoe[]
}

const numMatcher = /\.(\d)/
const numReplace = "-$1"

// NOTE qac: react router doesnt like the domain in the URL :(
// TODO qac: mabye fix this in Link / A components?
const urlFromSpoeAttrLocal = (attr) => {
  const fixed = urlFromSpoeAttr(attr)
  if (typeof window !== "undefined" && fixed.indexOf(window.location.hostname) >= 0) {
    const parts = fixed.split(window.location.hostname)
    return parts[1]
  }
  return fixed
}
const spoeImg = (url: string) => {
  // NOTE qac: some images sub number domains for a dash (for https subdomain support)
  // ex: https://mockdraft.1.baseball.cbssports.com/ownerlogo?size=36x36 => https://mockdraft-1.baseball.cbssports.com/ownerlogo?size=36x36
  const parts = url.split(".com")
  if (parts.length > 1) {
    const domainPart = parts.shift()!
    parts.unshift(domainPart.replace(numMatcher, numReplace))
  }
  return parts.join(".com")
}

function fixedName(str: string | null) {
  if (str) {
    try {
      return decodeURIComponent(str)
    } catch (err) {}
  }
  return str || ""
}

const formatFantasyTeams = (userTeams: any[], pathname: string) => {
  // console.log('userTeams')
  // console.dir(userTeams)
  const sports = [] as ISpoeSport[]
  userTeams
    .filter((spoe) => !spoe.isPlaceholder)
    .forEach((spoe, i) => {
      let sportName = spoe.sport || "OTHER"
      const isNcaaBracket = sportName === "mayhem"
      if (isNcaaBracket) {
        sportName = "BRACKETS"
      }
      if (sportName) {
        let sport = sports.find((s) => s.sportName === sportName)
        if (!sport) {
          sport = { sportName, spoes: [] }
          sports.push(sport)
        }
        const namePlaceholder = spoe.team_name || spoe.name || "Play Now!"
        const swap = !spoe.name
        const lineOne = fixedName(swap ? spoe.league_name : namePlaceholder)
        const lineTwo = fixedName(swap ? namePlaceholder : spoe.league_name)
        const iconBase = spoe.logo || spoe.logo_url || `sports.cbsimg.net/images/splash/football/36x36-${(lineOne || "u")[0].toLowerCase()}.jpg`
        const icon = urlFromSpoeAttrLocal(spoeImg(iconBase))
        let url = urlFromSpoeAttrLocal(spoe.base_url || "")
        // NOTE qac: we SHOULD respect the base_url and use http if the product requires http
        if (spoe.base_url && spoe.base_url.includes("http://")) {
          url = url.replace("https", "http")
        }
        sport.spoes.push({
          id: i,
          url,
          icon,
          sportName,
          lineOne,
          lineTwo,
        })
      }
    })
  return sports
}

export const AccountSpoes = (props: LayoutProps) => {
  const { pathname } = props
  // TODO qac: https://reactjs.org/blog/2020/02/26/react-v16.13.0.html#warnings-for-some-updates-during-render
  const currentUserSpoeQuery = useQuery<CurrentUserSpoeQuery>(CURRENT_USER_SPOE_QUERY)
  const userTeams = currentUserSpoeQuery.data?.userTeams.userTeams || emptyArray
  const formattedUserTeams = formatFantasyTeams(userTeams, pathname)
  const myTeamsUrl = `https://${constants.IS_PROD ? "" : "qa."}cbssports.com/fantasy/games/my-teams/`
  return (
    <>
      <MyTeamsContainer>
        {!currentUserSpoeQuery.loading ? (
          formattedUserTeams.map((sport) => (
            <MyTeamsSport key={sport.sportName}>
              <MyTeamsSportTitle>{sport.sportName}</MyTeamsSportTitle>
              {sport.spoes.map((spoe) => (
                <MyTeamsRow key={spoe.id} as={A} to={spoe.url}>
                  <MyTeamsRowLogo as="img" src={spoe.icon} alt={sport.sportName} />
                  <MyTeamsRowInfo className="row-info">
                    <MyTeamsRowName>{spoe.lineTwo}</MyTeamsRowName>
                    <MyTeamsRowLeague>{spoe.lineOne}</MyTeamsRowLeague>
                  </MyTeamsRowInfo>
                </MyTeamsRow>
              ))}
            </MyTeamsSport>
          ))
        ) : (
          <LoadingView />
        )}
      </MyTeamsContainer>
      <MyTeamsComponentBottom>
        <MyTeamsComponentBottomRow>
          <A external={true} to={myTeamsUrl}>
            Organize / See All Teams
          </A>
          <A to={constants.HELP_CENTER_URL}>Help</A>
        </MyTeamsComponentBottomRow>
        <MyTeamsComponentBottomRow>
          <A to="https://www.cbssports.com/settings">Account Settings</A>
          <A to={`/auth/logout?returnUrl=${encodeURIComponent(pathname)}`} data-cy="log-out">
            Log Out
          </A>
        </MyTeamsComponentBottomRow>
      </MyTeamsComponentBottom>
    </>
  )
}
// teamLogoUrl?: string;
// leagueName?: string;
// teamName?: string;
// currentLeagueId?: string;

function SiteNavSide(props: LayoutProps) {
  const { hasCbsAccount, teamLogoUrl, leagueName, teamName, currentLeagueId, productAbbrev, isCbsAppWebview } = props
  const location = useLocation()
  const { pathname, search: currentSearch } = location
  const search = getAuthSearch(`${pathname}${currentSearch ?? ""}`, productAbbrev, undefined, isCbsAppWebview)
  if (hasCbsAccount) {
    return (
      <Dropdown
        className={`has-league--${!!currentLeagueId}`}
        toggler={(props2) => {
          if (currentLeagueId) {
            return (
              <SpoeDropdownToggler as="button" type="button" {...props2}>
                <SpoeDropdownTogglerAvatar src={teamLogoUrl} alt={leagueName} />
                <SpoeDropdownTogglerCurrentLeagueInfo>
                  <div>{leagueName}</div>
                  <div>{teamName}</div>
                </SpoeDropdownTogglerCurrentLeagueInfo>
              </SpoeDropdownToggler>
            )
          } else {
            return (
              <SpoeDropdownNoLeagueBtn as="button" type="button" {...props}>
                <NavAvatarSvg />
                <div>My Teams</div>
              </SpoeDropdownNoLeagueBtn>
            )
          }
        }}
        container={(props2) => <SpoeDropdownWrapper {...props2} />}
        dropdown={(props2) => <NavSideDropdown {...props2}>{<AccountSpoes {...props} />}</NavSideDropdown>}
        valueName="SiteNavSide"
      />
    )
  } else {
    return (
      <Container>
        <NavSideItemAccount
          as={Link}
          to={{
            pathname: "/auth/login",
            search: search,
          }}
          rel="nofollow noopener"
        >
          Login
        </NavSideItemAccount>
      </Container>
    )
  }
}
export default React.memo(SiteNavSide)
