import React, { FC } from "react"
import styled from "styled-components"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"

interface IStandingsTableRowRootProps {
  hoverable?: boolean
}

const StandingsTableRowRoot = styled.div<IStandingsTableRowRootProps>`
  display: grid;
  grid-template-columns: repeat(18, minmax(3rem, 1fr));
  border-bottom: solid 1px ${theme.colors.overLight.white90};
  position: relative;

  &:hover {
    background-color: ${({ hoverable }) => (hoverable ? theme.colors.overLight.blue1 : "unset")};
  }
  &.is-mine--true::before {
    background: linear-gradient(
      to right,
      ${theme.colors.overLight.blue2} 0%,
      ${theme.colors.overLight.blue2} 5px,
      ${theme.colors.overLight.blue2} 5px,
      transparent 5px
    );
    content: "";
    position: absolute;
    width: 6px;
    height: 100%;
  }
`

interface IStandingsTableRowProps {
  hoverable?: boolean
  isMine?: boolean
}

const StandingsTableRow: FC<IStandingsTableRowProps> = ({ hoverable = false, children, isMine = false }) => {
  return (
    <StandingsTableRowRoot className={`is-mine--${isMine}`} hoverable={hoverable} data-cy="standings_row">
      {children}
    </StandingsTableRowRoot>
  )
}

export default StandingsTableRow
