import styled from "styled-components/macro"
import { fontWeight, newPalette as palette, fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

export const ContactListWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  max-height: 18.5rem;
  box-sizing: border-box;

  > .contact-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    height: 3rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    > .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      overflow: hidden;
      margin-right: 0.5rem;

      > .icon {
        width: 2rem;
        height: 2rem;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid ${palette.gray90};
        margin-right: 1rem;
        overflow: hidden;
      }

      > .contact-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        box-sizing: border-box;
        overflow: hidden;
        font-family: ${fontFamily.base};
        font-style: normal;
        letter-spacing: -0.10000000149011612px;
        text-align: left;

        > .name {
          font-size: 14px;
          font-weight: ${fontWeight.medium};
          line-height: 1.25rem;
          color: ${palette.gray20};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        > .email {
          font-size: 0.75rem;
          font-weight: ${fontWeight.regular};
          line-height: 1rem;
          color: ${palette.gray40};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    > svg {
      width: 1.25rem;
      height: 1.25rem;
      color: ${palette.lightBlue3};
    }
  }

  > .contact-item.is-disable {
    pointer-events: none;

    > svg {
      color: ${palette.gray40};
    }
  }

  @media (hover: hover) {
    > .contact-item {
      :hover:not(:active) {
        background-color: ${palette.lightestBlue};
        > .left {
          > .contact-info {
            > .name {
              color: ${palette.lightBlue3};
            }
            > .email {
              color: ${palette.lightBlue3};
            }
          }
        }
      }
    }
  }
`
