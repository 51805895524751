import Tournaments from "../server/services/data-layer/modules/sapi/tournaments"
import settings from "../settings"
import { ITournamentMatchup, TPlaysInto, TSportType } from "./db-typings"
import { GameSportType } from "./game-enums"

export const NcaabConfTournamentNames = [
  "Atlantic Coast Conference Tourney",
  "Big East Conference Tourney",
  "Big Ten Tourney",
  "Big 12 Conference Tourney",
  "Pac-12 Conference Tournament",
  "Southeastern Conference Tourney",
]
const TournamentAbbrevs = {
  "Atlantic Coast Conference Tourney": "ACC",
  "Big East Conference Tourney": "Big East",
  "Big Ten Tourney": "Big Ten",
  "Big 12 Conference Tourney": "Big 12",
  "Pac-12 Conference Tournament": "PAC-12",
  "Southeastern Conference Tourney": "SEC",
  // "NCAA Tournament": "NCAA",
}
// NOTE qac: this is how we make sure sim envs have unlocked tourneys
// - also: we want to use sportYear, which is 2019 for the "2020 tournaments"
const buildTournamentStartTimesFor = (sportYear: number) => {
  const adjustedYear = settings.USE_PREV_YEAR_TOURNAMENTS ? sportYear + 2 : sportYear + 1
  return {
    "Atlantic Coast Conference Tourney": `4:30 pm GMT-0400 March 9, ${adjustedYear}`,
    "Big East Conference Tourney": `7:00 pm GMT-0400 March 10, ${adjustedYear}`,
    "Big Ten Tourney": `6:00 pm GMT-0400 March 10, ${adjustedYear}`,
    "Big 12 Conference Tourney": `7:00 pm GMT-0400 March 13, ${adjustedYear}`,
    "Pac-12 Conference Tournament": `3:00 pm GMT-0400 March 13, ${adjustedYear}`,
    "Southeastern Conference Tourney": `7:00 pm GMT-0400 March 14, ${adjustedYear}`,
  }
}

function championshipGameName(sportType: TSportType) {
  // these values match the Primpy values: we need them to help identify specific games
  if (sportType === GameSportType.values.NCAAF.value) {
    return "NCAAF Championship"
  }
  if (sportType === GameSportType.values.NFL.value) {
    return `NFL Champion`
  }
  return `Tournament Winner`
}

const NcaaTournament = {
  _id: 1,
  name: "NCAA Tournament",
}
const NcaawTournament = {
  _id: 53,
  name: "Women's NCAA Tournament",
}

const NcaabConfTournaments = NcaabConfTournamentNames.map((name) => Tournaments.find((tournament) => tournament.name === name)!)
const AvailableIds = NcaabConfTournaments.map(({ _id }) => _id)
const defaultNcaaTournamentRoundBonuses = [1, 2, 4, 8, 16, 32]
const defaultNcaaTournamentSweetSixteenRoundBonuses = [4, 8, 16, 32] // NOTE/S16 TODO LL: Confirm with product this scores like this

function getMatchupSportYearFor(seasonDbRow) {
  // NOTE qac: we have to decide between whether we use simSportYear or sportYear for Matchup... touches a bunch of places, so lets centralize that here in case we need to change it
  const sportYear = seasonDbRow.sportYear
  // const extra = getExtraColumn(segmentDbRow)
  // const sportYear = extra.simulateYear || seasonDbRow.sportYear
  return sportYear
}

function getGroupPosition(
  roundOrdinal: number,
  tournamentRound: number,
  matchupsPerRound: number,
  maxRound: number,
  isPlayin: boolean,
): ITournamentMatchup["groupPosition"] {
  const groupI = Math.floor(roundOrdinal / (matchupsPerRound / 4))
  const isAboveSemis = tournamentRound >= maxRound - 1
  const groupPosition =
    (isPlayin && "top") ||
    (isAboveSemis && "bottom") ||
    (groupI === 0 && "top_left") ||
    (groupI === 1 && "bottom_left") ||
    (groupI === 2 && "top_right") ||
    (groupI === 3 && "bottom_right") ||
    null
  if (!groupPosition) {
    throw new Error(`no groupPosition for ${roundOrdinal} ${tournamentRound}`)
  }
  return groupPosition
}

const intoTop = "top" as TPlaysInto
const intoBot = "bottom" as TPlaysInto
function buildNcaaTournamentMatchups(sportYear: number, isWomenTournament = false) {
  // TODO qac: i know there is a better way to do this mathmatically...
  const seedMapping = [1, 16, 8, 9, 5, 12, 4, 13, 6, 11, 3, 14, 7, 10, 2, 15]
  const matchups = [] as ITournamentMatchup[]
  const tournamentId = isWomenTournament ? NcaawTournament._id : NcaaTournament._id
  const tournamentDescription = isWomenTournament ? NcaawTournament.name : NcaaTournament.name
  let tournamentRound = 1
  const totalRound2Matchups = 8 * 4
  while (tournamentRound < 8) {
    const adjRoundI = Math.max(0, tournamentRound - 2) // 0 - 6
    const isPlayin = tournamentRound === 1
    const reduceBy = Math.pow(2, adjRoundI)
    const matchupsPerRound = isPlayin ? 4 : totalRound2Matchups / reduceBy
    let roundOrdinal = 0
    while (roundOrdinal < matchupsPerRound) {
      const topItemSeed = tournamentRound === 2 ? seedMapping[(roundOrdinal % 8) * 2 + 0] : undefined
      const bottomItemSeed = tournamentRound === 2 ? seedMapping[(roundOrdinal % 8) * 2 + 1] : undefined
      const winnerPlaysIntoOrdinal = tournamentRound > 1 && tournamentRound < 7 ? Math.floor(roundOrdinal / 2) : undefined
      const winnerPlaysIntoPosition = winnerPlaysIntoOrdinal === undefined ? undefined : roundOrdinal % 2 === 1 ? intoBot : intoTop
      const groupPosition = getGroupPosition(roundOrdinal, tournamentRound, matchupsPerRound, 7, isPlayin)
      const groupName = (groupPosition === "top" && "First Four") || (groupPosition === "bottom" && "Final Four") || null
      // console.log(`creating: ${totalRound2Matchups}:${tournamentRound}:${matchupsPerRound}:${roundOrdinal}:${reduceBy}`)
      matchups.push({
        isPlayin,
        tournamentId,
        tournamentDescription,
        tournamentRound,
        roundOrdinal,
        winnerPlaysIntoOrdinal,
        winnerPlaysIntoPosition,
        topItemSeed,
        bottomItemSeed,
        groupPosition,
        groupName,
        sportYear,
        subsection: tournamentRound >= 4 ? "NcaaTournamentSweetSixteen" : null,
      })
      roundOrdinal = roundOrdinal + 1
    }
    tournamentRound = tournamentRound + 1
  }
  return matchups
}
function buildNcaabConferenceMatchups(sportYear: number, tournamentId?: number) {
  const matchups = [] as ITournamentMatchup[]
  const groupPosition = getGroupPosition(0, 1, 16, 7, true)
  const groupName = (groupPosition === "bottom" && "Championship") || null
  const tournamentsToBuild = tournamentId ? NcaabConfTournaments.filter(({ _id }) => _id === tournamentId) : NcaabConfTournaments

  tournamentsToBuild.forEach(({ _id, name }) => {
    const defaultAttrs = {
      tournamentId: _id,
      tournamentDescription: name,
      groupPosition,
      groupName,
      sportYear,
    }
    // -------------------------------
    // first round
    // -------------------------------
    // https://en.wikipedia.org/wiki/2021_ACC_Men%27s_Basketball_Tournament#Schedule
    if (name === "Atlantic Coast Conference Tourney") {
      // NOTE qac: https://nsjonline.com/article/2019/09/georgia-tech-postseason-ban-forces-change-to-acc-tourney-format/
      const hasDQedTeam = sportYear === 2019
      if (hasDQedTeam) {
        ;[0, 1].forEach((i) => {
          const round = 1
          const topItemSeed = (i === 0 && 12) || (i === 1 && 11) || undefined
          const bottomItemSeed = (i === 0 && 13) || (i === 1 && 14) || undefined
          matchups.push(
            Object.assign(
              {
                tournamentRound: round,
                roundOrdinal: i,
                winnerPlaysIntoOrdinal: i === 0 ? 0 : 2,
                winnerPlaysIntoPosition: intoBot,
                topItemSeed,
                bottomItemSeed,
              },
              defaultAttrs,
            ),
          )
        })
        ;[0, 1, 2, 3].forEach((i) => {
          const round = 2
          const topItemSeed = (i === 1 && 8) || (i === 0 && 5) || (i === 3 && 7) || (i == 2 && 6) || undefined
          const bottomItemSeed = (i === 1 && 9) || (i === 3 && 10) || undefined
          const matchup = Object.assign(
            {
              tournamentRound: round,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: i,
              winnerPlaysIntoPosition: intoBot,
              topItemSeed,
              bottomItemSeed,
            },
            defaultAttrs,
          )
          matchups.push(matchup)
        })
        ;[0, 1, 2, 3].forEach((i) => {
          const round = 3
          const topItemSeed = (i === 0 && 4) || (i === 1 && 1) || (i == 3 && 2) || (i == 2 && 3) || undefined
          const bottomItemSeed = undefined
          matchups.push(
            Object.assign(
              {
                tournamentRound: round,
                roundOrdinal: i,
                winnerPlaysIntoOrdinal: Math.floor(i / 2),
                winnerPlaysIntoPosition: ((i === 0 || i === 2) && intoTop) || ((i === 1 || i === 3) && intoBot) || undefined,
                topItemSeed,
                bottomItemSeed,
              },
              defaultAttrs,
            ),
          )
        })
      } else {
        ;[0, 1, 2].forEach((i) => {
          const round = 1
          const topItemSeed = (i === 0 && 12) || (i === 1 && 10) || (i === 2 && 11) || undefined
          const bottomItemSeed = (i === 0 && 13) || (i === 1 && 15) || (i === 2 && 14) || undefined
          matchups.push(
            Object.assign(
              {
                tournamentRound: round,
                roundOrdinal: i,
                winnerPlaysIntoOrdinal: i === 0 ? 0 : i + 1,
                winnerPlaysIntoPosition: intoBot,
                topItemSeed,
                bottomItemSeed,
              },
              defaultAttrs,
            ),
          )
        })
        ;[0, 1, 2, 3].forEach((i) => {
          const round = 2
          const topItemSeed = (i === 1 && 8) || (i === 0 && 5) || (i === 2 && 7) || (i == 3 && 6) || undefined
          const bottomItemSeed = (i === 1 && 9) || undefined
          const matchup = Object.assign(
            {
              tournamentRound: round,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: i,
              winnerPlaysIntoPosition: intoBot,
              topItemSeed,
              bottomItemSeed,
            },
            defaultAttrs,
          )
          matchups.push(matchup)
        })
        ;[0, 1, 2, 3].forEach((i) => {
          const round = 3
          const topItemSeed = (i === 0 && 4) || (i === 1 && 1) || (i == 2 && 2) || (i == 3 && 3) || undefined
          const bottomItemSeed = undefined
          matchups.push(
            Object.assign(
              {
                tournamentRound: round,
                roundOrdinal: i,
                winnerPlaysIntoOrdinal: Math.floor(i / 2),
                winnerPlaysIntoPosition: ((i === 0 || i === 2) && intoTop) || ((i === 1 || i === 3) && intoBot) || undefined,
                topItemSeed,
                bottomItemSeed,
              },
              defaultAttrs,
            ),
          )
        })
      }

      ;[0, 1].forEach((i) => {
        matchups.push(
          Object.assign(
            {
              tournamentRound: 4,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: 0,
              winnerPlaysIntoPosition: (i === 0 && intoTop) || (i === 1 && intoBot) || undefined,
            },
            defaultAttrs,
          ),
        )
      })
      matchups.push(
        Object.assign(
          {
            tournamentRound: 5,
            roundOrdinal: 0,
          },
          defaultAttrs,
        ),
      )
    }
    // These two are the same
    // https://en.wikipedia.org/wiki/2021_SEC_Men%27s_Basketball_Tournament#Schedule
    // https://en.wikipedia.org/wiki/2021_Big_Ten_Conference_Men%27s_Basketball_Tournament#Schedule
    if (name === "Southeastern Conference Tourney" || name === "Big Ten Tourney") {
      // NOTE qac: https://en.wikipedia.org/wiki/2021_SEC_Men%27s_Basketball_Tournament#Seeds
      const hasDQedTeam = sportYear === 2020 && name === "Southeastern Conference Tourney"
      const firstRoundSlots = hasDQedTeam ? [0] : [0, 1]
      firstRoundSlots.forEach((i) => {
        const round = 1
        const topItemSeed = (i === 0 && 12) || (i === 1 && 11) || undefined
        const bottomItemSeed = (i === 0 && 13) || (i === 1 && 14) || undefined
        matchups.push(
          Object.assign(
            {
              tournamentRound: round,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: i * 2,
              winnerPlaysIntoPosition: intoBot,
              topItemSeed,
              bottomItemSeed,
            },
            defaultAttrs,
          ),
        )
      })
      ;[0, 1, 2, 3].forEach((i) => {
        const round = 2
        const topItemSeed = (i === 1 && 8) || (i === 0 && 5) || (i === 3 && 7) || (i === 2 && 6) || undefined
        const bottomItemSeed = (i === 1 && 9) || (i === 3 && 10) || (hasDQedTeam && i === 2 && 11) || undefined
        const matchup = Object.assign(
          {
            tournamentRound: round,
            roundOrdinal: i,
            winnerPlaysIntoOrdinal: i,
            winnerPlaysIntoPosition: intoBot,
            topItemSeed,
            bottomItemSeed,
          },
          defaultAttrs,
        )
        matchups.push(matchup)
      })
      ;[0, 1, 2, 3].forEach((i) => {
        const round = 3
        const isSEC = true
        // NOTE qac: SEC only has a flip?
        const topItemSeed = (i === 0 && 4) || (i === 1 && 1) || (i === 2 && (isSEC ? 3 : 2)) || (i === 3 && (isSEC ? 2 : 3)) || undefined
        const bottomItemSeed = undefined
        matchups.push(
          Object.assign(
            {
              tournamentRound: round,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: Math.floor(i / 2),
              winnerPlaysIntoPosition: ((i === 0 || i === 2) && intoTop) || ((i === 1 || i === 3) && intoBot) || undefined,
              topItemSeed,
              bottomItemSeed,
            },
            defaultAttrs,
          ),
        )
      })
      ;[0, 1].forEach((i) => {
        matchups.push(
          Object.assign(
            {
              tournamentRound: 4,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: 0,
              winnerPlaysIntoPosition: (i === 0 && intoTop) || (i === 1 && intoBot) || undefined,
            },
            defaultAttrs,
          ),
        )
      })
      matchups.push(
        Object.assign(
          {
            tournamentRound: 5,
            roundOrdinal: 0,
          },
          defaultAttrs,
        ),
      )
    }
    // https://en.wikipedia.org/wiki/2021_Big_12_Men%27s_Basketball_Tournament#Bracket
    // https://en.wikipedia.org/wiki/2018_Big_East_Men%27s_Basketball_Tournament#Bracket
    if (name === "Big 12 Conference Tourney" || name === "Big East Conference Tourney") {
      // NOTE qac: big east added 1 more team in 2021 tourney
      const hasAddedTeam = sportYear >= 2020 && name === "Big East Conference Tourney"
      const firstRoundSlots = hasAddedTeam ? [0, 1, 2] : [0, 1]
      firstRoundSlots.forEach((i) => {
        const round = 1
        const topItemSeed = (i === 0 && 8) || (i === 1 && 7) || (i === 2 && 6) || undefined
        const bottomItemSeed = (i === 0 && 9) || (i === 1 && 10) || (i === 2 && 11) || undefined
        matchups.push(
          Object.assign(
            {
              tournamentRound: round,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: (i === 2 && 3) || i * 2,
              winnerPlaysIntoPosition: intoBot,
              topItemSeed,
              bottomItemSeed,
            },
            defaultAttrs,
          ),
        )
      })
      ;[0, 1, 2, 3].forEach((i) => {
        const round = 2
        const topItemSeed = (i === 0 && 1) || (i === 1 && 4) || (i === 2 && 2) || (i === 3 && 3) || undefined
        const bottomItemSeed = (i === 1 && 5) || (!hasAddedTeam && i === 3 && 6) || undefined
        const matchup = Object.assign(
          {
            tournamentRound: round,
            roundOrdinal: i,
            winnerPlaysIntoOrdinal: ((i === 0 || i === 1) && 0) || ((i === 2 || i === 3) && 1) || 0,
            winnerPlaysIntoPosition: ((i === 0 || i === 2) && intoTop) || ((i === 1 || i === 3) && intoBot) || undefined,
            topItemSeed,
            bottomItemSeed,
          },
          defaultAttrs,
        )
        matchups.push(matchup)
      })
      ;[0, 1].forEach((i) => {
        matchups.push(
          Object.assign(
            {
              tournamentRound: 3,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: 0,
              winnerPlaysIntoPosition: (i === 0 && intoTop) || (i === 1 && intoBot) || undefined,
            },
            defaultAttrs,
          ),
        )
      })
      matchups.push(
        Object.assign(
          {
            tournamentRound: 4,
            roundOrdinal: 0,
          },
          defaultAttrs,
        ),
      )
    }

    // https://en.wikipedia.org/wiki/2021_Pac-12_Conference_Men%27s_Basketball_Tournament#Bracket
    if (name === "Pac-12 Conference Tournament") {
      // NOTE qac: https://en.wikipedia.org/wiki/2021_Pac-12_Conference_Men%27s_Basketball_Tournament#Seeds
      const hasDQedTeam = sportYear === 2020
      const firstRoundSlots = hasDQedTeam ? [0, 1, 2] : [0, 1, 2, 3]
      const dqedOffset = hasDQedTeam ? 1 : 0
      firstRoundSlots.forEach((i) => {
        const topItemSeed = (i === 0 && 8) || (i === 1 - dqedOffset && 5) || (i === 2 - dqedOffset && 7) || (i === 3 - dqedOffset && 6) || undefined
        const bottomItemSeed =
          (i === 0 && 9) || (i === 1 - dqedOffset && 12) || (i === 2 - dqedOffset && 10) || (i === 3 - dqedOffset && 11) || undefined
        matchups.push(
          Object.assign(
            {
              tournamentRound: 1,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: hasDQedTeam && i > 0 ? i + 1 : i,
              winnerPlaysIntoPosition: intoBot,
              topItemSeed,
              bottomItemSeed,
            },
            defaultAttrs,
          ),
        )
      })
      ;[0, 1, 2, 3].forEach((i) => {
        const topItemSeed = (i === 0 && 1) || (i === 1 && 4) || (i === 2 && 2) || (i === 3 && 3) || undefined
        const bottomItemSeed = (hasDQedTeam && i === 1 && 5) || undefined
        matchups.push(
          Object.assign(
            {
              tournamentRound: 2,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: Math.floor(i / 2),
              winnerPlaysIntoPosition: ((i === 0 || i === 2) && intoTop) || ((i === 1 || i === 3) && intoBot) || undefined,
              topItemSeed,
              bottomItemSeed,
            },
            defaultAttrs,
          ),
        )
      })
      ;[0, 1].forEach((i) => {
        matchups.push(
          Object.assign(
            {
              tournamentRound: 3,
              roundOrdinal: i,
              winnerPlaysIntoOrdinal: 0,
              winnerPlaysIntoPosition: (i === 0 && intoTop) || (i === 1 && intoBot) || undefined,
            },
            defaultAttrs,
          ),
        )
      })
      matchups.push(
        Object.assign(
          {
            tournamentRound: 4,
            roundOrdinal: 0,
          },
          defaultAttrs,
        ),
      )
    }
    // -------------------------------
    // second round
    // -------------------------------
  })
  // clean null attrs (they fail on insert)
  const propsToRemove = ["bottomItemSeed", "topItemSeed"]
  for (const matchup of matchups) {
    for (const attr of propsToRemove) {
      if (matchup.hasOwnProperty(attr) && /object|undefined/.test(typeof matchup[attr])) {
        delete matchup[attr]
      }
    }
  }
  return matchups
}

export {
  NcaabConfTournaments,
  TournamentAbbrevs,
  Tournaments,
  AvailableIds,
  defaultNcaaTournamentRoundBonuses,
  defaultNcaaTournamentSweetSixteenRoundBonuses,
  buildNcaabConferenceMatchups,
  buildTournamentStartTimesFor,
  championshipGameName,
  NcaaTournament,
  NcaawTournament,
  buildNcaaTournamentMatchups,
  getMatchupSportYearFor,
}
