import React from "react"
import styled from "styled-components/macro"
import NavContainer from "../Navs/NavContainer"
import { breakpoints } from "../../../utils/style-utils"

const Styles = styled.div`
  overflow: hidden;
  width: 100%;

  &.multiple-entries--true {
    & > .nav-container {
      height: 1.6875rem;
    }
  }

  @media (max-width: ${breakpoints.sm - 1}px) {
    &.multiple-entries--true {
      display: none;
    }
  }
`

const PageTabsNav: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>> = ({ ref, children, ...rest }) => {
  return (
    <Styles {...rest}>
      <NavContainer as="nav" className="nav-container">
        {children}
      </NavContainer>
    </Styles>
  )
}

export default PageTabsNav
