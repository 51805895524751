import React from "react"

const PickEmArenaSvg = () => {
  return (
    <svg width="259" height="48" viewBox="0 0 259 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.475586 46.33H9.92559V31.84H19.0606C28.5106 31.84 34.8106 25.54 34.8106 17.035C34.8106 8.52997 28.5106 2.22997 19.0606 2.22997H0.475586V46.33ZM9.92559 23.02V11.05H18.7456C22.5256 11.05 25.0456 13.57 25.0456 17.035C25.0456 20.5 22.5256 23.02 18.7456 23.02H9.92559Z"
        fill="white"
      />
      <path d="M41.4059 46.33H50.8559V2.22997H41.4059V46.33Z" fill="white" />
      <path
        d="M81.4158 47.275C94.0158 47.275 100.316 38.455 100.316 38.455L94.0158 32.155C94.0158 32.155 89.6058 38.455 81.4158 38.455C73.5408 38.455 67.2408 32.155 67.2408 24.28C67.2408 16.405 73.5408 10.105 81.4158 10.105C89.2908 10.105 93.7008 16.405 93.7008 16.405L100.001 10.105C100.001 10.105 93.7008 1.28497 81.4158 1.28497C67.8708 1.28497 57.4758 11.68 57.4758 24.28C57.4758 36.88 67.8708 47.275 81.4158 47.275Z"
        fill="white"
      />
      <path
        d="M105.363 46.33H114.813V33.415L120.483 27.43L133.398 46.33H144.423L126.783 20.5L143.163 2.22997H131.823L114.813 21.13V2.22997H105.363V46.33Z"
        fill="white"
      />
      <path d="M159.851 18.61L166.466 3.80497L161.426 0.969971L152.606 14.515L159.851 18.61Z" fill="white" />
      <path d="M171.491 46.33H204.881V37.51H180.941V28.06H199.211V19.555H180.941V11.05H204.251V2.22997H171.491V46.33Z" fill="white" />
      <path
        d="M211.499 46.33H220.949V17.35L235.124 34.99L249.299 17.35V46.33H258.749V2.22997H250.244L235.124 21.13L220.004 2.22997H211.499V46.33Z"
        fill="white"
      />
    </svg>
  )
}

export default PickEmArenaSvg
