import { newPalette as basePalette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const palette = Object.assign({}, basePalette, {
  // standard ui
  uiBlue1: basePalette.brandPrimaryBlue,
  uiSubTextLink: basePalette.neutral95,
  uiNavNetworkBlue: basePalette.blue70, // network brand bar
  uiNavBlue1: basePalette.brandPrimaryBlue,
  uiNavLinkHighlighted: basePalette.white,
  uiNavLinkHover: basePalette.brandNavLinkHover,
  buttonBlue1: basePalette.blue60, // $button-primary-bg: $blue-60

  formError: basePalette.red60,

  matchupAnalysisGrey: basePalette.neutral0, // $surface-tertiary: $neutral-0

  injuryRed: basePalette.red60, // $text-negative: $red-60

  cardBorderGray: basePalette.neutral10, // $border: $neutral-10
  poolPageBorder: basePalette.neutral10,

  uiSubText1: basePalette.neutral60, // $text-tertiary: $neutral-60
  uiSubText2: basePalette.neutral0, // $surface-tertiary: $neutral-0
  uiSubText3: basePalette.neutral5, // $surface-secondary: $neutral-5
  uiSubText4: basePalette.neutral60, // $text-tertiary: $neutral-60
  uiSubText5: basePalette.neutral70, // text-secondary: $neutral-70

  default: basePalette.neutral95,

  gray1: basePalette.neutral70, // $text-secondary, $table-header-text: $neutral-70
  gray2: basePalette.neutral60, // $text-tertiary, $text-unselected: $neutral-60
  gray3: basePalette.neutral10, // $border: $neutral-10
  gray4: basePalette.neutral0, // $ui-bg-disabled: $neutral-0
  gray5: basePalette.neutral95, // $text-primary: $neutral-95
  gray6: basePalette.neutral5, // $surface-secondary: $neutral-5

  blue1: basePalette.brandPrimaryBlue, // $text-link-secondary, $button-primary-bg: $blue-60
  blue2: basePalette.blue70, // $ui-border-hover, $button-primary-bg-hover: $blue-70
  blue3: basePalette.blue20, // $blue-20
  blue4: basePalette.blue5, // $ui-bg-hover: $blue-5
  blueActive: basePalette.blue70, // $button-primary-bg-hover: $blue-70
  unknownButtonActiveBlue: basePalette.brandPrimaryBlue, // $button-primary-bg: $blue-60

  green: basePalette.green70, // $button-promo-primary-bg, $graphic-positive: $green-70
  green2: basePalette.green80, // $text-positive: $green-80

  red: basePalette.red60,

  orange: basePalette.orange50,

  yellow: basePalette.yellow50,

  grayUnknownText: basePalette.neutral70, // $text-secondary: $neutral-70

  // made up
  logoOrange: basePalette.orange50, // $orange-50
  bannerTopInfoTeal: "#00bcd4", // Design System: Keep as is for now but it is out of the Design System colors.

  buttonOrange1: basePalette.orange50,
  buttonOutlineYellow1: basePalette.yellow50,

  // OG
  ncaabConfPrimary: "#AE9041",
  ncaabConfSecondary: "#CDD8E1",
  ncaabConfTersery: "#E8EDF0",
  ncaafPrimary: basePalette.yellow50, // $yellow-50
  ncaafSecondary: basePalette.yellow60, // $gold: $yellow-60
  ncaafTersery: "#e6e7e8",
  nflParlayPrimary: "#1699DC",
  nflParlaySecondary: "#FF4C00",
  nflParlayTersery: "#0399DC",
  nflParlaySoloStopColor: "#040814",
  ncaafPostPrimary: "#59B9EB",
  ncaafPostSecondary: "#F7B010",
  ncaafPostTersery: "#1e7bd6",
  nflPostPrimary: "#AE9041",
  nflPostSecondary: "#BFCE97",
  nflPostTersery: "#E0E8CD",
  ncaabTournamentPrimary: "#ff8533",
  ncaabTournamentSecondary: "#0077d4",
  ncaabTournamentTersery: "#f5faff",

  // game palettes
  defaultDarkBgColor: basePalette.neutral100, // $surface-primary-dark: $neutral-100
  defaultLightBgColor: basePalette.neutral5, // $surface-secondary: $neutral-5
  ncaafPostSoloBgColor: "#012adb",
  lightBgGradiant: "#80a7b3",
  lightBgGradiantStopColor: "#bfbfbf",
})

export default palette
