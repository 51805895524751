import { layoutIds } from "./constants"
import palette from "./palette"
import { getThemeValues } from "./theme-helpers"

export interface IGameOg {
  uidPrefix: string
  metaPathPrefix: string
  frontendPathPrefix: string
  primaryColor: string
  secondaryColor: string
  themeColor: string
  bgColor: string
  ogImgW: string
  ogImgH: string
  name: string
  emailDesktopHeaderImageUrl: string | null
  emailMobileHeaderImageUrl: string | null
  seasonType: string | null
  ogVideoAssetName?: string
  ogImageAssetName: string
  ogVideoW?: string
  ogVideoH?: string
  sportType?: string
}

const defaultTheme = getThemeValues({ id: layoutIds.default })
const nflPickemTheme = getThemeValues({ id: layoutIds["football-pickem"] })
export const ogImgW = "2048"
export const ogImgH = "1170"

const NcaabConfBracket = {
  uidPrefix: "cbs-ncaab-confbracket",
  metaPathPrefix: "/static/game-assets/cbs-ncaab-confbracket",
  frontendPathPrefix: "college-basketball/conference-tournament/bracket",
  // primaryColor: "#ff8900",
  // secondaryColor: "#ffba00",
  // themeColor: "#ff8900",
  primaryColor: palette.ncaabConfPrimary,
  secondaryColor: palette.ncaabConfSecondary,
  themeColor: palette.ncaabConfTersery,
  bgColor: palette.ncaabConfTersery,
  ogImgW: `${1200 * 3}`,
  ogImgH: `${1200 * 3}`,
  name: "CBS NCAA Conference Tournament Brackets",
  emailMobileHeaderImageUrl: "/750x300_2X.jpg",
  emailDesktopHeaderImageUrl: "/1200x300_2X.jpg",
  ogImageAssetName: "/og-image.jpg",
  seasonType: "post",
} as IGameOg
const NcaabTournamentBracket = {
  uidPrefix: "cbs-ncaab-tournament",
  metaPathPrefix: "/static/game-assets/cbs-ncaab-tournament",
  frontendPathPrefix: "college-basketball/ncaa-tournament/bracket",
  primaryColor: palette.ncaabTournamentPrimary,
  secondaryColor: palette.ncaabTournamentSecondary,
  themeColor: palette.ncaabTournamentTersery,
  bgColor: palette.ncaabTournamentTersery,
  ogImgW,
  ogImgH,
  name: "CBS NCAAB Brackets",
  ogImageAssetName: "/og-image.jpg",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/2024_bracketgamesheaderimgdesktop_2.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/2024_bracketgamesheaderimgmobile_2.png",
  seasonType: "post",
} as IGameOg
const NcaawTournamentBracket = {
  uidPrefix: "cbs-ncaaw-tournament",
  metaPathPrefix: "/static/game-assets/cbs-ncaaw-tournament",
  frontendPathPrefix: "college-basketball/ncaaw-tournament/bracket",
  primaryColor: palette.ncaabTournamentPrimary,
  secondaryColor: palette.ncaabTournamentSecondary,
  themeColor: palette.ncaabTournamentTersery,
  bgColor: palette.ncaabTournamentTersery,
  ogImgW,
  ogImgH,
  name: "CBS NCAAW Brackets",
  ogImageAssetName: "/og-image.jpg",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/2024_bracketgamesheaderimgdesktop_2.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/2024_bracketgamesheaderimgmobile_2.png",
  seasonType: "post",
} as IGameOg
const NissanNcaabTournamentBracket = {
  uidPrefix: "nissan-ncaab-tournament",
  metaPathPrefix: "/static/game-assets/cbs-ncaab-tournament",
  frontendPathPrefix: "college-basketball/ncaa-tournament/bracket",
  primaryColor: palette.ncaabTournamentPrimary,
  secondaryColor: palette.ncaabTournamentSecondary,
  themeColor: palette.ncaabTournamentTersery,
  bgColor: palette.ncaabTournamentTersery,
  ogImgW,
  ogImageAssetName: "/og-image.jpg",
  ogImgH,
  name: "CBS NCAAB Brackets",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/2024_bracketgamesheaderimgdesktop_2-nissan.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/2024_bracketgamesheaderimgmobile_2-nissan.png",
  seasonType: "post",
} as IGameOg
const NissanNcaawTournamentBracket = {
  uidPrefix: "nissan-ncaaw-tournament",
  metaPathPrefix: "/static/game-assets/cbs-ncaaw-tournament",
  frontendPathPrefix: "college-basketball/ncaaw-tournament/bracket",
  primaryColor: palette.ncaabTournamentPrimary,
  secondaryColor: palette.ncaabTournamentSecondary,
  themeColor: palette.ncaabTournamentTersery,
  bgColor: palette.ncaabTournamentTersery,
  ogImgW,
  ogImageAssetName: "/og-image.jpg",
  ogImgH,
  name: "CBS NCAAW Brackets",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/2024_bracketgamesheaderimgdesktop_2-nissan.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/2024_bracketgamesheaderimgmobile_2-nissan.png",
  seasonType: "post",
} as IGameOg
const SweetSixteenNcaabTournamentBracket = {
  uidPrefix: "cbs-ncaab-tournament-sweet-sixteen",
  metaPathPrefix: "/static/game-assets/cbs-ncaab-tournament",
  frontendPathPrefix: "college-basketball/ncaa-tournament/bracket",
  primaryColor: palette.ncaabTournamentPrimary,
  secondaryColor: palette.ncaabTournamentSecondary,
  themeColor: palette.ncaabTournamentTersery,
  bgColor: palette.ncaabTournamentTersery,
  ogImgW,
  ogImgH,
  ogImageAssetName: "/og-image.jpg",
  name: "CBS NCAA Brackets",
  emailDesktopHeaderImageUrl: "/emailDesktopHeaderImageUrl.jpg",
  emailMobileHeaderImageUrl: "/emailMobileHeaderImageUrl.jpg",
  seasonType: "post",
} as IGameOg
const NflPlayoffPickem = {
  uidPrefix: "cbs-nfl-pickem",
  metaPathPrefix: "/static/game-assets/nfl-pickem-post",
  frontendPathPrefix: "football/pickem",
  primaryColor: palette.nflPostPrimary,
  secondaryColor: palette.nflPostSecondary,
  themeColor: palette.nflPostTersery,
  bgColor: palette.nflPostTersery,
  ogImgW: `${1200 * 4}`,
  ogImgH: `${630 * 4}`,
  ogImageAssetName: "/og-image.jpg",
  name: "CBS NFL Playoff Pickem",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/201217_playoffpickemheaderimgdesktop.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/201217_playoffpickemheaderimgmobile.png",
  seasonType: "post",
} as IGameOg
const NflPickem = {
  uidPrefix: "cbs-nfl-pickem",
  metaPathPrefix: "/static/game-assets/nfl-pickem",
  frontendPathPrefix: "football/pickem",
  primaryColor: nflPickemTheme.primary,
  secondaryColor: nflPickemTheme.secondary,
  themeColor: nflPickemTheme.primaryShadow,
  bgColor: nflPickemTheme.lightBgColor,
  ogImgW: "1400",
  ogImgH: "788",
  ogImageAssetName: "/og-image.jpg",
  name: "CBS Football Pickem",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/200727_footballpickemheaderdesktop.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/200727_footballpickemheadermobile.png",
  seasonType: "regular",
} as IGameOg
const NflParlay = {
  uidPrefix: "cbs-nfl-parlay",
  metaPathPrefix: "/static/game-assets/nfl-parlay",
  frontendPathPrefix: "football/parlay",
  primaryColor: palette.nflParlayPrimary,
  secondaryColor: palette.nflParlaySecondary,
  themeColor: palette.nflParlayTersery,
  bgColor: palette.nflParlayTersery,
  ogImgW: "2400",
  ogImgH: "1256",
  ogImageAssetName: "/og-image.jpg",
  name: "CBS Parlay Pick'em",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/200806_parlaypickemheaderimgdesktop.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/200806_parlaypickemheaderimgmobile.png",
  // ogVideoAssetName: "splash-video.mp4",
  // ogVideoW: `${560}`,
  // ogVideoH: `${841}`,
  seasonType: "regular",
} as IGameOg
const NcaafPickem = {
  uidPrefix: "cbs-ncaaf-pickem",
  metaPathPrefix: "/static/game-assets/ncaaf-pickem",
  frontendPathPrefix: "college-football/pickem",
  primaryColor: palette.ncaafPrimary,
  secondaryColor: palette.ncaafSecondary,
  themeColor: palette.ncaafTersery,
  bgColor: palette.ncaafTersery,
  ogImgW: `${1200 * 2}`,
  ogImgH: `${630 * 2}`,
  ogImageAssetName: "/og-image.jpg",
  name: "CBS College Football Pick'em",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/210715_cfbpickemheaderimgdesktopFIXED.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/210715_cfbpickemheaderimgmobileFIXED.png",
  seasonType: "regular",
} as IGameOg
const NcaafBowlPickem = {
  uidPrefix: "cbs-ncaaf-pickem",
  metaPathPrefix: "/static/game-assets/ncaaf-bowl-pickem",
  frontendPathPrefix: "college-football/pickem",
  primaryColor: palette.ncaafPostPrimary,
  secondaryColor: palette.ncaafPostSecondary,
  themeColor: palette.ncaafPostTersery,
  bgColor: palette.ncaafPostTersery,
  ogImgW: `${1200 * 2}`,
  ogImgH: `${630 * 2}`,
  ogImageAssetName: "/og-image.jpg",
  name: "CBS College Bowl Pick'em",
  emailDesktopHeaderImageUrl: `https://www.cbssports.com/images/email/graphics/191126_bowlpickemheaderimgdesktop.png`, // "/700x150.jpg", //
  emailMobileHeaderImageUrl: "https://www.cbssports.com/images/email/graphics/191126_bowlpickemheaderimgmobile.png", // "/600x150.jpg", //
  seasonType: "post",
} as IGameOg
const defaultOg = {
  uidPrefix: "",
  metaPathPrefix: "",
  frontendPathPrefix: "",
  primaryColor: defaultTheme.primary,
  secondaryColor: defaultTheme.secondary,
  themeColor: defaultTheme.primaryShadow,
  bgColor: palette.defaultLightBgColor,
  ogImgW: "720",
  ogImgH: "365",
  name: "CBS Sport Fantasy Games",
  emailDesktopHeaderImageUrl: null,
  emailMobileHeaderImageUrl: null,
  ogImageAssetName: "/og-image.jpg",
  seasonType: null,
} as IGameOg
const OPMFootballPickem = {
  uidPrefix: "cbs-football-pickem",
  metaPathPrefix: "/static/game-assets/nfl-pickem",
  frontendPathPrefix: "football/pickem",
  primaryColor: nflPickemTheme.primary,
  secondaryColor: nflPickemTheme.secondary,
  themeColor: nflPickemTheme.primaryShadow,
  bgColor: nflPickemTheme.lightBgColor,
  ogImgW: "1400",
  ogImgH: "788",
  ogImageAssetName: "/og-image.jpg",
  name: "CBS Football Pickem",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/200727_footballpickemheaderdesktop.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/200727_footballpickemheadermobile.png",
  seasonType: "regular",
} as IGameOg
const OPMSurvivorPickem = {
  uidPrefix: "cbs-nfl-survivor",
  metaPathPrefix: "/static/game-assets/nfl-pickem",
  frontendPathPrefix: "football/survivor",
  primaryColor: nflPickemTheme.primary,
  secondaryColor: nflPickemTheme.secondary,
  themeColor: nflPickemTheme.primaryShadow,
  bgColor: nflPickemTheme.lightBgColor,
  ogImgW: "1400",
  ogImgH: "788",
  ogImageAssetName: "/og-image.jpg",
  name: "CBS Football Survivor",
  emailDesktopHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/200727_footballpickemheaderdesktop.png",
  emailMobileHeaderImageUrl: "https://sports.cbsimg.net/images/email/graphics/200727_footballpickemheadermobile.png",
  seasonType: "regular",
} as IGameOg

// const Og = {
//   'college-basketball/conference-tournament/bracket': NcaabConfBracket,
//   'football/pickem': NflPlayoffPickem,
// }
const Og = [
  defaultOg,
  NcaabConfBracket,
  NflPlayoffPickem,
  NflParlay,
  NcaafPickem,
  NcaafBowlPickem,
  NflPickem,
  NcaabTournamentBracket,
  NcaawTournamentBracket,
  NissanNcaabTournamentBracket,
  SweetSixteenNcaabTournamentBracket,
  NissanNcaawTournamentBracket,
  OPMFootballPickem,
  OPMSurvivorPickem,
] as IGameOg[]

export default Og
