import styled from "styled-components/macro"
import { HorizontalOverflowContainer } from "../../../components/OverflowContainer"
import { gridBreakPoints, fontSizes, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const { breakpoints } = gridBreakPoints

// NOTE qac: darn looks like on devices a black line is drawn if no overflow... so lets just use a breakpoint below:
const NavContainer = styled(HorizontalOverflowContainer)`
  white-space: nowrap;
  overflow-y: hidden;
  font-size: ${fontSizes.regular};
  @media (max-width: ${pxToRem(breakpoints.sm)}) {
    & {
      padding: 0 ${pxToRem(16)};
      padding: 0 var(--grid-gap);
    }
  }
  @media (min-width: ${pxToRem(breakpoints.sm)}) {
    & {
      background-image: none;
    }
  }
`
export default NavContainer
