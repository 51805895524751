import * as React from "react"

const GroupSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 5H5V3c0-.6-.4-1-1-1s-1 .4-1 1v2H1c-.6 0-1 .4-1 1s.4 1 1 1h2v2c0 .6.5 1 1 1s1-.4 1-1V7h2c.6 0 1-.4 1-1s-.4-1-1-1zm11 1c1.7 0 3-1.3 3-3s-1.3-3-3-3c-.3 0-.6.1-.9.1.6.8.9 1.8.9 2.9 0 1.1-.3 2-.9 2.9.3 0 .6.1.9.1zm-5 0c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3zm0 2c-2 0-6 1-6 3v1c0 .5.4 1 1 1h10c.5 0 1-.5 1-1v-1c0-2-4-3-6-3zm6.6.2c.8.7 1.4 1.7 1.4 2.8v2h2.5c.3 0 .5-.2.5-.5V11c0-1.5-2.4-2.5-4.4-2.8z"
      fill="currentColor"
    />
  </svg>
)
export default GroupSvg
