import * as React from "react"

const Star = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" aria-hidden="true">
    <path
      d="M12.2444 17.4l5.2 3.1c.4.2.9-.1.8-.5l-1.5-5.9 4.6-3.9c.3-.3.2-.8-.3-.9l-6-.5-2.4-5.5c-.2-.4-.8-.4-.9 0L9.44438 8.7l-6 .5c-.4 0-.6.6-.3.9l4.6 4-1.4 5.9c-.1.4.4.8.8.5l5.10002-3.1z"
      stroke="#fff"
      fill="currentColor"
    />
  </svg>
)
export default Star
