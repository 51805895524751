import { ApolloProvider } from "@apollo/client"
import React from "react"
import { hydrate } from "react-dom"
import { BrowserRouter } from "react-router-dom"
import routes from "../routes"
import settings from "../settings"
import App from "./Base/App"
import ErrorBoundary from "./Base/ErrorBoundary"
import HelmetProvider from "./shared/react-helmet-async/HelmetProvider"
import createApolloClient from "./utils/createApolloClient"
import { ensureReady } from "./utils/ensureReady"

// const usesSw = !constants.IS_PRODISH

const client = createApolloClient()

const readyQ = ensureReady(routes).then((data) => {
  // console.log("musfiq", routes, data)
  const rootTag = document.getElementById("root")
  const ClientApp = (
    <ErrorBoundary>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <App routes={routes} data={data} />
          </BrowserRouter>
        </ApolloProvider>
      </HelmetProvider>
    </ErrorBoundary>
  )
  return hydrate(ClientApp, rootTag)
})
if (settings.PUBLIC_USES_SW) {
  // NOTE: qac: once again removing until we are shure this works without breaking us
  readyQ.then(async () => {
    if ("serviceWorker" in navigator) {
      const { Workbox } = await import("workbox-window")
      const script = document.createElement("script")
      const isModern = "noModule" in script
      const swUrl = `/service-worker${isModern ? "-modern" : ""}.js`
      const wb = new Workbox(swUrl)
      wb.addEventListener("activated", (event) => {
        // `event.isUpdate` will be true if another version of the service
        // worker was controlling the page when this version was registered.
        if (!event.isUpdate) {
          console.debug("Service worker activated for the first time!")

          // If your service worker is configured to precache assets, those
          // assets should all be available now.
        }
      })
      wb.addEventListener("waiting", (event) => {
        console.debug(`A new service worker has installed, but it can't activate until all tabs running the current version have fully unloaded.`)
      })
      // Register the service worker after event listeners have been added.
      wb.register()
      const message = async () => {
        const swVersion = await wb.messageSW({ type: "GET_VERSION" })
        console.debug("Service Worker version:", swVersion)
      }
      message()
    }
  })
}

if (module.hot) {
  module.hot.accept()
}
