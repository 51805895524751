import { GamesPerPeriodEnumType } from "@cbs-sports/sports-shared-client/build/cjs/types/globalTypes"
import { PeriodLengthEnum, PicksDeadlineTypeEnum, SportTypesEnum, SpreadTypeEnum } from "@cbs-sports/sports-shared-client/build/cjs/utils/enums"
import { PoolSettingsTypesEnum } from "./enums"
import { TRoundTimeframe } from "./pool-settings"

export type TPlayoffsSettingsSurvivor = {
  includePlayoffs: boolean // by default do not include playoffs
  resetTeamsRegularSeason: boolean // by default do not reset the teams that were picked in the regular season for playoffs
  resetTeamsEachRound: boolean // by default do not reset the teams that were picked in the playoffs by round
  maxNumTimesTeamIsPicked: number // by default make sure each playoff team can only be picked once
}

export type TTiebreakerSettingsSurvivor = {
  winningPercentage: boolean // total number of wins / total number of games of all of the teams that the user picked
}

// One time / Per Round Round is for survivor only if the pool gets restarted because everyone lost
export enum FinancesFeesFrequencySurvivor {
  ONE_TIME = "ONE_TIME",
  PER_ROUND = "PER_ROUND",
  UNKNOWN = "UNKNOWN",
}

export type TFinancesRebuysSurvivor = {
  maxNumberOfRebuys: number // by default do not allow rebuy's
  lastPeriodToRebuy: number // by default cut it off in the middle of the season
}

export type TFinancesFeesSurvivor = {
  entryFee: number
  frequency: FinancesFeesFrequencySurvivor
}

export type TFinancesSettingsSurvivor = {
  managerPlaysForFree: boolean
  fees: TFinancesFeesSurvivor
  rebuys?: TFinancesRebuysSurvivor
}
export type TPoolSettingsSurvivor = {
  sportTypes: SportTypesEnum[]
  periodLength: PeriodLengthEnum
  spreadType: SpreadTypeEnum
  includeMessageBoard: boolean
  picksDeadlineType: PicksDeadlineTypeEnum
  maxEntriesPerUser: number
  gamesPerPeriod: GamesPerPeriodEnumType // number of games that are avaiable to pick from
  showPickPercentages: boolean
  finances: TFinancesSettingsSurvivor
  tiebreaker: TTiebreakerSettingsSurvivor
  numByeWeeks: number // by default should be 0 and requires a pick to be made every week (missed picks are counted as wrong)
  restartPoolIfWinner: boolean // if all but 1 participants lose (we have a winner), should we restart the pool or not
  continuePlayingIfAllAliveLose: boolean // if all participants that were alive get eliminated in the same week, should they all continue on to the next week
  countTiesAsWins: boolean
  numPicksByPeriod: Record<number, number> // needs to be different than what we're currently using // this needs to = num teams in the game
  numMulligans: number // by default should be 0 to eliminate users on the first incorrect pick
  playoffs: TPlayoffsSettingsSurvivor
  rounds: Record<number, TRoundTimeframe>
  enableAutoPilot: boolean
  __typename: PoolSettingsTypesEnum
}
