import * as React from "react"
import LoadingView from "./client/components/LoadingView"
import { asyncComponent } from "./client/utils/asyncComponent"
import { TRoutes } from "./routes.d"

// NOTE qac: for preloading ability we want `route.name` to match `webpackChunkName`
// this way we can do preloads inside of Document.tsx!

function PlaceholderComponent() {
  return <LoadingView variant="view" />
}
const Placeholder = React.memo(PlaceholderComponent)

const AdminRootComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Admin/Root" */ "./client/Admin/Root"),
})
const AppShellComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "App/Shell" */ "./client/App/Shell"),
  Placeholder,
})

const routes: TRoutes = [
  {
    path: "/app-shell",
    name: "AppShell",
    exact: true,
    component: AppShellComponent,
  },
  {
    path: "/scratchpad",
    name: "Base/Scratchpad",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Base/Scratchpad" */ "./client/Base/Scratchpad"),
    }),
  },
  {
    path: `/games/roadblock`,
    name: `Game/Roadblock`,
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Pool/standings" */ "./client/App/PoolPages/components/GamesRoadblock"),
    }),
  },
  {
    path: "/auth/login",
    name: "Auth/Login",
    exact: true,
    prefetch: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Auth/Login" */ "./client/Auth/Login"),
    }),
  },
  {
    path: "/auth/signup",
    name: "Auth/Signup",
    exact: true,
    prefetch: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Auth/Signup" */ "./client/Auth/Signup"),
    }),
  },
  {
    path: "/auth/logout",
    name: "Auth/Logout",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Auth/Logout" */ "./client/Auth/Logout"),
    }),
  },
  {
    path: "/admin",
    name: "Admin/Root",
    exact: true,
    component: AdminRootComponent,
  },
  {
    path: "/admin/analytics",
    name: "Admin/Analytics",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Analytics" */ "./client/Admin/Analytics"),
    }),
  },
  {
    path: "/admin/game-instances",
    name: "Admin/GameInstances",
    exact: true,
    component: AdminRootComponent,
  },
  {
    path: "/admin/game-instances/new",
    name: "Admin/GameInstances/New",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/GameInstances/New" */ "./client/Admin/GameInstances/New"),
    }),
  },
  {
    path: "/admin/game-instances/:gameInstanceId/edit",
    name: "Admin/GameInstances/Edit",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/GameInstances/Edit" */ "./client/Admin/GameInstances/New"),
    }),
  },
  {
    path: "/admin/ops/service-scaling",
    name: "Admin/Ops/ServiceScaling",
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Ops/ServiceScaling" */ "./client/Admin/Ops/ServiceScaling"),
    }),
  },
  {
    path: "/admin/user/delete-user",
    name: "Admin/User/DeleteUser",
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/User/DeleteUser" */ "./client/Admin/User/DeleteUser"),
    }),
  },
  {
    path: "/admin/scoring/opc",
    name: "Admin/Scoring/OPC",
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/User/DeleteUser" */ "./client/Admin/Scoring/opc"),
    }),
  },
  {
    path: "/admin/scoring/ffbopc",
    name: "Admin/Scoring/FFBOPC",
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/User/DeleteUser" */ "./client/Admin/Scoring/ffbopc"),
    }),
  },
  {
    path: "/admin/user/pick-log",
    name: "Admin/User/PickLog",
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/User/PickLog" */ "./client/Admin/User/PickLog"),
    }),
  },
  {
    path: "/admin/database",
    name: "Admin/Database/DataTable",
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Database/DataTable" */ "./client/Admin/Database/DataTable"),
    }),
  },
  {
    path: "/admin/marketing/custom-copy",
    name: "Admin/Marketing/CustomCopy",
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Marketing/CustomCopy" */ "./client/Admin/Marketing/CustomCopy"),
    }),
  },
  {
    path: "/admin/marketing/segment",
    name: "Admin/Marketing/Segment",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Marketing/Segment" */ "./client/Admin/Marketing/Segment"),
    }),
  },
  {
    path: "/admin/marketing/email-templates",
    name: "Admin/Marketing/EmailTemplates",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Marketing/EmailTemplates" */ "./client/Admin/Marketing/EmailTemplates"),
    }),
  },
  {
    path: "/admin/marketing/when-to-watch-manager",
    name: "Admin/Marketing/WhenToWatchManager",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Marketing/WhenToWhatchManager" */ "./client/Admin/Marketing/WhenToWatchManager"),
    }),
  },
  {
    path: "/admin/marketing/newsletter-manager",
    name: "Admin/Marketing/NewsletterManager",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Marketing/NewsletterManager" */ "./client/Admin/Marketing/NewsletterManager"),
    }),
  },
  {
    path: "/admin/sim/date-bump",
    name: "Admin/Sim/DateBump",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Sim/DateBump" */ "./client/Admin/Sim/DateBump"),
    }),
  },
  {
    path: "/admin/sim/bracket-states",
    name: "Admin/Sim/BracketStates",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Sim/BracketStates" */ "./client/Admin/Sim/BracketStates"),
    }),
  },
  {
    path: "/admin/sim/game-simulation",
    name: "Admin/Sim/GameSimulation",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Sim/GameSimulation" */ "./client/Admin/Sim/GameSimulation"),
    }),
  },
  {
    path: "/admin/event-helpers/remove-event-from-slate",
    name: "Admin/EventHelpers/RemoveEventFromSlate",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/EventHelpers/RemoveEventFromSlate" */ "./client/Admin/EventHelpers/RemoveEventFromSlate"),
    }),
  },
  {
    path: "/admin/event-helpers/lock-event-down",
    name: "Admin/EventHelpers/LockEventDown",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/EventHelpers/LockEventDown" */ "./client/Admin/EventHelpers/LockEventDown"),
    }),
  },
  {
    path: "/admin/event-helpers/swap-event-ids",
    name: "Admin/EventHelpers/SwapEventIds",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/EventHelpers/SwapEventIds" */ "./client/Admin/EventHelpers/SwapEventIds"),
    }),
  },
  {
    path: "/admin/maint/pool/:poolId",
    name: "Admin/Maint/Pool",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Maint/Pool" */ "./client/Admin/Maint/Pool"),
    }),
  },
  {
    path: "/admin/maint/pool/:poolId/activities",
    name: "Admin/Maint/Page-Activities",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Maint/Page-Activities" */ "./client/Admin/Maint/Page-Activities"),
    }),
  },
  {
    path: "/admin/maint/pool/:poolId/entry/:entryId",
    name: "Admin/Maint/Entry",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Maint/Entry" */ "./client/Admin/Maint/Entry"),
    }),
  },
  {
    path: "/admin/cache",
    name: "Admin/Cache",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Admin/Cache" */ "./client/Admin/Cache"),
    }),
  },
  {
    path: "/pool/:slug",
    name: "Pool Slug",
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Base/PoolSlugFinder" */ "./client/Base/PoolSlugFinder"),
    }),
  },
]

const prefixes = [":sportType/:subsection"]
// TODO qac: switch from using /challenge hardcoded to /:challengePoolSlug
const poolRootPrefixes = ["/:gameType/challenge", "/:gameType/pools/:poolId", "/:gameType/nissan-challenge", "/:gameType/sweet-sixteen-challenge"]

const GameRootComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Game/Root" */ "./client/App/NonPoolPages/containers/Games"),
  Placeholder,
})

const GamePoolCreateComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Game/CreatePool" */ "./client/App/PoolSetupPages/containers/PoolSetup"),
  Placeholder,
})
const GamePoolCreateRoadBlockComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Game/CreatePool" */ "./client/App/PoolPages/components/GamesRoadblock/CreatePoolRoadBlock"),
  Placeholder,
})

const GamePicksComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/Picks" */ "./client/App/PoolPages/containers/Picks"),
  Placeholder,
})
const GameJoinComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/Join" */ "./client/App/PoolPages/containers/Picks"),
  Placeholder,
})
const GameStandings = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/standings" */ "./client/App/PoolPages/containers/PoolStandings"),
  Placeholder,
})
const GameInviteCenterComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/invite-center" */ "./client/App/PoolPages/containers/PoolInviteCenter"),
  Placeholder,
})
const GamePlayersComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/players" */ "./client/App/PoolPages/containers/MultipleEntriesArea/ManagePlayers"),
  Placeholder,
})
const GameSettingsComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/settings" */ "./client/App/PoolSetupPages/containers/PoolSetup"),
  Placeholder,
})
const GameHelpComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/help" */ "./client/App/PoolPages/containers/PoolHelp"),
  Placeholder,
})
const GameFileBugComponent = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/file-bug" */ "./client/App/PoolPages/containers/PoolBug"),
  Placeholder,
})
const PoolHome = asyncComponent({
  loader: () => import(/* webpackChunkName: "Pool/home" */ "./client/App/PoolPages/containers/PoolHome"),
  Placeholder,
})
// TODO qac: switch to using paths: [...] instead of making a new route per
for (const prefix of prefixes) {
  routes.push({
    path: `/${prefix}/:gameType/app-shell`,
    name: `AppShell`,
    exact: true,
    component: AppShellComponent,
  })
  routes.push({
    path: `/${prefix}/:gameType`,
    name: `Game/Root`,
    exact: true,
    prefetch: true,
    component: GameRootComponent,
  })
  routes.push({
    path: `/${prefix}/:gameType/create-pool`,
    name: `Game/CreatePool`,
    exact: true,
    prefetch: true,
    component: GamePoolCreateComponent,
  })
  routes.push({
    path: `/${prefix}/:gameType/create-pool/pool/:poolId/step/:formStep?`,
    name: `Game/CreatePool`,
    exact: false,
    prefetch: true,
    component: GamePoolCreateComponent,
  })
  routes.push({
    path: `/${prefix}/:gameType/create-pool/pool/:poolId/roadblock`,
    name: `Game/CreatePool/RoadBlock`,
    exact: false,
    prefetch: true,
    component: GamePoolCreateRoadBlockComponent,
  })

  for (const poolPrefix of poolRootPrefixes) {
    routes.push({
      path: `/${prefix}${poolPrefix}`,
      name: `Pool/Picks`,
      exact: true,
      prefetch: true,
      component: GamePicksComponent,
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/join`,
      name: `Pool/Join`,
      exact: true,
      prefetch: true,
      component: GameJoinComponent,
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/standings`,
      name: `Pool/standings`,
      exact: true,
      prefetch: true,
      component: GameStandings,
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/invite-center`,
      name: `Pool/invite-center`,
      exact: true,
      prefetch: true,
      component: GameInviteCenterComponent,
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/players`,
      name: `Pool/players`,
      exact: true,
      prefetch: true,
      component: GamePlayersComponent,
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/settings(/attribute/:formStep)?`,
      name: `Pool/settings`,
      exact: false,
      prefetch: true,
      component: GameSettingsComponent,
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/message-board`,
      name: `Pool/message-board`,
      exact: true,
      prefetch: true,
      component: asyncComponent({
        loader: () => import(/* webpackChunkName: "Game/message-board" */ "./client/App/PoolPages/containers/MessageBoard/PoolMessageBoard"),
        Placeholder,
      }),
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/home`,
      name: `Pool/home`,
      exact: true,
      prefetch: true,
      component: PoolHome,
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/help`,
      name: `Pool/help`,
      exact: true,
      component: GameHelpComponent,
    })
    routes.push({
      path: `/${prefix}${poolPrefix}/file-bug`,
      name: `Pool/file-bug`,
      exact: true,
      component: GameFileBugComponent,
    })
  }
}
routes.push({
  path: `/:sportType/:subsection/:gameType/help`,
  name: `Game/help`,
  exact: true,
  component: GameHelpComponent,
})

routes.push({
  name: `UnderConstruction`,
  path: "/under-construction",
  component: asyncComponent({
    loader: () => import(/* webpackChunkName: "Game/NotFound" */ "./client/Base/UnderConstruction"),
  }),
})

routes.push({
  name: `NotFound`,
  component: asyncComponent({
    loader: () => import(/* webpackChunkName: "Game/NotFound" */ "./client/Base/NotFound"),
  }),
})

export default routes
