import * as React from "react"
// import {Route} from "react-router";
import styled from "styled-components/macro"
import { TSlotId } from "../App/Layout/constants/adDefinitions"
import { LayoutProps } from "../App/Layout/typings"
import BidBarrel from "../utils/bid-barrel"
import { palette, pxToRem, zIndexes } from "../utils/style-utils"
import Helmet from "../shared/react-helmet-async/Helmet"
import Ad from "./Ad"

// background-image: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.05) 60%, rgba(255,255,255,1) 100%);
const StyledAd = styled.div`
  z-index: ${zIndexes.ui1};
  &.ad__banner--in-page {
    margin: ${pxToRem(8)} 0 0;
  }
  &.ad__banner--bottom {
    order: 3;
    background-color: white;
    box-shadow: 0px -${pxToRem(3)} ${pxToRem(3)} 0px rgba(0, 0, 0, 0.15);
    z-index: ${zIndexes.ui2};
  }
  &.ad__banner--skybox-collapsed {
    background: black;
    background-image: linear-gradient(180deg, ${palette.blue1}, ${palette.blue1}cc);
  }
`

const BannerAd = (props: { layoutProps: LayoutProps; adSlot: TSlotId }) => {
  const { layoutProps, adSlot } = props
  const {
    classNameSuffix,
    // skyboxHeight,
    // ptype,
    // pos,
    // sizes,
    // name,
    // xfpPageType,
    // xfpArena,
    // xfpFeature,
    // xfpInstance,
    // xfpPageType,
    ...rest
  } = BidBarrel.getDomValues({ adLayoutProps: layoutProps, adSlot })
  // NOTE qac: we dont need to do this since for the most part, we dont care about cleanup bugs of helmet
  // React.useEffect(() => {
  //   if (rest.skyboxHeight) {
  //     document.body.classList.add(`skybox-auto-collapse`)
  //     document.body.classList.add(`has-skybox`)
  //     return () => {
  //       document.body.classList.remove(`skybox-auto-collapse`)
  //       document.body.classList.remove(`has-skybox`)
  //     }
  //   }
  //   return
  // }, [rest.skyboxHeight])

  const className = `ad__banner ad__banner--${classNameSuffix}`
  const useShSkybox = layoutProps.adType === "sh-skybox"
  if (rest.skyboxHeight) {
    return (
      <StyledAd id="skybox-top-wrapper" className={className}>
        <Helmet>
          <body className="skybox-auto-collapse has-skybox" />
        </Helmet>
        <Ad useShSkybox={useShSkybox} adSlot={adSlot} {...rest} />
      </StyledAd>
    )
  } else {
    return <StyledAd as={Ad as any} adSlot={adSlot} useShSkybox={useShSkybox} className={className} {...rest} />
  }
}
export default BannerAd
