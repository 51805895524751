import React from "react"
import { ThemeProvider } from "styled-components/macro"

interface IProps {
  isNexusStyles: boolean
}

// NOTE qac: this merges with parent!
// https://github.com/styled-components/styled-components/blob/c3eedbeb79621acd668e15ed2d63107528ed81c3/packages/styled-components/src/models/test/ThemeProvider.test.js#L33
const withNexusStyles = { isNexusStyles: true } as any
const nonNexusStyles = { isNexusStyles: false } as any

const ToggleNexusTheme: React.FC<IProps> = ({ isNexusStyles, children }) => (
  <ThemeProvider theme={isNexusStyles ? withNexusStyles : nonNexusStyles}>{children}</ThemeProvider>
)

export default ToggleNexusTheme
