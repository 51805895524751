import * as React from "react"
import A from "../../../components/A"
import Dropdown from "../../../components/Dropdown"
import { getPoolNavSections } from "../constants/nav"
import {
  Container,
  DropdownSection,
  DropdownSectionItem,
  NavListDropdown,
  StyledNavListItem,
  StyledNavListItemContainer,
} from "../styles/SiteNavList.styles"
import { ISubnavLink, ISubnavLinkBase, LayoutProps } from "../typings"

const Toggler = ({ title, highlighted, ...rest }: ISubnavLinkBase) => (
  <StyledNavListItem as={A} highlighted={!!highlighted} {...rest}>
    {title}
  </StyledNavListItem>
)

const NavListItem = ({ subnav, to, highlighted, title }: ISubnavLink) => {
  if (subnav.length) {
    return (
      <Dropdown
        container={(props) => <StyledNavListItemContainer highlighted={!!highlighted} {...props} />}
        toggler={(props) => <Toggler highlighted={highlighted} to={to} title={title} {...props} />}
        dropdown={(props) => (
          <NavListDropdown {...props} highlighted={!!highlighted}>
            {subnav.map((section, i) => (
              <DropdownSection key={i}>
                {section.map((item, d) => (
                  <DropdownSectionItem as={A} key={d} to={item.to}>
                    {item.title}
                  </DropdownSectionItem>
                ))}
              </DropdownSection>
            ))}
          </NavListDropdown>
        )}
        valueName={to}
      />
    )
  } else {
    return (
      <StyledNavListItemContainer highlighted={subnav.length > 0}>
        <Toggler highlighted={highlighted} to={to} title={title} />
      </StyledNavListItemContainer>
    )
  }
}

class SiteNavList extends React.PureComponent<LayoutProps> {
  public render() {
    return (
      <Container>
        {getPoolNavSections(this.props).map((rest, i) => (
          <NavListItem key={i} {...rest} />
        ))}
      </Container>
    )
  }
}

export default SiteNavList
