import * as React from "react"
import WarningAlert from "@cbs-sports/sports-shared-client/build/cjs/components/icons/WarningAlert"
import { ConfirmDialogWrap } from "./ConfirmDialog.styles"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"

const ConfirmDialog = ({
  onCancel,
  onConfirm,
  title,
  subTitle,
  cancelText = "Cancel",
  confirmText = "Confirm",
  loading = false,
}: {
  onCancel?: () => void
  onConfirm?: () => void
  title: string
  subTitle: string | React.ReactNode
  cancelText?: string
  confirmText?: string
  loading?: boolean
}) => {
  return (
    <ConfirmDialogWrap>
      <div className="data-information">
        <div className="icon-wrap">
          <WarningAlert aria-label="Warning!" />
        </div>
        <div className="text-container">
          <span className="title">{title}</span>
          <span className="sub-title">{subTitle}</span>
        </div>
      </div>
      <div className="btn-container">
        <Button variant="clear" aria-label="Cancel And Go Back To Bracket" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="clear" aria-label="Clear All Picks" onClick={onConfirm} withLoading loading={loading}>
          {confirmText}
        </Button>
      </div>
    </ConfirmDialogWrap>
  )
}

export default ConfirmDialog
