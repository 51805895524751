import * as React from "react"

const New = ({ color = "currentColor", ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="20" fill="none" {...rest}>
      <path fill={color} d="M.5 6a6 6 0 0 1 6-6h30a6 6 0 0 1 6 6v8a6 6 0 0 1-6 6h-30a6 6 0 0 1-6-6V6Z" />
      <path
        fill="#fff"
        d="M9.412 14V5.744h1.38l1.848 3.144a39.89 39.89 0 0 1 1.212 2.244h.036a60.78 60.78 0 0 1-.036-2.064V5.744h1.488V14h-1.368l-1.86-3.156c-.42-.732-.936-1.668-1.212-2.244h-.036c.024.636.036 1.464.036 2.052V14H9.412Zm7.758 0V5.744h4.752v1.344h-3.156v2.064h2.964v1.344h-2.964v2.16h3.156V14H17.17Zm7.852 0c-.672-2.616-1.344-5.484-1.98-8.256h1.704c.228 1.224.492 2.58.732 3.78.156.732.348 1.668.48 2.46h.084a93.89 93.89 0 0 1 .48-2.532c.252-1.248.516-2.556.756-3.708h1.524c.252 1.188.528 2.52.768 3.696.156.744.372 1.824.492 2.544h.084c.12-.696.312-1.716.468-2.46.228-1.116.492-2.496.732-3.78h1.692A302.56 302.56 0 0 1 31.082 14h-1.86c-.204-.924-.396-1.884-.6-2.844a59.64 59.64 0 0 1-.552-3.048h-.06c-.156 1.02-.36 2.16-.552 3.06-.18.912-.396 1.98-.576 2.832h-1.86Z"
      />
    </svg>
  )
}

export default New
