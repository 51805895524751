import styled from "styled-components/macro"
import { palette } from "../utils/style-utils"

export const VerticalOverflowContainer = styled.div`
  & {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-attachment: local, local, scroll, scroll;
    background-image: linear-gradient(to top, ${palette.white}, ${palette.white}), linear-gradient(to top, ${palette.white}, ${palette.white}),
      linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0));

    background-position: bottom center, top center, bottom center, top center;
    background-repeat: no-repeat;
    background-color: ${palette.white};
    background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
    /* hide scrollbar */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
`
export const HorizontalOverflowContainer = styled.div`
  & {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background-attachment: local, local, scroll, scroll;
    background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
      linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: ${palette.white};
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    /* hide scrollbar */
    &:not(.show-scrollbar--true) {
      -ms-overflow-style: -ms-autohiding-scrollbar;
      scrollbar-width: none;
      & ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
  }
`
