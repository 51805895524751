import React, { Component } from "react"
import ReactDOM from "react-dom"
import styled, { keyframes } from "styled-components/macro"
import { onModalClose, onModalOpen } from "../utils/misc-utils"
import { fontWeight, layout, palette, pxToRem, zIndexes } from "../utils/style-utils"
import AnalyticScreen from "./AnalyticsScreen"
import CloseSvg from "./icons/Close"

interface IModalContext {
  modalNode: Element | null
  BackgroundComponent: any | null
}

type TModalType = "modal" | "dialog" | "mobileNav" | "sidebar" | "actionSheet" | "entryBar"
const defaultModalType = "modal"

interface IModalStateBase {
  value: "pre-opening" | "opening" | "open" | "closing" | "closed"
  modalType: TModalType
}
interface IModalState extends IModalStateBase {
  children: any | null
}
interface IModalProps {
  isOpen: boolean
  modalType?: TModalType
  beforeOpen?: () => Promise<any>
  afterOpen?: () => void
  afterClose?: () => void
  beforeClose?: () => Promise<any>
  onBackgroundClick?: (event: any) => Promise<any> | void
  onEscapeKeydown?: (event: any) => Promise<any> | void
  className?: string
  analyticsScreen?: string
  style?: React.CSSProperties
}

// const bodyDatasetHoldsKey = "modalholds";

const BaseModalBackground = styled.div<IModalStateBase>`
  display: flex;
  flex-flow: ${({ modalType }) => (["entryBar"].includes(modalType) ? "column nowrap" : "row nowrap")};
  align-items: center;
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: ${({ modalType }) => (modalType === "sidebar" ? "flex-start" : "center")};
  padding: ${({ modalType }) => (["mobileNav", "sidebar", "entryBar"].includes(modalType) ? 0 : pxToRem(10))};
  z-index: ${({ modalType }) => (["mobileNav", "sidebar", "entryBar"].includes(modalType) ? zIndexes.mobileNavModal : zIndexes.modalBg)};
  animation: ${(props) => (["pre-opening", "open", "opening"].includes(props.value) && ModalBackgroundIn) || ModalBackgroundOut} 0.4s ease;
  animation-fill-mode: forwards;
  pointer-events: ${(props) => (["open"].includes(props.value) ? "all" : "none")};
  contain: strict;
  top: ${({ modalType }) => (["mobileNav", "sidebar"].includes(modalType) ? pxToRem(layout.siteNavH) : 0)};
  @media (min-width: ${pxToRem(layout.useMobileNavW)}) {
    padding-top: ${({ modalType }) => (["mobileNav", "sidebar"].includes(modalType) ? 0 : pxToRem(layout.siteNavH + 10))};
    top: ${({ modalType }) => (["mobileNav", "sidebar"].includes(modalType) ? pxToRem(layout.siteNavH + layout.siteSubNavH) : 0)};
  }
  &.action-sheet__modal--mobile-as {
    z-index: ${zIndexes.mobileBotTabs - 1};
  }
`

const ModalBackgroundIn = keyframes`
  0% {
    background-color: rgba(0,0,0,0);
  }
  100% {
    background-color: rgba(0,0,0,0.6);
  }
`
const ModalBackgroundOut = keyframes`
  0% {
    background-color: rgba(0,0,0,0.6);
  }
  100% {
    background-color: rgba(0,0,0,0);
  }
`

// const FromBottomModalWrapperIn = keyframes`
//   0% {
//     transform: translateY(-100vh);
//     opacity: 0;
//   }
//   15% {
//     opacity: 1;
//   }
//   100% {
//     transform: translateY(0);
//   }
// `;
// const FromBottomModalWrapperOut = keyframes`
// 0% {
//   transform: translateY(0);
//   opacity: 1;
// }
// 25% {
//   opacity: 1;
// }
// 100% {
//   transform: translateY(-100vh);
//   opacity: 0;
// }
// `;
const ModalWrapperIn = keyframes`
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  40% {
    transform: translateY(30px);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
`
const ModalWrapperOut = keyframes`
0% {
  transform: translateY(0);
  opacity: 1;
}
60% {
  opacity: 1;
}
100% {
  transform: translateY(30px);
  opacity: 0;
}
`

const DialogWrapperIn = keyframes`
  0% {
    transform: scale(1.4);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`
const DialogWrapperOut = keyframes`
0% {
  transform: scale(1);
  opacity: 1;
}
60% {
  opacity: 1;
}
100% {
  transform: scale(0.5);
  opacity: 0;
}
`

const ModalNavIn = keyframes`
0% {
  transform: translateY(-100%);
  opacity: 0;
}
20% {
  transform: translateY(-100%);
  opacity: 0;
}
60% {
  opacity: 1;
}
100% {
  transform: translateY(0);
  opacity: 1;
}
`
const ModalNavOut = keyframes`
0% {
  transform: translateY(0);
  opacity: 1;
}
60% {
  opacity: 1;
}
100% {
  transform: translateY(-100%);
  opacity: 0;
}
`

const SidebarIn = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`
const SidebarOut = keyframes`
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(-100%);
}
`

const modalWrapperAnimations = {
  modal: {
    open: ModalWrapperIn,
    close: ModalWrapperOut,
  },
  dialog: {
    open: DialogWrapperIn,
    close: DialogWrapperOut,
  },
  actionSheet: {
    open: DialogWrapperIn,
    close: DialogWrapperOut,
  },
  mobileNav: {
    open: ModalNavIn,
    close: ModalNavOut,
  },
  sidebar: {
    open: SidebarIn,
    close: SidebarOut,
  },
  // entryBar: {
  //   open: FromBottomModalWrapperIn,
  //   close: FromBottomModalWrapperOut,
  // },
}

const modalMinHeight = "30vh"
const ModalWrapperBase = styled.div<IModalStateBase>`
  max-width: 100%;
  max-height: 100%;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  animation-fill-mode: forwards;
  &.modal-state--pre-opening {
    pointer-events: none;
    opacity: 0;
    animation-name: none !important;
  }
`
const StandardModalWrapper = styled(ModalWrapperBase)`
  flex: 0 1 auto;
  min-height: ${modalMinHeight};
  animation-name: ${({ value }) => (["open", "opening"].includes(value) ? modalWrapperAnimations.modal.open : modalWrapperAnimations.modal.close)};
  animation-timing-function: ${({ value }) =>
    ["open", "opening"].includes(value) ? "cubic-bezier(0.4, 0.0, 0.2, 1)" : "cubic-bezier(0.4, 0.0, 1, 1)"};
  animation-duration: ${({ value }) => (["open", "opening"].includes(value) ? "0.4s" : "0.2s")};
`
const DialogModalWrapper = styled(ModalWrapperBase)`
  flex: 1 1 100%;
  max-width: ${pxToRem(layout.dialogMaxWidth)};
  min-height: ${pxToRem(layout.dialogMaxHeight)};
  animation-name: ${({ value }) => (["open", "opening"].includes(value) ? modalWrapperAnimations.dialog.open : modalWrapperAnimations.dialog.close)};
  animation-timing-function: ${({ value }) =>
    ["open", "opening"].includes(value) ? "cubic-bezier(0.4, 0.0, 0.2, 1)" : "cubic-bezier(0.4, 0.0, 1, 1)"};
  animation-duration: ${({ value }) => (["open", "opening"].includes(value) ? "0.4s" : "0.2s")};
`
const MobileNavModalWrapper = styled(ModalWrapperBase)`
  flex: 1 1 100%;
  height: 100%;
  animation-name: ${({ value }) =>
    ["open", "opening"].includes(value) ? modalWrapperAnimations.mobileNav.open : modalWrapperAnimations.mobileNav.close};
  animation-timing-function: ${({ value }) =>
    ["open", "opening"].includes(value) ? "cubic-bezier(0.4, 0.0, 0.2, 1)" : "cubic-bezier(0.4, 0.0, 1, 1)"};
  animation-duration: ${({ value }) => (["open", "opening"].includes(value) ? "0.4s" : "0.2s")};
  & > * {
    max-width: ${pxToRem(layout.accountNavW)};
    margin: 0 auto;
    box-shadow: 0 0 ${pxToRem(15)} ${pxToRem(3)} rgba(0, 0, 0, 0.6);
  }
`
const SidebarModalWrapper = styled(ModalWrapperBase)`
  overflow: visible;
  height: 100%;
  flex: 0 1 ${pxToRem(450)};
  max-width: ${pxToRem(450)};
  animation-name: ${({ value }) =>
    ["open", "opening"].includes(value) ? modalWrapperAnimations.sidebar.open : modalWrapperAnimations.sidebar.close};
  animation-timing-function: ${({ value }) =>
    ["open", "opening"].includes(value) ? "cubic-bezier(0.4, 0.0, 0.2, 1)" : "cubic-bezier(0.4, 0.0, 1, 1)"};
  animation-duration: ${({ value }) => (["open", "opening"].includes(value) ? "0.4s" : "0.2s")};
`
const ActionSheetModalWrapper = styled(ModalWrapperBase)`
  animation-name: ${({ value }) => (["open", "opening"].includes(value) ? modalWrapperAnimations.modal.open : modalWrapperAnimations.modal.close)};
  animation-timing-function: ${({ value }) =>
    ["open", "opening"].includes(value) ? "cubic-bezier(0.4, 0.0, 0.2, 1)" : "cubic-bezier(0.4, 0.0, 1, 1)"};
  animation-duration: ${({ value }) => (["open", "opening"].includes(value) ? "0.4s" : "0.2s")};
  justify-content: flex-end;
  display: flex;
  flex-flow: column;
  width: 90%;
  align-self: flex-end;
`
const EntryBarModalWrapper = styled(ModalWrapperBase)`
  flex: 1 0 auto;
  width: 100%;
  height: 90%;
  margin-top: 10vh;
  background-color: ${palette.white};
  border-top-left-radius: ${pxToRem(16)};
  border-top-right-radius: ${pxToRem(16)};
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.1s linear;
  transform: translateY(100%);
  opacity: 0;
  &.modal-state--opening,
  &.modal-state--open {
    opacity: 1;
    transform: translateY(0);
  }
  &.modal-state--closing {
    transition: transform 0.4s cubic-bezier(0.4, 0, 1, 1), opacity 0.1s linear 0.3s;
  }
`

interface IModalElement {
  minHeight?: number
  wrapper: any
}
interface IModalElements {
  modal: IModalElement
  actionSheet: IModalElement
  dialog: IModalElement
  mobileNav: IModalElement
  sidebar: IModalElement
  entryBar: IModalElement
}

const modalElements: IModalElements = {
  modal: {
    wrapper: StandardModalWrapper,
  },
  actionSheet: {
    wrapper: ActionSheetModalWrapper,
  },
  dialog: {
    wrapper: DialogModalWrapper,
    minHeight: layout.dialogMaxHeight,
  },
  mobileNav: {
    wrapper: MobileNavModalWrapper,
  },
  sidebar: {
    wrapper: SidebarModalWrapper,
  },
  entryBar: {
    wrapper: EntryBarModalWrapper,
  },
}

const { Provider, Consumer } = React.createContext<IModalContext>({
  modalNode: null,
  BackgroundComponent: BaseModalBackground,
})

class ModalProvider extends Component<any, any> {
  public static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.backgroundComponent !== prevState.BackgroundComponent && nextProps.backgroundComponent) {
      return { BackgroundComponent: nextProps.backgroundComponent }
    }

    return null
  }
  constructor(props) {
    super(props)

    this.state = {
      modalNode: null,
      BackgroundComponent: BaseModalBackground,
    }
  }

  public setModalNode = (modalNode) => {
    // console.log(`setModalNode`);
    this.setState({ modalNode })
  }

  public render() {
    const value = {
      modalNode: this.state.modalNode,
      BackgroundComponent: this.state.BackgroundComponent,
    }
    return (
      <Provider value={value}>
        {this.props.children}
        <div ref={this.setModalNode} />
      </Provider>
    )
  }
}

class Modal extends Component<IModalProps, IModalState> {
  private node: React.DOMElement<any, any> | null = null
  private escapeKeyBound = false
  private isRaf = false
  private animationTimeout: number | null = null

  constructor(props) {
    super(props)
    this.state = {
      value: "closed",
      modalType: defaultModalType,
      children: null,
    }
  }

  public componentDidMount() {
    if (this.props.isOpen && this.state.value === "closed") {
      const q = this.props.beforeOpen ? this.props.beforeOpen() : Promise.resolve()
      q.then(this.open)
    }
  }

  public cancelTimeout = () => {
    if (this.animationTimeout) {
      if (this.isRaf) {
        cancelAnimationFrame(this.animationTimeout)
      } else {
        clearTimeout(this.animationTimeout)
      }
      this.isRaf = false
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    const { value } = this.state
    // Handle state changes
    if (prevState.value !== value) {
      // console.debug(`going: ${prevState.value} --> ${value}`);
      if (value === "closed") {
        this.cleanUp()
        if (this.props.afterClose) {
          this.props.afterClose()
        }
      }
      if (value === "pre-opening") {
        this.bindListeners()
        this.cancelTimeout()
        // this.isRaf = true;
        // this.animationTimeout = requestAnimationFrame(this.onPreOpen);
        this.animationTimeout = setTimeout(this.onPreOpen)
      }
      if (value === "opening") {
        this.bindListeners()
        this.cancelTimeout()
        this.animationTimeout = setTimeout(this.onOpened, 800)
      }
      if (value === "open") {
        if (this.props.afterOpen) {
          setTimeout(this.props.afterOpen)
        }
      }
      if (value === "closing") {
        this.cancelTimeout()
        this.animationTimeout = setTimeout(this.onClosed, 400)
      }
    }

    // Handle prop changes
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        const q = this.props.beforeOpen ? this.props.beforeOpen() : Promise.resolve()
        q.then(this.open)
      } else if (["opening", "open"].includes(value)) {
        const q = this.props.beforeClose ? this.props.beforeClose() : Promise.resolve()
        q.then(this.close)
      } else {
        console.warn(`ignoring change in props.isOpen due to state: ${value}`)
      }
    } else if (prevProps.modalType !== this.props.modalType) {
      // console.log("isDialog: " + this.props.isDialog);
      this.setState({ modalType: this.props.modalType || defaultModalType })
    }
  }

  public close = () => {
    // console.log("close");
    // NOTE qac: dont change , isDialog!
    this.setState({ value: "closing", children: this.props.children })
  }

  public onPreOpen = () => {
    // console.log("onPreOpen");
    this.setState({
      value: "opening",
      modalType: this.props.modalType || defaultModalType,
    })
  }

  public open = () => {
    // console.log("open");
    this.setState({
      value: "pre-opening",
      modalType: this.props.modalType || defaultModalType,
    })
  }

  public onOpened = () => {
    // console.log("onOpened");
    this.animationTimeout = null
    this.setState({
      value: "open",
      modalType: this.props.modalType || defaultModalType,
    })
  }

  public onClosed = () => {
    // console.log("onClosed");
    this.animationTimeout = null
    this.setState({
      value: "closed",
      modalType: this.props.modalType || defaultModalType,
      children: null,
    })
  }

  public componentWillUnmount() {
    if (this.props.isOpen) {
      this.cleanUp()
    }
  }

  public cleanUp = () => {
    if (typeof document !== "undefined") {
      if (this.escapeKeyBound) {
        this.escapeKeyBound = false
        onModalClose()
        document.removeEventListener("keydown", this.onKeydown)
      }
    }
  }
  public bindListeners = () => {
    if (typeof document !== "undefined") {
      if (!this.escapeKeyBound) {
        this.escapeKeyBound = true
        onModalOpen()
        document.addEventListener("keydown", this.onKeydown)
      }
    }
  }

  public onKeydown = (e) => {
    if (e.key === "Escape") {
      if (this.props.onEscapeKeydown) {
        this.props.onEscapeKeydown(e)
      } else {
        this.close()
      }
    }
  }

  public onBackgroundClick = (e) => {
    // console.log("onBackgroundClick");
    if (this.node === e.target) {
      if (this.props.onBackgroundClick) {
        this.props.onBackgroundClick(e)
      } else {
        if (e) {
          e.preventDefault()
        }
        this.close()
      }
    }
  }

  public render() {
    const { children, analyticsScreen, className, style } = this.props
    const { value, modalType } = this.state
    // console.log(`, value: ${value}`);
    // console.log(`render ${this.state.isDialog}`, this.props);
    // console.dir(this.state.children)
    const Wrapper = modalElements[this.state.modalType].wrapper
    const classNames = [`modal-type--${this.state.modalType}`, `modal-state--${value}`]
    if (className) {
      classNames.push(className)
    }
    const combinedClassName = classNames.join(" ")
    return (
      <Consumer>
        {({ modalNode, BackgroundComponent }) => {
          if (modalNode && BackgroundComponent && value !== "closed") {
            return ReactDOM.createPortal(
              <BackgroundComponent
                className={combinedClassName}
                value={value}
                onClick={this.onBackgroundClick}
                modalType={modalType}
                ref={(node) => {
                  this.node = node
                }}
              >
                <Wrapper value={value} modalType={modalType} className={combinedClassName} style={style}>
                  {(analyticsScreen && <AnalyticScreen subfeature={analyticsScreen.toLowerCase()} title={analyticsScreen} isModal={true} />) || null}
                  {this.state.children || children}
                </Wrapper>
              </BackgroundComponent>,
              modalNode,
            )
          } else {
            return null
          }
        }}
      </Consumer>
    )
  }
}

const modalPadding = 16
interface IModalContent {
  minHeight?: number
}
const ModalContent = styled.div<IModalContent>`
  background-color: ${palette.white};
  min-height: ${({ minHeight }) => (minHeight ? pxToRem(minHeight) : modalMinHeight)};
  display: flex;
  flex-flow: column;
  &.is-padded--true {
    padding: ${pxToRem(modalPadding)};
  }
  /* give dialogs some flexability and nice to haves! */
  &.is-dialog--true {
    justify-content: space-between;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    & strong {
      font-weight: ${fontWeight.bold};
    }
    & * {
      margin-bottom: 1rem;
      line-height: 1.3em;
    }
  }
  & .modal-content__dialog-icon {
    width: 30%;
    max-width: ${pxToRem(170)};
    min-width: ${pxToRem(100)};
    color: ${palette.gray2};
  }
`
const ModalWrapperStyled = styled.div`
  &.has-shadow--true {
    box-shadow: 0 0 ${pxToRem(15)} ${pxToRem(3)} rgba(0, 0, 0, 0.6);
  }
  &.variant--white {
    color: ${palette.default};
    background-color: ${palette.white};
    border-radius: ${pxToRem(6)};
  }
`
const ModalHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexes.modalHeader};
  &.variant--blueheader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: ${pxToRem(layout.modalHeaderHeight)};
    background-size: auto 70%;
    background-position: ${pxToRem(10)};
    background-color: ${palette.uiNavBlue1};
    font-size: ${pxToRem(15)};
    color: ${palette.white};
    text-transform: uppercase;
    @media (max-width: ${pxToRem(500)}) {
      & {
        justify-content: flex-start;
        padding-left: ${pxToRem(10)};
      }
    }
  }
  &.variant--white {
    background-color: ${palette.white};
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(26)};
    color: ${palette.neutral70};
    padding-top: ${pxToRem(53)};
    margin-bottom: ${pxToRem(16)};
    text-align: center;
  }
`
const ModalCloseBtnStyled = styled.button`
  position: absolute;
  color: currentColor;
  height: ${pxToRem(layout.modalHeaderHeight)};
  width: ${pxToRem(layout.modalHeaderHeight)};
  padding: ${pxToRem(layout.modalHeaderHeight * 0.1)};
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  will-change: opacity;
  z-index: ${zIndexes.ui1};
  @media (hover: hover) {
    :hover:not(:active) {
      opacity: 0.8;
    }
  }
  :active {
    opacity: 0.7;
  }
`

type BtnType = "button" | "reset" | "submit" | undefined
type IModalCloseBtnProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
const ModalCloseBtn = ({ type = "button", ref, ...rest }: IModalCloseBtnProps) => (
  <ModalCloseBtnStyled type={type as BtnType} {...rest}>
    <CloseSvg />
  </ModalCloseBtnStyled>
)

interface IWrapperProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode
  isDialog?: boolean
  header?: React.ReactNode | null
  icon?: React.ReactNode
  closeBtnAction?: any | null
  padded?: boolean
  modalType?: TModalType
  excludeShadow?: boolean
  variant?: "blueheader" | "white"
}

const ModalWrapper = ({
  children,
  className,
  icon,
  ref,
  variant = "blueheader",
  header = null,
  closeBtnAction = null,
  isDialog = false,
  padded = true,
  excludeShadow = false,
  modalType = defaultModalType,
  ...rest
}: IWrapperProps) => {
  const classNamesBase = [`variant--${variant}`, `is-padded--${padded}`, `is-dialog--${isDialog}`, `has-shadow--${excludeShadow}`]
  const classNames = [`modal-wrapper__wrapper`].concat(classNamesBase)
  if (className) {
    classNames.push(className)
  }
  return (
    <ModalWrapperStyled className={classNames.join(" ")} {...rest}>
      {(header && (
        <ModalHeader className={classNamesBase.join(" ")}>
          {header}
          {closeBtnAction && <ModalCloseBtn onClick={closeBtnAction} />}
        </ModalHeader>
      )) ||
        (closeBtnAction && <ModalCloseBtn onClick={closeBtnAction} />) ||
        null}
      <ModalContent className={classNamesBase.join(" ")} minHeight={modalElements[modalType].minHeight}>
        {icon && <div className="modal-content__dialog-icon">{icon}</div>}
        {children}
      </ModalContent>
    </ModalWrapperStyled>
  )
}

export default Modal
export { ModalProvider, ModalWrapper }
