import * as React from "react"

const CirclePlusSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4 11h-3v3c0 .5-.4 1-1 1s-1-.5-1-1v-3H8c-.6 0-1-.4-1-1s.4-1 1-1h3V8c0-.6.4-1 1-1s1 .4 1 1v3h3c.5 0 1 .4 1 1s-.5 1-1 1z"
      fill="currentColor"
    />
  </svg>
)

export default CirclePlusSvg
