// This file represents the beginning of the marriage of DB segment + season values and the FE routes

import { enum_archiveStatus, enum_groupPosition } from "../../typings/db-models.d"
import { TGameType, TPlaysInto, TPoolType, TSportType, TSubsectionType } from "./db-typings"
import {
  AvailableOptionEnum,
  ClientsEnum,
  DeleteReasonKeyEnum,
  EntryOrderByEnum,
  ENUM_AWAY,
  ENUM_BET365,
  ENUM_BET365_NEW_JERSEY,
  ENUM_BET888,
  ENUM_CAESARS,
  ENUM_CANTORGAMING,
  ENUM_CBS,
  ENUM_CONSENSUS,
  ENUM_DOWN,
  ENUM_DRAFT_KINGS,
  ENUM_HOME,
  ENUM_HTML_FORMAT,
  ENUM_MGM,
  ENUM_ISE,
  ENUM_NEUTRAL,
  ENUM_NONE,
  ENUM_OVER,
  ENUM_POINTS_BET,
  ENUM_TEXT_FORMAT,
  ENUM_UNDER,
  ENUM_UP,
  ENUM_WESTGATE,
  ENUM_WILLIAM_HILL_CASINO,
  ENUM_WYNN,
  ForgotPickFallbackEnum,
  GamesPerPeriodEnum,
  GameTypeEnum,
  GameWeightTypeEnum,
  MessageCategoryEnum,
  PeriodLengthEnum,
  PicksDeadlineTypeEnum,
  PoolTypeEnum,
  RoundBonusTypeEnum,
  SeasonArchiveStatusEnum,
  SeasonEnumType,
  SportTypesEnum,
  SpreadTypeEnum,
  TiebreakerTypeEnum,
  RoundModifierTypeEnum,
  ENUM_POOL_ORDER_DEFAULT,
  ENUM_TIE,
  ENUM_FAVORED,
  ENUM_UNDERDOG,
  FormInputTypeEnum,
  AutoPickProgrammaticEnum,
  SortingOrderEnum,
  ENUM_WILLIAM_HILL_NEW_JERSEY,
} from "./enums"
import {
  ForgotPickFallback,
  GamesPerPeriod,
  GameWeightType,
  ITypesEnum,
  MultipleEntriesOption,
  PeriodLengthType,
  PickCountRangeOption,
  PicksDeadlineType,
  RoundBonusTypes,
  SeasonTypeOptions,
  SpreadType,
  SubsectionTypes,
  TiebreakerTypes,
  TTypesEnumArray,
  ValidTiebreakerQuestions,
  RoundModifierTypes,
  RoundModifiersOption,
  FinancesFeesFrequencySurvivorType,
  FinancesFeesFrequencyOPMType,
  MissedPeriodsOPMType,
} from "./pool-settings"
import { FinancesFeesFrequencyOPM, MissedPeriodsOPMEnum } from "./pool-settings-opm"
import { FinancesFeesFrequencySurvivor } from "./pool-settings-survivor"

interface IGraphqlEnumValue<T> {
  description: string
  value: T
}
interface IHash<T> {
  [key: string]: T
}

type TGraphqlValuesHash<T> = IHash<IGraphqlEnumValue<T>>
interface IGraphqlEnum<T> {
  name: string
  description: string
  values: TGraphqlValuesHash<T>
  // val?: number;
}

export const MatchupRoundOrdinalDescription = `The Matchup's position within its round, as depicted on the bracket, counting from top to bottom, zero-indexed.
If tree shaped, top-left to bottom-left to top-right to bottom-right.`

const mapTypesEnumToValues = <T>(typesEnum: Array<ITypesEnum<T>>) =>
  Object.keys(typesEnum).reduce((accum, key) => {
    const subsectionType = typesEnum[key]
    if (subsectionType.value) {
      accum[subsectionType.value] = {
        description: subsectionType.description || subsectionType.label,
        value: subsectionType.value,
      }
      // if (subsectionType.hasOwnProperty("val")) {
      //   accum[subsectionType.value].val = subsectionType.val
      // }
    }
    return accum
  }, {} as TGraphqlValuesHash<T>)

export const PoolOrderByType = {
  name: "PoolOrderByType",
  description: "How to sort pools for this node",
  values: {
    [ENUM_POOL_ORDER_DEFAULT]: {
      description: `Default ordering is subject to change, and currently order Pools by when they were created, with oldest at the top.`,
      value: ENUM_POOL_ORDER_DEFAULT,
    },
    // [ENUM_POOL_ORDER_ENTRY_COUNT]: {
    //   description: "Order pools by the number of entries",
    //   value: ENUM_POOL_ORDER_ENTRY_COUNT,
    // },
  },
}

export const EntryOrderByType = {
  name: "EntryOrderByType",
  description: `How to sort the entries for this node`,
  values: {
    [EntryOrderByEnum.OVERALL_RANK]: {
      description: "Rank of the entry within the season",
      value: EntryOrderByEnum.OVERALL_RANK,
    },
    [EntryOrderByEnum.PERIOD_RANK]: {
      description: "Rank of this entry for the given period. Excludes all other period ranks.",
      value: EntryOrderByEnum.PERIOD_RANK,
    },
    [EntryOrderByEnum.ROLES]: {
      description: "Entries with higher elevation of roles are put first.",
      value: EntryOrderByEnum.ROLES,
    },
    [EntryOrderByEnum.PROMOTED_TO_COPY]: {
      description: "Ordered by specified order of copying heirarchy.",
      value: EntryOrderByEnum.PROMOTED_TO_COPY,
    },
    [EntryOrderByEnum.CHAMPION]: {
      description: "Entries will be ordered by the chosen champion",
      value: EntryOrderByEnum.CHAMPION,
    },
    [EntryOrderByEnum.CORRECT_PICKS]: {
      description: "Entries with a higher number of correct picks are put first",
      value: EntryOrderByEnum.CORRECT_PICKS,
    },
    [EntryOrderByEnum.MAX_POINTS]: {
      description: "Entries with a higher maximum Score will be put first",
      value: EntryOrderByEnum.MAX_POINTS,
    },
    [EntryOrderByEnum.USER_ENTRIES_FIRST]: {
      description: "User entries will be put first",
      value: EntryOrderByEnum.USER_ENTRIES_FIRST,
    },
    [EntryOrderByEnum.STATUS]: {
      describe: "User with higher number of picks, will be put first",
      value: EntryOrderByEnum.STATUS,
    },
  },
}
export const SortingOrderByType = {
  name: "SortingOrderByType",
  description: `How to sort ASC or DESC`,
  values: {
    [SortingOrderEnum.ASC]: {
      description: "Order ASC",
      value: SortingOrderEnum.ASC,
    },
    [SortingOrderEnum.DESC]: {
      description: "Order DESC",
      value: SortingOrderEnum.DESC,
    },
  },
}

export const EventOddsBookNameEnumType = {
  name: "EventOddsBookName",
  description: `Unique name of Odds Book`,
  values: {
    CONSENSUS: {
      description: `Official Consensus of all Books`,
      value: ENUM_CONSENSUS,
    },
    BET365: {
      value: ENUM_BET365,
    },
    BET888: {
      value: ENUM_BET888,
    },
    CAESARS: {
      value: ENUM_CAESARS,
    },
    MGM: {
      value: ENUM_MGM,
    },
    DRAFT_KINGS: {
      value: ENUM_DRAFT_KINGS,
    },
    BET365_NEW_JERSEY: {
      value: ENUM_BET365_NEW_JERSEY,
    },
    CANTORGAMING: {
      value: ENUM_CANTORGAMING,
    },
    WYNN: {
      value: ENUM_WYNN,
    },
    ISE: {
      value: ENUM_ISE,
    },
    WILLIAM_HILL_CASINO: {
      value: ENUM_WILLIAM_HILL_CASINO,
    },
    WESTGATE: {
      value: ENUM_WESTGATE,
    },
    POINTS_BET: {
      value: ENUM_POINTS_BET,
    },
    WILLIAM_HILL_NEW_JERSEY: {
      description: `William Hill New Jersey`,
      value: ENUM_WILLIAM_HILL_NEW_JERSEY,
    },
    CBS: {
      description: `CBS Sports currated book.  Currently only officially used for Pickem products.`,
      value: ENUM_CBS,
    },
  },
}
export const OddsBookNames = Object.keys(EventOddsBookNameEnumType.values).filter((d) => d !== ENUM_CBS)

export const EventPossessionEnumType = {
  name: "EventPossession",
  description: `Marker of which side has current possession (if applicable)`,
  values: {
    [ENUM_HOME]: {
      description: `Home team has possession`,
      value: ENUM_HOME,
    },
    [ENUM_AWAY]: {
      description: `Away team has possession`,
      value: ENUM_AWAY,
    },
    [ENUM_NONE]: {
      description: `No team has possession`,
      value: ENUM_NONE,
    },
  },
}
export const EventTeamOddsTrendEnumType = {
  name: "EventTeamOddsTrend",
  description: `Direction of movement of odds`,
  values: {
    [ENUM_NEUTRAL]: {
      description: `No movement one way or the other`,
      value: ENUM_NEUTRAL,
    },
    [ENUM_UP]: {
      description: `Increasing value trend`,
      value: ENUM_UP,
    },
    [ENUM_DOWN]: {
      description: `Decreasing value trend`,
      value: ENUM_DOWN,
    },
  },
}
export const EventTeamOddsTotalsChoiceEnumType = {
  name: "EventTeamOddsTotalsChoiceEnum",
  description: `Choice for totals based odds`,
  values: {
    [ENUM_OVER]: {
      description: `Choice of total score being over (greater than) the projected.`,
      value: ENUM_OVER,
    },
    [ENUM_UNDER]: {
      description: `Choice of total score being under (less than) the projected.`,
      value: ENUM_UNDER,
    },
  },
}

export const MessageCategoryEnumType = {
  name: "MessageCategory",
  description: `Category of pool messages`,
  values: {
    [MessageCategoryEnum.PUBLIC]: {
      description: "Displays only messages that were sent to the entire pool",
      value: MessageCategoryEnum.PUBLIC,
    },
    [MessageCategoryEnum.SUBSECT]: {
      description: "Private messages between particular entries in a pool",
      value: MessageCategoryEnum.SUBSECT,
    },
    [MessageCategoryEnum.ALL]: {
      description: "Displays messages from everybody and individual pool entries.",
      value: MessageCategoryEnum.ALL,
    },
  },
}

export const FormInputTypeEnumType = {
  name: "FormInputTypeEnumType",
  description: `A form input type.  Mostly corrisponds to the HTML spec for input types.`,
  values: {
    [FormInputTypeEnum.CHECKBOX]: {
      value: FormInputTypeEnum.CHECKBOX,
    },
  },
}

export const DeleteReasonEnumType = {
  name: "DeleteReason",
  description: `Reason for delete a messages`,
  values: {
    [DeleteReasonKeyEnum.ATTACKING_ANOTHER_MEMBER]: {
      description: "Attacking another pool member",
      value: DeleteReasonKeyEnum.ATTACKING_ANOTHER_MEMBER,
    },
    [DeleteReasonKeyEnum.FOUL_LANGUAGE]: {
      description: "Foul language",
      value: DeleteReasonKeyEnum.FOUL_LANGUAGE,
    },
    [DeleteReasonKeyEnum.OFFENSIVE_LANGUAGE]: {
      description: "Abusive language",
      value: DeleteReasonKeyEnum.OFFENSIVE_LANGUAGE,
    },
    [DeleteReasonKeyEnum.OTHER]: {
      description: "Other reason",
      value: DeleteReasonKeyEnum.OTHER,
    },
  },
}

export const FormatEnumType = {
  name: "Format",
  description: `Format`,
  values: {
    HTML: {
      description: "Format HTML",
      value: ENUM_HTML_FORMAT,
    },
    TEXT: {
      description: "Format HTML",
      value: ENUM_TEXT_FORMAT,
    },
  },
}

export const SeasonArchiveStatusEnumType = {
  name: "SeasonArchiveStatusEnumType",
  description: `Status of this Season's Archive process.
  Archiving occurs in two phases to allow User's the opertunity to reactivate / view recent Pools and Entries
  while still keeping the current data as slim and clean as possible.`,
  values: {
    [SeasonArchiveStatusEnum.partial]: {
      description: `Partially archived: all Pool records and data are archived, but Pools and Entries remain unarchived.
      Pools and Entries will therefore still appear in the User's lobby and SPOE.`,
      value: SeasonArchiveStatusEnum.partial as enum_archiveStatus,
    },
    [SeasonArchiveStatusEnum.full]: {
      description: `Fully archived: all records and data including the Pools and Entries.
      Pools and Entries will no longer appear in the User's lobby and SPOE, and will only be accessible via CTA's to "View Archived Pools".`,
      value: SeasonArchiveStatusEnum.full as enum_archiveStatus,
    },
  },
}

export const SeasonType = {
  name: "SeasonEnumType",
  description: `Valid season types.  Not all sports/games need to specify a season, at which point this can be ignored.`,
  values: mapTypesEnumToValues<SeasonEnumType>(SeasonTypeOptions),
}

export const GameStateType = {
  name: "GameStateEnumType",
  description: `Game states allow `,
  values: {
    ACTIVE: {
      description: "Active",
      value: "ACTIVE",
    },
    OFFSEASON: {
      description: "Offseason",
      value: "OFFSEASON",
    },
    RETIRED: {
      description: "Retired",
      value: "RETIRED",
    },
  },
}

export const ClientOptions = [
  { label: "CBS Sports", value: ClientsEnum.CBS },
  { label: "Nissan", value: ClientsEnum.NISSAN },
  { label: "TESTING CBS Sports", value: ClientsEnum.TESTING_CBS },
] as TTypesEnumArray<ClientsEnum>
export const GameClient = {
  name: "GameClientEnumType",
  description: `Specifies a client who owns/sponsors a game.
  This allows having a standard CBS instance of the NCAAMarchTournament, but also run an instance separatly for Coca Cola.`,
  values: mapTypesEnumToValues<ClientsEnum>(ClientOptions),
}

export const SportTypeOptions = [
  { label: "NFL", value: SportTypesEnum.NFL as TSportType, description: "National Football League" },
  { label: "NCAA Men's Basketball", value: SportTypesEnum.NCAAB as TSportType, description: "Men's NCAA Basketball" },
  { label: "NCAA Football", value: SportTypesEnum.NCAAF as TSportType, description: "Men's NCAA Football" },
  { label: "NCAA Women's Basketball", value: SportTypesEnum.NCAAW as TSportType, description: "Women's NCAA Basketball" },
] as TTypesEnumArray<SportTypesEnum>
export const GameSportType = {
  name: "GameSportTypeEnumType",
  description: `A combination of the sport with the league level.
  Examples: Nfl, NcaaBasketball, PgaTour`,
  values: mapTypesEnumToValues<SportTypesEnum>(SportTypeOptions),
}
export const GameSubsectionType = {
  name: "GameSubsectionEnumType",
  description: `Specific subsection of a season/sport.
  Examples: ConferenceTournament, Draft`,
  values: mapTypesEnumToValues<TSubsectionType>(SubsectionTypes),
} as IGraphqlEnum<TSubsectionType>
export const GameTypeOptions = [
  {
    label: "Bracket",
    value: GameTypeEnum.BRACKET as TGameType,
    description: `Brackets involves choosing a team in a defined matchup.
  It differs from Pickem in that the user is charged with picking EVERY matchup
  in the whole tournament / bracket at once.`,
  },
  { label: "Pickem", value: GameTypeEnum.PICKEM as TGameType, description: `Pickem involves choosing a team in a heads up matchup.` },
  {
    label: "Parlay",
    value: GameTypeEnum.PARLAY as TGameType,
    description: `Parlay is similar to Pickem, with the difference being any incorrect pick nullifies all points for that Period.`,
  },
] as TTypesEnumArray<GameTypeEnum>
export const GameGameType = {
  name: "GameGameTypeEnumType",
  description: `Style of the gameplay.
  Examples: Pickem, Bracket, StagesBracket, DailySalary, SeasonSalary, SeasonDraftManage`,
  values: mapTypesEnumToValues<GameTypeEnum>(GameTypeOptions),
}
export const PoolTypeOptions = [
  {
    label: "Challenge",
    value: PoolTypeEnum.CHALLENGE as TPoolType,
    description: `Challenge types *have a single pool* and therefore all entries compete against eachother.
  The pool is usually administrated by an admin user.
  Challenge types are used when:
  - An overall/global leaderboard is desired for a game
  - A client desires a pool in a game, but we know that pool will probably be over the recommended maxMembers for that game type`,
  },
  {
    label: "Manager",
    value: PoolTypeEnum.MANAGER as TPoolType,
    description: `Manager types *have multiple pools* that are created by standard (non-admin) users.`,
  },
] as TTypesEnumArray<PoolTypeEnum>
export const GamePoolType = {
  name: "GamePoolTypeEnumType",
  description: `A combination of the sport/league and the season/tournament/event.
  Examples: NflPlayoff, NflAllSeasons, NflThanksgivingWeek, NCAAMarchTournament, PgatourMasters`,
  values: mapTypesEnumToValues<PoolTypeEnum>(PoolTypeOptions),
}
export const TiebreakerQuestionKeyType = {
  name: "TiebreakerQuestionKeyEnumType",
  description: `Type of attribute the Question's value should track.`,
  values: {},
}
ValidTiebreakerQuestions.forEach(({ key, label }) => {
  TiebreakerQuestionKeyType.values[key] = {
    description: label,
    value: key,
  }
})
export const ExpertPicksSourceType = {
  name: "ExpertPicksSourceType",
  description: `List of possible expert/sportsline values`,
  values: {
    [AutoPickProgrammaticEnum.EXPERT_WALLY_SZCZERBIAK]: {
      description: "WALLY SZCZERBIAK",
      value: AutoPickProgrammaticEnum.EXPERT_WALLY_SZCZERBIAK,
    },
    [AutoPickProgrammaticEnum.EXPERT_GARY_PARRISH]: {
      description: "GARY PARRISH",
      value: AutoPickProgrammaticEnum.EXPERT_GARY_PARRISH,
    },
    [AutoPickProgrammaticEnum.EXPERT_MATT_NORLANDER]: {
      description: "MATT NORLANDER",
      value: AutoPickProgrammaticEnum.EXPERT_MATT_NORLANDER,
    },
    [AutoPickProgrammaticEnum.EXPERT_TIM_DOYLE]: {
      description: "TIM DOYLE",
      value: AutoPickProgrammaticEnum.EXPERT_TIM_DOYLE,
    },
    [AutoPickProgrammaticEnum.EXPERT_JERRY_PALM]: {
      description: "JERRY PALM",
      value: AutoPickProgrammaticEnum.EXPERT_JERRY_PALM,
    },
    [AutoPickProgrammaticEnum.EXPERT_SARAH_KUSTOK]: {
      description: "SARAH_KUSTOK",
      value: AutoPickProgrammaticEnum.EXPERT_SARAH_KUSTOK,
    },
    [AutoPickProgrammaticEnum.EXPERT_TRAVIS_BRANHAM]: {
      description: "TRAVIS BRANHAM",
      value: AutoPickProgrammaticEnum.EXPERT_TRAVIS_BRANHAM,
    },
    [AutoPickProgrammaticEnum.EXPERT_JOSH_NAGEL]: {
      description: "JOSH NAGEL",
      value: AutoPickProgrammaticEnum.EXPERT_JOSH_NAGEL,
    },
    [AutoPickProgrammaticEnum.EXPERT_OPTIMAL_BRACKET]: {
      description: "SPORTSLINE OPTIMAL BRACKET",
      value: AutoPickProgrammaticEnum.EXPERT_OPTIMAL_BRACKET,
    },
    [AutoPickProgrammaticEnum.EXPERT_UPSET_BRACKET]: {
      description: "SPORTSLINE UPSET BRACKET",
      value: AutoPickProgrammaticEnum.EXPERT_UPSET_BRACKET,
    },
    [AutoPickProgrammaticEnum.EXPERT_ISABEL_GONZALEZ]: {
      description: "ISABEL GONZALEZ (NCAAW)",
      value: AutoPickProgrammaticEnum.EXPERT_ISABEL_GONZALEZ,
    },
    [AutoPickProgrammaticEnum.EXPERT_JACK_MALONEY]: {
      description: "Jack Maloney (NCAAW)",
      value: AutoPickProgrammaticEnum.EXPERT_JACK_MALONEY,
    },
    [AutoPickProgrammaticEnum.EXPERT_MARIA_CORNELIUS]: {
      description: "Maria Cornelius (NCAAW)",
      value: AutoPickProgrammaticEnum.EXPERT_MARIA_CORNELIUS,
    },
    [AutoPickProgrammaticEnum.EXPERT_AARON_BARZILAI]: {
      description: "Aaron Barzilai (NCAAW)",
      value: AutoPickProgrammaticEnum.EXPERT_AARON_BARZILAI,
    },
    [AutoPickProgrammaticEnum.EXPERT_ERICA_AYALA]: {
      description: "Erica Ayala (NCAAW)",
      value: AutoPickProgrammaticEnum.EXPERT_ERICA_AYALA,
    },
    [AutoPickProgrammaticEnum.EXPERT_TERRIKA_FOSTER_BRASBY]: {
      description: "Terrika Foster-Brasby (NCAAW)",
      value: AutoPickProgrammaticEnum.EXPERT_TERRIKA_FOSTER_BRASBY,
    },
    [AutoPickProgrammaticEnum.EXPERT_SL_CALVIN_WETZEL]: {
      description: "SPORTSLINE Calvin Wetzel (NCAAW)",
      value: AutoPickProgrammaticEnum.EXPERT_SL_CALVIN_WETZEL,
    },
  },
}

// pool settings graphql enums

export const PicksDeadlineTypeGraphqlObject = {
  name: "PicksDeadlineEnumType",
  description: `Deadline logic for Picks to be made in a Period.`,
  values: mapTypesEnumToValues<PicksDeadlineTypeEnum>(PicksDeadlineType),
} as IGraphqlEnum<PicksDeadlineTypeEnum>
export const SpreadTypeGraphqlObject = {
  name: "SpreadEnumType",
  description: `Point spread sport betting type.`,
  values: mapTypesEnumToValues<SpreadTypeEnum>(SpreadType),
} as IGraphqlEnum<SpreadTypeEnum>
export const GameWeightTypeGraphqlObject = {
  name: "GameWeightEnumType",
  description: `Weighting scheme for weights placed on Picks.`,
  values: mapTypesEnumToValues<GameWeightTypeEnum>(GameWeightType),
} as IGraphqlEnum<GameWeightTypeEnum>
export const TiebreakerTypeGraphqlObject = {
  name: "TiebreakerEnumType",
  description: `Types of tiebreaker approaches.`,
  values: mapTypesEnumToValues<TiebreakerTypeEnum>(TiebreakerTypes),
} as IGraphqlEnum<TiebreakerTypeEnum>
export const ForgotPickFallbackTypeGraphqlObject = {
  name: "ForgotPickFallbackEnumType",
  description: `Types of fallback schemes for forgoten or late Picks.`,
  values: mapTypesEnumToValues<ForgotPickFallbackEnum>(ForgotPickFallback),
} as IGraphqlEnum<ForgotPickFallbackEnum>
export const GamesPerPeriodGraphqlObject = {
  name: "GamesPerPeriodEnumType",
  description: `Games per period Options.`,
  values: mapTypesEnumToValues<GamesPerPeriodEnum>(GamesPerPeriod),
} as IGraphqlEnum<GamesPerPeriodEnum>
export const RoundBonusTypeGraphqlObject = {
  name: "RoundBonusEnumType",
  description: `Types of Round/Period Bonus approaches.`,
  values: mapTypesEnumToValues<RoundBonusTypeEnum>(RoundBonusTypes),
} as IGraphqlEnum<RoundBonusTypeEnum>
export const RoundModifierTypeGraphqlObject = {
  name: "RoundModifierType",
  description: `Types of round bonus approaches.`,
  values: mapTypesEnumToValues<RoundModifierTypeEnum>(RoundModifierTypes),
} as IGraphqlEnum<RoundModifierTypeEnum>
export const MultipleEntriesOptionGraphqlObject = {
  name: "MultipleEntriesOption",
  description: `Options for multiple Entries.`,
  values: mapTypesEnumToValues<AvailableOptionEnum>(MultipleEntriesOption),
} as IGraphqlEnum<AvailableOptionEnum>
export const PickCountRangeOptionGraphqlObject = {
  name: "PickCountRangeOption",
  description: `Options for limiting maximum and minimum Picks count.`,
  values: mapTypesEnumToValues<AvailableOptionEnum>(PickCountRangeOption),
} as IGraphqlEnum<AvailableOptionEnum>
export const RoundModifiersOptionGraphqlObject = {
  name: "RoundModifiersOption",
  description: `Determines availability of round modifiers.`,
  values: mapTypesEnumToValues<AvailableOptionEnum>(RoundModifiersOption),
} as IGraphqlEnum<AvailableOptionEnum>

export const PeriodLengthGraphqlObject = {
  name: "PeriodLengthEnumType",
  description: `Options for Period Length.`,
  values: mapTypesEnumToValues<PeriodLengthEnum>(PeriodLengthType),
} as IGraphqlEnum<PeriodLengthEnum>

export const FinancesFeesFrequencySurvivorGraphqlObject = {
  name: "FinancesFeesFrequencySurvivorEnumType",
  description: "Frequency of fees for survivor pools",
  values: mapTypesEnumToValues<FinancesFeesFrequencySurvivor>(FinancesFeesFrequencySurvivorType),
} as IGraphqlEnum<FinancesFeesFrequencySurvivor>

export const FinancesFeesFrequencyOPMGraphqlObject = {
  name: "FinancesFeesFrequencyOPMEnumType",
  description: "Frequency of fees for OPM pools",
  values: mapTypesEnumToValues<FinancesFeesFrequencyOPM>(FinancesFeesFrequencyOPMType),
} as IGraphqlEnum<FinancesFeesFrequencyOPM>

export const MissedPeriodsOPMGraphqlObject = {
  name: "MissedPeriodsOPMEnumType",
  description: "Scoring policy of entries that did not make picks in the period",
  values: mapTypesEnumToValues<MissedPeriodsOPMEnum>(MissedPeriodsOPMType),
} as IGraphqlEnum<MissedPeriodsOPMEnum>

export const MatchupPositionGraphqlObject = {
  name: "MatchupPositionEnumType",
  description: `A playin Matchup is not pickable, however the resulting winner is considered a pickable Item.
  If picked, once the playin Matchup resolves, the Pick is replaced by the winning Item as if the picker chose it.`,
  values: {
    top: {
      description: `Resides in the top position of a Matchup visually.
      Usually is the Home team (if a team), but not always.`,
      value: "top" as TPlaysInto,
    },
    bottom: {
      description: `Resides in the bottom position of a Matchup visually.
      Usually is the Away team (if a team), but not always.`,
      value: "bottom" as TPlaysInto,
    },
  },
}

export const MatchupGroupPositionGraphqlObject = {
  name: "GroupPositionEnum",
  description: `Possible mapping positions for a Matchup's Group.
  Used to visually position a group of Matchup's visually.
  Usually corrisponds to Matchup.roundOrdinal`,
  values: {
    top: {
      description: "Top (Playin)",
      value: "top" as enum_groupPosition,
    },
    bottom: {
      description: "Bottom (Final Rounds)",
      value: "bottom" as enum_groupPosition,
    },
    top_left: {
      description: "Top Left (section: #1)",
      value: "top_left" as enum_groupPosition,
    },
    bottom_left: {
      description: "Bottom Left (section: #2)",
      value: "bottom_left" as enum_groupPosition,
    },
    top_right: {
      description: "Top right (section: #3)",
      value: "top_right" as enum_groupPosition,
    },
    bottom_right: {
      description: "Bottom Right (section: #4)",
      value: "bottom_right" as enum_groupPosition,
    },
  },
}

export const SpreadPushDeciderEnumGraphqlObject = {
  name: "SpreadPushDeciderEnum",
  description: `For Pools using spreads, this value is used to determine how to deal with pushes (ties with spreads).
  The basic logic goes: if UNDERDOG, then the underdog wins the push; if FAVORED, favored wins the push; otherwise its a push (tie/draw) still.
  Examples of how this converts into Prop questions:
  If spread is 3.0 and spreadPushDecider is FAVORED: "favored win by 3 or more points".
  If spread is 3.0 and spreadPushDecider is UNDERDOG: "favored win by 4 or more points".`,
  values: {
    FAVORED: {
      description: "Favored win a push",
      value: ENUM_FAVORED,
    },
    UNDERDOG: {
      description: "Underdog win a push",
      value: ENUM_UNDERDOG,
    },
    TIE: {
      description: "Push results in a tie (no winner / points awarded)",
      value: ENUM_TIE,
    },
  },
}
