import React from "react"

const WhistleSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <g clip-path="url(#clip0)">
      <path
        d="M37.81 8H18.309c-3.184 0-6.368 1.396-8.557 3.39C8.955 9.396 7.164 8 4.975 8 2.19 8 0 10.193 0 12.985c0 2.792 2.189 4.985 4.975 4.985.398 0 .796 0 1.194-.2 0 .599-.199.998-.199 1.596-.398 7.378 5.97 13.36 13.334 12.563 5.174-.599 9.353-4.587 10.348-9.572.199-1.396.199-2.792.199-4.187-.2-1.197.796-2.194 1.99-2.194h5.572c1.791 0 2.587-.997 2.587-1.994V9.994C39.801 8.798 38.806 8 37.81 8zM4.976 13.982c-.597 0-.995-.399-.995-.997s.398-.997.995-.997.995.399.995.997-.398.997-.995.997zM17.91 25.946c-3.383 0-5.97-2.592-5.97-5.982s2.587-5.982 5.97-5.982c3.384 0 5.97 2.592 5.97 5.982s-2.586 5.982-5.97 5.982zm0-9.97c2.19 0 3.98 1.795 3.98 3.988 0 2.194-1.79 3.988-3.98 3.988-2.189 0-3.98-1.794-3.98-3.988 0-2.193 1.791-3.988 3.98-3.988z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default WhistleSvg
