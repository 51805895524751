import { ReactNode } from "react"
import { ANCHORS } from "@cbs-sports/sports-shared-client/build/cjs/components/Popover"
import { PoolSeasonStandingsQuery } from "../../../../../../__generated__/PoolSeasonStandingsQuery"

export type BracketStatusType = "COMING_SOON" | "PICKABLE" | "LOCKED" | "ARCHIVED"

export type CHAMPION_PICK_STATUS = "UNKNOWN" | "CORRECT" | "INCORRECT"

export const DEFAULT_PLACEHOLDER = "—"
export type ColumnAlignType = "left" | "center" | "right"
export type PoolDataEntryType = PoolSeasonStandingsQuery["gameInstance"]["pool"]["entries"]["edges"][number]["node"]
export type DataEntryFn = (entry: PoolDataEntryType) => ReactNode

export const defaultDataEntryFnForKey = (fieldName: string) => (entry: PoolDataEntryType) => {
  return entry[fieldName]
}

export type ColumnDataType = {
  key: string
  label: string
  size?: number
  align?: ColumnAlignType
  description?: string
  descriptionTooltipProps?: {
    anchor?: ANCHORS
  }
  sortable?: boolean
  render?: DataEntryFn
  customClass?: string
}

export type StandingsTableSortOptionsType = {
  enabled: boolean
  sortBy?: string | null
  onSortChange?: (col: string) => void
}

export const COLUMN_MAP = {
  championTeam: "CHAMPION",
  correctPicks: "CORRECT_PICKS",
  fantasyPoints: "OVERALL_RANK",
  maxPoints: "MAX_POINTS",
  status: "STATUS",

  CHAMPION: "championTeam",
  CORRECT_PICKS: "correctPicks",
  OVERALL_RANK: "fantasyPoints",
  MAX_POINTS: "maxPoints",
  STATUS: "status",
}
