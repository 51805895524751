import * as React from "react"
import styled from "styled-components/macro"
import { fontWeight, layout, skyboxH, zIndexes } from "../../../../utils/style-utils"
import { pxToRem, palette, gridBreakPoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const { breakpoints } = gridBreakPoints
export const GameMarqeeWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  flex: 1;
  padding-top: ${pxToRem(16)};
  align-items: center;
  &.page-marquee__has-multiple-entries-per-user--true {
    padding-top: 0;
  }
  &.page-marquee__variant--compact {
    height: ${pxToRem(layout.poolTitleH)};
  }

  & .page-marquee__sponsor {
    height: 100%;
    margin-left: ${pxToRem(16)};
    padding-left: ${pxToRem(14)};
    border-left: 1px solid ${palette.gray90};
    & svg {
      width: ${pxToRem(80)};
      max-width: 27vw;
    }
  }
  @media (max-width: ${pxToRem(breakpoints.sm - 1)}) {
    & {
      padding-left: ${pxToRem(16)};
      padding-right: ${pxToRem(16)};
    }

    & .page-marquee__sponsor {
      & svg {
        width: ${pxToRem(64)};
      }
    }
  }
`
export const GameMarqeeRightCol = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.page-marquee__variant--standard {
    height: ${pxToRem(layout.pageMarqueeMinH)};
  }
  @media (max-width: ${pxToRem(breakpoints.md - 1)}) {
    &.page-marquee__variant--standard {
    height: ${pxToRem(layout.pageMarqueeMinH - 16)};
  }
  @media (max-width: ${pxToRem(breakpoints.sm - 1)}) {
    & {
      display: none;
    }
  }
`
export const Nbsp = () => <span>{"\u00A0"}</span>
export const PoolPageCardBody = styled.div`
  min-height: 4rem;
  background-color: #fff;
  border-radius: 0 0 ${pxToRem(8)} ${pxToRem(8)};
  border: solid 1px ${palette.gray90};
  border-top: 0;
  margin: 0;
  &.is-modal {
    position: relative;
  }
`
export const PicksPageLayoutContainer = styled.div`
  --page-max-w: calc(${pxToRem(layout.picksPageMaxW)} - var(--grid-margin) * 2);
  max-width: ${pxToRem(layout.pageContentMaxW)};
  max-width: var(--page-max-w);
  padding: 0 calc(var(--grid-margin) - var(--grid-gap));
  margin: 0 auto;

  &.is-bracket--true {
    & .bracket-section--midd {
      padding-bottom: 0 !important;
    }
    .col__middle {
      max-width: none;
      @media (max-width: 1600px) {
        & {
          overflow: hidden;
        }
      }
    }
  }

  & .weightingmode {
    padding: 1.05rem 1rem;
    width: 100%;
    margin: 0 0 0.5rem;
    & > * {
      margin: 0;
    }
    & > span {
      margin-left: 0.75rem;
      font-weight: ${fontWeight.semiBold};
      text-transform: uppercase;
    }
  }

  & > * {
    margin-top: ${pxToRem(layout.pageGutterW)};
    margin-top: var(--grid-gap);
  }
  & .col__right {
    flex: 0 0 300px;
    & .sportsline-ad {
      iframe {
        width: 100%;
        height: ${pxToRem(445)};
        background-color: ${palette.white};
      }
    }

    & > * {
      margin-bottom: ${pxToRem(layout.pageGutterW)};
      margin-bottom: var(--grid-gap);
    }
  }
  & .col__middle {
    flex: 1 1 auto;
    max-width: ${pxToRem(780)};
  }

  & .dt-handheld--false.static-copy {
    & > .custom-copy__text {
      position: static;
    }
  }

  & .gambling-partner-inline-ad-container {
    margin-bottom: 1.5rem;
    margin-bottom: var(--grid-gap);
    display: block;
  }

  @media (min-width: ${pxToRem(breakpoints.lg)}) {
    & .dt-desktop--false {
      display: none;
    }
  }
  @media (max-width: ${pxToRem(breakpoints.lg - 1)}) {
    & .col__right {
      display: none;
    }
  }
  @media (min-width: ${pxToRem(breakpoints.sm)}) {
    & {
      padding-right: ${pxToRem(layout.pageGutterW)};
      padding-right: var(--grid-margin);
      display: flex;
      flex-flow: row nowrap;
    }
    & > * {
      margin-left: ${pxToRem(layout.pageGutterW)};
      margin-left: var(--grid-gap);
    }
    & .col__middle {
      min-height: 75vh;
      margin-left: ${pxToRem(16)};
      margin-left: var(--grid-gap);
    }
    & .col__left {
      flex: 0 0 ${pxToRem(layout.picksPageLeftColW)};
      max-width: ${pxToRem(layout.picksPageLeftColW)};
      position: sticky;
      top: 0;
      /* page - skybox height */
      max-height: calc(89vh - ${skyboxH});
    }
    &.is-bracket--true .col__left {
      flex: 0 0 ${pxToRem(layout.picksPageBracketLeftColW)};
      max-width: ${pxToRem(layout.picksPageBracketLeftColW)};
    }
    & .col__group {
      flex: 1 1 100%;
      display: flex;
      flex-flow: row nowrap;
    }
    & .dt-handheld--true {
      display: none;
    }
    &.is-multiple-horiz-sections--false {
      padding: 0;
      max-width: unset;
    }
    &.is-multiple-horiz-sections--false > * {
      margin: 0;
    }
  }
  @media (max-width: ${pxToRem(breakpoints.sm - 1)}) {
    & .col__group {
      margin-top: 0;
    }
    & .col__left {
      &.handheld-uses-sticky--true {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: ${zIndexes.ui1};
        pointer-events: none;
        & .sticky-wrapper {
          pointer-events: all;
        }
      }
    }
    &.is-bracket--true .col__left {
      margin-top: 0;
    }
    & .dt-handheld--false {
      display: none;
    }
    &.is-multiple-horiz-sections--false {
      padding: 0;
    }
    &.is-multiple-horiz-sections--false > * {
      margin: 0;
    }
    &.is-bottom-sticky-pick-slip--true .is-hidden-for-bottom-pick-slip--true {
      display: none;
    }
    &.is-middle-col-dimmed--true .col__middle {
      position: relative;
    }
    & .picks-page-layout__dimmer {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #0000001f;
    }
  }
`

export const StyledPoolPageActions = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: max-height 0.4s ease-out;
  will-change: max-height;
  max-height: ${pxToRem(400)};
  height: auto;
  margin: 0 auto;
  max-width: var(--page-max-w);
  overflow: visible;

  & .pool-page-actions__items {
    display: flex;
    flex-direction: row;
  }

  // Small Mobile - Center
  @media (max-width: ${pxToRem(breakpoints.sm - 1)}) {
    margin-bottom: calc(0.75rem - 1px);
    &.is-toggled--true {
      margin-top: calc(0.75rem - 1px);
      transition: margin-top 500ms ease, margin-bottom 500ms ease;
    }

    &.is-toggled--false {
      margin-top: -2.75rem;
      margin-bottom: 0;
      transition: margin-top 500ms ease, margin-bottom 500ms ease;
    }
  }

  // Large Mobile - Move under nav and allow it to collapse/show/hide
  @media (max-width: ${pxToRem(breakpoints.md - 1)}) {
    position: relative;
    margin-right: 0.5rem;

    &.is-toggled--true {
      margin-top: calc(0.75rem - 1px);
      transition: margin-top 500ms ease, margin-bottom 500ms ease;
    }

    &.is-toggled--false {
      margin-top: -2rem;
      transition: margin-top 500ms ease, margin-bottom 500ms ease;
    }
  }

  // Desktop - Show in Marquee top/right
  @media (min-width: ${pxToRem(breakpoints.md)}) {
    position: absolute;
    margin-top: -4.7rem;
    right: var(--grid-gap);
    z-index: ${zIndexes.ui4};
  }
`
export const PoolPage = styled.div`
  //misalignment on big screen (when we show scroll bar)
  @media (min-width: 1304px) and (max-width: 1330px) {
    padding-left: calc(calc(100vw - 1304px) / 2);
  }
  //misalignment on big screen (when we show scroll bar)
  @media (min-width: 1330px) {
    padding-left: calc(100vw - 100%);
  }
`
