import styled from "styled-components"
import { palette, pxToRem } from "../../../utils/style-utils"

export const BottomBannerAdContainer = styled.div`
  padding: ${pxToRem(20)} 0;
`

export const FooterContainer = styled.div`
  background: ${palette.white};
  height: 100%;
  width: 100%;

  > div {
    min-width: unset;
    margin-bottom: 0;
  }

  & svg {
    width: inherit;
  }

  a:before {
    content: "";
  }
  span.icon-moon-facebook {
    :before {
      content: "\\e646" !important;
    }
  }
  span.icon-moon-twitter {
    :before {
      content: "\\e647" !important;
    }
  }
  span.icon-moon-youtube-play {
    :before {
      content: "\\e68b" !important;
    }
  }
  span.icon-moon-instagram {
    :before {
      content: "\\e907" !important;
    }
  }
  span.icon-moon-flipboard {
    :before {
      content: "\\e915" !important;
    }
  }
  span.icon-moon-tiktok {
    :before {
      content: "\\e939" !important;
    }
  }
`
