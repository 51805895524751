import { useQuery } from "@apollo/client"
import * as React from "react"
import { useRouteMatch } from "react-router"
import HamburgerSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Hamburger"
import { GlobalMediaNavQuery } from "../../../../__generated__/GlobalMediaNavQuery"
import { intoSlices } from "../../../../common/misc-utils"
import { IGameRootRouteMatch } from "../../../../routes.d"
import Dropdown from "../../../components/Dropdown"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import { GLOBAL_MEDIA_NAV_QUERY } from "../../queries"
import {
  CbsLogo,
  CbsLogoSection,
  CbsLogoSport,
  DropdownContainer,
  LogoLockup,
  NavDropdownItemIcon,
  NavDropdownItemText,
  NavSection,
  SiteNavHamburger,
  StyledNavDropdownItem,
} from "../styles/SiteNavDropdown.styles"
import { LayoutProps } from "../typings"
import PickEmArena from "../../../components/icons/PickEmArena"

const NavDropdownItem = ({ to, title, iconString, highlighted = false, isExternal = false }) => (
  <StyledNavDropdownItem highlighted={highlighted} title={title} to={to} target={isExternal ? "_blank" : undefined} external={isExternal}>
    {iconString && <NavDropdownItemIcon highlighted={highlighted} className={`icon-moon-${iconString || ""}`} />}
    <NavDropdownItemText className={isExternal ? "external-link" : ""}>{title}</NavDropdownItemText>
  </StyledNavDropdownItem>
)

interface ITogglerLayoutProps extends LayoutProps {
  isToggled: boolean
}

const Toggler = (props: ITogglerLayoutProps) => {
  // console.log(props);
  // const parts = props.pathname.split("/");
  // NOTE qac: the first item is ""
  // const sportCategories = sportTypeCategories[parts[1]];
  // const sportType = sportCategories && sportCategories.sportTypes[0].toLowerCase();
  const { isToggled } = props
  return (
    <LogoLockup {...props} as="button">
      <SiteNavHamburger className={`is-toggled--${isToggled}`}>
        <HamburgerSvg className={`is-toggled--${isToggled}`} />
      </SiteNavHamburger>
      <CbsLogo as={Link} to="https://www.cbssports.com/">
        <span className="icon-moon-cbs-eye" role="img" aria-label="CBS Sports" />
      </CbsLogo>
      <CbsLogoSection>
        <CbsLogoSport aria-label="CBS Sports PickEm Games">
          <PickEmArena />
        </CbsLogoSport>
      </CbsLogoSection>
    </LogoLockup>
  )
}

const DropdownContents = (props: LayoutProps) => {
  const match = useRouteMatch<IGameRootRouteMatch>()
  const sportType = (match && match.params.sportType) || "football"
  const skip = !sportType
  // TODO qac: https://reactjs.org/blog/2020/02/26/react-v16.13.0.html#warnings-for-some-updates-during-render
  const globalMediaNavQuery = useQuery<GlobalMediaNavQuery>(GLOBAL_MEDIA_NAV_QUERY, { skip })
  const arr = globalMediaNavQuery.data?.mediaGlobalNav
  const slices = arr && intoSlices(arr, 8)
  // console.dir(props)
  // console.dir(match)
  // console.dir(globalMediaNavQuery)
  return (
    <DropdownContainer {...props}>
      {slices ? (
        slices.map((items, i) => (
          <NavSection key={i}>
            {items.map(({ href, id, ...rest }) => {
              const highlighted = href.includes("/fantasy")
              return <NavDropdownItem key={id} to={href} highlighted={highlighted} {...rest} />
            })}
          </NavSection>
        ))
      ) : (
        <LoadingView style={{ width: "100%", height: "40vh" }} />
      )}
    </DropdownContainer>
  )
}

class SiteNavDropdown extends React.PureComponent<LayoutProps> {
  public render() {
    return <Dropdown {...this.props} toggler={Toggler} dropdown={DropdownContents} valueName="SiteNav" />
  }
}

export default SiteNavDropdown
