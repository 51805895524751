import React, { useEffect } from "react"
import { useLocation } from "react-router"
import styled from "styled-components/macro"
import BracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Bracket"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import BidBarrel from "../utils/bid-barrel"
import Ad from "./Ad"
import PoolDataContext, { PoolDataContextType } from "../Contexts/PoolDataContext"
import { withoutDomain } from "../../common/url-utils"
import { TAd } from "../App/Layout/typings"
import { impressionCacheBusterRegex, isMarchMadnessProductAbbrevMatcher } from "../../common/common-utils-helpers"
import ATTSponsorLogoSvg from "./icons/ATTSponsorLogo"
import NissanTMSvg from "./icons/NissanTM"

const SponsorLogoContainer = styled.div<{ logoWidth: string }>`
  & > .sponsor-logo-content {
    width: ${(props) => props.logoWidth};
    height: ${(props) => props.logoWidth};
    display: flex;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const SponsorLogoWith1x1 = React.memo(({ impressionUrl, logoWidth = "6.25rem" }: { impressionUrl: string; logoWidth?: string }) => {
  const poolDataContext: PoolDataContextType = React.useContext(PoolDataContext)
  const { productSeason } = poolDataContext || emptyObject

  useEffect(() => {
    const oneByOneImg = new Image()
    oneByOneImg.src = impressionUrl.replace(impressionCacheBusterRegex, Date.now().toString())
    oneByOneImg.height = 1
    oneByOneImg.width = 1
  }, [])

  if (!productSeason) {
    return null
  }

  const supportedMpids = isMarchMadnessProductAbbrevMatcher
  if (supportedMpids.test(productSeason.productAbbrev)) {
    return (
      <>
        <SponsorLogoContainer className="sponsor-logo-container" logoWidth={logoWidth}>
          {/w?bpm/.test(productSeason.productAbbrev) ? (
            <div className="sponsor-logo-content">
              <ATTSponsorLogoSvg className="att-logo" />
            </div>
          ) : (
            <div className="sponsor-logo-content">
              <NissanTMSvg className="nissan-logo" />
            </div>
          )}
        </SponsorLogoContainer>
      </>
    )
  }
  return null
})

export const SponsorLogoAd = React.memo(() => {
  const location = useLocation()
  const poolDataContext: PoolDataContextType = React.useContext(PoolDataContext)
  const { deviceType, poolDetail, productSeason } = poolDataContext || emptyObject
  const { pathname } = location
  const adLayoutProps = {
    deviceType,
    pathname,
    poolRootPathname: (poolDetail && withoutDomain(poolDetail.url)) || undefined,
    productAbbrev: productSeason && productSeason.productAbbrev,
    adType: "logo" as TAd,
  }

  const bracketLogoAdProps = BidBarrel.getDomValues({
    adLayoutProps,
    adSlot: "bracket_logo",
    requestedPos: "top",
  })

  return (
    <Ad
      adSlot={`bracket_logo`}
      useShSkybox={false}
      style={{ justifyContent: "flex-start", height: "auto" }}
      {...BidBarrel.extractProps(bracketLogoAdProps)}
    />
  )
})

const BracketLogoContainer = styled.div<{ logoWidth: string }>`
  display: flex;
  min-width: ${(props) => props.logoWidth};
  & > .bracket-logo {
    width: ${(props) => props.logoWidth};
    height: ${(props) => props.logoWidth};

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const Spacer = styled.div`
  width: 1px;
  background-color: ${palette.gray90};
  margin: 0 ${pxToRem(30)};
`

interface ILogoWithAdProps {
  showLogo?: boolean
  showSpacer?: boolean
  showSponsorLogo?: boolean
  logoWidth?: string
  sponsorLogoWidth?: string
  impressionUrl?: string
}

const LogoWithAd = React.memo(
  ({
    showLogo = true,
    showSpacer = true,
    showSponsorLogo = true,
    logoWidth = "6.25rem",
    sponsorLogoWidth = "6.25rem",
    impressionUrl,
  }: ILogoWithAdProps) => {
    return (
      <BracketLogoContainer logoWidth={logoWidth} className="bracket-logo-container">
        {showLogo && (
          <div className="bracket-logo">
            <BracketSvg />
          </div>
        )}
        {impressionUrl && showSponsorLogo && (
          <>
            {showSpacer && <Spacer />}
            <SponsorLogoWith1x1 impressionUrl={impressionUrl} logoWidth={sponsorLogoWidth} />
          </>
        )}
      </BracketLogoContainer>
    )
  },
)

export default LogoWithAd
