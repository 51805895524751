import React, { useEffect } from "react"
import { useMutation } from "@apollo/client"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import WomensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/WomensBracket"
import MensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/MensBracket"
import styled from "styled-components/macro"
import { FlexCols } from "../../../components/FlexComponents"
import { fontSizes, fontWeight, palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

import { MARK_WELCOME_BACK_MODAL_AS_VIEWED_MUTATION } from "../../queries"
import {
  markWelcomeBackModalAsViewedMutation,
  markWelcomeBackModalAsViewedMutationVariables,
} from "../../../../__generated__/markWelcomeBackModalAsViewedMutation"
import { Link } from "react-router-dom"
import { withoutDomain } from "../../../utils/url-utils"
const ModalContent = styled.div`
  padding: 1.4rem ${pxToRem(40)};
  & .brackets-icon {
    width: ${pxToRem(65)};
    height: ${pxToRem(65)};
  }
  & .title-and-description {
    margin-top: 0.625rem;
    & .modal-title {
      font-weight: ${fontWeight.bold};
      font-size: ${pxToRem(18)};
      line-height: 1.5rem;
      letter-spacing: -0.1px;
      color: ${palette.gray20};
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .modal-description {
      width: 22rem;
      line-height: 1.25rem;
      color: ${palette.gray40};
      font-size: ${fontSizes.regular};
      margin-top: 0.625rem;
      & .list-item-title {
        font-weight: ${fontWeight.bold};
        font-size: ${fontSizes.medium};
        color: ${palette.default};
      }
      & .list-item-subtitle {
        color: ${palette.gray40};
        & .new-tag {
          color: ${palette.lightBlue3};
          font-weight: ${fontWeight.semiBold};
          text-transform: uppercase;
        }
      }
    }
  }
  & ul {
    li {
      white-space: normal;
      list-style: square;
      color: ${palette.default};
      margin-left: ${pxToRem(30)};
      margin-bottom: 0.625rem;
      strong {
        font-weight: 700;
        color: ${palette.default};
      }
    }
  }
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: ${pxToRem(14)};
    & > :first-child {
      margin-right: ${pxToRem(16)};
    }
  }
`
interface IManagerWelcomeBackModalProps {
  isOpen: boolean
  onClose: () => void
  year: number | undefined
  poolId: string
  poolUrl: string
  onInvitePoolClick: () => void
  isWomenBracket: boolean
}
const ManagerWelcomeBackModal: React.FC<IManagerWelcomeBackModalProps> = (props) => {
  const { isOpen, onClose, onInvitePoolClick, poolId, poolUrl, isWomenBracket } = props
  const [updateWelcomeBackModalSeenByManagerMutation] = useMutation<
    markWelcomeBackModalAsViewedMutation,
    markWelcomeBackModalAsViewedMutationVariables
  >(MARK_WELCOME_BACK_MODAL_AS_VIEWED_MUTATION)
  useEffect(() => {
    if (isOpen) {
      updateWelcomeBackModalSeenByManagerMutation({ variables: { poolId: poolId } }).catch((err) => window.SH_ERROR_NOTIFY(err, "welcome back modal"))
    }
  }, [isOpen, poolId, updateWelcomeBackModalSeenByManagerMutation])
  return (
    <Modal isOpen={isOpen} padded={false} closeBtnAction={onClose}>
      <ModalContent>
        <FlexCols>
          <div className="brackets-icon">{isWomenBracket ? <WomensBracketSvg /> : <MensBracketSvg />}</div>
          <div className="title-and-description">
            <span className="modal-title">Welcome Back to Your Pool</span>
            <p className="modal-description">
              We are looking forward to another great tournament! Before the madness starts, here are a few things to get your pool in order before
              tip-off:
            </p>
            <ul className="modal-description">
              <li>
                <h6 className="list-item-title">Update Pool Details</h6>
                <p className="list-item-subtitle">Review/Edit your pool’s scoring rules or update your pool name within pool settings.</p>
              </li>
              <li>
                <h6 className="list-item-title">Allow Participants to Invite</h6>
                <p className="list-item-subtitle">
                  <span className="new-tag">New!</span> Allow pool participants to invite their friends by opening access to the pool invite options.
                  You can activate this new feature within pool settings.
                </p>
              </li>
              <li>
                <h6 className="list-item-title">Alert Previous Members</h6>
                <p className="list-item-subtitle">Let previous pool members know your pool is back for the new year.</p>
              </li>
            </ul>
          </div>
        </FlexCols>
        <div className="action-buttons">
          <StyledButton
            className="variant--secondary cta"
            as={Link}
            to={{ pathname: `${withoutDomain(poolUrl)}/settings`, state: { actions: { showInviteAllOnSave: true } } }}
          >
            Update Settings
          </StyledButton>
          <Button onClick={onInvitePoolClick}>Alert Players</Button>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ManagerWelcomeBackModal)
