import React from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { IJoinPoolProps } from "../PoolSetupTypes.d"
import LimboPage from "../../PoolPages/containers/MultipleEntriesArea/LimboPage"
import constants from "../../../../common/constants"

function JoinPoolWrapper(props: IJoinPoolProps) {
  const { poolRoot, joinUrl, isPoolJoinRoute, entryId } = props
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch<any>()
  const messageBoardMatch = useRouteMatch({
    path: constants.MESSAGE_BOARD_ROUTE,
    strict: true,
    sensitive: true,
  })

  const testingChallengePoolMatch = useRouteMatch({
    path: constants.TESTING_CHALLENGE_POOL_ROUTE,
    strict: true,
    sensitive: true,
  })

  // TODO qac: memoize callbacks
  const onFinish = () => history.push(poolRoot)
  const redirectOnMount = isPoolJoinRoute ? (entryId ? onFinish : undefined) : () => history.replace(joinUrl)
  // NOTE qac: invitedViaUser && invitedViaUser.avatarUrl doesnt work for avatars!

  return (
    <LimboPage
      {...props}
      history={history}
      location={location}
      match={match}
      redirectOnMount={messageBoardMatch?.isExact || testingChallengePoolMatch?.isExact ? undefined : redirectOnMount}
    />
  )
}

export default JoinPoolWrapper
