import { fontSizes, palette, zIndexes } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"

export const DropDownMenuWrap = styled.div`
  position: relative;

  & > .option-list-container {
    position: absolute;
    background: ${palette.white};
    border-radius: 0.25rem;
    margin-top: 1px;
    font-size: ${fontSizes.regular};
    z-index: ${zIndexes.toolTip};
    display: none;
    padding: 0;

    ul {
      display: block;
      padding: 0;
      margin: 0;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 0.25rem;
      max-height: 15rem;
      overflow-y: auto;

      & > .separator {
        margin: 0.5rem 0;
        background-color: ${palette.gray90};
        width: 100%;
        height: 1px;
        pointer-events: none;
      }

      & > li {
        color: ${palette.gray50};
      }
    }

    & > ul.option-container {
      padding: 0.5rem 0;
    }

    li:active,
    li:focus,
    li.is-selected {
      outline: none;
      user-select: none;
      border: none;
      color: ${palette.gray20};

      & > .menu-content {
        color: ${palette.gray20};
      }

      > a {
        outline: none;
        user-select: none;
        border: none;
      }
    }
  }
  & > .option-list-container.display-block {
    display: block;
  }
`
