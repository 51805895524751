import { useContext } from "react"
import PoolDataContext, { PoolDataContextType } from "../Contexts/PoolDataContext"
import constants from "../../common/constants"

export enum StandingOptions {
  None = 0, //Represents an empty set of options
  Pagination = 1 << 0, //When to show the pagination control in the for the overall standings page
  Sorting = 1 << 1, //When to make the overall standings section sortable. Pool might still be non-sortable if it is outside the sortable threshold (for performance)
  DisplayCompletedBracketsCount = 1 << 2, //When to show the completed brackets
  DisplayPrelockBrackets = 1 << 3, // When to display an empty list of brackets in the Pre-Lock state or just show a message instead
  DisplayPrelockStatus = 1 << 4, //When to show the status column in the Pre-Lock state
  DisplayPoolHistory = 1 << 5, //When to display teh dropdown to select a previous edition of the current pool
}

type OptionsType = number
type OptionCheckerType = (opt: StandingOptions) => boolean

const DEFAULT_STANDING_OPTIONS = StandingOptions.Pagination | StandingOptions.Sorting

function checkOption(alloptions: OptionsType): OptionCheckerType {
  return (opt: StandingOptions) => {
    return !!(alloptions & opt)
  }
}

function useStandingOptions(): [OptionsType, OptionCheckerType] {
  const context = useContext<PoolDataContextType>(PoolDataContext)
  const gameInstanceUid = context?.gameInstanceUid ?? ""

  let optionsForGameInstance: number = StandingOptions.None

  switch (gameInstanceUid) {
    case constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID:
    case constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID:
    case constants.NCAAB_SWEET_SIXTEEN_GAME_INSTANCE_UID:
      optionsForGameInstance = StandingOptions.None
      break
    case constants.NCAAB_MANAGER_GAME_INSTANCE_UID:
    case constants.NCAAW_MANAGER_GAME_INSTANCE_UID:
      optionsForGameInstance =
        StandingOptions.Pagination |
        StandingOptions.Sorting |
        StandingOptions.DisplayCompletedBracketsCount |
        StandingOptions.DisplayPoolHistory |
        StandingOptions.DisplayPrelockBrackets |
        StandingOptions.DisplayPrelockStatus
      break
    case constants.NISSAN_NCAAB_CHALLENGE_GAME_INSTANCE_UID:
    case constants.NISSAN_NCAAW_CHALLENGE_GAME_INSTANCE_UID:
      optionsForGameInstance =
        StandingOptions.Pagination |
        StandingOptions.Sorting |
        StandingOptions.DisplayCompletedBracketsCount |
        StandingOptions.DisplayPrelockBrackets |
        StandingOptions.DisplayPrelockStatus
      break
    default:
      optionsForGameInstance = DEFAULT_STANDING_OPTIONS
      break
  }

  const hasOption = checkOption(optionsForGameInstance)

  return [optionsForGameInstance, hasOption]
}

export default useStandingOptions
