import { CookieOptions } from "express"
import constants from "./constants"

export const fantasyJWTCookieName = `fantasyTokenPicks-${constants.APP_ENV}`
export const cbsPidCookieName = `pid`
export const cbsPpidCookieName = `ppid`
export const cbsFlySessionCookieName = `fly-session`
export const isNativeAppCookieName = `_sh-is-native-app`
export const nativePidQueryParam = `cbs-app-pid`
export const nativeAccessTokenHeadersParam = `access-token`
export const nativeAccessTokenQueryParam = `cbs-app-access-token`
export const cbsAppChromeToggleQueryParam = `c` // c=sc triggers the chrome removal on cbs.com
export const cbsAppChromeToggleCookieName = `fly_params_c` // this is set to whatever the value is (ex: %22sc%22)
export const cbsAppUserTokenCookieName = `picks-cbs-app-token`
export const cbsAppCookieChromeValue = "sc"
export const localStateCookieName = "_sh-local-state"
export const flyGeoCookieName = "fly_geo"
export const flyGeoOverrideCookieName = "fly_geo_override"
export const initialReferrerCookieName = "initial_referrer"
export const pwaShellTtagSuffix = "appshell"
export const cbsDebugCookieName = "debug"
export const picksPoolsExists = "picks-pools-exists"
export const picksAutoFillCallout = "picks-autofill-callout"
export const importantCookieNames = [
  fantasyJWTCookieName,
  cbsPidCookieName,
  cbsPpidCookieName,
  cbsFlySessionCookieName,
  isNativeAppCookieName,
  // nativePidQueryParam,
  // nativeAccessTokenHeadersParam,
  // cbsAppChromeToggleCookieName,
  // cbsAppCookieChromeValue,
  localStateCookieName,
  flyGeoCookieName,
  flyGeoOverrideCookieName,
  initialReferrerCookieName,
  // pwaShellTtagSuffix,
  cbsDebugCookieName,
]
export const fantasyJWTCookieExpiresIn = 3600 * 1000 * 24 * 365 * 10 // 10 years
export const localCookieOptions = { maxAge: fantasyJWTCookieExpiresIn, path: "/" } as CookieOptions
export const cbsPidCookieOptions = {
  maxAge: fantasyJWTCookieExpiresIn,
  path: "/",
  domain: ".cbssports.com",
  sameSite: constants.SSL ? "none" : "lax",
  secure: constants.SSL,
} as CookieOptions
// ?c=sc&cbs-app-access-token=Bearer%20otRvPaxI%2FqVE6CnKlz5MnXitaqRR1PnOOTJObMIzwZEyQgRX57ZmTw4dvEBxipWtoIcAKR0HqxfZ%2FGdxldGDfQ%3D%3D
// otRvPaxI/qVE6CnKlz5MnXitaqRR1PnOOTJObMIzwZEyQgRX57ZmTw4dvEBxipWtoIcAKR0HqxfZ/GdxldGDfQ==

export function isCookiePresent(cookieName: string): boolean {
  if (typeof document === "undefined") {
    return false
  }
  const re = new RegExp(`^(.*;)?\\s*${cookieName}\\s*=\\s*[^;]+(.*)?$`)
  return re.test(document.cookie)
}
