import styled from "styled-components/macro"
import { layout, pxToRem } from "../../../utils/style-utils"

const PageContent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${pxToRem(layout.pageContentMaxW)};
  max-width: var(--page-max-w);
`
export default PageContent
