import React from "react"
import styled from "styled-components/macro"
import { breakpoints, layout, pxToRem } from "../../../utils/style-utils"
import PageContent from "../../atoms/Page/PageContent"

// https://github.com/cbs-sports/site-sportsmedia/blob/qa/src/SportsMedia/CssBundle/Resources/sass/v2/theme-helper/_breakpoints.scss
// $bp-mobile-height: 450;
// $bp-mobile-min: 321;
// $bp-mobile-max: 500;
// $bp-mobile: $bp-mobile-max;
// $bp-tablet: 768;
// $bp-desktop-narrow: 1024;
// $bp-desktop: 1278;
// $bp-desktop-wide: 1536;
// @mixin breakpoint($point) {
//   @media (min-width: $point + 0px) {
//       @content;
//   }
// }

// @mixin breakpointMax($point) {
//   @media (max-width: $point - 1px) {
//       @content;
//   }
// }

// @mixin breakpointMinMax($pointMin, $pointMax) {
//   @media (min-width: $pointMin + 0px) and (max-width: $pointMax - 1px) {
//       @content;
//   }
// }
// // Mobile/Tablet landscape.
// @mixin breakpointOrientation($pointMin, $pointMax, $orientation) {
//   @media (min-device-width: $pointMin + 0px) and (max-device-width: $pointMax - 1px) and (orientation: $orientation) {
//       @content;
//   }
// }
// xs: 0,
// sm: 768,
// md: 1024,
// lg: 1278,
// xl: 1536,
const bpTablet = pxToRem(breakpoints.sm)
const bpLaptop = pxToRem(breakpoints.md)
const bpDesktop = pxToRem(breakpoints.lg)
const pPad = pxToRem(layout.pageGutterW)
const sidecolW = pxToRem(layout.pageRightSideColW)

//github.com/cbs-sports/site-sportsmedia/blob/qa/src/SportsMedia/CssBundle/Resources/sass/v2/utilities/_page-layouts.scss
const PageLayout = styled.main`
  display: -ms-grid;
  display: grid;
  box-sizing: border-box;
  padding: ${pPad};
  padding: var(--grid-gap) var(--grid-margin);
  &.grid-layout__padded--false {
    padding-left: 0;
    padding-right: 0;
  }

  /* default to mobile: */
  grid-gap: var(--grid-gap);
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  /* implement css-grid-layout("fluid-col")
  * 'Fluid-col' is a 12 col layout where the last column is always 300px.
  */
  &.grid-layout__variant--fluid-col {
    @media (min-width: ${bpLaptop}) {
      & {
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ${sidecolW};
        grid-template-columns: repeat(11, minmax(0, 1fr)) ${sidecolW};
      }
    }
  }

  /*
  * implement css-grid-layout()
  * All other grid layouts will follow a 12 col layout.
  * Mobile to tablet 4 col.
  * Tablet and above is 12 col.
  */
  &.grid-layout__variant--standard {
    @media (min-width: ${bpTablet}) {
      & {
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: repeat(12, minmax(0, 1fr));
      }
    }
  }
`

const MainCol = styled.section`
  box-sizing: border-box;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1/-1;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: ${pPad};
    margin-right: var(--grid-gap);
  }
  @media (min-width: ${bpTablet}) {
    & {
      -ms-grid-column-span: 12;
      grid-column: 1/-1;
    }
  }
  &.grid-layout__cols--3 {
    & {
      grid-row: 1/2;
    }
    @media (min-width: ${bpLaptop}) {
      & {
        -ms-grid-column-span: 11;
        grid-column: 1/12;
      }
    }
    @media (min-width: ${bpLaptop}) {
      & {
        -ms-grid-column: 5;
        -ms-grid-column-span: 7;
        grid-column: 5/12;
      }
    }
    @media (min-width: ${bpDesktop}) {
      & {
        -ms-grid-column: 4;
        -ms-grid-column-span: 8;
        grid-column: 4/12;
      }
    }
  }
  &.grid-layout__cols--2 {
    @media (min-width: ${bpLaptop}) {
      & {
        -ms-grid-column-span: 11;
        grid-column: 1/12;
      }
    }
  }
`

const LeftCol = styled.aside`
  box-sizing: border-box;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1/-1;
  -ms-grid-row: 3;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-right: ${pPad};
    margin-right: var(--grid-gap);
  }
  @media (min-width: ${bpTablet}) {
    &.grid-layout__cols--3 {
      -ms-grid-column-span: 12;
      grid-column: 1/12;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      grid-row: 2/3;
    }
  }
  @media (min-width: ${bpLaptop}) {
    &.grid-layout__cols--3 {
      -ms-grid-column-span: 4;
      grid-column: 1/5;
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      grid-row: 1/2;
    }
  }
  @media (min-width: ${bpDesktop}) {
    &.grid-layout__cols--3 {
      -ms-grid-column-span: 3;
      grid-column: 1/4;
    }
  }
`

const RightCol = styled.aside`
  box-sizing: border-box;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1/-1;
  -ms-grid-row: 2;
  @media (min-width: ${bpTablet}) {
    & {
      -ms-grid-column-span: 12;
      -ms-grid-column-span: 1;
      -ms-grid-column: 12;
      grid-column: 12/-1;
      -ms-grid-row: 1;
      grid-row: 1/2;
    }
  }
  @media (max-width: ${pxToRem(breakpoints.md - 1)}) {
    display: none;
  }
`

export interface IPageLayoutProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  leftCol?: React.ReactNode
  rightCol?: React.ReactNode
  mobilePadded?: boolean
  padded?: boolean
}

const Page: React.FC<IPageLayoutProps> = ({ children, leftCol, rightCol, padded = true, mobilePadded = false, className = undefined, ...rest }) => {
  const isThreeCol = !!(children && leftCol && rightCol)
  const isOneCol = !!children && !leftCol && !rightCol
  const isTwoCol = !isThreeCol && !isOneCol
  const variant = (isOneCol && "standard") || "fluid-col"
  const baseClassNames = [
    `grid-layout__variant--${variant}`,
    `grid-layout__padded--${!!padded}`,
    `grid-layout__mobile-padded--${!!mobilePadded}`,
    `grid-layout__cols--${(isOneCol && 1) || (isTwoCol && 2) || 3}`,
  ]
  const classNames = baseClassNames.concat([])
  if (className) {
    classNames.push(className)
  }
  const baseClassName = baseClassNames.join(" ")
  return (
    <PageContent>
      <PageLayout className={classNames.join(" ")}>
        {leftCol && <LeftCol className={baseClassName}>{leftCol}</LeftCol>}
        {children && <MainCol className={baseClassName}>{children}</MainCol>}
        {rightCol && <RightCol className={baseClassName}>{rightCol}</RightCol>}
      </PageLayout>
    </PageContent>
  )
}

export default Page
