import React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import styled from "styled-components/macro"
import { TSlotId } from "../App/Layout/constants/adDefinitions"
import { TAd } from "../App/Layout/typings"
import BidBarrel, { IAdSlot } from "../utils/bid-barrel"
import { pxToRem, skyboxH } from "../utils/style-utils"
import DCLK from "./DCLK"

interface IProps extends IAdSlot, RouteComponentProps {
  className?: string
  skyboxHeight?: number
  useShSkybox?: boolean
  adType?: TAd
  adSlot: TSlotId
  style?: React.CSSProperties
  dataMetadata?: unknown
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  & > div {
    max-height: 100%;
    overflow: hidden;
  }
`
const SkyboxContainer = styled.div`
  width: 100%;
  min-height: ${skyboxH};
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  & > * > * {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }
`

// const register = ({sizes, pos, name, ptype}) => BidBarrel.register(name, sizes, pos, ptype);
// const teardown = ({sizes, pos, name, ptype}) => BidBarrel.teardown(name, sizes, pos, ptype);

class Ad extends React.PureComponent<IProps> {
  public skyboxEl: any
  public height = 0
  public siteNavEl: any

  public componentDidUpdate(prevProps: IProps) {
    // console.debug(`componentDidUpdate`, prevProps.name, this.props.name)
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      BidBarrel.refresh(this.props)
    }
  }

  // public onResize = (newHeight: number) => {
  //   // console.log(`------ dims: ${newHeight}`)
  //   if (this.height !== newHeight) {
  //     this.height = newHeight;
  //     if (!this.siteNavEl && typeof(document) !== "undefined") {
  //       this.siteNavEl = document.getElementById("faux-cbs-site-nav");
  //     }
  //     if (this.siteNavEl) {
  //       this.siteNavEl.style.top = `${newHeight}px`;
  //     }
  //   }
  // }

  // public onAttrChange = (mutations: any) => {
  //   // console.log("------ attributes changed", mutations)
  //   const mutation = mutations.find(({type}) => type === "attributes");
  //   const target = mutation && mutation.target;
  //   let height = target && target.dataset.skyboxmaxheight;
  //   if (height && target.children.length) {
  //     const styleAttrParts = ((target.getAttribute("style") || "").split(";").find((p) => /height\:/i.test(p)) || "").split("height: ");
  //     if (styleAttrParts.length > 1) {
  //       height = styleAttrParts[1].split("px")[0];
  //     }
  //     // console.log(`via height: ${height}`, target)
  //     this.onResize(parseInt(height));
  //   } else {
  //     // console.log(`via el`, this.skyboxEl)
  //     if (this.skyboxEl) {
  //       this.onResize(this.skyboxEl.getBoundingClientRect().height);
  //     }
  //   }
  // }

  public componentDidMount() {
    // console.debug(`componentDidMount`)
    // console.log("🚀 DEBUG -> Ad -> componentDidMount -> register: this.props = ", this.props)
    BidBarrel.register(this.props)
  }
  public componentWillUnmount() {
    // console.debug(`componentWillUnmount`)
    // console.log("🚀 DEBUG -> Ad -> componentWillUnmount -> this.props.adSlot:", this.props.adSlot)
    BidBarrel.teardown(this.props)
  }

  public render() {
    const { adSlot, adType, sizes, name, className, skyboxHeight, useShSkybox, style: styleProps, dataMetadata } = this.props
    if (skyboxHeight) {
      if (useShSkybox) {
        return (
          <SkyboxContainer className={className} style={styleProps}>
            <DCLK adType={adType} adSlot={adSlot} />
            <div id={name} data-skyboxminheight={skyboxHeight} data-skyboxmaxheight={skyboxHeight} />
          </SkyboxContainer>
        )
      } else {
        return (
          <SkyboxContainer className={className} style={styleProps}>
            <DCLK adType={adType} adSlot={adSlot} />
            <div id={name} data-skyboxminheight={skyboxHeight} data-skyboxmaxheight={skyboxHeight} />
          </SkyboxContainer>
        )
      }
    } else {
      const parsed = JSON.parse(sizes) as any[]
      const multiSized = typeof parsed[0] !== "number"
      const heights = multiSized ? parsed.map((pair) => pair[1]) : ([parsed[1]] as number[])
      const maxH = Math.max(...heights)
      const style = adSlot === "mpu_top" ? { height: `${maxH}px`, ...styleProps } : { height: pxToRem(skyboxHeight || maxH), ...styleProps }
      return (
        <Container className={className} style={style}>
          <DCLK adType={adType} adSlot={adSlot} />
          <div id={name} data-metadata={dataMetadata} />
        </Container>
      )
    }
  }
}

export default withRouter(Ad)
