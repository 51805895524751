import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import React, { useContext, useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { InviteRowWrap, PasswordModalWrap, PoolNameWrap } from "./InviteRow.styles"
import { CurrentUserPendingInvites_pendingInvitations } from "../../../../../__generated__/CurrentUserPendingInvites"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { DECLINE_POOL_INVITATION_MUTATION } from "../../../queries"
import { DeclinePoolInvitationMutation, DeclinePoolInvitationMutationVariables } from "../../../../../__generated__/DeclinePoolInvitationMutation"
import GenericConfirmationModal from "../EntryModals/GenericConfirmationModal"
import { getStringParam, withoutDomain } from "../../../../../common/url-utils"
import GenericEntryModal from "../EntryModals/GenericEntryModal"
import FormInput from "@cbs-sports/sports-shared-client/build/cjs/components/Form/Input"
import { extractValidationError } from "../../../../../common/apiErrors"
import { PoolDetailsQuery_pool } from "../../../../../__generated__/PoolDetailsQuery"
import { getAuthSearch, getInvitationInfo } from "../../../../utils/url-utils"
import { pseudoDecrypt } from "../../../../../common/string-utils"
import { formatMembersCount } from "../../../../utils/data-utils"
import { TUpsertEntryMutation } from "../../../PoolSetupPages/PoolSetupTypes"
import Analytics from "../../../../utils/analytics"
import { useDeviceType } from "../../../../Base/DeviceType"
import PoolDataContext from "../../../../Contexts/PoolDataContext"
import CreateEntryForm from "../../../PoolSetupPages/components/CreateEntryForm"
import JoinedToPoolModal from "../EntryModals/JoinedToPoolModal"
import constants from "../../../../../common/constants"
import WhistleSvg from "../../../../components/icons/Whistle"

import { EmailSenderType, invitedBySearchParamKey, senderRoleSearchParamKey } from "../../../../../common/email-invites"

interface InviteRowProps {
  mode?: "view" | "join"
  invite?: CurrentUserPendingInvites_pendingInvitations
  pool?: PoolDetailsQuery_pool
  isLoggedIn?: boolean
  isCbsAppWebview?: boolean
  mutation?: TUpsertEntryMutation
}

type WarningModalType = "manager" | "participant" | null

const InviteRow = ({ mode = "view", invite, pool: propPool, mutation, isLoggedIn, isCbsAppWebview = false }: InviteRowProps) => {
  const poolData = useContext(PoolDataContext)
  const { isChallengePool, entryId, upsertEntryMutation, gameInstanceUid, productSeason, centralBracketState } = poolData || emptyObject

  const deviceType = useDeviceType()
  const [loading, setLoading] = useState(false)
  const [joining, setJoining] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState<WarningModalType>(null)
  const [showCreateEntry, setShowCreateEntry] = useState(false)
  const [poolPassword, setPoolPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const history = useHistory()
  const location = useLocation()
  const lobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)
  const { invitedBy, pool } = invite || emptyObject
  const { name, avatarUrl, entriesCount, slogan, usesMagicLink, id: poolId, url } = propPool || pool || emptyObject
  const { inviteUrl } = pool || emptyObject
  const to = inviteUrl ? withoutDomain(inviteUrl) : ""

  const [declinePoolInvitationMutation] = useMutation<DeclinePoolInvitationMutation, DeclinePoolInvitationMutationVariables>(
    DECLINE_POOL_INVITATION_MUTATION,
    {
      refetchQueries: ["CurrentUserPendingInvites"],
      awaitRefetchQueries: true,
      variables: {
        invitationId: invite?.pendingInvitationId || "",
      },
    },
  )

  const onDecline = async () => {
    setShowModal(false)
    setLoading(true)
    try {
      if (invite?.pendingInvitationId) {
        const result = await declinePoolInvitationMutation()
        if (!result) {
          console.error("An error has occurred, please try again later")
        }
      }
      if (mode === "join") {
        history.push(lobbyMatch?.url || "")
      }
    } catch (error: any) {
      const errorData = extractValidationError(error)
      if (errorData?.errors?.base?.length > 0) {
        const errosMessage = errorData.errors.base[0]
        console.error(errosMessage)
      }
    } finally {
      setLoading(false)
    }
  }

  const joinPool = async () => {
    setJoining(true)
    Analytics.trackInteraction("join pool - submit")
    const invitedByEntryId = getStringParam(invitedBySearchParamKey, location.search)
    const variables = {
      poolId,
      password: poolPassword,
      invitationId: invite?.pendingInvitationId,
      invitedByEntryId,
    }
    if (mutation) {
      await mutation({ variables })
        .then((res) => {
          Analytics.trackInteraction("join pool - success")
          if (deviceType === "handheld") {
            setTimeout(() => {
              history.push(withoutDomain(`/games/roadblock?from=join-flow&gameType=${gameInstanceUid}`))
            }, 2000)
          } else {
            if (centralBracketState?.bracketState?.isPreTournament) {
              setTimeout(() => {
                history.replace({
                  pathname: withoutDomain(`${url}/standings`),
                  state: {
                    modal: {
                      modalId: JoinedToPoolModal.modalKey,
                    },
                  },
                })
              }, 2000)
            } else {
              setTimeout(
                () => {
                  history.push(withoutDomain(res.data?.upsertEntry?.entry.url || `${url}/standings`))
                },
                showPasswordModal ? 2000 : 1,
              )
            }
          }
          setShowPasswordModal(false)
        })
        .catch((err) => {
          const apiErrors = extractValidationError(err)
          if (apiErrors?.errors?.password?.length > 0) {
            setErrorMessage("Incorrect password")
          } else if (apiErrors?.errors?.openInvites?.length) {
            setShowWarningModal("participant")
          } else if (apiErrors?.errors?.base?.length > 0) {
            setErrorMessage(apiErrors.errors.base[0])
          }
          Analytics.trackInteraction("join pool - fail")
        })
        .finally(() => {
          setJoining(false)
          setShowCreateEntry(false)
        })
    }
  }

  const hidePoolPasswordModal = () => {
    setShowPasswordModal(false)
    setPoolPassword("")
    setErrorMessage("")
  }

  const onPoolPasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPoolPassword(event.currentTarget.value)
  }
  const onJoinClick = () => {
    if (isChallengePool) {
      setShowCreateEntry(true)
    } else if (usesMagicLink && poolPassword) {
      joinPool()
    } else {
      setShowPasswordModal(true)
    }
  }

  useEffect(() => {
    const { encryptedPassword } = getInvitationInfo(location.search)
    const pass = pseudoDecrypt(encryptedPassword || "")
    setPoolPassword(pass)
    handleWarningModal(pass)
  }, [location.search])

  const handleWarningModal = (poolPassword: string) => {
    if (usesMagicLink && isLoggedIn && mode === "join") {
      const encryptedSenderRole = getStringParam(senderRoleSearchParamKey, location.search)
      const senderRole = (encryptedSenderRole ? pseudoDecrypt(encryptedSenderRole) : undefined) as EmailSenderType | undefined
      const poolUsesOpenInvites =
        (constants.ENABLE_OPEN_INVITES &&
          poolData?.poolDetail?.poolSettings.__typename === "PoolSettings" &&
          poolData?.poolDetail.poolSettings.openInvites) ||
        false
      if (typeof senderRole !== "undefined" && !["manager", "participant"].includes(senderRole)) {
        setShowWarningModal("manager")
      } else if (senderRole === "participant" && !poolUsesOpenInvites) {
        // show invalid link modal if sent by a participant and pool no longer uses open invites
        setShowWarningModal("participant")
      } else if (!poolPassword) {
        setShowWarningModal("manager")
      }
    }
  }

  const { pathname, search: currentSearch } = location
  const search = getAuthSearch(`${pathname}${currentSearch ?? ""}`, productSeason?.productAbbrev, productSeason?.masterProductId, isCbsAppWebview)

  const onWarningModalClose = () => {
    setShowWarningModal(null)
    history.push(lobbyMatch?.url || "")
  }

  return (
    <InviteRowWrap className={`mode--${mode}`}>
      <div className="left-side">
        <div className="logo">
          <img src={avatarUrl} alt="Pool Logo" />
        </div>
        <div className="pool-information">
          <span className="members-count">{formatMembersCount(entriesCount, "Bracket")}</span>
          <PoolNameWrap className={`mode--${mode}`} as={Link} to={to}>
            {name}
          </PoolNameWrap>
          <span className="invited-by">{mode === "view" ? `Invited by ${invitedBy}` : slogan || ""}</span>
        </div>
      </div>
      {isLoggedIn && (
        <div className="invites-actions">
          <Button variant="secondary" onClick={() => setShowModal(true)} withLoading loading={loading} data-cy="decline-btn">
            Decline
          </Button>
          {mode === "join" && (
            <Button onClick={onJoinClick} withLoading loading={joining} data-cy="join-now-btn">
              Join Now
            </Button>
          )}
          {mode === "view" && to && (
            <StyledButton className="variant--primary" as={Link} to={to}>
              View Invite
            </StyledButton>
          )}
        </div>
      )}
      {!isLoggedIn && (
        <div className="invites-actions">
          <StyledButton
            as={Link}
            to={{
              pathname: "/auth/signup",
              search,
            }}
            className="variant--secondary"
          >
            Sign Up
          </StyledButton>
          <StyledButton
            className="variant--primary"
            as={Link}
            to={{
              pathname: "/auth/login",
              search,
            }}
          >
            Log In
          </StyledButton>
        </div>
      )}
      <GenericConfirmationModal
        isOpen={showModal}
        onConfirm={onDecline}
        onClose={() => setShowModal(false)}
        title="Decline invitation?"
        message="You can't undo this action."
        ctaButtonLabel="Decline"
      />
      <GenericConfirmationModal
        isOpen={showWarningModal === "manager"}
        onConfirm={onWarningModalClose}
        title="Whoops! Invalid link."
        message={`You are attempting to join a pool with an invalid invite link. Contact the pool manager to request a valid invite link.`}
        showCancel={false}
        modalIcon={<WhistleSvg />}
        ctaButtonLabel="Close"
      />
      <GenericConfirmationModal
        isOpen={showWarningModal === "participant"}
        onConfirm={onWarningModalClose}
        title="Whoops! Invalid link."
        message={`This invite link has been invalidated. Please contact the pool manager for a valid invite link.`}
        showCancel={false}
        modalIcon={<WhistleSvg />}
        ctaButtonLabel="Close"
      />
      <GenericEntryModal title="ENTER POOL PASSWORD" isOpen={showPasswordModal}>
        <PasswordModalWrap>
          <FormInput label="Pool Password" value={poolPassword} onChange={onPoolPasswordChange} type="password" error={errorMessage} />
          <div className="modal-actions">
            <Button variant="secondary" onClick={hidePoolPasswordModal} data-cy="cancel-btn">
              Cancel
            </Button>
            <Button onClick={joinPool} disabled={poolPassword.length < 1} withLoading loading={joining} data-cy="join-pool-btn">
              Join Pool
            </Button>
          </div>
        </PasswordModalWrap>
      </GenericEntryModal>
      {showCreateEntry && productSeason && (
        <CreateEntryForm
          entryId={entryId}
          poolId={poolId}
          mutation={upsertEntryMutation}
          gameInstanceUid={gameInstanceUid}
          redirectOnMount={undefined}
          season={productSeason}
          onFinish={null}
          isCommingSoon={centralBracketState?.bracketState?.isPreTournament}
          {...getInvitationInfo(location.search)}
        />
      )}
    </InviteRowWrap>
  )
}

export default InviteRow
