import * as React from "react"
import styled from "styled-components/macro"
import Helmet from "../../../shared/react-helmet-async/Helmet"
import { bBlendMode, bgColor, bgImage, bgPosition, bgSize } from "../../../../common/splash-themed-styles"
import { canUseDom } from "../../../utils/misc-utils"

interface IFixedBgScreenProps {
  isModal?: boolean
  isRoot?: boolean
  className?: string
}
const AbsBgScreenStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  pointer-events: none;
  z-index: -1;
  transform: translateZ(0);
  background-color: ${bgColor};
  background-size: ${bgSize};
  background-blend-mode: ${bBlendMode};
  background-position: ${bgPosition};
  background-image: ${bgImage};
  contain: strict;
`
const FixedBgScreenStyled = styled.div`
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  pointer-events: none;
  z-index: 0;
  transform: translateZ(0);
  background-image: ${bgImage};
  background-color: ${bgColor};
  background-size: ${bgSize};
  background-blend-mode: ${bBlendMode};
  background-position: ${bgPosition};
  contain: strict;
  .show-fixed-bg-screen--true & {
    display: block !important;
  }
  @media (prefers-color-scheme: dark) {
    .is-cbs-app-webview--true.is-android-browser--true & {
      display: none !important;
    }
  }
`

const FixedBgScreen = React.memo((props: IFixedBgScreenProps) => {
  const { isRoot, isModal, className } = props
  // UUUUGH we need to stop trying to use classname like this...
  React.useEffect(() => {
    if (!isModal && !isRoot) {
      document.body.classList.add(`show-fixed-bg-screen--true`)
      window.requestAnimationFrame(() => document.body.classList.add(`show-fixed-bg-screen--true`))
      return () => {
        document.body.classList.remove(`show-fixed-bg-screen--true`)
        window.requestAnimationFrame(() => document.body.classList.remove(`show-fixed-bg-screen--true`))
      }
    }
    return
  }, [isModal, isRoot])
  if (isRoot) {
    return <FixedBgScreenStyled id="fixed-bg-screen" />
  } else if (isModal) {
    return <AbsBgScreenStyled className={className} />
  } else {
    // const theme = useContext(ThemeContext);
    // const style = theme && {
    //   backgroundColor: bgColor({theme}),
    // } || undefined;
    // NOTE qac: we use helmet for SSR, and the use effect above to fix bugs in helmet :/
    if (canUseDom) {
      return null
    }
    return (
      <Helmet>
        <body className={`show-fixed-bg-screen--true`} />
      </Helmet>
    )
  }
})

export default FixedBgScreen
