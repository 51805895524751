import Analytics from "../../../../utils/analytics"

const trackingElements = {
  "start-new-topic": {
    moduleName: "brackets message board",
    moduleLocation: "brackets start topic thread",
    clickText: "Start New Topic",
  },
  "send-new-topic": {
    moduleName: "brackets message board",
    moduleLocation: "brackets new topic modal",
    clickText: "Send - Public Message",
  },
  "discard-new-topic": {
    moduleName: "brackets message board",
    moduleLocation: "brackets new topic modal",
    clickText: "Discard",
  },
  "delete-topic": {
    moduleName: "brackets message board",
    moduleLocation: "brackets delete thread",
    clickText: "Delete",
  },
  "reply-to-topic": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets reply to thread",
    clickText: "Reply to Topic",
  },
  "send-reply-to-topic": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets reply to topic modal",
    clickText: "Send",
  },
  "discard-reply-to-topic": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets reply to topic modal",
    clickText: "Discard",
  },
  "quote-post": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets quote thread",
    clickText: "Quote",
  },
  "send-quote": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets quote thread modal",
    clickText: "Send",
  },
  "discard-quote": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets quote thread modal",
    clickText: "Discard",
  },
  "mute-user": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets mute",
    clickText: "mute-user",
  },
  "unmute-user": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets mute",
    clickText: "unmute-user",
  },
  "delete-topic-in-thread": {
    moduleName: "brackets inside thread",
    moduleLocation: "brackets delete thread",
    clickText: "Delete",
  },
  "manage-players-mute": {
    moduleName: "brackets manage players",
    moduleLocation: "brackets mute",
    clickText: "Mute from Message Board",
  },
  "manage-players-unmute": {
    moduleName: "brackets manage players",
    moduleLocation: "brackets mute",
    clickText: "Unmute from Message Board",
  },
  "email-opt-out": {
    moduleName: "bracket pool manager",
    moduleLocation: "bracket settings - personal info",
    clickText: "Save - email notification opt out",
  },
  "disable-message-board": {
    moduleName: "bracket pool manager",
    moduleLocation: "brackets settings - edit pool",
    clickText: "Message Board - Remove",
  },
}

type MessageBoardKey = keyof typeof trackingElements
export const trackActionByKey = (key: MessageBoardKey) => {
  if (trackingElements[key]) {
    const { moduleName, moduleLocation, clickText } = trackingElements[key]
    Analytics.trackAction(moduleName, moduleLocation, clickText)
  }
}
