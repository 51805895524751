import styled from "styled-components"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const px4 = pxToRem(4)
const px5 = pxToRem(5)
const px10 = pxToRem(10)
const px20 = pxToRem(20)
const px24 = pxToRem(24)

export const BracketsHomeWrapper = styled.div`
  color: ${bracketTheme.colors.overLight.white20};

  .home__title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${px20} ${px24};

    .home__title-main {
      display: flex;
      align-items: center;
    }

    .home__title-download {
      margin-left: ${px4};
    }

    .home__title-row-extra {
      display: flex;
      & > * {
        margin-left: 1.5rem;
      }
    }
  }

  .home__subtitle-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem 0.5rem;
  }

  .home__bottom-margin {
    margin-bottom: 2rem;
  }

  .home__overall-standings-table {
    & > div[class*="StandingsTableRow"] {
      &:last-of-type {
        border-bottom: none;
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }
  }

  .home__off-left-scroll-shadow {
    border-radius: 0 0 0 0.5rem;
  }

  .home__off-right-scroll-shadow {
    border-radius: 0 0 0.5rem 0;
  }

  .home__standings-notification {
    display: flex;
    align-items: center;

    .tooltip__wrapper {
      display: flex;
    }
  }

  .home__standings-notification-text {
    font-size: ${px10};
  }

  .home__standings-notification {
    display: flex;
    align-items: center;
  }

  .home__standings-total-container {
    display: flex;
    align-items: center;
    flex-flow: row-reverse;

    .tooltip__wrapper {
      display: flex;
    }
  }

  .home__standings-info-circle {
    display: flex;
    align-items: center;
    padding-right: ${px5};
  }
`
