import * as React from "react"

const WBPCFFLogoPromoSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={93} height={67} viewBox="0 0 93 67" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.917725 28.9015H6.86755V22.9482H0.917725V28.9015Z" fill="#F56401" />
    <path fillRule="evenodd" clipRule="evenodd" d="M60.7211 14.6837H54.7718L57.5384 8.73034H63.4877L60.7211 14.6837Z" fill="#F56401" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.83 9.36784L13.9502 4.92007L17.142 2.5304L26.0223 6.97765L22.83 9.36784Z" fill="#F56401" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.722 25.6692L17.5544 21.5271L19.4745 18.7962L20.6422 22.9383L18.722 25.6692Z" fill="#F56401" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.9978 16.744L26.7507 11.1019L32.8812 7.73526L33.1278 13.3773L26.9978 16.744Z" fill="#F56401" />
    <path fillRule="evenodd" clipRule="evenodd" d="M73.7261 21.332L77.3884 17.9511L79.064 23.188L75.4017 26.5689L73.7261 21.332Z" fill="#F56401" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.19599 11.4109L8.71206 12.9242L7.51606 15.5967L3 14.0839L4.19599 11.4109Z" fill="#FFCB00" />
    <path fillRule="evenodd" clipRule="evenodd" d="M82.8111 38.3932L81.6983 32.5427L88.0599 34.1495L89.1727 40L82.8111 38.3932Z" fill="#FFD601" />
    <path fillRule="evenodd" clipRule="evenodd" d="M73.4495 15.654L74.5567 12.6366L81 4.92073L79.8928 7.9387L73.4495 15.654Z" fill="#FFCB00" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.5502 4.56785L70.2859 0.103638L72.7261 3.27096L67.9904 7.73517L65.5502 4.56785Z"
      fill="#FFCB00"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M66.6249 23.332L65.9967 22.9688L68.5359 18.5666L69.1636 18.9298L66.6249 23.332Z" fill="#FFCB00" />
    <path fillRule="evenodd" clipRule="evenodd" d="M44.2608 13.1205L43.7478 13.634L40.1578 10.04L40.6708 9.5264L44.2608 13.1205Z" fill="#FFCB00" />
    <g clipPath="url(#clip0_1135_1616)">
      <path
        d="M47 6C39.0795 6 31.8691 7.50943 26.6957 10.2524C23.6809 11.8511 21.3274 13.8637 19.7024 16.2333C17.9089 18.8505 17 21.9099 17 25.3264C17 31.7679 22.7233 42.3968 27.6228 49.5443C29.4731 52.2426 32.3845 56.1684 35.7503 59.5484C40.0128 63.8292 43.7965 66 47.002 66C50.2076 66 53.76 63.8758 57.9819 59.6843C61.3133 56.3774 64.2327 52.5368 66.0952 49.8973C71.368 42.4252 77.002 32.0459 77.002 25.3264C77.002 21.9099 76.0931 18.8505 74.2997 16.2333C72.6746 13.8637 70.3232 11.8511 67.3064 10.2524C62.1309 7.50943 54.9205 6 47 6Z"
        fill="#C1C6C8"
      />
      <path
        d="M47 6.62891C32.8003 6.62891 17.6289 11.5407 17.6289 25.3264C17.6289 31.6238 23.2913 42.1168 28.1422 49.1893C31.4796 54.0543 39.9945 65.3731 47.002 65.3731C54.0095 65.3731 62.2221 54.2978 65.5818 49.5362C70.7999 42.1412 76.3731 31.8977 76.3731 25.3264C76.371 11.5407 61.1996 6.62891 47 6.62891Z"
        fill="#0C2340"
      />
      <path
        d="M73.8147 25.0059C73.8147 17.0022 67.493 13.0481 61.0454 11.0964C67.8034 15.6673 72.0923 23.393 72.0923 31.8957C72.0923 32.0033 72.0923 32.1108 72.0902 32.2203C73.2162 29.3191 73.8127 26.848 73.8127 25.0059H73.8147Z"
        fill="#D6001C"
      />
      <path
        d="M24.1008 15.7971C24.4782 15.7139 24.8697 15.6673 25.2715 15.6673C26.0546 15.6673 26.7991 15.8357 27.4707 16.138C29.0471 14.1822 30.9156 12.4617 33.0235 11.0477C29.7511 12.0256 26.509 13.5188 24.1029 15.7971H24.1008Z"
        fill="#D6001C"
      />
      <path
        d="M22.6725 25.7362C21.6175 25.1519 20.7816 24.2248 20.3129 23.1028C20.2298 23.7115 20.1831 24.3424 20.1831 25.0038C20.1831 26.7141 20.7897 29.1507 21.9056 32.0357C21.9056 31.989 21.9056 31.9424 21.9056 31.8957C21.9056 29.7817 22.1713 27.7164 22.6725 25.7362Z"
        fill="#D6001C"
      />
      <path
        d="M39.4528 55.8336C38.0245 55.3832 36.6389 54.8071 35.312 54.1071C37.1298 56.2577 38.8908 58.0592 40.5301 59.4571C39.887 58.392 39.4995 57.1544 39.4528 55.8336Z"
        fill="#D6001C"
      />
      <path
        d="M54.5472 55.8316C54.5046 57.0326 54.184 58.1627 53.6423 59.1568C55.1599 57.8259 56.785 56.1663 58.4567 54.2268C57.2009 54.872 55.8923 55.4076 54.5452 55.8316H54.5472Z"
        fill="#D6001C"
      />
      <path
        d="M46.9999 7.76099C39.469 7.76099 32.7435 11.21 28.3187 16.6167C28.6778 16.8643 29.0044 17.1564 29.2925 17.481C33.484 12.34 39.8646 9.05131 46.9999 9.05131C59.5968 9.05131 69.8443 19.2988 69.8443 31.8957C69.8443 44.4926 59.5968 54.7401 46.9999 54.7401C34.4031 54.7401 24.1556 44.4926 24.1556 31.8957C24.1556 29.9967 24.3889 28.1525 24.8291 26.3875C24.3889 26.351 23.9648 26.2617 23.5611 26.1258C23.1067 27.974 22.8652 29.9075 22.8652 31.8957C22.8652 45.225 33.6707 56.0304 46.9999 56.0304C60.3292 56.0304 71.1347 45.225 71.1347 31.8957C71.1347 18.5664 60.3292 7.76099 46.9999 7.76099Z"
        fill="#EA7600"
      />
      <path
        d="M33.7804 17.3004L32.2689 18.8261L31.8895 18.4508C31.7536 18.3229 31.711 18.1931 31.7556 18.0389C31.7921 17.9314 31.8145 17.901 31.995 17.6839L32.4495 17.1503C32.5327 17.0509 32.5631 17.0083 32.5813 16.9657C32.6077 16.9068 32.5895 16.8439 32.5205 16.777C32.4556 16.7121 32.3927 16.6898 32.344 16.708C32.2831 16.7303 32.2486 16.7608 31.9828 17.0286C31.7637 17.2497 31.711 17.3106 31.6846 17.3775C31.6481 17.4566 31.6704 17.5216 31.7597 17.6108L31.7942 17.6453L31.4371 18.0044L31.3296 17.8969C31.22 17.7894 31.2038 17.7691 31.1693 17.696C31.1064 17.5642 31.1389 17.4181 31.2667 17.2578C31.3701 17.1259 31.5264 16.9576 31.7333 16.7466C32.1086 16.3672 32.2567 16.2454 32.3947 16.2008C32.5448 16.1521 32.6787 16.2069 32.8796 16.4077C33.0865 16.6127 33.1413 16.7425 33.0946 16.9109C33.0683 17.0083 33.0013 17.1097 32.8471 17.2862L32.3927 17.8117C32.2689 17.9598 32.2608 17.972 32.2507 18.0085C32.2405 18.0612 32.2588 18.1099 32.3075 18.1586C32.3196 18.1708 32.3379 18.189 32.3582 18.2053L33.5126 17.0387L33.7763 17.3004H33.7804Z"
        fill="white"
      />
      <path
        d="M34.9348 14.2268C35.1194 14.2329 35.2716 14.3587 35.5556 14.736C35.8457 15.1215 35.9248 15.2919 35.8964 15.4624C35.866 15.6409 35.7321 15.7829 35.2432 16.1501C34.6203 16.6188 34.5067 16.6796 34.318 16.6431C34.1517 16.6107 34.0218 16.491 33.7358 16.1116C33.4517 15.7342 33.3726 15.5415 33.4274 15.3548C33.474 15.1925 33.6546 15.012 34.103 14.6731C34.5676 14.3242 34.7603 14.2187 34.9348 14.2268ZM34.1679 15.8133C34.4398 16.1745 34.4844 16.1745 34.9591 15.8174C35.2026 15.6328 35.302 15.5313 35.3223 15.4441C35.3426 15.3528 35.3101 15.2858 35.1275 15.0424C34.8536 14.6772 34.809 14.6752 34.3424 15.0262C33.8818 15.3731 33.8717 15.4218 34.1659 15.8133H34.1679Z"
        fill="white"
      />
      <path
        d="M38.5846 14.0828L36.6958 15.1094L36.4402 14.6407C36.3469 14.4784 36.3428 14.3425 36.43 14.2065C36.495 14.1152 36.5274 14.0909 36.7628 13.9347L37.3531 13.5553C37.4627 13.4843 37.5033 13.4518 37.5317 13.4173C37.5743 13.3686 37.5743 13.3037 37.5276 13.2185C37.485 13.1394 37.4302 13.0988 37.3795 13.1008C37.3146 13.1049 37.274 13.1252 36.9413 13.3037C36.6674 13.4518 36.6005 13.4944 36.5558 13.5512C36.497 13.6182 36.499 13.6851 36.5599 13.7967L36.5842 13.8393L36.1379 14.0807L36.0648 13.9468C35.9918 13.8109 35.9817 13.7886 35.9695 13.7074C35.9472 13.5634 36.0202 13.4315 36.1886 13.3159C36.3245 13.2205 36.5234 13.1029 36.783 12.9608C37.2517 12.7052 37.4282 12.6322 37.5743 12.6281C37.7325 12.6241 37.8441 12.7154 37.98 12.9649C38.12 13.2205 38.1363 13.3605 38.0409 13.5086C37.9861 13.5938 37.8948 13.673 37.696 13.7967L37.1077 14.17C36.9454 14.2775 36.9352 14.2857 36.9149 14.3181C36.8906 14.3648 36.8946 14.4175 36.9271 14.4784C36.9352 14.4926 36.9474 14.5169 36.9616 14.5372L38.4041 13.7521L38.5826 14.0787L38.5846 14.0828Z"
        fill="white"
      />
      <path
        d="M40.4714 12.9649L39.0999 13.466L38.8767 12.8553L39.8302 11.6157L40.4409 11.3926L40.8183 12.4273L41.0983 12.3258L41.2261 12.6768L40.9461 12.7783L41.0699 13.1171L40.5911 13.2915L40.4714 12.9629V12.9649ZM40.1062 11.9627L39.323 12.973L40.3395 12.6017L40.1062 11.9627Z"
        fill="white"
      />
      <path
        d="M43.196 10.6845L43.8858 12.0134L44.1982 10.5689L44.8678 10.4918L45.4845 11.8308L45.874 10.3782L46.4381 10.3132L45.8923 12.2244L45.1477 12.3096L44.5918 11.0416L44.3179 12.4029L43.5795 12.4881L42.634 10.7474L43.198 10.6825L43.196 10.6845Z"
        fill="white"
      />
      <path
        d="M49.307 10.508C49.5869 10.581 49.7127 10.7352 49.731 11.0335C49.7371 11.1085 49.731 11.2566 49.7168 11.4798C49.6843 11.9221 49.6255 12.0986 49.4591 12.2163C49.3435 12.3015 49.1852 12.336 48.9174 12.3278C48.818 12.3278 48.5725 12.3116 48.394 12.2994C47.9578 12.269 47.7346 12.2447 47.6271 12.2142C47.4263 12.1635 47.3126 12.0783 47.2538 11.9383C47.1929 11.7983 47.1848 11.6624 47.2132 11.2607C47.2355 10.9523 47.2457 10.8752 47.2883 10.7555C47.3552 10.5648 47.4689 10.4674 47.6799 10.4208C47.8361 10.3883 48.0045 10.3883 48.4894 10.4228C49.0168 10.4593 49.1791 10.4776 49.307 10.508ZM47.903 10.8975C47.8239 10.9503 47.7955 11.0396 47.7772 11.3033C47.759 11.5731 47.7732 11.6725 47.8381 11.7354C47.9071 11.8004 47.9984 11.8186 48.4183 11.849C48.8992 11.8835 49.0006 11.8734 49.0696 11.7902C49.1264 11.7273 49.1467 11.636 49.1649 11.3905C49.1974 10.928 49.1893 10.9219 48.5036 10.8732C48.0937 10.8448 47.9801 10.8509 47.9051 10.8975H47.903Z"
        fill="white"
      />
      <path
        d="M50.794 10.6663L51.6542 10.8854L52.0031 12.2345L52.9506 11.2161L53.8047 11.4331L53.3523 13.2144L52.8228 13.0805L53.1981 11.7111L52.0579 12.8858L51.6258 12.7762L51.1977 11.2039L50.865 12.5835L50.3416 12.4496L50.794 10.6683V10.6663Z"
        fill="white"
      />
      <path
        d="M56.6431 12.6423L56.4747 13.0237L55.1174 12.4232L54.9896 12.7133L56.2698 13.2794L56.1237 13.6121L54.8435 13.0461L54.7117 13.3443L56.0872 13.9529L55.9188 14.3344L54.0381 13.5025L54.7806 11.8227L56.6431 12.6464V12.6423Z"
        fill="white"
      />
      <path
        d="M57.5236 13.0988L58.2722 13.5796L58.4548 15.3549L59.1974 14.1721L59.6417 14.4581L58.6516 16.0041L57.8949 15.5192L57.7183 13.7643L56.9839 14.9349L56.5315 14.6448L57.5236 13.0988Z"
        fill="white"
      />
      <path
        d="M60.364 15.0485L60.7778 15.3852L60.4796 15.7504C60.2361 16.0669 60.084 16.0933 59.7979 15.86L59.6782 15.7626L59.8568 15.5455C59.9988 15.6591 60.0596 15.6571 60.155 15.5394L60.1672 15.5252L60.0515 15.4319L60.364 15.0505V15.0485Z"
        fill="white"
      />
      <path
        d="M60.9602 17.2153C60.875 17.3086 60.8629 17.339 60.8913 17.4039C60.9095 17.4445 61.011 17.5642 61.1367 17.69C61.5932 18.1505 61.6602 18.1911 61.7677 18.0836C61.8205 18.0308 61.8347 17.9659 61.8083 17.9132C61.7893 17.8753 61.7122 17.7874 61.577 17.6494L61.2199 17.2903C60.9805 17.0489 60.8953 16.9353 60.8547 16.8095C60.8101 16.6675 60.871 16.5214 61.0495 16.3449C61.2848 16.1116 61.4492 16.0791 61.6805 16.2232C61.8123 16.3023 61.9746 16.4443 62.2566 16.7304C62.7111 17.1889 62.8044 17.3045 62.8369 17.4587C62.8653 17.6089 62.8146 17.7184 62.6178 17.9111L62.2343 17.5236C62.3479 17.4121 62.3256 17.3573 62.07 17.0996C61.9442 16.9718 61.7718 16.8054 61.7048 16.7466C61.5932 16.6492 61.5303 16.6411 61.4553 16.7141C61.4005 16.7689 61.3924 16.8318 61.435 16.9008C61.4532 16.9292 61.4573 16.9393 61.5851 17.0712L61.9523 17.4405C61.9868 17.4749 62.1552 17.6575 62.2181 17.7286C62.4534 17.9882 62.4413 18.1952 62.1775 18.4549C61.9361 18.6943 61.7616 18.7288 61.5303 18.5766C61.4127 18.4995 61.2077 18.3189 60.9257 18.0349C60.4774 17.5825 60.3881 17.4729 60.3313 17.3005C60.2907 17.1808 60.3496 17.0408 60.5038 16.8886C60.5159 16.8765 60.5403 16.8521 60.5707 16.8257L60.9562 17.2153H60.9602Z"
        fill="white"
      />
      <path
        d="M36.9188 50.1469C36.7747 50.3721 36.7179 50.4431 36.6428 50.4938C36.4988 50.5892 36.3324 50.5729 36.0809 50.435C35.9794 50.3842 35.7907 50.2686 35.4803 50.0738C35.032 49.7918 34.9021 49.6802 34.8271 49.52C34.7358 49.3252 34.7926 49.1345 35.0847 48.6719C35.3424 48.2621 35.4824 48.1444 35.7035 48.1546C35.8942 48.1607 36.0565 48.2337 36.4927 48.5076C37.073 48.8728 37.1927 48.9641 37.2839 49.1122C37.3712 49.2542 37.3428 49.4287 37.1987 49.6579L37.1663 49.7087L36.7139 49.4246C36.8112 49.2481 36.7849 49.2116 36.375 48.9539C35.9875 48.7105 35.9125 48.676 35.8212 48.6902C35.7319 48.7064 35.6812 48.7612 35.5229 49.0128C35.2876 49.3861 35.3059 49.4348 35.7603 49.7229C36.1113 49.944 36.164 49.9744 36.2472 49.9866C36.3324 50.0008 36.3892 49.9683 36.4602 49.8568L36.9188 50.1448V50.1469Z"
        fill="white"
      />
      <path
        d="M38.0894 49.4551L38.5986 49.6884L38.0245 50.9463L39.1748 51.4717L38.9862 51.8856L37.3286 51.1268L38.0935 49.4571L38.0894 49.4551Z"
        fill="white"
      />
      <path
        d="M42.1431 50.9888L42.0193 51.3865L40.6012 50.9442L40.5058 51.2465L41.8428 51.6624L41.7353 52.0093L40.3983 51.5934L40.3009 51.9038L41.7373 52.3522L41.6135 52.7498L39.6497 52.1371L40.1974 50.3822L42.141 50.9888H42.1431Z"
        fill="white"
      />
      <path
        d="M42.5325 51.1045L43.1412 51.2019L43.6362 52.689L44.5877 51.4352L45.2025 51.5346L43.9081 53.1881L43.2081 53.0765L42.5305 51.1065L42.5325 51.1045Z"
        fill="white"
      />
      <path
        d="M47.7992 51.5812L47.7931 51.9971L46.308 51.9728L46.304 52.2893L47.7039 52.3116L47.6978 52.6748L46.2979 52.6525L46.2918 52.9771L47.7952 53.0014L47.7891 53.4173L45.7339 53.3849L45.7623 51.5468L47.7992 51.5792V51.5812Z"
        fill="white"
      />
      <path d="M48.4729 51.5285L49.0288 51.4636L49.1891 52.8371L50.4449 52.691L50.4977 53.1434L48.6859 53.3544L48.4729 51.5285Z" fill="white" />
      <path
        d="M51.5951 51.0315L52.3194 50.8428L53.7537 52.3684L53.1613 52.5226L52.9037 52.2508L51.7574 52.547L51.6681 52.9081L51.0554 53.0663L51.5951 51.0294V51.0315ZM52.6176 51.9302L52.0495 51.3216L51.8548 52.129L52.6176 51.9322V51.9302Z"
        fill="white"
      />
      <path
        d="M53.5854 50.4776L54.4031 50.1266L55.9774 50.9726L55.4134 49.6944L55.9003 49.4855L56.6246 51.1755L55.7989 51.5305L54.2448 50.6926L54.8027 51.9586L54.3097 52.1696L53.5854 50.4796V50.4776Z"
        fill="white"
      />
      <path
        d="M56.3711 49.2339L57.7182 48.394C58.0469 48.1891 58.2254 48.1302 58.3999 48.1708C58.5379 48.2012 58.6352 48.2763 58.7752 48.465C58.826 48.534 58.897 48.6395 58.9436 48.7145C59.0309 48.8545 59.1222 49.0209 59.1648 49.1203C59.2561 49.3232 59.2155 49.5504 59.0654 49.6884C58.9883 49.7634 58.9213 49.8101 58.7549 49.9135L57.3449 50.792L56.3731 49.2339H56.3711ZM58.3816 49.6295C58.5095 49.5484 58.5196 49.5443 58.5642 49.4895C58.6576 49.382 58.6373 49.2826 58.4689 49.0128C58.264 48.6861 58.1666 48.6537 57.8927 48.8241L57.0893 49.3252L57.5884 50.1245L58.3837 49.6295H58.3816Z"
        fill="white"
      />
      <path
        d="M25.2715 25.3366C27.6458 25.3366 29.5705 23.4118 29.5705 21.0375C29.5705 18.6632 27.6458 16.7385 25.2715 16.7385C22.8972 16.7385 20.9724 18.6632 20.9724 21.0375C20.9724 23.4118 22.8972 25.3366 25.2715 25.3366Z"
        fill="white"
      />
      <path
        d="M28.5784 23.044H29.0755C28.3552 24.4074 26.9209 25.3366 25.2715 25.3366C22.8978 25.3366 20.9724 23.4112 20.9724 21.0375C20.9724 18.6638 22.8978 16.7385 25.2715 16.7385C27.6452 16.7385 29.5705 18.6638 29.5705 21.0375C29.5705 21.6725 29.4325 22.2771 29.185 22.8188L29.0613 21.0071H27.9454L27.8136 21.4717H27.986L27.4362 22.3441L27.3591 21.005H26.227L26.0972 21.4676H26.2696L25.5311 22.5916H24.9225C24.8596 22.5895 24.6121 22.5307 24.6121 22.2264C24.6121 21.9221 24.9022 21.4798 25.1964 21.4798H25.3201C25.2999 21.5569 25.2552 21.7131 25.2552 21.7131H25.7969L25.9917 21.005H25.1558C24.6364 21.005 24.2185 21.3763 24.0846 21.7577L24.3017 21.005H23.5591L23.4293 21.4696H23.622L23.4293 22.1493L22.9424 21.003H22.2485L22.1187 21.4676H22.3094L21.8773 23.0379H22.4332C22.4332 23.0379 22.6482 22.2589 22.703 22.058C22.7821 22.2467 23.1087 23.0379 23.1087 23.0379H23.7275L24.0116 22.0397C23.9852 22.1878 23.9852 22.3441 24.0116 22.4983C24.0663 22.8249 24.3849 23.0379 24.7318 23.0379H25.8537L26.0485 22.7458H26.8418L26.858 23.0379H27.5579L27.7385 22.7458H28.548L28.5804 23.0379L28.5784 23.044ZM28.0895 22.2568H28.5419L28.5196 21.6056L28.0895 22.2568ZM26.365 22.2649H26.8194L26.7971 21.6117L26.367 22.2649H26.365Z"
        fill="#009CDE"
      />
      <path
        d="M29.5725 23.046C29.3818 23.046 29.2256 23.2022 29.2256 23.3929C29.2256 23.5837 29.3818 23.7399 29.5725 23.7399C29.7632 23.7399 29.9194 23.5837 29.9194 23.3929C29.9194 23.2022 29.7673 23.046 29.5725 23.046ZM29.8464 23.3929C29.8464 23.5431 29.7247 23.6668 29.5725 23.6668C29.4204 23.6668 29.2986 23.5451 29.2986 23.3929C29.2986 23.2408 29.4183 23.1191 29.5725 23.1191C29.7267 23.1191 29.8464 23.2388 29.8464 23.3929Z"
        fill="#009CDE"
      />
      <path
        d="M29.739 23.3158C29.739 23.2367 29.6659 23.1901 29.599 23.1901H29.4326V23.5938H29.5097V23.4416H29.5584L29.6578 23.5938H29.7511L29.6456 23.4376C29.6781 23.4295 29.739 23.395 29.739 23.3158ZM29.6619 23.3158C29.6619 23.3503 29.6294 23.3727 29.601 23.3727H29.5117V23.257H29.601C29.6314 23.257 29.6619 23.2793 29.6619 23.3158Z"
        fill="#009CDE"
      />
      <path d="M58.9761 45.2372H61.5425L62.3317 44.2471H59.5908L58.9761 45.2372Z" fill="#EA7600" />
      <path d="M53.7964 45.2372H57.29L57.8479 44.2471H54.1656L53.7964 45.2372Z" fill="#EA7600" />
      <path d="M52.2484 44.2471H47.9797L48.0305 45.2372H51.8771L52.2484 44.2471Z" fill="#EA7600" />
      <path d="M44.2266 44.2471H39.8748L40.3434 45.2372H44.2671L44.2266 44.2471Z" fill="#EA7600" />
      <path d="M35.2714 44.2471H31.6682L32.4574 45.2372H35.8739L35.2714 44.2471Z" fill="#EA7600" />
      <path
        d="M49.2742 20.3497H46.024V21.7922H46.5718L46.5799 26.0263L44.4984 20.3497H42.3499L42.3559 29.4205H41.8V30.863H45.0502V29.4205H44.5024L44.4963 25.2006L46.3811 30.3518C46.4988 30.6825 46.7422 30.863 47.0425 30.863H48.7244L48.7183 21.7922H49.2762V20.3497H49.2742Z"
        fill="white"
      />
      <path
        d="M55.9936 29.4205L54.7032 20.9361C54.6363 20.5607 54.3746 20.3497 53.9992 20.3497H51.1609L49.8321 29.4205H49.3208V30.863H52.571V29.4205H52.1266L52.1733 28.9864H53.6442L53.6929 29.4205H53.2607V30.863H56.5109V29.4205H55.9915H55.9936ZM52.3579 27.1381L52.8773 22.1675L53.4393 27.1381H52.3579Z"
        fill="white"
      />
      <path
        d="M41.1549 21.7395V20.297H37.9048V21.7395H38.3775L38.3816 29.4206H37.9048V30.863H38.3836H40.1263H41.1549V29.4206H40.6802L40.6761 21.7395H41.1549Z"
        fill="white"
      />
      <path
        d="M37.29 20.3497H31.6682V21.7922H32.1409L32.145 29.4206H31.6682V30.863H34.9184V29.4206H34.4436L34.4477 26.8074H36.2493C36.5942 26.8074 36.7585 26.6269 36.8052 26.2962L37.0162 24.8537H34.4335V22.2994H35.8597V22.7478H37.3022V20.3477H37.288L37.29 20.3497Z"
        fill="white"
      />
      <path
        d="M60.8892 28.2844V28.9093H59.96L59.9539 21.7902H60.4286V20.3477H57.1785V21.7902H57.6552L57.6593 29.4185H57.1785V30.861H61.7757C62.072 30.861 62.2687 30.7393 62.3174 30.4857H62.3316V28.2824H60.8892V28.2844Z"
        fill="white"
      />
      <path
        d="M44.0744 31.8917H40.0472L38.6189 33.1394V42.2934L40.037 43.5411H44.0642L45.4925 42.2934V33.1394L44.0744 31.8917ZM42.9443 34.3546V41.0619C42.9463 41.2607 42.851 41.3784 42.6339 41.3784H41.4531C41.2543 41.3784 41.1671 41.2627 41.1671 41.0781V34.3709C41.1651 34.172 41.2604 34.0544 41.4775 34.0544H42.6583C42.8571 34.0544 42.9443 34.17 42.9443 34.3546Z"
        fill="white"
      />
      <path
        d="M61.7594 42.0438L60.6152 38.9641L61.6073 38.0816L61.6012 33.1374L60.1831 31.8896H54.7235L54.7296 42.0438H54.1636V43.5411H57.8459V42.0438H57.2798L57.2839 39.3293H58.1319L59.6393 43.5411H62.3295V42.0438H61.7574H61.7594ZM58.7507 37.1666H57.2859V34.0544H58.7669C58.9658 34.0544 59.053 34.17 59.053 34.3547L59.0611 36.8521C59.0611 37.0509 58.9678 37.1686 58.7507 37.1686V37.1666Z"
        fill="white"
      />
      <path
        d="M53.9546 31.8917H53.5103H50.9642H50.53V33.3889H50.9642V41.0619C50.9682 41.2607 50.8729 41.3784 50.6558 41.3784H49.5582C49.3594 41.3784 49.2721 41.2627 49.2721 41.0781L49.2681 33.3889H49.7083V31.8917H49.266H46.7199H46.2837V33.3889H46.7219V42.2934L48.1441 43.5411H52.0881L53.5164 42.2934V33.3889H53.9566V31.8917H53.9546Z"
        fill="white"
      />
      <path
        d="M37.8987 31.8917H31.6682V33.4904H32.1916L32.1977 41.9444H31.6702V43.5431H35.2734V41.9444H34.7479L34.752 39.0493H36.7483C37.1318 39.0493 37.3124 38.8505 37.3651 38.4833L37.5984 36.8846H34.7358V34.0544H36.3162V34.5494H37.9149V31.8896H37.8987V31.8917Z"
        fill="white"
      />
      <path
        d="M61.9605 32.0966C61.9605 32.2467 61.8428 32.3644 61.6886 32.3644C61.5344 32.3644 61.4167 32.2467 61.4167 32.0966C61.4167 31.9464 61.5385 31.8328 61.6886 31.8328C61.8387 31.8328 61.9605 31.9505 61.9605 32.0966ZM61.4837 32.0966C61.4837 32.2142 61.5709 32.3076 61.6906 32.3076C61.8103 32.3076 61.8915 32.2142 61.8915 32.0986C61.8915 31.983 61.8063 31.8856 61.6886 31.8856C61.5709 31.8856 61.4837 31.9809 61.4837 32.0966ZM61.646 32.2345H61.5851V31.9708C61.6095 31.9667 61.644 31.9627 61.6866 31.9627C61.7373 31.9627 61.7596 31.9708 61.7779 31.983C61.7921 31.9951 61.8043 32.0154 61.8043 32.0418C61.8043 32.0702 61.7819 32.0925 61.7495 32.1027V32.1067C61.7759 32.1169 61.7901 32.1351 61.7982 32.1716C61.8063 32.2122 61.8103 32.2284 61.8185 32.2386H61.7515C61.7515 32.2386 61.7393 32.2041 61.7312 32.1737C61.7272 32.1453 61.7109 32.1311 61.6764 32.1311H61.648V32.2386L61.646 32.2345ZM61.646 32.0844H61.6744C61.7089 32.0844 61.7353 32.0722 61.7353 32.0459C61.7353 32.0215 61.717 32.0053 61.6785 32.0053C61.6622 32.0053 61.6501 32.0053 61.644 32.0093V32.0844H61.646Z"
        fill="white"
      />
      <path
        d="M50.2076 29.4753C50.1812 29.6619 50.0209 29.7999 49.8322 29.7999H49.7024V30.4816H52.1938V29.7999H52.1288C52.0213 29.7999 51.9179 29.7532 51.8468 29.6741C51.7738 29.595 51.7393 29.4875 51.7515 29.3799L51.7982 28.9458C51.8184 28.753 51.9807 28.6049 52.1755 28.6049H53.6484C53.8412 28.6049 54.0055 28.751 54.0258 28.9437L54.0745 29.3779C54.0866 29.4854 54.0522 29.593 53.9812 29.6741C53.9101 29.7553 53.8067 29.8019 53.6971 29.8019H53.6464V30.4836H56.1378V29.8019H55.9998C55.8111 29.8019 55.6529 29.664 55.6245 29.4794L55.3851 27.9009H50.4449L50.2137 29.4773L50.2076 29.4753Z"
        fill="#C1C6C8"
      />
      <path
        d="M42.7336 29.4185C42.7336 29.52 42.6931 29.6153 42.622 29.6863C42.551 29.7573 42.4537 29.7979 42.3542 29.7979H42.1777V30.4796H44.6691V29.7979H44.5028C44.2938 29.7979 44.1234 29.6275 44.1234 29.4185V27.8969H42.7357V29.4185H42.7336Z"
        fill="#C1C6C8"
      />
      <path
        d="M38.6516 29.6883C38.5806 29.7593 38.4832 29.7999 38.3838 29.7999H38.2844V30.4816H40.7758V29.7999H40.6825C40.4735 29.7999 40.3031 29.6295 40.3031 29.4205V27.8989H38.7632V29.4205C38.7632 29.522 38.7247 29.6173 38.6537 29.6883H38.6516Z"
        fill="#C1C6C8"
      />
      <path
        d="M34.5392 29.7999H34.4458C34.3444 29.7999 34.249 29.7594 34.178 29.6883C34.107 29.6173 34.0664 29.52 34.0664 29.4185V27.8969H32.5245V29.4185C32.5245 29.52 32.486 29.6153 32.415 29.6863C32.344 29.7573 32.2466 29.7979 32.1472 29.7979H32.0498V30.4796H34.5412V29.7979L34.5392 29.7999Z"
        fill="#C1C6C8"
      />
      <path
        d="M58.0409 29.4185C58.0409 29.52 58.0003 29.6153 57.9293 29.6863C57.8583 29.7573 57.7609 29.7979 57.6615 29.7979H57.5601V30.4796H61.778C61.9342 30.4796 61.9423 30.441 61.9463 30.4126C61.9463 30.4025 61.9504 30.3944 61.9524 30.3863V28.6638H61.2708V28.9093C61.2708 29.1183 61.1003 29.2887 60.8914 29.2887H59.9622C59.7532 29.2887 59.5828 29.1183 59.5828 28.9093V27.8969H58.0429V29.4185H58.0409Z"
        fill="#C1C6C8"
      />
      <path d="M46.7363 30.2239C46.8296 30.4836 46.9879 30.4836 47.0386 30.4836H48.3411V27.8989H45.8862L46.7363 30.2239Z" fill="#C1C6C8" />
      <path
        d="M48.2864 43.1617H51.9443L53.1352 42.1209V39.9014H51.3479V41.0599C51.3499 41.3216 51.2464 41.4798 51.1612 41.5691C51.074 41.6563 50.9178 41.7598 50.6581 41.7598H49.5585C49.313 41.7598 49.1628 41.6584 49.0776 41.5752C48.9924 41.49 48.891 41.3338 48.891 41.0802V39.9014H47.1016V42.123L48.2844 43.1637L48.2864 43.1617Z"
        fill="#C1C6C8"
      />
      <path
        d="M40.1813 43.1617H43.9225L45.1134 42.1209V39.9014H43.326V41.0599C43.328 41.3216 43.2245 41.4798 43.1393 41.5691C43.0521 41.6563 42.8959 41.7598 42.6362 41.7598H41.4554C41.2099 41.7598 41.0598 41.6584 40.9746 41.5752C40.8894 41.49 40.7879 41.3338 40.7879 41.0802V39.9035H38.9985V42.125L40.1793 43.1658L40.1813 43.1617Z"
        fill="#C1C6C8"
      />
      <path
        d="M59.9093 43.1617H61.9503V42.4253H61.7576C61.5993 42.4253 61.4573 42.3259 61.4005 42.1778L60.5565 39.9014H58.7407L59.9093 43.1617Z"
        fill="#C1C6C8"
      />
      <path
        d="M34.8901 42.3238H34.7461C34.6447 42.3238 34.5493 42.2833 34.4763 42.2122C34.4053 42.1412 34.3647 42.0439 34.3647 41.9424L34.3687 39.9014H32.5753V41.9424C32.5753 42.0439 32.5367 42.1392 32.4657 42.2122C32.3947 42.2833 32.2973 42.3238 32.1979 42.3238H32.0498V43.1617H34.8922V42.3238H34.8901Z"
        fill="#C1C6C8"
      />
      <path
        d="M54.9996 42.3137C54.9286 42.3847 54.8312 42.4253 54.7318 42.4253H54.5452V43.1617H57.4667V42.4253H57.28C57.1786 42.4253 57.0832 42.3847 57.0102 42.3137C56.9392 42.2427 56.8986 42.1453 56.8986 42.0439L56.9026 39.9014H55.1092V42.0459C55.1092 42.1473 55.0706 42.2427 54.9996 42.3157V42.3137Z"
        fill="#C1C6C8"
      />
      <path
        d="M50.514 59.7452C50.5282 59.7676 50.5444 59.7899 50.5586 59.8122C50.5829 59.8447 50.6073 59.8771 50.6337 59.9096C50.6519 59.9319 50.6682 59.9542 50.6884 59.9786C50.727 60.0252 50.7696 60.0739 50.8142 60.1206C50.8447 60.153 50.8751 60.1835 50.9076 60.2139C50.9278 60.2342 50.9461 60.2524 50.9684 60.2727C51.0029 60.3052 51.0394 60.3377 51.0759 60.3701C51.0942 60.3863 51.1125 60.4026 51.1328 60.4188C51.1733 60.4533 51.2139 60.4878 51.2565 60.5223C51.9991 59.8832 52.5976 59.0838 52.9952 58.1729C52.9262 58.2196 52.8532 58.2642 52.7822 58.3109C52.7396 58.3372 52.697 58.3656 52.6523 58.392C52.5834 58.4346 52.5144 58.4752 52.4434 58.5158C52.3906 58.5462 52.3379 58.5746 52.2851 58.6051C52.2202 58.6416 52.1532 58.6781 52.0863 58.7126C51.9727 58.7714 51.8591 58.8282 51.7414 58.883C51.6927 58.9053 51.642 58.9276 51.5933 58.9479C51.5081 58.9865 51.4229 59.023 51.3336 59.0595C51.2809 59.0818 51.2281 59.1021 51.1754 59.1224C51.0841 59.1589 50.9907 59.1934 50.8954 59.2279C50.8487 59.2441 50.8041 59.2624 50.7574 59.2766C50.6154 59.3253 50.4734 59.3719 50.3293 59.4145C50.3537 59.4693 50.3821 59.5241 50.4125 59.5789C50.4227 59.5971 50.4348 59.6154 50.445 59.6337C50.4673 59.6702 50.4896 59.7067 50.514 59.7432V59.7452Z"
        fill="#EA7600"
      />
      <path
        d="M50.2258 60.9544C50.0635 60.7961 49.9174 60.6338 49.7896 60.4675C49.7774 60.4512 49.7632 60.435 49.751 60.4188C49.7226 60.3802 49.6962 60.3417 49.6699 60.3031C49.6455 60.2686 49.6232 60.2341 49.6009 60.1997C49.5806 60.1672 49.5603 60.1327 49.54 60.1002C49.5136 60.0556 49.4893 60.011 49.467 59.9663C49.4528 59.94 49.4386 59.9136 49.4264 59.8872C49.3919 59.8142 49.3595 59.7411 49.3311 59.6661C48.7447 59.7837 48.1341 59.8588 47.5051 59.8852V62.0844C48.5662 62.0033 49.5583 61.6665 50.4165 61.137C50.3536 61.0822 50.2927 61.0274 50.2339 60.9706C50.2298 60.9665 50.2258 60.9625 50.2217 60.9584L50.2258 60.9544Z"
        fill="#EA7600"
      />
      <path
        d="M51.5 56.8562C51.4432 56.8968 51.3885 56.9374 51.3337 56.9779C51.3134 56.9942 51.2911 57.0104 51.2708 57.0266C51.2241 57.0631 51.1775 57.1017 51.1328 57.1402C51.1085 57.1605 51.0841 57.1808 51.0618 57.2011C51.0212 57.2356 50.9847 57.2721 50.9462 57.3066C50.9218 57.3309 50.8975 57.3553 50.8731 57.3796C50.8407 57.4121 50.8082 57.4466 50.7778 57.479C50.7534 57.5054 50.7291 57.5338 50.7068 57.5602C50.6804 57.5906 50.654 57.6231 50.6297 57.6555C50.6053 57.686 50.5851 57.7164 50.5627 57.7468C50.5424 57.7752 50.5201 57.8057 50.5019 57.8341C50.4796 57.8686 50.4593 57.9031 50.439 57.9355C50.4228 57.9619 50.4065 57.9883 50.3923 58.0146C50.372 58.0552 50.3538 58.0938 50.3355 58.1343C50.3254 58.1567 50.3152 58.177 50.3051 58.1993C50.2807 58.2601 50.2584 58.323 50.2422 58.3839C50.3883 58.3372 50.5323 58.2865 50.6743 58.2338C50.7048 58.2236 50.7332 58.2114 50.7636 58.2013C50.9015 58.1485 51.0395 58.0917 51.1734 58.0329C51.1896 58.0268 51.2038 58.0187 51.2201 58.0126C51.3479 57.9558 51.4757 57.8949 51.5974 57.8321C51.6137 57.8239 51.6279 57.8158 51.6441 57.8097C51.776 57.7408 51.9058 57.6698 52.0316 57.5967C52.0438 57.5886 52.058 57.5805 52.0701 57.5744C52.3298 57.4202 52.5753 57.2559 52.8046 57.0794C52.8107 57.0753 52.8167 57.0692 52.8228 57.0652C53.0582 56.8826 53.2753 56.6919 53.4741 56.489C53.4903 56.3713 53.5045 56.2516 53.5147 56.1299C53.0602 56.2516 52.5997 56.3612 52.1371 56.4586C51.9241 56.5762 51.7232 56.6979 51.5427 56.8237C51.5285 56.8339 51.5143 56.842 51.5 56.8521V56.8562Z"
        fill="#EA7600"
      />
      <path
        d="M47.5072 58.8647C48.0813 58.8383 48.6392 58.7694 49.1748 58.6618C49.1809 58.5584 49.1931 58.4549 49.2093 58.3534C49.2154 58.319 49.2256 58.2845 49.2337 58.25C49.2479 58.183 49.2621 58.1161 49.2824 58.0512C49.2945 58.0106 49.3128 57.97 49.327 57.9294C49.3473 57.8706 49.3676 57.8118 49.3939 57.755C49.4122 57.7123 49.4366 57.6697 49.4568 57.6271C49.4832 57.5744 49.5096 57.5196 49.54 57.4669C49.5664 57.4222 49.5948 57.3796 49.6212 57.335C49.6536 57.2843 49.6861 57.2335 49.7206 57.1849C49.753 57.1402 49.7875 57.0976 49.822 57.053C49.8585 57.0063 49.8971 56.9576 49.9377 56.911C49.9681 56.8765 49.9985 56.842 50.031 56.8075C49.1972 56.9089 48.3532 56.9657 47.5051 56.984V58.8647H47.5072Z"
        fill="#EA7600"
      />
      <path
        d="M40.5281 56.491C40.727 56.6939 40.944 56.8866 41.1794 57.0672C41.1855 57.0713 41.1916 57.0774 41.1976 57.0814C41.4269 57.2579 41.6724 57.4223 41.93 57.5764C41.9422 57.5846 41.9564 57.5927 41.9686 57.5988C42.0944 57.6718 42.2242 57.7428 42.3561 57.8118C42.3723 57.8199 42.3865 57.828 42.4028 57.8341C42.5265 57.897 42.6523 57.9558 42.7801 58.0147C42.7964 58.0208 42.8106 58.0289 42.8268 58.035C42.9607 58.0938 43.0966 58.1486 43.2366 58.2033C43.267 58.2155 43.2954 58.2257 43.3259 58.2358C43.4679 58.2886 43.6099 58.3393 43.758 58.3859C43.7418 58.3251 43.7195 58.2622 43.6951 58.2013C43.687 58.179 43.6748 58.1587 43.6647 58.1364C43.6464 58.0978 43.6302 58.0573 43.6079 58.0187C43.5937 57.9923 43.5774 57.966 43.5612 57.9396C43.5409 57.9051 43.5206 57.8726 43.5003 57.8382C43.4801 57.8077 43.4598 57.7793 43.4395 57.7509C43.4172 57.7205 43.3949 57.6901 43.3725 57.6596C43.3482 57.6272 43.3218 57.5967 43.2954 57.5643C43.2731 57.5359 43.2488 57.5095 43.2244 57.4831C43.194 57.4507 43.1615 57.4162 43.1291 57.3837C43.1047 57.3594 43.0804 57.335 43.056 57.3107C43.0195 57.2762 42.981 57.2397 42.9424 57.2052C42.9181 57.1849 42.8958 57.1646 42.8714 57.1423C42.8268 57.1037 42.7821 57.0672 42.7355 57.0287C42.7152 57.0124 42.6929 56.9962 42.6726 56.98C42.6198 56.9394 42.5651 56.8988 42.5062 56.8582C42.492 56.8481 42.4778 56.838 42.4636 56.8298C42.2831 56.7041 42.0822 56.5823 41.8692 56.4647C41.4066 56.3693 40.9481 56.2577 40.4937 56.136C40.5038 56.2557 40.518 56.3754 40.5342 56.4931L40.5281 56.491Z"
        fill="#EA7600"
      />
      <path
        d="M46.4929 56.984C45.6449 56.9678 44.8029 56.9089 43.967 56.8095C43.9995 56.844 44.0299 56.8765 44.0604 56.9109C44.1009 56.9576 44.1375 57.0043 44.176 57.053C44.2105 57.0976 44.245 57.1402 44.2774 57.1848C44.314 57.2335 44.3444 57.2842 44.3769 57.335C44.4053 57.3776 44.4337 57.4222 44.458 57.4668C44.4884 57.5196 44.5148 57.5744 44.5412 57.6271C44.5635 57.6697 44.5858 57.7123 44.6041 57.7549C44.6284 57.8117 44.6508 57.8706 44.671 57.9294C44.6852 57.97 44.7035 58.0106 44.7157 58.0511C44.736 58.1161 44.7502 58.183 44.7644 58.25C44.7725 58.2845 44.7826 58.3189 44.7887 58.3534C44.807 58.4549 44.8191 58.5583 44.8232 58.6618C45.3588 58.7693 45.9167 58.8383 46.4909 58.8647V56.984H46.4929Z"
        fill="#EA7600"
      />
      <path
        d="M43.6727 59.4166C43.5368 59.376 43.4008 59.3314 43.269 59.2867C43.2182 59.2705 43.1695 59.2502 43.1209 59.234C43.0397 59.2056 42.9606 59.1751 42.8815 59.1447C42.8186 59.1204 42.7577 59.096 42.6948 59.0696C42.6339 59.0453 42.5731 59.0189 42.5122 58.9926C42.4412 58.9621 42.3702 58.9297 42.2992 58.8972C42.2566 58.8769 42.216 58.8587 42.1754 58.8384C41.7616 58.6395 41.37 58.4164 41.0068 58.1729C41.4045 59.0818 42.003 59.8832 42.7455 60.5223C42.9545 60.3539 43.1351 60.1835 43.2852 60.007C43.2953 59.9948 43.3075 59.9826 43.3176 59.9704C43.342 59.94 43.3663 59.9096 43.3907 59.8791C43.4089 59.8548 43.4272 59.8305 43.4434 59.8081C43.4617 59.7818 43.48 59.7574 43.4962 59.731C43.5165 59.6986 43.5368 59.6681 43.555 59.6357C43.5672 59.6154 43.5794 59.5951 43.5895 59.5748C43.6179 59.5221 43.6463 59.4693 43.6686 59.4166H43.6727Z"
        fill="#EA7600"
      />
      <path
        d="M46.4929 59.8812C45.864 59.8548 45.2533 59.7797 44.667 59.662C44.6406 59.7351 44.6081 59.8081 44.5736 59.8791C44.5615 59.9055 44.5452 59.9339 44.5331 59.9603C44.5107 60.0029 44.4884 60.0475 44.4641 60.0901C44.4438 60.1246 44.4235 60.1591 44.4032 60.1916C44.3829 60.224 44.3606 60.2565 44.3403 60.2889C44.3139 60.3295 44.2855 60.3681 44.2571 60.4086C44.2429 60.4289 44.2267 60.4492 44.2105 60.4695C44.036 60.6988 43.825 60.9199 43.5815 61.1329C44.4418 61.6625 45.4338 61.9992 46.4949 62.0804V59.8812H46.4929Z"
        fill="#EA7600"
      />
      <path
        d="M72.8915 29.9501C72.36 22.7762 68.9151 16.4139 63.7335 12.0418C62.851 11.6847 61.9502 11.3682 61.0474 11.0964C67.8053 15.6673 72.0942 23.393 72.0942 31.8957C72.0942 32.0033 72.0942 32.1108 72.0922 32.2203C72.3985 31.4291 72.6643 30.6724 72.8915 29.9501Z"
        fill="#A72B2A"
      />
      <path
        d="M27.4706 16.14C29.047 14.1842 30.9155 12.4638 33.0235 11.0497C32.1207 11.3195 31.2219 11.6299 30.3394 11.9829C28.9638 13.1333 27.7121 14.4256 26.6023 15.8356C26.9026 15.9127 27.1927 16.0162 27.4706 16.14Z"
        fill="#A72B2A"
      />
      <path
        d="M22.6725 25.7362C22.4006 25.5861 22.143 25.4116 21.9036 25.2189C21.51 26.7019 21.2442 28.2377 21.1184 29.8121C21.3436 30.5181 21.6053 31.2607 21.9056 32.0377C21.9056 31.991 21.9056 31.9444 21.9056 31.8977C21.9056 29.7837 22.1714 27.7184 22.6725 25.7382V25.7362Z"
        fill="#A72B2A"
      />
      <path
        d="M39.4528 55.8336C38.0245 55.3832 36.6389 54.8071 35.312 54.1071C35.7604 54.6387 36.2067 55.1479 36.649 55.6368C37.5843 56.0446 38.548 56.3976 39.536 56.6959C39.4934 56.4139 39.463 56.1258 39.4528 55.8336Z"
        fill="#A72B2A"
      />
      <path
        d="M54.5472 55.8316C54.5371 56.1237 54.5087 56.4118 54.4661 56.6959C55.3669 56.424 56.2494 56.1075 57.1076 55.7423C57.5539 55.2574 58.0043 54.7523 58.4567 54.2268C57.2009 54.872 55.8923 55.4076 54.5452 55.8316H54.5472Z"
        fill="#A72B2A"
      />
      <path
        d="M31.2321 40.9787L31.2281 34.452H30.7047V28.4549H31.1814L31.1774 22.7539H31.1064C29.5543 25.4441 28.6677 28.5664 28.6677 31.8957C28.6677 35.225 29.5442 38.3007 31.0739 40.9787H31.2321Z"
        fill="#009CDE"
      />
      <path
        d="M61.3925 20.5405V22.7539H60.9177L60.9218 27.3207H63.2955V31.8815L62.8309 31.8856C62.837 31.918 62.841 31.9505 62.8451 31.985C62.8755 32.3136 62.768 32.618 62.5671 32.8452L62.5753 38.5157L61.7516 39.2481L62.4332 41.0822H62.8715C64.4377 38.3818 65.3365 35.2453 65.3365 31.8977C65.3365 27.6088 63.8636 23.6648 61.3965 20.5425L61.3925 20.5405Z"
        fill="#009CDE"
      />
      <path
        d="M47.0042 13.5634V17.3329L51.1754 19.5687L54.8617 17.339L59.1202 19.3841H60.4004C57.0549 15.8032 52.2933 13.5634 47.0062 13.5634H47.0042Z"
        fill="#009CDE"
      />
      <path
        d="M47.0041 13.5634C47.0041 13.5634 47.0021 13.5634 47.0001 13.5634C41.711 13.5634 36.9473 15.8032 33.6018 19.3841H34.8455L39.1445 17.3248L42.5387 19.3841H43.1656L47.0041 17.3309L43.2894 15.3407L47.0041 13.5634Z"
        fill="#009CDE"
      />
      <path d="M42.8329 19.5646L39.1445 17.3268L43.2894 15.3427L47.0041 17.3329L42.8329 19.5646Z" fill="#005EB8" />
      <path d="M47.0042 13.5634V17.3329L50.7169 15.3488L47.0042 13.5634Z" fill="#005EB8" />
      <path
        d="M42.3337 49.6296C43.62 48.6172 45.239 48.0085 47 48.0085C48.761 48.0085 50.38 48.6152 51.6662 49.6296C53.5652 49.1305 55.3424 48.3372 56.9472 47.2985H37.0527C38.6575 48.3372 40.4368 49.1325 42.3337 49.6296Z"
        fill="#C1C6C8"
      />
      <path
        d="M43.6851 49.9298C44.7603 50.1266 45.8681 50.2301 47.0001 50.2301C48.1322 50.2301 49.2399 50.1266 50.3152 49.9298C49.3414 49.3557 48.2093 49.0229 47.0001 49.0229C45.791 49.0229 44.6569 49.3536 43.6851 49.9298Z"
        fill="#009CDE"
      />
      <path
        d="M35.59 46.2455H58.4121C58.5785 46.1137 58.7408 45.9798 58.901 45.8418H35.1011C35.2614 45.9798 35.4257 46.1137 35.59 46.2455Z"
        fill="#C1C6C8"
      />
      <path
        d="M37.0528 47.2985H56.9473C57.1603 47.1605 57.3693 47.0205 57.5742 46.8745H36.4238C36.6308 47.0205 36.8397 47.1625 37.0507 47.2985H37.0528Z"
        fill="#005EB8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1135_1616">
        <rect width={60} height={60} fill="white" transform="translate(17 6)" />
      </clipPath>
    </defs>
  </svg>
)

export default WBPCFFLogoPromoSvg
