import React from "react"

const NissanTMSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.95 398.15">
    <path d="M68.25 139.88c24.71-72.22 93-120.74 169.83-120.74s145.13 48.52 169.84 120.74l.2.6h54.07v-6.47l-22.49-2.61c-13.86-1.62-16.83-7.73-20.59-15.48l-.96-1.91c-32.5-69.25-103.2-114-180.12-114s-147.62 44.75-180.11 114l-.92 1.88c-3.75 7.75-6.75 13.86-20.58 15.48l-22.49 2.64v6.5H68ZM408.06 257.68l-.2.6c-24.72 72.22-92.96 120.73-169.83 120.73-76.89 0-145.13-48.54-169.78-120.77l-.21-.6H13.93v6.49l22.48 2.62c13.84 1.62 16.84 7.74 20.58 15.48l.91 1.86c32.49 69.28 103.19 114.05 180.11 114.06 76.9 0 147.6-44.76 180.1-114l.92-1.89c3.75-7.73 6.71-13.85 20.58-15.46l22.5-2.62v-6.49ZM95.99 171.36h12.25v55.4H95.99zM68.88 226.75v-55.39H56.62v41.28l-40.84-41.28H0v55.39h12.26v-41.56l41.13 41.56h15.49zM343.19 171.36l-34.59 55.39h14.87l6.15-9.89h40.34l6.15 9.89h14.86l-34.57-55.39Zm20.44 35h-27.69l13.86-22.26ZM277.52 215.22c-.35.06-1.91.11-2.53.11h-49.31v11.42h50.08c.42 0 3.3 0 4-.08 10.22-.91 14.87-9.42 14.87-16.93s-4.88-15.82-14.15-16.73a36.69 36.69 0 0 0-4-.18h-32.74a19.23 19.23 0 0 1-3.64-.28 4.77 4.77 0 0 1-3.52-4.83 5 5 0 0 1 3.61-4.82 12.12 12.12 0 0 1 3.43-.25h47.26v-11.3h-48a34.77 34.77 0 0 0-4.8.25 15.9 15.9 0 0 0-14 16.14c0 6.86 4.31 14.86 13.78 16.23a39.8 39.8 0 0 0 5.15.23h31.9c.6 0 2 0 2.31.07a5.53 5.53 0 0 1 .29 10.94M185.58 215.22c-.36.06-1.92.11-2.53.11h-49.32v11.42h50.09c.41 0 3.3 0 4-.08 10.22-.91 14.88-9.42 14.88-16.93s-4.85-15.82-14.11-16.72a36.94 36.94 0 0 0-4-.18H151.8a19.15 19.15 0 0 1-3.64-.28 4.78 4.78 0 0 1-3.53-4.83 5 5 0 0 1 3.62-4.82 12.12 12.12 0 0 1 3.43-.25h47.25v-11.3h-48a34.68 34.68 0 0 0-4.8.25 15.91 15.91 0 0 0-14 16.14c0 6.86 4.31 14.86 13.78 16.23a39.88 39.88 0 0 0 5.16.23h31.89c.61 0 2 0 2.32.07a5.53 5.53 0 0 1 .29 10.94M475.95 226.75v-55.39h-12.26v41.28l-40.84-41.28h-15.78v55.39h12.26v-41.56l41.13 41.56h15.49z" />
    <path
      d="M447.93 363.95a10.42 10.42 0 1 1-10.59 10.36 10.35 10.35 0 0 1 10.59-10.36Zm0 18.79a8.08 8.08 0 0 0 8.08-8.37 8.12 8.12 0 1 0-8.08 8.37Zm-4-3v-10.49a20.63 20.63 0 0 1 3.87-.35c1.88 0 4.61.35 4.61 3.08a2.66 2.66 0 0 1-2.16 2.45v.17c1 .34 1.54 1.19 1.82 2.56a9.32 9.32 0 0 0 .8 2.62h-2.39a8.93 8.93 0 0 1-.8-2.62c-.4-1.65-1.31-1.65-3.47-1.65v4.27Zm2.33-5.92c1.77 0 3.71 0 3.71-1.6 0-1-.69-1.65-2.34-1.65a6 6 0 0 0-1.37.12Z"
      fill="#231916"
    />
  </svg>
)

export default NissanTMSvg
