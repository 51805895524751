import * as React from "react"
import warning from "warning"
import { CentralSeasonFragment } from "../../__generated__/CentralSeasonFragment"
import NcaabBracketGames from "./icons/BracketGames"
import DefaultLogo from "./icons/Circle"
import InlineAsset from "./InlineAsset"
import { CentralSegment } from "../types/types"

export interface ILogoBaseProps {
  gameInstanceUid: string
  segment?: CentralSegment
  season?: CentralSeasonFragment
  id?: string
}

export interface ILogo extends ILogoBaseProps {
  isChoicePage?: boolean
  className?: string
  style?: any
}

const Logos = {
  bpm: NcaabBracketGames,
  default: DefaultLogo,
}

export const Logo = React.memo((props: ILogo) => {
  const { isChoicePage = false, segment, season, gameInstanceUid, ...rest } = props
  const ssn = (segment && segment.season) || season
  if (!isChoicePage && ssn?.svgLogoFullPath) {
    return <InlineAsset {...rest} fullPath={ssn.svgLogoFullPath} isUrl />
  }
  let LogoEl = (ssn?.productAbbrev && Logos[ssn?.productAbbrev]) || Logos[gameInstanceUid]
  warning(!!LogoEl, `unknown gameInstanceUid/prodAbbrev: ${ssn?.productAbbrev || gameInstanceUid}`)
  if (!LogoEl) {
    LogoEl = DefaultLogo
  }
  return <LogoEl {...rest} />
})
