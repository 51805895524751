import styled from "styled-components/macro"
import { palette, fontWeight, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const colorFn = ({ isActive = false }) => (isActive ? palette.gray20 : palette.gray40)
const hoverColor = ({ isActive = false }) => (isActive ? palette.gray20 : palette.lightBlue1)
const borderColor = ({ isActive = false }) => (isActive ? palette.lightBlue1 : "transparent")

const NavItem = styled.div<{ isActive?: boolean }>`
  color: ${colorFn};
  display: inline-block;
  font-size: ${pxToRem(14)};
  font-weight: ${fontWeight.semiBold};
  padding: ${pxToRem(10)} ${pxToRem(14)};
  transition: all 0.2s ease-out;
  position: relative;

  &:first-child {
    padding-left: 0;
    &:after {
      left: 0;
    }
  }
  @media (hover: hover) {
    &:hover {
      color: ${hoverColor};
    }
  }
  &:active {
    color: ${hoverColor};
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: ${pxToRem(12)};
    right: ${pxToRem(12)};
    height: ${pxToRem(3)};
    background-color: ${borderColor};
    bottom: 0;
    transition: all 0.2s ease-out;
  }
`
export default NavItem
