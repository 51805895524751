import { FormikErrors, FormikProps, withFormik } from "formik"
import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { RadioButton } from "@cbs-sports/sports-shared-client/build/cjs/components/RadioButton"
import { buildClassNames, palette, fontWeight, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { extractValidationError, FormSuccessStatus } from "../../../components/Form"
import Modal from "../../../components/Modal"
import Analytics from "../../../utils/analytics"
import { ICreateEntryFormProps, ICreateEntryFormValues, ICreateEntryProps } from "../PoolSetupTypes.d"
import { CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node } from "../../../../__generated__/CentralCurrentUsersEntriesQuery"
import { RadioDualLineEntryRow } from "../../PoolPages/containers/EntryRow"
import UpsertEntryNameModal from "../../PoolPages/components/EntryModals/UpsertEntryNameModal"
import GenericEntryModal, { noop } from "../../PoolPages/components/EntryModals/GenericEntryModal"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { useHistory } from "react-router"
import { withoutDomain } from "../../../../common/url-utils"

const ModalActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  & > button:first-child {
    margin: 0 1rem 0 0;
  }
`
const EntriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.gray20};
  max-height: ${pxToRem(169)};
  overflow: hidden auto;
  width: 100%;
`
const Message = styled.span`
  width: 100%;
  margin-top: -0.5rem;
  color: ${palette.red};
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
`

const CreateBracketModalRoot = styled.div`
  width: calc(${pxToRem(488)} - ${pxToRem(80)});

  .modal__section {
    margin-bottom: 1.5rem;
  }

  .modal__section--options,
  .modal__entries-wrapper {
    border-radius: 0.25rem;
    border: solid 1px ${palette.gray90};
  }

  .modal__section--subtitle {
    margin-bottom: 0.75rem;
  }

  .modal__section--options {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    color: ${palette.gray20};
  }

  .modal__text--disabled {
    opacity: 0.4;
  }

  .modal__option--item {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .modal__option--entry {
    margin: 0.5rem 1rem;

    &:first-of-type {
      margin-top: 1rem;
    }

    &:last-of-type {
      margin-bottom: 1rem;
    }
  }

  .modal__entries-wrapper {
    max-height: ${pxToRem(169)};
    overflow: hidden auto;
  }

  .label-content {
    font-weight: ${bracketTheme.fonts.weights.semireg};
    margin-left: 2rem;
    color: ${bracketTheme.colors.overLight.white20};
    font-size: ${pxToRem(14)};
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    min-width: 0;
  }
`

type Props = ICreateEntryProps & FormikProps<ICreateEntryFormValues>
interface IModalProps extends Omit<ICreateEntryFormProps, "invitedByEntryId" | "invitedVia" | "joinKey" | "invitationId" | "encryptedPassword"> {
  isOpen: boolean
  periodId?: string
  afterClose?: (event?: any) => void
  invitedByEntryId?: string | null | number
  invitedVia?: string | null | number
  joinKey?: string | null | number
  invitationId?: string | null | number
  encryptedPassword?: string | null
}

const CreateEntryFormComponent = (props: Props) => {
  const [showModal, setShowModal] = useState(true)
  const { gameInstanceUid, redirectOnMount, isCommingSoon } = props
  useEffect(() => {
    if (typeof redirectOnMount === "function") {
      redirectOnMount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const season = props.season || { season: "regular", year: new Date().getFullYear() }
  return (
    <UpsertEntryNameModal
      isOpen={showModal}
      redirectToLobby
      title="Name Your Bracket"
      close={() => setShowModal(false)}
      gameInstanceUid={gameInstanceUid}
      isCommingSoon={isCommingSoon}
      mode="bpc"
      seasonType={season.season}
      year={season.year}
      productAbbrev="bpc"
    />
  )
}

const createEntryFormikOptions = {
  mapPropsToValues: (props: any) => {
    return {
      name: props.initialName || "",
      entryId: props.entryId || null,
      poolId: props.poolId || null,
      gameInstanceUid: props.gameInstanceUid,
      invitationId: props.invitationId,
      sponsorData: (props.season?.seasonSignupSponsorDataForm && { name: props.season?.seasonSignupSponsorDataForm.name }) || null,
    }
  },

  validate: (values: ICreateEntryFormValues) => {
    const errors: FormikErrors<any> = {}
    if (!values.name) {
      errors.name = "Please fill in your name"
    }
    return errors
  },

  handleSubmit: (variables, actions) => {
    // console.log(`variables:`)
    // console.dir(variables)
    //   console.log(`actions:`)
    //   console.dir(actions)
    const { mutation, onFinish } = actions.props
    Analytics.trackInteraction("name entry - submit")
    return mutation({ variables })
      .then((res) => {
        // console.log('res')
        // console.dir(res)
        // NOTE qac: if there is no onFinish, that means this component gets unmounted after success
        if (onFinish) {
          actions.setStatus(FormSuccessStatus)
          actions.setSubmitting(false)
          setTimeout(() => {
            onFinish(null, res.data.upsertEntry.entry.pool.id)
          }, 900)
        }
        Analytics.trackInteraction("name entry - success", { createEntry: true })
      })
      .catch((err) => {
        // console.log('err')
        // console.dir(err)
        const apiErrors = extractValidationError(err)
        actions.setErrors(apiErrors.errors)
        actions.setSubmitting(false)
        Analytics.trackInteraction("name entry - fail")
      })
  },
}

const CreateEntryForm = withFormik<ICreateEntryFormProps, ICreateEntryFormValues>(createEntryFormikOptions)(CreateEntryFormComponent)

interface CreateOrCopyEntryFromProps extends Omit<IModalProps, "isOpen"> {
  entries: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[]
  onClose: (event: React.SyntheticEvent) => Promise<unknown> | void
}

const CreateOrCopyEntryFrom = ({
  entries,
  onFinish,
  onClose,
  mutation,
  initialName,
  poolId,
  gameInstanceUid,
  invitationId,
  isCommingSoon,
}: CreateOrCopyEntryFromProps) => {
  const importableBrackets = entries.filter((e) => e.hasMadeAPick)
  const canImportABracket = importableBrackets.length > 0
  const [selectedEntryId, setSelectedEntryId] = useState<string | undefined>(() => {
    const [firstEntry] = importableBrackets.filter((e) => e.hasMadeAPick)
    return firstEntry?.id
  })
  const [newBracket, setNewBracket] = useState(true)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const handleChange = (isNew = true) => {
    if (isNew) {
      setNewBracket(true)
    } else {
      setNewBracket(false)
    }
  }

  const handleChangeSelectEntry = (entry: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node) => {
    setSelectedEntryId(entry.id)
  }

  const handleOnContinue = () => {
    Analytics.trackAction("brackets welcome", "picking options", newBracket ? "create new bracket" : "import bracket")
    if (newBracket || selectedEntryId) {
      setIsLoading(true)
      mutation({
        variables: {
          name: initialName || "",
          poolId,
          gameInstanceUid,
          invitationId: invitationId ? invitationId.toString() : null,
          entryIdToCopy: newBracket ? undefined : selectedEntryId,
        },
      })
        .then((res) => {
          setIsLoading(false)

          // NOTE qac: if there is no onFinish, that means this component gets unmounted after success
          setTimeout(() => {
            if (onFinish) {
              onFinish(null, res.data?.upsertEntry?.entry.pool?.id || "")
            }
            if (!isCommingSoon) {
              const entry = res.data?.upsertEntry?.entry || emptyObject
              const { url } = entry
              history.push(withoutDomain(url))
            }
          }, 300)
          Analytics.trackInteraction("name entry - success", { createEntry: true })
        })
        .catch((err) => {
          setSelectedEntryId(undefined)
          setIsLoading(false)
          setError("Error creating your bracket please try again")
          Analytics.trackInteraction("name entry - fail")
        })
    }
  }

  return (
    <>
      <div className="modal__section">
        <div className="modal__section--subtitle">
          <div className="modal__text--body1">I would like to:</div>
        </div>
        <div>
          <div className="modal__section--options">
            <div className="modal__option--item">
              <RadioButton label="Create a new bracket" name="new_bracket" onChange={() => handleChange()} isChecked={newBracket} />
            </div>
            <div className="modal__option--item">
              <RadioButton
                label="Import a bracket from one of my other CBSSports.com pools"
                name="import_bracket"
                onChange={() => handleChange(false)}
                isChecked={!newBracket}
                disabled={!canImportABracket}
              />
            </div>
          </div>
        </div>
      </div>

      {importableBrackets.length > 0 && (
        <div className="modal__section">
          <div className="modal__section--subtitle">
            <div
              className={buildClassNames({
                "modal__text--body1": true,
                "modal__text--disabled": newBracket,
              })}
            >
              Select a bracket you would like to import:
            </div>
          </div>
          <div className="modal__entries-wrapper">
            <EntriesWrapper>
              {importableBrackets.map((entry) => {
                return (
                  <RadioDualLineEntryRow
                    className="modal__option--entry"
                    key={entry.id}
                    entry={entry}
                    disabled={newBracket || !entry.hasMadeAPick}
                    name={`create-bracket-from-${entry.id}`}
                    isChecked={selectedEntryId === entry.id}
                    onChange={() => handleChangeSelectEntry(entry)}
                  />
                )
              })}
            </EntriesWrapper>
          </div>
        </div>
      )}

      {error && <Message>{error}</Message>}
      <ModalActions>
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleOnContinue} disabled={!newBracket && !selectedEntryId} withLoading loading={isLoading}>
          Continue
        </Button>
      </ModalActions>
    </>
  )
}

interface EntryNameModalProps extends IModalProps {
  entryId?: string
  entries?: CentralCurrentUsersEntriesQuery_currentUser_entries_edges_node[]
  isCopyOrCreate?: boolean
}

export class EntryNameModal extends React.PureComponent<EntryNameModalProps> {
  public static modalKey = "EntryNameModal"

  public onBackgroundClick = (event) => {
    const { onFinish } = this.props
    if (onFinish) {
      onFinish(null, "", event)
    }
  }

  public render() {
    const {
      isOpen,
      isCopyOrCreate,
      entries,
      afterClose,
      invitedByEntryId,
      invitedVia,
      joinKey,
      invitationId,
      encryptedPassword,
      ...rest
    } = this.props
    if (isCopyOrCreate && entries) {
      return (
        <GenericEntryModal isOpen={isOpen} afterClose={afterClose} onBackgroundClick={noop} onEscapeKeydown={noop} title="Create Bracket">
          <CreateBracketModalRoot>
            <CreateOrCopyEntryFrom
              {...rest}
              onClose={this.onBackgroundClick}
              entries={entries}
              invitedByEntryId={invitedByEntryId || null}
              invitedVia={invitedVia || null}
              joinKey={joinKey || null}
              invitationId={invitationId || null}
              encryptedPassword={encryptedPassword || null}
            />
          </CreateBracketModalRoot>
        </GenericEntryModal>
      )
    }
    return (
      <Modal isOpen={isOpen} modalType="dialog" onBackgroundClick={this.onBackgroundClick} afterClose={afterClose}>
        <CreateEntryForm
          {...rest}
          isModal={true}
          invitedByEntryId={invitedByEntryId || null}
          invitedVia={invitedVia || null}
          joinKey={joinKey || null}
          invitationId={invitationId || null}
          encryptedPassword={encryptedPassword || null}
        />
      </Modal>
    )
  }
}

export default CreateEntryForm
