import warning from "warning"
import { IFeRow } from "./common-utils-types"
import constants, { registeredTrademark } from "./constants"
import {
  ENUM_NONE,
  ENUM_PARLAY,
  ENUM_PICKEM,
  ENUM_POST,
  ENUM_REGULAR,
  GameTypeEnum,
  PoolTypeEnum,
  SeasonEnumType,
  SportTypesEnum,
  SubsectionEnum,
  TtagSportTypeEnum,
} from "./enums"
import { emptyObject, emptyVariable } from "./misc-utils"
import { IFullPoolSettings } from "./pool-settings"
import { upperFirst } from "./string-utils"
import palette from "./palette"
import sftp from "ssh2-sftp-client"
import {
  isMarchMadnessMatcher,
  isMarchMadnessProductAbbrevMatcher,
  isNCAAWTournamentMatcher,
  isNissanMarchMadnessMatcher,
} from "./common-utils-helpers"
import { DbRow } from "./db-typings"

interface IFeRowWithCustomCopy extends IFeRow {
  customCopy: null | any
}
interface IGameInstanceCopy extends IFeRowWithCustomCopy {
  uid: string
}

export type ISeasonType = {
  id: string
  season: "post" | "pre" | "regular"
  year: number
  masterProductId: number
  challengePoolSlug: string | null
  challengePoolId: string | null
  productAbbrev: string
  isArchived: boolean
  isCurrent: boolean
  svgLogoFullPath: string
  customCopy: any | null
  appPromoUrl: string
  rulesUrl: string
  hasPrizes: boolean
}

const splashPageOptionsText = {
  // "cbs-nfl-pickem-challenge": "Compete against Will Brinson and other experts from the Pick Six podcast.",
  // "cbs-nfl-pickem-manager": "Play against friends, family and coworkers with spreads, weights and bonuses.",
  // "cbs-ncaab-confbracket-challenge": "Make your picks and compete to win up to $10,000.",
  // "cbs-ncaab-confbracket-manager": "Play against friends. Pick your favorite conference tournament or play all 6.",
  "cbs-ncaaf-pickem-challenge": "Pick the best 10 games on the slate and compete for $1,000 every week.",
  "cbs-ncaaf-pickem-manager": "Pick straight up or with spreads using our top 10 games or your own custom setup.",
  // "cbs-ncaaf-pickem-challenge": "Enter the College Pick'em Challenge and compete for $1,000 every week.",
  // "cbs-ncaaf-pickem-manager": "Play against friends, family and coworkers picking straight up or against the spread.",
}
const ruleshrefs = {
  "cbs-ncaaf-pickem-challenge-post": "https://www.cbssports.com/fantasy/college-football/games/bowl-pickem/rules",
  "cbs-ncaaf-pickem-manager-post": "https:///www.cbssports.com/info/about/tos/opm-college-football-bowl",
  "cbs-nfl-pickem-challenge": "https://www.cbssports.com/fantasy/football/games/pickem/rules",
  "cbs-nfl-pickem-manager": "https://www.cbssports.com/info/about/tos/opm",
  "cbs-nfl-pickem-playoff-challenge-post": "https://www.cbssports.com/fantasy/football/games/playoff-pickem/rules",
  "cbs-nfl-pickem-challenge-post": "https://www.cbssports.com/fantasy/football/games/playoff-pickem/rules",
  "cbs-nfl-pickem-manager-post": "https://www.cbssports.com/info/about/tos/opm-playoffs",
  "cbs-ncaab-confbracket-challenge": "https://www.cbssports.com/fantasy/bracket/games/conf/bpc/rules",
  "cbs-ncaab-confbracket-manager": "https://www.cbssports.com/fantasy/bracket/games/conf/bpm/tou",
  "cbs-ncaaf-pickem-challenge": "https://www.cbssports.com/fantasy/college-football/games/pickem/rules",
  "cbs-ncaaf-pickem-manager": "https://www.cbssports.com/info/about/tos/opm-college-football",
  "cbs-nfl-parlay-challenge": "https://www.cbssports.com/fantasy/football/games/parlay/rules",
  "cbs-ncaab-tournament-challenge": "https://www.cbssports.com/fantasy/bracket/games/bpc/rules",
  "cbs-ncaaw-tournament-challenge": "https://www.cbssports.com/fantasy/bracket/games/wbpc/rules",
  "cbs-ncaaw-tournament-manager": "https://www.cbssports.com/fantasy/bracket/games/wbpm/rules", //TODO: Get WBPM Rules Link for 2023
  "cbs-ncaab-tournament-manager": "https://www.cbssports.com/fantasy/bracket/games/bpm/rules",
  "nissan-ncaab-tournament-challenge": "https://www.cbssports.com/fantasy/bracket/games/bpc1/rules",
  "nissan-ncaaw-tournament-challenge": "https://www.cbssports.com/fantasy/bracket/games/wbpc1/rules",
  "cbs-ncaab-tournament-sweet-sixteen-challenge": "https://www.cbssports.com/fantasy/bracket/games/bpc/rules", // NOTE/S16 TODO LL: Need to update for sweet 16
}

const todoText = `TODO (edit in admin mode)`
export function getRulesLink(
  gameInstanceUid: string,
  seasonType: string,
  productAbbrev: string,
  year: number,
  ttagSegment: string,
  excludeTtag = false,
) {
  const href =
    ruleshrefs[`${gameInstanceUid}-${seasonType}`.toLowerCase()] || ruleshrefs[gameInstanceUid.toLowerCase()] || "https://www.cbssports.com"
  if (excludeTtag) {
    return href
  }
  const ttag = buildTtag(productAbbrev, year, "FF", ttagSegment)
  return `${href}?ttag=${ttag}`
}

export function buildDisclaimer(gameInstanceUid: string, seasonType: string, productAbbrev: string, year: number, ttagSegment = "disclaimer") {
  const rulesLink = getRulesLink(gameInstanceUid, seasonType, productAbbrev, year, ttagSegment)
  const combined = [gameInstanceUid, seasonType].join("-").toLowerCase()
  const isChallenge = /challenge/.test(gameInstanceUid)
  const atag = `<a target="_blank" rel="noopener" href="${rulesLink}" data-cy="terms-of-use-link">${
    isChallenge ? "Official Rules" : "Terms of Use"
  }</a>`
  // const gamblingPreamble = isChallenge ? `This Challenge may not be used to conduct, advertise or promote any form of gambling. ` : ""
  // tslint:disable-next-line
  const preamble = `No purchase or wireless device necessary to enter or win. This Challenge is open to persons who are: (a) Registered Users; (b) legal residents of the United States (for purposes of clarity, Puerto Rico and all US territories and possessions are expressly excluded); (c) at least twenty-one (21) years of age or older at the date of registration in the Challenge. Data rates may apply. `
  const postAmble = `Void where prohibited by law. See ${atag} for details.`
  if (combined.includes("nfl-pickem") && combined.includes("post")) {
    if (isChallenge) {
      // tslint:disable-next-line
      return `${preamble} The Challenge begins December 21, 2023 and ends February 11, 2024 with the conclusion of the Super Bowl. Void where prohibited by law. See ${atag} for details.`
    } else {
      // tslint:disable-next-line
      return `CBSSports.com Playoff Pick'em Manager is open to Registered Users of CBSSports.com, twenty-one (21) years of age or older at the time of registration. CBSSports.com Playoff Pick'em Manager is subject to the complete applicable ${atag}.`
    }
  } else if (combined.includes("nfl-pickem")) {
    // tslint:disable-next-line
    if (isChallenge) {
      return `${preamble} Early signup period begins August 1, 2023. The Contest begins on September 7, 2023 and ends at the conclusion of the last NFL game played on January 7, 2024. ${postAmble}`
    }
    return `CBSSports.com Football Pick'em Manager is open to Registered Users of CBSSports.com, twenty-one (21) years of age or older at the time of registration. CBSSports.com Football Pick'em Manager is subject to the complete applicable ${atag}.`
  } else if (combined.includes("ncaaf-pickem") && combined.includes("post")) {
    // tslint:disable-next-line
    return `${preamble} The Challenge will begin Monday, December 8, 2019 and end on January 13th, 2020. ${postAmble}`
  } else if (combined.includes("ncaab-confbracket") && isChallenge) {
    // tslint:disable-next-line
    return `${preamble} The Challenge will begin March 9, 2021 and end on March 14th, 2021 at the conclusion of the last conference tournament championship game. ${postAmble}`
  } else if (combined.includes("ncaab-confbracket")) {
    // tslint:disable-next-line
    return `2021 CBSSports.com Conference Bracket Manager is open to Registered Users who are at least twenty-one (21) years of age at the date of entry. Msg and data rates may apply. See full ${atag} for details.`
  } else if (combined.includes("ncaaf-pickem")) {
    // tslint:disable-next-line
    if (isChallenge) {
      return `${preamble} Early signup period begins August 1, 2023. The Contest begins on September 3, 2023 and ends at the conclusion of the last game on the weekly slate played on November 26, 2023. ${postAmble}`
    }
    return `CBSSports.com College Football Pick'em Manager is open to Registered Users of CBSSports.com, twenty-one (21) years of age or older at the time of registration. CBSSports.com College Football Pick'em Manager is subject to the complete applicable ${atag}.`
  } else if (combined.includes("nfl-parlay")) {
    // tslint:disable-next-line
    return `${preamble} The Challenge begins on August 11, 2020 and ends at the conclusion of the last NFL game played on January 3, 2021. ${postAmble}`
  } else if (isMarchMadnessMatcher.test(combined)) {
    const isWomens = isNCAAWTournamentMatcher.test(gameInstanceUid)
    const isNissan = isNissanMarchMadnessMatcher.test(gameInstanceUid)
    // NOTE/S16 TODO LL: Would need a specific one for sweet 16, unless we can use bpc, which it is defaulting to now
    if (isWomens && isNissan) {
      // tslint:disable-next-line
      return `No Purchase necessary to enter to win. A purchase will not improve your chances of winning. This challenge is open to: (A) Active Employees of Nissan North America Inc. who are employed through the duration of the sweepstakes period (expressly excluded are any and all Nissan/Infiniti dealership employees); (B) Who are registered users of CBSSports.com ; (C ) Who are legal residents of one (1) of the United States or D.C. AND (D) Who are at least twenty-one (21) years old in their state of residence at the date of entry.  Void in Puerto Rico, and all other U.S. Territories and Possessions and where prohibited by law. Data Rates may apply.  Must complete bracket by 11:30 AM ET on March 22, 2024. Sweepstakes begins on March 17, 2024 and will run through the final game of the tournament scheduled for April 7, 2024. See <a target="_blank" rel="noopener" href="https://www.cbssports.com/fantasy/bracket/games/wbpc1/rules">Official Rules</a> for Details.`
    } else if (isNissan) {
      // tslint:disable-next-line
      return `No Purchase necessary to enter to win. A purchase will not improve your chances of winning. This challenge is open to: (a) active Employees of Nissan North America Inc. who are employed through the duration of the sweepstakes period (expressly excluded are any and all Nissan/Infiniti dealership employees); (b) who are registered users of CBSSports.com (c ) who are legal residents of one (1) of the United States or D.C. and (d) Who are at least twenty-one (21) years old in their state of residence at the date of entry.  Void in Puerto Rico, and all other U.S. Territories and Possessions and where prohibited by law. Data Rates may apply. Must complete bracket by 12:00 PM ET on March 21, 2024. Sweepstakes begins on March 17, 2024 and will run through the final game of the tournament scheduled for April 8, 2024. See <a target="_blank" rel="noopener" href="https://www.cbssports.com/fantasy/bracket/games/bpc1/rules">Official Rules</a> for Details.`
    } else if (productAbbrev === "bpc" || productAbbrev === "wbpc") {
      // tslint:disable-next-line
      return `No purchase or wireless device necessary to enter or win. The 2024 CBSSports.com Bracket Challenge is open to persons who: (a) are Registered Users; (b) are legal residents of the United States or Puerto Rico: (c) are at least 21 years old; (d) have a valid driver's license issued by your state; and (e) have proof of valid car insurance at the date of entry in this Promotion. In order to be eligible for the Combined Grand Prize, the eligible participant must enter both the 2024 CBSSports.com Men's Bracket Challenge and the 2024 CBSSports.com Women's Bracket Challenge games, and complete your bracket(s) by 12:00 PM ET on March 21, 2024. Sweepstakes begins on March 17, 2024 and ends April 8, 2024. Void in all other U.S. Territories and Possessions and where prohibited by law. See <a target="_blank" rel="noopener" href="https://www.cbssports.com/fantasy/bracket/games/bpc/rules?ttag=FF24_lk_cbssports_cbb_bpc_rules">Official Rules</a> for details.`
    } else if (productAbbrev === "wbpm") {
      // tslint:disable-next-line
      return `Open to Registered Users who are at least 21 years of age at the date of entry. Void in all other U.S. Territories and Possessions and where prohibited by law. Women’s Bracket Manager is subject to the complete applicable <a target="_blank" rel="noopener" href="https://www.cbssports.com/fantasy/bracket/games/wbpm/rules?ttag=FF24_lk_cbssports_cbb_wbpm_rules">Terms of Use</a>. Msg and data rates may apply.`
    } else {
      // BPM
      // tslint:disable-next-line
      return `Open to Registered Users who are at least 21 years of age at the date of entry. Void in all other U.S. Territories and Possessions and where prohibited by law. Men’s Bracket Manager is subject to the complete applicable <a target="_blank" rel="noopener" href="https://www.cbssports.com/fantasy/bracket/games/bpm/rules?ttag=FF24_lk_cbssports_cbb_bpm_rules">Terms of Use</a>. Msg and data rates may apply.`
    }
  } else {
    return `DISCLAIMER: Pending. See ${atag}`
  }
}

export const defaultAppStoreLink = "https://smart.link/5c1cf22d22736"

export function appStoreLinkFor(gameInstanceUid?: string, seasonType?: string, year?: number) {
  const combined = `${seasonType || ""}-${gameInstanceUid || ""}`
  if (/post-ncaaf/i.test(combined)) {
    return "https://cbs-sports.app.link/9tE0my4wU1"
  } else if (/ncaaf/i.test(combined)) {
    return "https://cbs-sports.app.link/Chq1fVqVR0"
  } else if (/ncaab-tournament/i.test(combined)) {
    // TODO: YHB Replace Branch Link
    return defaultAppStoreLink
  } else if (/parlay|nfl/i.test(combined)) {
    return "https://cbs-sports.app.link/m2ghc29ty7"
  } else {
    return defaultAppStoreLink
  }
}
/*
In order to implement more of a platform centric approach, we would like to control ttag creation for each game programatically via the following formulat:

Each outbound link will contain a TTAG of the following format:

`<target><year>_lk_cbssports_<productAbbrev>_<ttagSegment>`

* `target`: "SL" | "FF" | "SHQ" (coorisponding to where the link puts the user: "Sportsline", "Fantasy", "HQ")
* `year`: last 2 digits of year
* `sportType`: ex: `plopm`, `bpmc`... dilinates sport (requested for simpler lookup in addition to productAbbrev)
* `productAbbrev`: ex: `plopm`, `bpmc`... dilinates game type, sport, and pool type
* `ttagSegment`: what the link pertains to (dynamic), ex: "matchupAnalysis", "mainNav", "pickSlip"
*/

export function buildTtag(productAbbrev: string, year: number, target: "SL" | "FF" | "SHQ", ttagSegment: string) {
  return `${target}${buildTagSuffix(productAbbrev, year, ttagSegment)}`
}

export function buildTagSuffix(productAbbrev: string, year: number, ttagSegment: string) {
  const productAbbrevWoPoolType = productAbbrev.substr(0, productAbbrev.length - 1)
  const ttagSport =
    (/ffb/.test(productAbbrevWoPoolType) && TtagSportTypeEnum.cfb) ||
    (/op$/.test(productAbbrevWoPoolType) && TtagSportTypeEnum.nfl) ||
    (/bp$/.test(productAbbrevWoPoolType) && TtagSportTypeEnum.cbb) ||
    TtagSportTypeEnum.unk
  // let _year = year
  // if (/cnf/.test(productAbbrev)) {
  //   _year = year + 1
  // }
  return `${year.toString().substr(2, 2)}_lk_cbssports_${ttagSport}_${productAbbrev}_${ttagSegment}`
}

export function championshipGameName(sportType: SportTypesEnum) {
  if (sportType === SportTypesEnum.NCAAF) {
    return `NCAAF Championship`
  }
  if (sportType === SportTypesEnum.NFL) {
    return `NFL Champion`
  }
  return `Tournament Winner`
}

export const customCopyLinkClassname = "custom-copy__edit-link"
export const customCopyTextClassname = "custom-copy__text"

export function getGameText(gameInstanceUid: string, segment: any, poolSettings?: IFullPoolSettings | null) {
  const { season } = segment
  return buildMappingFor(gameInstanceUid, season.season, season.year, season.productAbbrev, undefined, season, segment, poolSettings)
}
export function getSSRGameText(gameInstanceUid: string, season: DbRow, segment: DbRow, ttag?: string) {
  // TODO qac: we need to properly support CentralSegmentFragment and poolSettings from the SSR:
  return buildMappingFor(gameInstanceUid, segment.seasonType, season.year, season.productAbbrev, ttag, season as any, segment as any)
}
export function customCopyHtml(
  code: string,
  gameInstance: IGameInstanceCopy,
  season?: ISeasonType,
  segment?: any,
  poolSettings?: IFullPoolSettings,
  showAdminEditLink = false,
  defaultHtml?: string,
  fallbackHtml?: string,
  bypassCustomCopy = false,
) {
  const editUrl =
    (showAdminEditLink &&
      `${constants.DOMAIN}/admin/marketing/custom-copy?code=${encodeURIComponent(code)}&gameInstanceId=${encodeURIComponent(
        gameInstance.id,
      )}&seasonId=${encodeURIComponent(season?.id || "")}`) ||
    ""
  const editLink = (editUrl && ` <a class="${customCopyLinkClassname}" target="_blank" rel="nofollow noopenner" href="${editUrl}">[EDIT]</a>`) || ""
  const customCopy = (season?.customCopy || emptyObject)[code] || (gameInstance.customCopy || emptyObject)[code]
  if (customCopy && !bypassCustomCopy) {
    return customCopy + editLink
  }
  // see if we can use gameText:
  if (season && gameTextKeys.includes(code)) {
    const gameText = buildMappingFor(gameInstance.uid, season.season, season.year, season.productAbbrev, code, season, segment, poolSettings)
    const tof = typeof gameText[code]
    if (tof === "string") {
      return gameText[code] + editLink // .replace("EDIT", "CUSTOMIZE");
    }
    if (tof === "object" && Array.isArray(gameText[code])) {
      return gameText[code].join("<br />") + editLink // .replace("EDIT", "CUSTOMIZE");
    }
  }
  // defaultHtml means its intended to use this, but with possible overrides
  if (defaultHtml) {
    return defaultHtml + editLink // .replace("EDIT", "CUSTOMIZE");
  }
  // missing text cases
  warning(!defaultHtml, `missing customCopyHtml code: '${code}'`)
  if (showAdminEditLink) {
    return `<strong>[MISSING CUSTOM COPY]</strong>${editLink}FALLBACK: ${fallbackHtml || "[none]"}&nbsp;`
  } else {
    return fallbackHtml || "&nbsp;"
  }
}

export function usesFrontsideAuthGate(gameInstanceUid: string, year: number) {
  const isNflParlay = /nfl-parlay/.test(gameInstanceUid)
  const isNcaaFPickem = /ncaaf-pickem/.test(gameInstanceUid)
  const isNFLPickem = /nfl-pickem/.test(gameInstanceUid)
  return (isNflParlay && year < 2020) || isNcaaFPickem || isNFLPickem
}

export function getDefaultPoolAvatarUrl(
  poolType: PoolTypeEnum,
  gameType: GameTypeEnum,
  sportType: SportTypesEnum,
  subsection: SubsectionEnum,
  seasonType: SeasonEnumType,
  year: number,
  productAbbrev: string,
) {
  switch (productAbbrev) {
    case "opc":
      return {
        avatarUrl: "https://sports.cbsimg.net/picks-games/logos/football/nfl-pickem.png",
      }
    case "ffbopc":
      return {
        avatarUrl: "https://sports.cbsimg.net/picks-games/logos/college-football/ncaaf-pickem.png",
      }
    default:
      const isChallenge = poolType === PoolTypeEnum.CHALLENGE
      const isPost = seasonType === SeasonEnumType.post
      const isMarchMadness = subsection === SubsectionEnum.NcaaTournament && isPost
      if (isChallenge && isMarchMadness) {
        return {
          avatarUrl: `/static/game-assets/cbs-ncaab-tournament/bpc-logo-transparent-512x512.png`,
        }
      }

      return {}
  }
}

export function getPrizingInfo(
  poolType: PoolTypeEnum,
  gameType: GameTypeEnum,
  sportType: SportTypesEnum,
  subsection: SubsectionEnum,
  seasonType: SeasonEnumType,
  year: number,
) {
  const isChallenge = poolType === PoolTypeEnum.CHALLENGE
  const isPost = seasonType === SeasonEnumType.post
  const isNcaaf = sportType === SportTypesEnum.NCAAF
  const isNfl = sportType === SportTypesEnum.NFL
  if (!isChallenge) {
    return {}
  }
  if (gameType === GameTypeEnum.PARLAY && isNfl) {
    if (year === 2019) {
      const ftpInfo: sftp.ConnectOptions | null =
        (typeof window !== "undefined" && {
          host: "ftp.scapromotions.com",
          username: "86104",
          password: "nflcbs86104",
        }) ||
        null
      const prizingDetails =
        (ftpInfo && {
          ftp: ftpInfo,
        }) ||
        null
      return {
        periodPrizeAmount: 1000000,
        hasPrizes: true,
        prizingDetails,
      }
    }
    if (year === 2020) {
      return {
        periodPrizeAmount: 1000,
        seasonPrizeAmount: 20000,
        hasPrizes: true,
      }
    }
  }
  if (subsection === SubsectionEnum.ConferenceTournament) {
    if (year < 2021) {
      return {
        hasPrizes: true,
        seasonPrizeAmount: 5000,
      }
    }
  }
  if (gameType === GameTypeEnum.PICKEM && isNfl && isPost) {
    if (year === 2020) {
      return {
        hasPrizes: true,
        seasonPrizeAmount: 5000,
      }
    }
  }
  if (gameType === GameTypeEnum.PICKEM && isNfl && !isPost) {
    if (year === 2020) {
      return {
        hasPrizes: true,
        periodPrizeAmount: 1000,
      }
    }
  }
  if (isNcaaf && isPost) {
    if (isPost && year < 2020) {
      return {
        hasPrizes: true,
        seasonPrizeAmount: 5000,
      }
    }
    if (!isPost && year < 2020) {
      return {
        hasPrizes: true,
        periodPrizeAmount: 1000,
      }
    }
  }
  if (subsection === SubsectionEnum.NcaaTournament) {
    if (year === 2021) {
      return {
        hasPrizes: true,
        seasonCustomPrize: `Enter for a chance to win a Nissan Rogue and a trip to the 2022 Final Four${registeredTrademark}!`,
      }
    }
  }
  return {}
}

export function authGateText(isPoolCreate: boolean, gameInstanceUid: string, year: number, hasPrizes: boolean) {
  const textLines = [] as string[]
  if (isPoolCreate) {
    textLines.push(`Login or signup to create your pool`)
  } else if (usesFrontsideAuthGate(gameInstanceUid, year)) {
    textLines.push(`Make your picks, compete ${hasPrizes ? "for the prize" : "for the title"}.`)
  } else {
    textLines.push(`In order to compete ${hasPrizes ? "for the prize" : "for the title"},`)
    textLines.push(`you need to login or sign up below.`)
  }
  return textLines
}

export function splashPageHtml(gameInstanceUid: string, seasonType?: string) {
  const isChallenge = /challenge/.test(gameInstanceUid)
  const isNcaabConfBracket = /ncaab-confbracket/.test(gameInstanceUid)
  const isNfl = /nfl-/.test(gameInstanceUid)
  const isNcaaF = /ncaaf-/.test(gameInstanceUid)
  const html =
    (isChallenge && isNcaabConfBracket && `Fill out your conference brackets and get up to six chances to win <strong>$10,000</strong>.`) ||
    (isNcaabConfBracket && `Pick against your friends using <strong>your rules</strong> and <strong>your preferred conference(s)</strong>.`) ||
    (isChallenge && isNfl && `Make your playoff picks each week through the title game to compete for the <strong>$5,000 cash prize</strong>.`) ||
    (isNcaaF &&
      isChallenge &&
      seasonType === "post" &&
      `Pick all 40 bowl games and compete against the CBS Sports community for the <strong>$5,000 cash prize</strong>.`) ||
    (isNcaaF && isChallenge && `Compete for the <strong>$100,000 jackpot</strong> and guaranteed cash prizes every week.`) ||
    (isNfl && `Create a private pool where you can play straight up or against the spread with options for round, seed, and more.`) ||
    `Create a private pool with <strong>customizable rules</strong>, scoring, slate of games, and more.`
  return `<span>${html}</span>`
}

const whAbbrevs = ["plopc"]

export function getSponsorKeyFor(season?: ISeasonType | null) {
  if (season) {
    if (whAbbrevs.includes(season.productAbbrev) && season.year === 2020) {
      return "williamhill"
    } else if (isMarchMadnessProductAbbrevMatcher.test(season.productAbbrev)) {
      return "nissanOrHertz"
    } else if (constants.IS_OPC_SPONSORED && season.productAbbrev === "opc") {
      return "trico"
    } else if (constants.IS_POPC_SPONSORED && season.productAbbrev === "popc") {
      return "trico"
    }
  }
  return null
}

function buildMappingFor(
  gameInstanceUid: string,
  seasonType: string,
  year?: number,
  productAbbrev?: string,
  ttagSegment?: string,
  season?: ISeasonType,
  segment?: any | null,
  poolSettings?: IFullPoolSettings | null,
  // isChallengePool: boolean,
) {
  // console.log(`buildMappingFor : ${gameInstanceUid}`)
  const sportType =
    segment?.sportType ||
    (/nfl/.test(gameInstanceUid) && SportTypesEnum.NFL) ||
    (/ncaaf/.test(gameInstanceUid) && SportTypesEnum.NCAAF) ||
    (/ncaab/.test(gameInstanceUid) && SportTypesEnum.NCAAB)
  const _seasonType = seasonType.toLowerCase()
  const _year = year || new Date().getFullYear()
  const gameType = segment?.gameType || ENUM_PICKEM
  const isNissan = /nissan/.test(gameInstanceUid)
  const isChallenge = /challenge/.test(gameInstanceUid)
  const isNflPickem = /nfl-pickem/.test(gameInstanceUid)
  const isNcaabSweetSixteenBracket = /ncaab-tournament-sweet-sixteen/.test(gameInstanceUid)
  const isNcaabBracket = /ncaab-tournament/.test(gameInstanceUid)
  const isNcaawBracket = /ncaaw-tournament/.test(gameInstanceUid)
  const isNcaabConfBracket = /ncaab-confbracket/.test(gameInstanceUid)
  const isNcaaFPickem = /ncaaf-pickem/.test(gameInstanceUid)
  const isNflParlay = /nfl-parlay/.test(gameInstanceUid)
  const isRegSeason = _seasonType === ENUM_REGULAR
  const isPostSeason = _seasonType === ENUM_POST
  const isNcaafPost = isNcaaFPickem && isPostSeason
  const periodLength = poolSettings?.periodLength || ""
  const seasonPrefix = isRegSeason ? "" : `${upperFirst(_seasonType)} `
  const sponsorKey = getSponsorKeyFor(season)
  const isFootball = /nfl|ncaaf/.test(gameInstanceUid)
  const isNCAABracket = isNcaabBracket || isNcaawBracket
  const isOPMFootballPickem = /cbs-football-pickem/.test(gameInstanceUid)
  const isOPMFootballSurvivor = /cbs-nfl-survivor/.test(gameInstanceUid)
  const title =
    (isOPMFootballPickem && `Football Pick'em`) ||
    (isOPMFootballSurvivor && `Football Survivor`) ||
    (isNcaafPost && `College Bowl Pick'em`) ||
    (isNcaaFPickem && `College Football Pick'em`) ||
    // (isNcaabSweetSixteenBracket && `${year} NCAA${registeredTrademark} Sweet 16 Challenge`) || // NOTE/S16 LL: Do we want this
    (isNcaabBracket && `${year} NCAA${registeredTrademark} Men's Bracket Games`) ||
    (isNcaawBracket && `${year} NCAA${registeredTrademark} Women's Bracket Games`) ||
    (isNflPickem && `${isPostSeason ? "Playoff" : `${seasonPrefix}Football`} Pick'em`) ||
    (isNflParlay && `${seasonPrefix}Parlay Pick'em`) ||
    (isNcaabConfBracket && `Conference Tournament Brackets`) ||
    `unknown`
  const topTitleText = (isNCAABracket && `March Madness${registeredTrademark}`) || undefined
  const messageExtraForBrackets = isNcaabBracket ? ` so we can compete all of March Madness ${registeredTrademark}` : ""
  const inviteBackModalMessage = `My ${title} pool POOL_NAME_REPLACE is back for another year on CBSSports.com. Visit the pool before the tournament starts${messageExtraForBrackets}!`
  const comingSoonText =
    ((isOPMFootballPickem || isOPMFootballSurvivor) && `Picks are Coming Soon!`) ||
    (isNcaaFPickem && isPostSeason && `It's Almost Bowl Season...`) ||
    (isNflPickem && isPostSeason && `We'll See You in the Playoffs!`) ||
    // (isFootball && `Get Ready for Football!`) ||
    (!isPostSeason && `Picks are Coming Soon!`) ||
    `unknown`
  // const comingSoonText = isPostSeason && "We'll See You in the Playoffs!" || "Picks are Coming Soon!";
  const comingSoonSubtext = [] as string[]
  if (isNflPickem && isPostSeason) {
    comingSoonSubtext.push("Picks will be available here shortly after the playoff match-ups are announced. Check back in soon!")
  } else if (isNcaaFPickem && isPostSeason) {
    comingSoonSubtext.push("Picks will be available here shortly after the bowl match-ups and lines are announced. Check back in soon!")
  } else if (isNcaabConfBracket) {
    comingSoonSubtext.push(
      "We're finishing out the regular season but the madness is coming. Picks will be available shortly after seeding is finalized.",
    )
  } else if (isNcaaFPickem) {
    if (isChallenge) {
      comingSoonSubtext.push("The Challenge will begin with games scheduled on 9/2.")
      comingSoonSubtext.push("We will notify you when picks are open.")
    } else {
      comingSoonSubtext.push("Games will be available for selection soon.")
      comingSoonSubtext.push("We will notify you when picks are available.")
    }
  } else {
    comingSoonSubtext.push("Games will be available closer to season start.")
    comingSoonSubtext.push("We will notify you when picks are available!")
  }
  // const comingSoonText = isRegSeason ? "Picks are Coming Soon!" : "We'll See You in the Playoffs!" ;
  const hasPrizes = season ? season.hasPrizes : isChallenge
  const metaDescriptions = [`Play ${title.replace("Parlay", "Football")} games with CBS Sports${hasPrizes ? " and win cash prizes" : ""}`]
  const metaTitles = [`${title} Game From CBS Sports`]
  if (isNflParlay) {
    metaDescriptions.push("Play against your friends, compete for weekly cash and get the chance to win $20,000 season prize.")
    // metaTitles.push("Win up to $20,000")
  } else if (isRegSeason && hasPrizes) {
    metaDescriptions.push("Play against your friends and have a chance to win $1,000 every week.")
  } else {
    metaDescriptions.push("Play against your friends.")
  }
  metaTitles.push("CBSSports.com")
  // TODO qac: should be based on sport
  const yearSpan = isNcaabConfBracket ? `${_year}` : `${_year}-${(_year + 1).toString().substring(2)}`

  const tagSuffix = (year && ttagSegment && productAbbrev && buildTagSuffix(productAbbrev, year, ttagSegment)) || undefined
  const ttag = (tagSuffix && `?ttag=FF${tagSuffix}`) || ""
  const titleWithSuffix =
    ((isChallenge && isNcaabConfBracket && `Conference Bracket`) ||
      (isChallenge && isNcaabSweetSixteenBracket && `Sweet 16`) ||
      (isChallenge && isNcaabBracket && `Men's Bracket`) ||
      (isChallenge && isNcaawBracket && `Women's Bracket`) ||
      title) + (isChallenge && !isNflParlay ? " Challenge" : "")
  const _authGateText = authGateText(!isChallenge, gameInstanceUid, _year, hasPrizes)
  const titleWithClient = `${isNissan ? "Nissan Employee" : "CBS Sports"} ${titleWithSuffix}`
  const disclaimerHtml = buildDisclaimer(gameInstanceUid, _seasonType, productAbbrev || "unknown", _year, ttagSegment)
  const splashPageChallOnlyItems = [] as string[]
  const groupOptions = [] as string[]
  const splashPageCtaText = isChallenge ? (gameType === "BRACKET" ? `Fill out your brackets` : "Play Solo") : `Run a Pool`
  const splashPageBtnCta = isChallenge ? "Play Now" : `Start a Pool`
  const rulesOrTosText = isChallenge ? "No purchase necessary. See official rules" : "See terms for details"
  const titleWithPool = `${title} Pool`
  const splashSettingsHighlight3 = groupOptions.pop() || emptyVariable
  const splashSettingsHighlight2 = groupOptions.pop() || emptyVariable
  const splashSettingsHighlight1 = groupOptions.pop() || emptyVariable
  const splashPageChallOnlyTitle = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlySubTitle = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlyHeading = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlyGroup1Header = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlyGroup1P = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlyGroup2Header = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlyGroup2P = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlyGroup3Header = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlyGroup3P = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlySecondCta = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageChallOnlyUpsellCta = splashPageChallOnlyItems.pop() || emptyVariable
  const splashPageTitleCta = "How would you like to play?"
  const createEntryPrompt = `Name your entry and compete ${hasPrizes ? `for the cash!` : `to show your skill!`}`
  const createEntryTitle = `Welcome to ${titleWithClient}!`
  const splashPageOldStyleDescription = `Run a pool or play solo ${hasPrizes ? `for cash prizes` : `against the competition`}`
  const seasonText = `${year} ${titleWithSuffix}`
  const splashPageOldStyleTitle = seasonText
  const upsellCardPicksClosed = "The window for making picks is now closed."
  const upsellCardPicksClosedPromo = "However, there is still time to get in the action with"
  // const rulesUrl = (ruleshrefs[gameInstanceUid] || "unknown") + ttag
  const rulesUrl = getRulesLink(gameInstanceUid, _seasonType, productAbbrev || "unknown", _year, tagSuffix || "picks-rules")
  let picksPageRulesHtml = todoText
  let picksPageStartedGamesHtml = todoText
  if (isNflParlay) {
    picksPageRulesHtml = `
    <div class="flex-row justify-content--space-between">
      <header>
        <strong>How to play</strong>
      </header>
      <a href="${rulesUrl}" target="_blank" rel="noopener noreferrer">Official Rules</a>
    </div>
    <p>✍️ <strong>Make Picks</strong> - Answer any five questions below, enter your tiebreakers, and submit your entry.</p>
    <p>💵 <strong>Weekly Cash Prize</strong> - Get all 5 correct any week of the season for a chance to win!</p>
    <p>🏆 <strong>$20,000 Season Prize</strong> - Answer the most questions correctly for a chance at the jackpot!</p>
    <small><p>No purchase or wireless device necessary to enter or win. US only. 18+ only. Data rates may apply. See <a href="${rulesUrl}" target="_blank" rel="noopener noreferrer">Official Rules</a> for details.</p></small>
    `
  } else if (isNcaafPost) {
    picksPageRulesHtml = `
    <div class="flex-row justify-content--space-between">
      <header>
        <strong>How to play</strong>
      </header>
      <a href="${rulesUrl}" target="_blank" rel="noopener noreferrer">Official Rules</a>
    </div>
    <p>✍️ <strong>Make Picks</strong> - Pick every scheduled bowl game against the spread.</p>
    <p>🏆 <strong>Fill your tiebreaker</strong> - Select a champion and guess the total points scored in the championship game.</p>
    <p>🔒 <strong>Hurry!</strong> - Picks lock at the start of the first game!</p>
    <p>💵 <strong>$5,000 Cash Prize</strong> - Earn the most points for the chance to win the jackpot!</p>
    `
  } else if (isNflPickem && isPostSeason) {
    picksPageRulesHtml = `
    <div class="flex-row justify-content--space-between">
      <header>
        <strong>How to play</strong>
      </header>
      <a href="${rulesUrl}" target="_blank" rel="noopener noreferrer">Official Rules</a>
    </div>
    <p>✍️ <strong>Make Picks</strong> - Pick games for each round of the NFL Playoffs.</p>
    <p>💯 <strong>Bonus Points!</strong> - The value of a correct pick increases each round.</p>
    <p>💵 <strong>$5,000 Cash Prize</strong> - Earn the most points for the chance to win the jackpot!</p>
    `
  } else if ([ENUM_PICKEM].includes(gameType)) {
    const hasTiebreaker = poolSettings?.mainTiebreaker !== ENUM_NONE
    const maxPicks = poolSettings?.maxPicksPerPeriodCount || 0
    picksPageRulesHtml = `
    <div class="flex-row justify-content--space-between">
      <header>
        <strong>How to play</strong>
      </header>
      <a href="${rulesUrl}" target="_blank" rel="noopener noreferrer">Official Rules</a>
    </div>
    <p>✍️ <strong>Make Picks</strong> - Pick ${maxPicks ? `${maxPicks} of ` : ""}the games below against the spread${
      hasTiebreaker && ` and submit your tiebreakers`
    } to enter the challenge.</p>
    `
  }
  const lobbyChallengePromotionLabel = "Join the Challenge"
  if (gameType === ENUM_PARLAY) {
    // no change, parlay is still available
    picksPageStartedGamesHtml = picksPageRulesHtml
  } else if (["WEEKLY", "ROUND"].includes(periodLength)) {
    picksPageStartedGamesHtml = `
    <div>
      <header>
        <strong>Games have already started!</strong>
      </header>
      <p>What can you still do?</p>
    </div>
    <p>✍️ <strong>Make Picks</strong> - Some games are still pickable!</p>
    <p>⬆️ <strong>Accumulate points</strong> - All correct picks add to your season total.</p>
    <p>🔔 <strong>Get Notified</strong> - Look out for a pick reminder to get into next ${periodLength.toLowerCase().replace("ly", "")}'s action!</p>
    `
  }
  // splash bg:
  const staticRoot = `${constants.DOMAIN}/static`
  const splashPageIsCustomVersion = (year && year < 2020 && "true") || null
  const staticGameAssetsPath = `${staticRoot}/game-assets/${gameInstanceUid.replace("cbs-", "").replace(/-(manager|challenge)/, "")}`
  const splashPageMidLeftDecoratorImageUrl = (isFootball && `${staticRoot}/sport-assets/football/splash-decorator-mid-left.svg`) || null
  const splashPageBottomRightDecoratorImageUrl = (isFootball && `${staticRoot}/sport-assets/football/splash-decorator-bottom-right.svg`) || null
  const splashPagePhonePromoImageUrl = (isFootball && `${staticGameAssetsPath}/promo.png`) || null
  const splashPageBgImageRule = `url(${staticRoot}/sport-assets/football/splash-bg-light.jpg), radial-gradient(85% 50%, ${palette.lightBgGradiantStopColor} 0%, ${palette.lightBgGradiant} 100%)`
  const splashPageBgColor = "transparent"
  const splashPageBgColorStart = palette.lightBgGradiant
  const splashPageBgColorStop = palette.lightBgGradiantStopColor
  const splashPageBgSize = "cover"
  const splashPageBgBlendMode = "overlay"
  const splashPageBgPosition = "top left"
  const splashPageBgIsFixed = splashPageIsCustomVersion ? null : "true"
  const sponsorLogoUrl = sponsorKey === "williamhill" && `${staticRoot}/sponsors/williamhill.svg`
  const sponsorLogoDoubleClickSuffix = undefined
  const mapping = {
    splashPageHtml: splashPageHtml(gameInstanceUid, seasonType),
    authGateText: _authGateText,
    createEntryPrompt,
    createEntryTitle,
    comingSoonText,
    title,
    yearSpan,
    seasonText,
    titleWithClient,
    titleWithSuffix,
    topTitleText,
    rulesUrl,
    picksPageRulesHtml,
    picksPageStartedGamesHtml,
    splashPageOptionsText: splashPageOptionsText[gameInstanceUid] || todoText,
    // sponsorImgSrc: isNcaabConfBracket ? `https://sportsflyqa.cbsistatic.com/fly-570/bundles/sportsmediacss/images/bracket-games/logo-hertz-black.png` : null,
    hasPrizes,
    ttag,
    tagSuffix,
    tag: tagSuffix,
    disclaimerHtml,
    comingSoonSubtext,
    metaDescription: metaDescriptions.join(". "),
    metaTitle: metaTitles.join(" - "),
    seasonType: _seasonType,
    sportType,
    isChallenge,
    // TODO qac: remove this hack and figure out better way to manage sportsline email values
    use2021ConfBracketSportslineEmailPromo: isNcaabConfBracket && _year === 2021,
    splashSettingsHighlight1,
    splashSettingsHighlight2,
    splashSettingsHighlight3,
    splashPageChallOnlyTitle,
    splashPageChallOnlySubTitle,
    splashPageChallOnlyHeading,
    splashPageChallOnlyGroup1Header,
    splashPageChallOnlyGroup1P,
    splashPageChallOnlyGroup2Header,
    splashPageChallOnlyGroup2P,
    splashPageChallOnlyGroup3Header,
    splashPageChallOnlyGroup3P,
    splashPageChallOnlySecondCta,
    splashPageChallOnlyUpsellCta,
    splashPageCtaText,
    splashPageBtnCta,
    splashPageTitleCta,
    rulesOrTosText,
    titleWithPool,
    splashPageOldStyleDescription,
    splashPageOldStyleTitle,
    upsellCardPicksClosed,
    upsellCardPicksClosedPromo,
    splashPageIsCustomVersion,
    splashPageMidLeftDecoratorImageUrl,
    splashPageBottomRightDecoratorImageUrl,
    splashPagePhonePromoImageUrl,
    splashPageBgImageRule,
    splashPageBgColor,
    splashPageBgColorStart,
    splashPageBgColorStop,
    splashPageBgSize,
    splashPageBgBlendMode,
    splashPageBgPosition,
    splashPageBgIsFixed,
    sponsorLogoUrl,
    sponsorLogoDoubleClickSuffix,
    sponsorKey,
    lobbyChallengePromotionLabel,
    inviteBackModalMessage,
  }
  if (isNcaabConfBracket) {
    mapping.comingSoonText = "It's Almost Tournament Time!"
  }
  return mapping
}
export const gameTextKeys = [
  "splashPageHtml",
  "comingSoonText",
  "title",
  "yearSpan",
  "seasonText",
  "titleWithClient",
  "titleWithSuffix",
  "splashPageOptionsText",
  "disclaimerHtml",
  "comingSoonSubtext",
  "metaDescription",
  "metaTitle",
  "authGateText",
  "createEntryPrompt",
  "createEntryTitle",
  "splashSettingsHighlight1",
  "splashSettingsHighlight2",
  "splashSettingsHighlight3",
  "splashPageCtaText",
  "splashPageBtnCta",
  "rulesOrTosText",
  "titleWithPool",
  "splashPageChallOnlyTitle",
  "splashPageChallOnlySubTitle",
  "splashPageChallOnlyHeading",
  "splashPageChallOnlyGroup1Header",
  "splashPageChallOnlyGroup1P",
  "splashPageChallOnlyGroup2Header",
  "splashPageChallOnlyGroup2P",
  "splashPageChallOnlyGroup3Header",
  "splashPageChallOnlyGroup3P",
  "splashPageChallOnlySecondCta",
  "splashPageChallOnlyUpsellCta",
  "splashPageTitleCta",
  "splashPageOldStyleDescription",
  "splashPageOldStyleTitle",
  "upsellCardPicksClosed",
  "upsellCardPicksClosedPromo",
  "picksPageRulesHtml",
  "picksPageStartedGamesHtml",
  "sponsorLogoUrl",
  "sponsorLogoDoubleClickSuffix",
  "sponsorKey",
  // splash page bg values:
  "splashPageIsCustomVersion",
  "splashPageMidLeftDecoratorImageUrl",
  "splashPageBottomRightDecoratorImageUrl",
  "splashPagePhonePromoImageUrl",
  "splashPageBgImageRule",
  "splashPageBgColor",
  "splashPageBgColorStart",
  "splashPageBgColorStop",
  "splashPageBgSize",
  "splashPageBgBlendMode",
  "splashPageBgPosition",
  "splashPageBgIsFixed",
  "lobbyChallengePromotionLabel",
]

export default buildMappingFor
