import React from "react"
import { Link as RRLink, LinkProps as RRLinkProps } from "react-router-dom"
import { isExternalHref, processHref, withoutDomain } from "../../common/url-utils"
import { canUseDom } from "../utils/misc-utils"
import { ThemeContext } from "styled-components/macro"
import { findMatchedRoute, loadRouteComponent } from "../utils/async-route-utils"
import routes from "../../routes"

export interface ISharedLinkProps {
  preload?: boolean
  external?: boolean
  nofollow?: boolean
  isCbsAppWebview?: boolean
  target?: string
  isActive?: boolean
  isMainItem?: boolean
}
export interface IProps extends RRLinkProps, ISharedLinkProps {
  highlighted?: boolean
}

const loadedComponents = new Map<string, boolean>()

const ExternalLink: React.FC<IProps> = ({
  preload,
  external,
  nofollow,
  highlighted,
  to,
  isActive,
  isMainItem,
  isCbsAppWebview,
  target = "_blank",
  ...rest
}) => {
  const stringTo = (typeof to === "string" && to) || ""
  const themeContext = React.useContext(ThemeContext)
  const isRealCbsAppWebview = isCbsAppWebview || themeContext.isCbsAppWebview
  // console.log(`to: ${stringTo} --> ${processHref(stringTo)}`)
  rest.href = rest.href || processHref(stringTo, isRealCbsAppWebview)
  if (isExternalHref(stringTo)) {
    rest.rel = `noopener${nofollow ? " nofollow" : ""}`
  }
  return <a target={target} {...rest} />
}

export class Link extends React.PureComponent<IProps> {
  public render() {
    const { preload, external, nofollow, highlighted, to, isActive, isMainItem, isCbsAppWebview, ...rest } = this.props
    const stringTo = (typeof to === "string" && to) || ""
    if (stringTo && (external || isExternalHref(stringTo))) {
      return <ExternalLink {...this.props} />
    } else {
      const toWithoutDomain = stringTo ? withoutDomain(stringTo) : to
      // console.log(`toWithoutDomain: ${stringTo} --> ${toWithoutDomain}`)
      if (preload !== false && canUseDom && typeof toWithoutDomain === "string") {
        const { route } = findMatchedRoute(routes, toWithoutDomain)
        if (route && route.prefetch && route.component && route.name && !loadedComponents.get(route.name)) {
          loadedComponents.set(route.name, true)
          loadRouteComponent(route)
          // console.debug(`prefetching chunk: ${route.name}`)
        }
      }
      return <RRLink {...rest} to={toWithoutDomain} />
    }
  }
}

export default Link
