import After from "./After"
import * as React from "react"
import { useLocation, useHistory } from "react-router"
import { TRouteData, TRoutes } from "../../routes.d"
import usePoolData from "../hooks/usePoolData"
import { findMatchedRoute } from "../utils/async-route-utils"
import PoolDataContext from "../Contexts/PoolDataContext"
import useClientSettings from "../hooks/useClientSettings"
import ClientSettingsContext from "../Contexts/ClientSettingsContext"

interface IAppProps {
  data: TRouteData
  routes: TRoutes
  isLoggedInUser?: boolean
}

const Wrapper = (props: IAppProps) => {
  const { routes, data } = props
  const location = useLocation()
  const history = useHistory()
  const route = findMatchedRoute(routes, location.pathname)
  const match = route.match
  const routerProps = { location, history, match }
  const poolData = usePoolData(routerProps)
  const settings = useClientSettings()
  return (
    <ClientSettingsContext.Provider value={settings}>
      <PoolDataContext.Provider value={poolData}>
        <After data={data} {...routerProps} routes={routes} poolData={poolData} />
      </PoolDataContext.Provider>
    </ClientSettingsContext.Provider>
  )
}

const App: React.FC<IAppProps> = (props) => {
  const [display, setDisplay] = React.useState(props.isLoggedInUser ? false : true)
  React.useEffect(() => {
    setDisplay(true)
  }, [])
  return display ? <Wrapper {...props} /> : null
}

export default App
