import React from "react"
import styled from "styled-components/macro"
import constants from "../../common/constants"
import Code from "./Code"

const Divider = styled.hr`
  background-color: rgba(0, 0, 0, 0.12);
  height: 0.1em;
  margin-bottom: 1em;
`
const Container = styled.div`
  background-color: white;
  border-radius: 0.4em;
  padding: 1em;
  margin: 1.5em 0.5em 0;
  width: auto;
`
const Title = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 1em;
`

const Section = (props) => {
  return (
    <Container>
      {props.title && <Title>{props.title}</Title>}
      {props.title && <Divider />}
      {props.children}
    </Container>
  )
}
const noop = () => ({} as any)
const ErrorView = ({ error, hide = constants.IS_PRODISH, refetch = noop, title = "Error in view:" }) => (
  <Section title={title}>
    {hide ? (
      <p>There was an error in retrieving your account. Our team has been notified. Please try again or contact support.</p>
    ) : (
      <Code>{JSON.stringify(error, null, 2)}</Code>
    )}
    {refetch !== noop && (
      <button type="button" onClick={() => refetch!()}>
        Retry
      </button>
    )}
  </Section>
)
export default ErrorView
