import { sportTypeCategories } from "../../../../common/constants"
import { ISubnavLink, LayoutProps } from "../typings"

const hasHttp = /https?:/
export const processHref = (hrefOrPath: string) => {
  let httpLink = hasHttp.test(hrefOrPath) ? hrefOrPath : `https://www.cbssports.com${hrefOrPath}`
  if (!httpLink.includes("?")) {
    httpLink = httpLink + "?ttag=CBSSports_Pickem_Games"
  }
  return httpLink
}
const subnav = []

const NavSections: ISubnavLink[] = [
  {
    to: "/fantasy",
    title: "Home",
    matcher: /fantasy$/,
    subnav,
  },
  {
    to: "/fantasy/baseball",
    matcher: /\/baseball/,
    title: "Baseball",
    subnav: [
      [
        {
          to: "/fantasy/baseball",
          title: "Home",
        },
        {
          to: "/fantasy/baseball/rankings",
          title: "Rankings",
        },
        {
          to: "/fantasy/baseball/players",
          title: "Player Search",
        },
        {
          to: "/fantasy/baseball/injuries",
          title: "Injury Report",
        },
        {
          to: "/fantasy/baseball/depth-chart",
          title: "Depth Chart",
        },
        {
          to: "/fantasy/baseball/stats/sortable/points/C/standard/stats/2018/ytd",
          title: "Stats",
        },
        {
          to: "/fantasy/baseball/probable-pitchers",
          title: "Probable Pitchers",
        },
      ],
      [
        {
          to: "/fantasy/baseball/two-start-pitchers",
          title: "Two-Start Pitchers",
        },
        {
          to: "/fantasy/baseball/trends",
          title: "Roster Trends",
        },
        {
          to: "/fantasy/baseball/players/news",
          title: "Player News",
        },
        {
          to: "https://www.sportsline.com/mlb/player-projections/player-stats/all-players/?ttag=SL18_cbs_nav_leftrail",
          title: "Projections",
        },
        {
          to: "https://www.sportsline.com/insiders/50026895/fantasy-baseball-top-200-trade-values-ranking-for-h2h-leagues/",
          title: "Trade Chart",
        },
        {
          to: "https://www.sportsline.com/insiders/50031208/fantasy-baseball-start-and-sit-recommendations-for-the-best-and-worst-matchups/",
          title: "Matchups",
        },
        {
          to: "https://www.sportsline.com/insiders/50000636/2018-mlb-fantasy-baseball-rankings-from-a-proven-projection-model",
          title: "Rankings",
        },
      ],
    ],
  },
  {
    to: "/fantasy/football",
    matcher: /\/football/,
    title: "Football",
    subnav: [
      [
        {
          to: "/fantasy/football",
          title: "Home",
        },
        {
          to: "/fantasy/football/rankings",
          title: "Rankings",
        },
        {
          to: "/fantasy/football/stats/sortable/points/QB/standard/projections/2018/ytd",
          title: "Projections",
        },
        {
          to: "/fantasy/football/trends",
          title: "Roster Trends",
        },
        {
          to: "/fantasy/football/players/news",
          title: "Player News",
        },
        {
          to: "/fantasy/football/players",
          title: "Player Search",
        },
        {
          to: "/fantasy/football/injuries",
          title: "Injury Report",
        },
      ],
      [
        {
          to: "/fantasy/football/depth-chart",
          title: "Depth Chart",
        },
        {
          to: "/fantasy/football/stats/sortable/points/C/standard/stats/2018/ytd",
          title: "Stats",
        },
        {
          to: "/fantasy/football/show/fantasy-football-today/",
          title: "Fantasy Football Today",
        },
        {
          to: "https://www.sportsline.com/nfl/season-long-fantasy/",
          title: "Fantasy Hub",
        },
        {
          to: "/fantasy/games",
          title: "Fantasy Games",
        },
        {
          to: "/fantasy/football/games/draftkit/?ftag=SPM-16-10ace4a",
          title: "Buy Your Draft Board",
        },
      ],
    ],
  },
  {
    to: "/fantasy/basketball",
    matcher: /\/basketball/,
    title: "Basketball",
    subnav: [
      [
        {
          to: "/fantasy/basketball",
          title: "Home",
        },
        {
          to: "/fantasy/basketball/rankings",
          title: "Rankings",
        },
        {
          to: "/fantasy/basketball/stats/sortable/points/C/standard/projections/2017/ytd",
          title: "Projections",
        },
        {
          to: "/fantasy/basketball/trends",
          title: "Roster Trends",
        },
        {
          to: "/fantasy/basketball/players",
          title: "Player Search",
        },
      ],
      [
        {
          to: "/fantasy/basketball/injuries",
          title: "Injury Report",
        },
        {
          to: "/fantasy/football/depth-chart",
          title: "Depth Chart",
        },
        {
          to: "/fantasy/basketball/players/news",
          title: "Player News",
        },
        {
          to: "/fantasy/basketball/stats/sortable/points/C/standard/ytd",
          title: "Stats",
        },
      ],
    ],
  },
  {
    to: "/fantasy/hockey",
    matcher: /\/hockey/,
    title: "Hockey",
    subnav: [
      [
        {
          to: "/fantasy/hockey",
          title: "Home",
        },
        {
          to: "/fantasy/hockey/rankings",
          title: "Rankings",
        },
        {
          to: "/fantasy/hockey/stats/sortable/points/C/standard/projections/2018/ytd",
          title: "Projections",
        },
        {
          to: "/fantasy/hockey/trends",
          title: "Roster Trends",
        },
        {
          to: "/fantasy/hockey/players",
          title: "Player Search",
        },
      ],
      [
        {
          to: "/fantasy/hockey/injuries",
          title: "Injury Report",
        },
        {
          to: "/fantasy/hockey/depth-chart",
          title: "Depth Chart",
        },
        {
          to: "/fantasy/hockey/players/news",
          title: "Player News",
        },
        {
          to: "/fantasy/hockey/stats/sortable/points/C/standard/ytd",
          title: "Stats",
        },
      ],
    ],
  },
]

const PoolNavSectionsParts = {
  nflNews: {
    to: "https://www.cbssports.com/nfl/",
    title: "News",
    subnav,
  } as ISubnavLink,
  nflPowerRankings: {
    to: "https://www.cbssports.com/nfl/powerrankings/",
    title: "Power Rankings",
    subnav,
  } as ISubnavLink,
  nflOdds: {
    to: "https://www.sportsline.com/nfl/odds/?ftag=CBS-16-10aai1a",
    title: "Odds",
    subnav,
  } as ISubnavLink,
  nflAdvice: {
    to: "https://www.sportsline.com/nfl/picks/",
    title: "Advice",
    subnav,
  } as ISubnavLink,
  nflAdvicetrue: {
    to: "https://www.sportsline.com/nfl/picks/",
    title: "Advice",
    subnav: [
      [
        {
          to: "https://www.cbssports.com/nfl/picks/experts/",
          title: "Expert Picks",
        },
        {
          to: "https://www.sportsline.com/nfl/picks/?ftag=CBS-16-10aai1c",
          title: "Computer Picks",
        },
      ],
    ],
  } as ISubnavLink,
  ncaabNews: {
    to: "https://www.cbssports.com/college-basketball?ftag=CBS-16-10aac8a",
    title: "News",
    subnav,
  } as ISubnavLink,
  ncaabOdds: {
    to: "https://www.sportsline.com/college-basketball/odds/?ttag=SL21_lk_cbssports_cbb_picksnav",
    title: "Odds",
    subnav,
  } as ISubnavLink,
  ncaabAdvice: {
    to: "https://www.cbssports.com/college-basketball/expert-picks?ftag=CBS-16-10aac8a",
    title: "Advice",
    subnav: [
      [
        {
          to: "https://www.cbssports.com/college-basketball/expert-picks?ftag=CBS-16-10aac8a",
          title: "Expert Picks",
        },
        {
          to: "https://www.sportsline.com/college-basketball/picks/?ttag=SL21_lk_cbssports_cbb_picksnav",
          title: "Computer Picks",
        },
      ],
    ],
  } as ISubnavLink,
  ncaabBracketology: {
    to: "https://www.cbssports.com/college-basketball/bracketology?ftag=CBS-16-10aac8a",
    title: "Bracketology",
    subnav,
  } as ISubnavLink,
  ncaabRankings: {
    to: "https://www.cbssports.com/college-basketball/rankings?ftag=CBS-16-10aac8a",
    title: "Rankings",
    subnav,
  } as ISubnavLink,
  ncaabCbkLive: {
    to: "https://www.cbssports.com/college-basketball/cbk-live/?ftag=CBS-16-10aac8b",
    title: "CBK Live",
    subnav,
  } as ISubnavLink,
  ncaafNews: {
    to: "https://www.cbssports.com/college-football/",
    title: "News",
    subnav,
  } as ISubnavLink,
  ncaafSchedule: {
    to: "https://www.cbssports.com/college-football/schedule/",
    title: "Schedule",
    subnav,
  } as ISubnavLink,
  ncaafRankings: {
    to: "https://www.cbssports.com/college-football/rankings/",
    title: "Rankings",
    subnav,
  } as ISubnavLink,
  ncaafOddstrue: {
    to: "https://www.sportsline.com/college-football/odds/?ftag=CBS-16-10aai1d",
    title: "Odds",
    subnav,
  } as ISubnavLink,
  ncaafAdvicetrue: {
    to: "https://www.cbssports.com/college-football/advice/",
    title: "Advice",
    subnav: [
      [
        {
          to: "https://www.cbssports.com/collegefootball/features/writers/expert/picks",
          title: "Expert Picks",
        },
        {
          to: "https://www.sportsline.com/college-football/picks/?ftag=CBS-16-10aai1e",
          title: "Computer Picks",
        },
      ],
    ],
  } as ISubnavLink,
}
const parts = [PoolNavSectionsParts, NavSections]
for (const section of parts) {
  const items = Object.values(section)
  for (const item of items) {
    const processedTo = item.to && processHref(item.to)
    if (item.to && processedTo !== item.to) {
      item.to = processedTo
    }
    if (item.subnav) {
      for (const subnavItems of item.subnav) {
        for (const subnavItem of subnavItems) {
          const processedToS = subnavItem.to && processHref(subnavItem.to)
          if (subnavItem.to && processedTo !== subnavItem.to) {
            subnavItem.to = processedToS
          }
        }
      }
    }
  }
}

const addNavFor = (nav: ISubnavLink[], sportType: string, section: string, hasNextPhaseReleased: boolean) => {
  const navItem = PoolNavSectionsParts[`${sportType}${section}${hasNextPhaseReleased.toString()}`] || PoolNavSectionsParts[`${sportType}${section}`]
  if (nav) {
    nav.push(navItem)
  }
}

export const getPoolNavSections = (props: LayoutProps) => {
  const {
    pathname,
    // poolRootPathname,
    // isCbsAppWebview,
  } = props
  const parts = pathname.split("/")
  // const substitution = poolRootPathname || ""
  // NOTE qac: the first item is ""
  const sportCategories = sportTypeCategories[parts[1]]
  const sportType = sportCategories && sportCategories.sportTypes[0].toLowerCase()
  const hasNextPhaseReleased = true
  let navSections: ISubnavLink[] = [] as ISubnavLink[]

  if (sportType) {
    addNavFor(navSections, sportType, "News", hasNextPhaseReleased)
    if (sportType === "ncaaf") {
      addNavFor(navSections, sportType, "Schedule", hasNextPhaseReleased)
      addNavFor(navSections, sportType, "Rankings", hasNextPhaseReleased)
      addNavFor(navSections, sportType, "Odds", hasNextPhaseReleased)
      addNavFor(navSections, sportType, "Advice", hasNextPhaseReleased)
    } else if (sportType === "ncaab") {
      addNavFor(navSections, sportType, "Odds", hasNextPhaseReleased)
      addNavFor(navSections, sportType, "Advice", hasNextPhaseReleased)
      addNavFor(navSections, sportType, "Bracketology", hasNextPhaseReleased)
      addNavFor(navSections, sportType, "Rankings", hasNextPhaseReleased)
    } else if (sportType === "nfl") {
      addNavFor(navSections, sportType, "Odds", hasNextPhaseReleased)
      addNavFor(navSections, sportType, "Advice", hasNextPhaseReleased)
      addNavFor(navSections, sportType, "PowerRankings", hasNextPhaseReleased)
    }
    navSections = navSections.filter((d) => !!d)
  } else {
    navSections = NavSections
  }
  return navSections
}
