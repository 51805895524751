import { pseudoEncrypt } from "./string-utils"
import { setParam } from "./url-utils"
export const invitationIdSearchParamKey = "invitation-id"
export const invitedBySearchParamKey = "invited-by"
export const invitedViaSearchParamKey = "via-medium"
export const invitedJoinKeySearchParamKey = "join-key"
export const encryptedPoolPasswordParamKey = "pool-join-key"

export const senderRoleSearchParamKey = "sender-role"
export type EmailSenderType = "manager" | "participant"
export const getPoolUrl = (poolOrSubPool: any) => poolOrSubPool.url
export const getPoolInvitationUrl = (
  poolOrSubPool: any,
  entryId: string,
  medium: string,
  ttag?: string,
  password?: string,
  invitationId?: string,
  senderRole?: EmailSenderType,
) => {
  const baseUrl = getPoolUrl(poolOrSubPool)
  const search = getPoolInvitationUrlSearch(entryId, medium, poolOrSubPool.joinKey, ttag, password, invitationId, senderRole)
  const hasSearch = `${baseUrl}`.indexOf("?") > 0
  return `${baseUrl}${hasSearch ? "&" : "?"}${search}`
}
export const getPoolInvitationUrlSearch = (
  entryId: string,
  medium: string,
  joinKey?: string,
  ttag?: string,
  password?: string,
  invitationId?: string,
  senderRole?: EmailSenderType,
) => {
  let search = setParam("", invitedBySearchParamKey, entryId)
  search = setParam(search, invitedViaSearchParamKey, medium)
  if (joinKey) {
    search = setParam(search, invitedJoinKeySearchParamKey, joinKey)
  }
  if (ttag) {
    const parts = ttag.split("ttag=")
    search = setParam(search, "ttag", parts[parts.length - 1])
  }
  if (password) {
    search = setParam(search, encryptedPoolPasswordParamKey, pseudoEncrypt(password))
  }
  if (invitationId) {
    search = setParam(search, invitationIdSearchParamKey, invitationId)
  }
  if (senderRole) {
    search = setParam(search, senderRoleSearchParamKey, pseudoEncrypt(senderRole))
  }
  return search
}
