import { getThemeValues, themeBtnHighlightBgColor } from "./theme-helpers"
import palette from "./palette"
import { IThemePassin } from "./theme-helpers"

export function inputBorderFor({ theme }: IThemePassin) {
  return getThemeValues(theme).isDarkSplashBg ? "rgba(0,0,0,0.0)" : palette.gray3
}
export function inputBgColorFor({ theme }: IThemePassin) {
  return getThemeValues(theme).isDarkSplashBg ? `${palette.blue1}85` : palette.white
}
export function inputFocusStyleFor({ theme }: IThemePassin) {
  const fullTheme = getThemeValues(theme)
  return fullTheme.isDarkSplashBg
    ? `background-color: ${palette.blue1}4f`
    : // NOTE qac: we shouldnt do ${theme.isNexusStyles ? palette.blue3 : fullTheme.btnBorderColor}` because it could be red!
      `border-color: ${palette.blue2}`
}
export function placeholderColorFor({ theme }: IThemePassin) {
  return getThemeValues(theme).isDarkSplashBg ? "rgba(255,255,255,0.85)" : palette.gray1
}

export function settingsEditBtnColorFor({ theme }: IThemePassin) {
  return theme.isNexusStyles || !getThemeValues(theme).isDarkSplashBg ? themeBtnHighlightBgColor : palette.white
}
