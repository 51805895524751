import React, { FC, useMemo } from "react"
import styled from "styled-components"
import { CentralSeasonFragment } from "../../../../__generated__/CentralSeasonFragment"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"

const MONTH_NAMES = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const MONTH_ABBREVIATIONS = {
  January: "Jan.",
  February: "Feb.",
  March: "Mar.",
  April: "Apr.",
  May: "May",
  June: "June",
  July: "July",
  August: "Aug.",
  September: "Sept.",
  October: "Oct.",
  November: "Nov.",
  December: "Dec.",
}

const px14 = pxToRem(14)

const WhenToWatchRoot = styled.div`
  padding: 0;
  margin: 0;
  background: ${theme.colors.overLight.white};
  width: 100%;
  border-radius: 0.5rem;
  border: solid 1px ${theme.colors.overLight.white90};

  .watch__title {
    padding: 1.25rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${theme.colors.overLight.white20};
    font-weight: ${theme.fonts.weights.bold};
    text-transform: uppercase;
  }

  .watch__row {
    display: flex;
    padding: 1rem 0.75rem;
    font-size: ${px14};
    line-height: 1rem;
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.white20};

    &:not(:first-of-type) {
      border-top: solid 1px ${theme.colors.overLight.white90};
    }
  }

  .watch__row--title {
    text-transform: uppercase;
    font-family: ${theme.fonts.family.condensed};
    font-weight: ${theme.fonts.weights.semireg};
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 1.25px;
    color: ${theme.colors.overLight.white50};
    padding-top: 0;
    padding-bottom: 0.5rem;
  }

  .watch__column {
    padding: 0 0.75rem;
    white-space: nowrap;
  }

  .watch__column--1 {
    flex: 3;
  }

  .watch__column--2 {
    flex: 4;
  }
`

const buildSingleMonthDate = (datesByMonth: Record<string, number[]>) => {
  return Object.keys(datesByMonth)
    .map((k) => `${k} ${datesByMonth[k].join("-")}`)
    .join("")
}

const buildMultiMonthDate = (datesByMonth: Record<string, number[]>) => {
  return Object.keys(datesByMonth)
    .map((k) => `${MONTH_ABBREVIATIONS[k]} ${datesByMonth[k].join("-")}`)
    .join("-")
}

interface IProps {
  season?: CentralSeasonFragment
}

const WhenToWatch: FC<IProps> = ({ season }) => {
  const whenToWatchData = useMemo(() => {
    const data = season?.whenToWatch?.map((item) => {
      const datesByMonth = item.dates.reduce((obj, current) => {
        try {
          let currentTimestamp = Date.parse(current)
          if (isNaN(currentTimestamp)) {
            currentTimestamp = Number(current)
          }
          if (!isNaN(currentTimestamp)) {
            const currentDate = new Date(currentTimestamp).toISOString().split("T", 1)[0]
            const currentMonth = MONTH_NAMES[Number(currentDate.split("-")[1])]
            obj[currentMonth] = [...(obj[currentMonth] ?? []), currentDate.split("-")[2]]
          }
          return obj
        } catch (err) {
          console.warn(`When to watch invalid timestamp: ${current}`)
          return obj
        }
      }, {})

      const isMultiMonth = Object.keys(datesByMonth).length > 1
      const dates = isMultiMonth ? buildMultiMonthDate(datesByMonth) : buildSingleMonthDate(datesByMonth)

      return { periodName: item.periodName, dates }
    })
    return data
  }, [season])

  const rows = whenToWatchData?.length
    ? whenToWatchData.map((item, i) => (
        <div className="watch__row" key={i}>
          <div className="watch__column watch__column--1">{item.dates}</div>
          <div className="watch__column watch__column--2">{item.periodName}</div>
        </div>
      ))
    : null

  return (
    <WhenToWatchRoot>
      <div className="watch__title" data-cy="when-to-watch-header">
        When To Watch
      </div>
      <div className="watch__table">
        <div className="watch__row watch__row--title">
          <div className="watch__column watch__column--1" data-cy="when-to-watch-date">
            Date
          </div>
          <div className="watch__column watch__column--2" data-cy="when-to-watch-event">
            Event
          </div>
        </div>
        {rows}
      </div>
    </WhenToWatchRoot>
  )
}

export default WhenToWatch
