import * as Sentry from "@sentry/browser"
import React from "react"
import { createGlobalStyle } from "styled-components/macro"
import { cbsFlySessionCookieName, cbsPidCookieName, fantasyJWTCookieName } from "../../common/cookies"
import { styles } from "../../server/middleware/error-page-shared"

const GlobalErrorStyles = createGlobalStyle`
${styles}
.flex-wrapper {
  min-height: 80vh;
}
`

class ErrorReport extends React.PureComponent<{ showRetry?: boolean; body?: any; sentryEventId?: string }> {
  public logoutClick = () => {
    document.cookie = `${cbsPidCookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
    document.cookie = `${fantasyJWTCookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GM; path=/;T`
    document.cookie = `${cbsFlySessionCookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
    try {
      localStorage.clear()
    } catch (err) {}
    window.location.reload()
  }

  public reportClick = () => {
    if (this.props.sentryEventId) {
      Sentry.showReportDialog({ eventId: this.props.sentryEventId })
    } else {
      Sentry.showReportDialog()
    }
  }

  public render() {
    return (
      <>
        <GlobalErrorStyles />
        <div className="flex-wrapper">
          <div className="content-container shadow">
            <div className="subheader primary-text-color">CBS Fantasy</div>
            <div className="hr" />
            <div className="context secondary-text-color">
              <p>{this.props.body || `Something went wrong! Our team has been alerted and is looking into the issue`}</p>
            </div>
            <div className="buttons-container">
              <button type="button" className="button" onClick={this.reportClick}>
                Report
              </button>
              {this.props.showRetry ? (
                <button type="button" className="button" id="logout-button" onClick={this.logoutClick}>
                  <span className="fa fa-refresh" />
                  {`Retry`}
                </button>
              ) : (
                <a href="https://www.cbssports.com/" className="button">
                  CBSSports.com
                </a>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ErrorReport
