import fetch from "node-fetch"
import { useState, useEffect } from "react"

const useClientSettings = () => {
  const [settings, seSettings] = useState<Record<string, string> | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchData = await fetch("/client-settings")
        const data = await fetchData.json()
        seSettings(data)
      } catch (error) {}
    }

    fetchData()
  }, [])

  return settings
}
export default useClientSettings
