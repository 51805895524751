import React from "react"
import styled from "styled-components/macro"
import { layout } from "../../../utils/style-utils"
import { palette, pxToRem, gridBreakPoints, zIndexes } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const { breakpoints } = gridBreakPoints

const PageMarqueeStyled = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-max-w);
  z-index: ${zIndexes.ui2};
  background: ${palette.white};
  @media (min-width: ${pxToRem(breakpoints.sm)}) {
    & {
      padding: 0 var(--grid-margin);
    }
  }
`
// NOTE qac: min-height is needed for IE when in flex: col
// NOTE qac: z index is needed if there is a secondary menu
const PageMarqueeContainer = styled.div`
  background-color: ${palette.white};
  z-index: ${zIndexes.ui3};
  box-shadow: 0 1px 0 0 ${palette.gray90};
  &.page-marquee__has-secondary-nav--true {
    z-index: ${zIndexes.ui3};
  }
  &.page-marquee__variant--compact {
    height: ${pxToRem(layout.pageMarqueeCompactMinH)};
    min-height: ${pxToRem(layout.pageMarqueeCompactMinH)};
  }
`

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  variant?: "standard" | "compact"
  children: React.ReactNode
}

function PageMarquee({ children, ref, variant = "standard", className = "", ...rest }: IProps) {
  const classNames = [`page-marquee__variant--${variant}`]
  if (className) {
    classNames.push(className)
  }
  return (
    <PageMarqueeContainer className={classNames.join(" ")} {...rest}>
      <PageMarqueeStyled>{children}</PageMarqueeStyled>
    </PageMarqueeContainer>
  )
}

export default PageMarquee
