import styled from "styled-components/macro"

const Code = styled.pre`
  background-color: #cccccc50;
  max-height: 30em;
  overflow: auto;
  padding: 1.5em;
  color: rgba(0, 0, 0, 0.95);
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 75%;
  line-height: 140%;
  white-space: pre;
  box-shadow: inset 0 0 1em 0.4em rgba(0, 0, 0, 0.05);
`

export default Code
