import React from "react"
import { MenuItem, StyledMenuLink as SharedMenuLink } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import { ListItemIcon } from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import Link, { ISharedLinkProps } from "../Link"
import NavItem from "../../shared/atoms/Navs/NavItem"
import styled from "styled-components/macro"
import { fontFamily, fontWeight, palette, buildClassNames as cx, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

export interface IDropDownMenuItem extends ISharedLinkProps {
  disabled?: boolean
  id: string
  icon?: React.ReactElement
  iconPosition?: "left" | "right"
  label: string
  subtitle?: string
  isMainItem?: boolean
  withIcon?: boolean
  to?: string
  useNavItem?: boolean
  className?: string

  dataCy?: string
}

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamily.base};
  font-size: 0.75rem;
  font-style: normal;
  text-align: left;
  font-weight: ${fontWeight.medium};
  line-height: 16px;
  overflow: hidden;

  &.fix-width {
    width: 10.125rem;
  }
  &.fix-width.with-margin {
    width: 7.5rem;
  }

  &.with-margin {
    margin-left: 1rem;
  }
  &.extra-margin {
    margin-left: 2.5rem;
  }
  & > .item-title,
  .item-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .item-title.main-item {
    line-height: 1;
    letter-spacing: -0.1px;
    font-size: ${pxToRem(14)};
  }
  & > .item-title.with-subtitle {
    line-height: 0.75rem;
    letter-spacing: 0px;
  }

  & > .item-subtitle {
    line-height: 0.75rem;
    font-weight: ${fontWeight.regular};
    letter-spacing: -0.1px;
    margin-top: 0.25rem;
  }
`

const MenuLink = styled(SharedMenuLink)`
  @media (hover: hover) {
    :hover {
      & .item-title {
        color: ${palette.lightBlue3};
      }
    }
  }
`

const DropDownItem = ({
  to,
  icon,
  iconPosition = "left",
  label,
  isMainItem,
  useNavItem,
  subtitle,
  withIcon,
  dataCy,
  ...nonLinkRest
}: IDropDownMenuItem) => {
  const { preload, external, isCbsAppWebview, isActive, ...rest } = nonLinkRest
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const linkProps: any = to ? { preload, external, isCbsAppWebview, isActive, as: Link, to } : {}

  if (isMainItem) {
    if (useNavItem) {
      return (
        <NavItem {...linkProps} {...rest} data-cy="kos-leo">
          {icon && iconPosition === "left" && <ListItemIcon alignment="left">{icon}</ListItemIcon>}
          {label}
          {icon && iconPosition === "right" && <ListItemIcon alignment="right">{icon}</ListItemIcon>}
        </NavItem>
      )
    }
    return (
      <MenuItem isMainItem active={isActive} {...linkProps} {...rest}>
        <InfoWrap>
          <span
            className={cx({
              "item-title": true,
              "main-item": isMainItem,
            })}
          >
            {label}
          </span>
        </InfoWrap>
        {icon && (
          <ListItemIcon size="sm" alignment={iconPosition}>
            {icon}
          </ListItemIcon>
        )}
      </MenuItem>
    )
  }
  return (
    <MenuItem {...rest}>
      <MenuLink {...linkProps} data-cy={dataCy}>
        {withIcon && icon}
        <InfoWrap
          className={cx({
            "with-margin": withIcon,
            "fix-width": !isMainItem,
            "extra-margin": withIcon && !icon,
          })}
        >
          <span
            className={cx({
              "item-title": true,
              "with-subtitle": !!subtitle,
            })}
          >
            {label}
          </span>
          {subtitle && <span className="item-subtitle">{subtitle}</span>}
        </InfoWrap>
      </MenuLink>
    </MenuItem>
  )
}

export default DropDownItem
