import React from "react"
import { TPoolRouteProps } from "../../../../../routes.d"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import PageLayout, { IPageLayoutProps } from "../../../../shared/molecules/PageLayout/PageLayout"
import { StyledPoolPageActions, PicksPageLayoutContainer } from "./PoolPage.styles"
import MultipleEntriesPerUserRightCol from "../MultipleEntriesPerUserRightCol"
import PoolPageHeader, { IPoolPageHeaderProps } from "../PoolPageHeader"
import { GridWrapper } from "../../../Layout/components/Layout"
import { getStringParam } from "@cbs-sports/sports-shared-client/build/cjs/utils/url-utils"

interface IPicksPageContainer {
  handheldUsesSticky: boolean
  leftCol: React.ReactElement | null
  rightCol: React.ReactElement | null
  topCenterElement: React.ReactElement | null
  bottomCenterElement: React.ReactElement | null
  className?: string
}

const PicksPageLayoutLeftColWrapper: React.FC<{ hasRightCol: boolean }> = ({ hasRightCol, children }) => {
  if (hasRightCol) {
    return <div className="col__group">{children}</div>
  } else {
    return <>{children}</>
  }
}

export const PicksPageLayout: React.FC<IPicksPageContainer> = (props) => {
  const { leftCol, rightCol, topCenterElement, bottomCenterElement, children, handheldUsesSticky, className } = props
  return (
    <PicksPageLayoutContainer className={className}>
      <PicksPageLayoutLeftColWrapper hasRightCol={!!rightCol}>
        {topCenterElement}
        {leftCol && <div className={`col__left handheld-uses-sticky--${handheldUsesSticky}`}>{leftCol}</div>}
        <div className="col__middle">{children}</div>
      </PicksPageLayoutLeftColWrapper>
      {rightCol && <div className="col__right dt-desktop--true">{rightCol}</div>}
      {bottomCenterElement}
    </PicksPageLayoutContainer>
  )
}

export const PoolPageActions = ({ children, poolPageActionsOpen = false }) => {
  return (
    <StyledPoolPageActions className={`is-toggled--${poolPageActionsOpen}`}>
      <div className="pool-page-actions__items">{children}</div>
    </StyledPoolPageActions>
  )
}

export interface IStandardPoolPageContainerProps extends TPoolRouteProps, IPageLayoutProps {
  select?: null | React.ReactNode
  rightAction?: null | React.ReactNode
  analyticsFeature?: string
  analyticsSubfeature?: string
  analyticsTitle?: string
  poolPageHeaderLayoutOptions?: IPoolPageHeaderProps["layoutOptions"]
}

export const StandardPoolPageContainer: React.FC<IStandardPoolPageContainerProps> = ({
  children,
  select = null,
  className = undefined,
  analyticsFeature = "",
  analyticsSubfeature = "",
  analyticsTitle = "",
  rightAction = null,
  poolData,
  match,
  location,
  history,
  routes,
  poolPageHeaderLayoutOptions,
  ...rest
}) => {
  const { deviceType, poolDetail, poolDetailIsLoading, detailedEntry, currentUser } = poolData
  const currentSearch = location.search
  const rootMessageId = getStringParam("rootMessageId", currentSearch)

  const showPoolHeader = (poolDetail || poolDetailIsLoading) && !rootMessageId
  // We only want to do this for BPM
  const showSponsorData = poolDetail?.gameInstanceUid && /cbs-ncaa(w|b)-tournament-manager/.test(poolDetail.gameInstanceUid)
  let poolRank = 0
  let entriesCount = 0
  let correctPicks = 0
  if (showSponsorData && poolDetail) {
    poolRank = detailedEntry?.poolRank || 0
    correctPicks = detailedEntry?.correctPicks || 0

    entriesCount = poolDetail.entriesCount || 0
    // NOTE LL: being defensive here to say max rank is the number of entries in the pool if the rank is actually higher than the entries count
    if (poolRank > entriesCount) {
      poolRank = entriesCount
    }
  }

  return (
    <>
      {showSponsorData && detailedEntry && poolDetail && (
        <div
          // switch to user name instead of entry name
          id={`ATT-AD-VALUES`}
          data-sponsorUserName={currentUser.firstName || detailedEntry.name || ""}
          data-sponsorUserRank={poolRank}
          data-sponsorTotalParticipants={entriesCount}
          data-sponsorCorrectPicks={correctPicks}
          style={{ display: "none" }}
        />
      )}
      {(analyticsFeature && (
        <AnalyticScreen feature={analyticsFeature} subfeature={analyticsSubfeature} title={analyticsTitle} deviceType={deviceType} />
      )) ||
        null}
      {(select || rightAction) && (
        <PoolPageActions>
          {select}
          {rightAction}
        </PoolPageActions>
      )}
      <PageLayout
        className={className}
        rightCol={
          deviceType !== "handheld" ? (
            <MultipleEntriesPerUserRightCol poolData={poolData} match={match} location={location} history={history} routes={routes} {...rest} />
          ) : null
        }
        {...rest}
      >
        {showPoolHeader && routes && (
          <PoolPageHeader
            poolData={poolData}
            location={location}
            history={history}
            match={match}
            routes={routes}
            layoutOptions={poolPageHeaderLayoutOptions}
          />
        )}
        <GridWrapper>{children}</GridWrapper>
      </PageLayout>
    </>
  )
}
