import styled from "styled-components"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const px12 = pxToRem(12)
const px14 = pxToRem(14)
const px20 = pxToRem(20)
const px24 = pxToRem(24)

export const TypographyWrapper = styled.div`
  color: ${theme.colors.overLight.white20};

  &.variant--regular {
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${px14};
    line-height: ${px20};
  }

  &.variant--heading {
    font-weight: ${theme.fonts.weights.bold};
    font-size: ${px20};
    line-height: ${px24};
  }

  &.variant--subheading {
    font-weight: ${theme.fonts.weights.bold};
    font-size: 1rem;
    line-weight: 1rem;
  }

  &.variant--table-head {
    font-weight: ${theme.fonts.weights.semireg};
    font-size: ${px12};
    line-height: ${px12};
  }

  &.variant--body1 {
    font-weight: ${theme.fonts.weights.regular};
    font-size: ${px12};
    line-height: ${px14};
  }

  &.capitalization--uppercase {
    text-transform: uppercase;
  }

  &.color--muted {
    color: ${theme.colors.overLight.white50};
  }

  &.spacing--compressed {
    letter-spacing: -0.1px;
  }

  &.spacing--expanded {
    letter-spacing: 1.25px;
  }

  &.weight--bold {
    font-weight: ${theme.fonts.weights.bold};
  }
`
