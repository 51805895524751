import React, { FC } from "react"
import GenericConfirmationModal, { IGenericConfirmationModalProps } from "./GenericConfirmationModal"

const CONFIRMATION_TITLE = "Leave pool?"
const CONFIRMATION_MESSAGE = "Brackets in pools you leave will be deleted."
const CTA_BUTTON_LABEL = "Leave Pool"

type LeavePoolConfirmationPropsType = Pick<IGenericConfirmationModalProps, "isOpen" | "onClose" | "onConfirm" | "loading">

const LeavePoolConfirmation: FC<LeavePoolConfirmationPropsType> = ({ isOpen, onClose, onConfirm, loading }) => {
  return (
    <GenericConfirmationModal
      isOpen={isOpen}
      title={CONFIRMATION_TITLE}
      message={CONFIRMATION_MESSAGE}
      ctaButtonLabel={CTA_BUTTON_LABEL}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={loading}
    />
  )
}

export default LeavePoolConfirmation
