import React, { FC } from "react"
import styled from "styled-components"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import {
  ColumnDataType,
  ColumnAlignType,
  PoolDataEntryType,
  DEFAULT_PLACEHOLDER,
  StandingsTableSortOptionsType,
  COLUMN_MAP,
} from "./StandingsTable.common"

interface IStandingsTableDataRootProps {
  size?: number
  align?: ColumnAlignType
}

const StandingsTableDataRoot = styled.div<IStandingsTableDataRootProps>`
  grid-column-end: span ${({ size }) => size ?? 1};
  height: 3rem;
  display: flex;
  justify-content: ${({ align }) => (align === "center" ? "center" : align === "right" ? "flex-end" : "flex-start")};
  align-items: center;
  font-size: ${pxToRem(14)};
  line-height: 1rem;
  letter-spacing: -0.1px;
  color: ${theme.colors.overLight.white20};
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &:first-of-type {
    padding-left: 1.5rem;
  }

  &:last-of-type {
    padding-right: 1.5rem;
  }

  &.cell--sorted {
    background-color: ${theme.colors.overLight.blue1};
  }

  &.champion-column {
    justify-content: center;
    margin-left: ${pxToRem(10)};
  }
`

interface IProps {
  columnData: ColumnDataType
  entry: PoolDataEntryType
  sortOptions: StandingsTableSortOptionsType
}

const StandingsTableDataCell: FC<IProps> = ({ columnData: { key, size, align, render, customClass = "" }, entry, sortOptions }) => {
  const sorted = sortOptions.enabled && sortOptions.sortBy === COLUMN_MAP[key]
  return (
    <StandingsTableDataRoot size={size} align={align} className={`${customClass} ${sorted ? "cell--sorted" : ""}`} data-cy="standings-data-cell">
      {render?.(entry) ?? DEFAULT_PLACEHOLDER}
    </StandingsTableDataRoot>
  )
}

export default StandingsTableDataCell
