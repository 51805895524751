import React from "react"
import { MenuItem } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import { ListItemIcon } from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import ArrowDropSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ArrowDrop"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import DropDownMenuItem, { IDropDownMenuItem } from "./DropDownItem"
import { DropDownMenuWrap } from "./DropDownMenu.styles"
import { useHistory } from "react-router"

const DropDownGap = 2
export interface IDropDownMenuProps extends IDropDownMenuItem {
  items?: IDropDownMenuItem[]
}

const iconStyle: React.CSSProperties = {
  height: "14px",
}
const DropDownMenu = ({ items, to, ...rest }: IDropDownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const history = useHistory()

  const goToUrl = React.useCallback(
    (url: string): void => {
      if (url) {
        history.push(url)
      }
    },
    [history],
  )

  const handleMouseEnter = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (items?.length) {
        setAnchorEl(event.currentTarget)
      }
    },
    [items],
  )
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (to) {
        goToUrl(to)
      } else if (items?.length) {
        setAnchorEl(event.currentTarget)
      }
    },
    [items, to, goToUrl],
  )

  const handleAnchorMouseLeave = React.useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const target = event.currentTarget
    const { top, left, right } = target.getBoundingClientRect()
    const { pageY, pageX } = event

    const mouseTop = pageY - DropDownGap
    const mouseLeft = pageX - DropDownGap
    const mouseRight = pageX + DropDownGap

    if (top >= mouseTop || left >= mouseLeft || right <= mouseRight) {
      setAnchorEl(null)
    }
  }, [])

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [])

  const isOpen = Boolean(anchorEl)

  if (items?.length) {
    const { label, icon, iconPosition, isMainItem, isActive, dataCy, ...dropDownProps } = rest
    return (
      <DropDownMenuWrap>
        <MenuItem
          isMainItem
          active={isActive}
          {...dropDownProps}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleAnchorMouseLeave}
          isHighlight={isOpen}
          className="main-menu-item"
          data-cy={dataCy}
        >
          {label}
          <ListItemIcon alignment="right" size="sm" style={iconStyle}>
            <ArrowDropSvg />
          </ListItemIcon>
        </MenuItem>
        <div
          className={cx({
            "option-list-container": true,
            "display-block": isOpen,
          })}
          onMouseLeave={handleClose}
        >
          <ul className={`option-container`}>
            {items.map((item, index) => {
              const { id } = item
              const key = `${id}-key-${index}`
              return <DropDownMenuItem {...item} key={key} isActive={false} />
            })}
          </ul>
        </div>
      </DropDownMenuWrap>
    )
  }
  return <DropDownMenuItem {...rest} to={to} />
}
export default DropDownMenu
