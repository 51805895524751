import React, { FC } from "react"
import styled from "styled-components"
import { buildClassNames as cn } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import RadioButton from "@cbs-sports/sports-shared-client/build/cjs/components/RadioButton"
import DualLineEntryRow, { IDualLineEntryRowProps } from "./DualLineEntryRow"

const RadioDualLineEntryRowRoot = styled.div`
  .radio-icon {
    top: 50%;
    transform: translateY(-50%);
  }
`

interface IRadioDualLineEntryRowProps extends IDualLineEntryRowProps {
  isChecked: boolean
  onChange: () => void
  name: string
  className?: string
}

const RadioDualLineEntryRow: FC<IRadioDualLineEntryRowProps> = ({ name, entry, isChecked, onChange, disabled, className = "", ...rest }) => {
  return (
    <RadioDualLineEntryRowRoot className={cn({ [className]: !!className })}>
      <RadioButton
        label={<DualLineEntryRow entry={entry} disabled={disabled} {...rest} />}
        name={name}
        isChecked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
    </RadioDualLineEntryRowRoot>
  )
}

export default RadioDualLineEntryRow
