import React, { ReactText, useCallback, useEffect, useRef } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { ListItemIcon } from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { MenuItem, Menu, MenuLink, MenuBase } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import { Avatar } from "@cbs-sports/sports-shared-client/build/cjs/components/Avatar"
import HorizontalSeparator from "@cbs-sports/sports-shared-client/build/cjs/components/HorizontalSeparator"
import { ANCHORS } from "@cbs-sports/sports-shared-client/build/cjs/components/Popover"
import SettingsSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Settings"
// import AlertSVG from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Alerts"
import { PoolPageHeaderWrap, PoolMarqueeWrapper } from "./PoolPageHeader.styles"
import DropDownMenu from "../../../../components/DropDownMenu/DropDownMenu"
import useNavMenu, { INavMenuProps } from "../../../../hooks/useNavMenu"
import { Nbsp } from "../PoolPage/PoolPage.styles"
import ManageAlertsModal from "../ManageAlertsModal"
import { withoutDomain } from "../../../../../common/url-utils"
import AddBracketComponent from "../MultipleEntriesArea/AddBracketComponent"
import LeavePoolConfirmation from "../EntryModals/LeavePoolConfirmation"
import { PoolDetailsQuery, PoolDetailsQueryVariables } from "../../../../../__generated__/PoolDetailsQuery"
import { POOL_DETAILS_QUERY } from "../../../queries"
import { LeavePools, LeavePoolsVariables } from "../../../../../__generated__/LeavePools"
import { LEAVE_POOLS_MUTATION } from "../../../queries"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import EditEntryModal from "../EntryModals/EditEntryModal"
import PoolRulesModal from "../EntryModals/PoolRulesModal"
import LeaveSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Leave"
import PersonalSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Personal"
import PoolSettingsSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/PoolSettings"
import { StarSVG } from "../../../../components/icons"
import usePageHeaderOptions, { PageHeaderOptions } from "../../../../hooks/usePageHeaderOption"
import { formatMembersCount, isCurrentUserLoggedIn } from "../../../../utils/data-utils"
import InviteAllPreviousPoolMembersModal from "../InviteAllPreviousPoolMembersModal"
import ManagerWelcomeBackModal from "../ManagerWelcomeBackModal"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import Analytics from "../../../../utils/analytics"
import UpsertEntryNameModal from "../EntryModals/UpsertEntryNameModal"
import SettingsSavedModal from "../SettingsSavedModal"
import { PoolSettingsTypesEnum } from "../../../../../common/enums"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import NewSettingPopover from "../NewSettingPopover"
import constants from "../../../../../common/constants"
type LayoutOptionsType = {
  showAddBracketControl?: boolean
  showNavigation?: boolean
}

const POOL_RULES_CONTENT_PROPS: { mode: "full" | "rules" } = { mode: "full" }

export interface IPoolPageHeaderProps extends INavMenuProps {
  layoutOptions?: LayoutOptionsType
}

const PoolPageHeader: React.FC<IPoolPageHeaderProps> = (props) => {
  const { poolData } = props
  const {
    hasManagerRole,
    productSeason,
    poolDetail,
    detailedEntry,
    upsertEntryMutation,
    currentUser,
    myEntriesForArea,
    centralBracketState,
    hasAutogeneratedName,
    isInComingSoon,
    deviceType,
    poolId,
    isChallengePool,
    detailedMember,
  } = poolData
  const [, hasOption] = usePageHeaderOptions()
  const gameHasDisplayMemberCountOption = hasOption(PageHeaderOptions.DisplayMemberCount)
  const isMobileView = deviceType === "handheld"
  const { data: poolDetails } = useQuery<PoolDetailsQuery, PoolDetailsQueryVariables>(POOL_DETAILS_QUERY, {
    variables: {
      poolId: poolId,
    },
    skip: hasManagerRole || isMobileView,
  })

  const showAddBracketControl = isMobileView ? false : props.layoutOptions?.showAddBracketControl ?? true
  const showNavigation = isMobileView ? false : props.layoutOptions?.showNavigation ?? true
  const [settingsElement, setSettingsElement] = React.useState<null | HTMLElement>(null)
  const [showManageAlertsModal, setShowManageAlertsModal] = React.useState<boolean>(false)
  const [showEditPersonalInfo, setShowEditPersonalInfo] = React.useState(false)

  //Pool Settings Modal. Only for non-manager users
  const [showPoolSettingsModal, setShowPoolSettingsModal] = React.useState<boolean>(false)
  const { showInviteAllOption } = props.history.location.state?.["actions"] || {}
  //TODO: Once we have a Settings Saved modal (not part of welcome back flow), we can use "displaySettingsSavedModal" from state.actions and initialize state with this, so we display the modal. showInviteAllOption will then be used to determine if we need to show the welcome back content.
  const [showPoolSettingsSavedModal, setShowPoolSettingsSavedModal] = React.useState<boolean>(showInviteAllOption || false)

  //Leave pool confirmation modal actions
  const [showLeavePoolConfirmationModal, setShowLeavePoolConfirmationModal] = React.useState<boolean>(false)
  const closeLeavePoolConfirmationModal = () => setShowLeavePoolConfirmationModal(false)
  const [leavePools, { loading: isLeavingPool }] = useMutation<LeavePools, LeavePoolsVariables>(LEAVE_POOLS_MUTATION, {
    refetchQueries: ["PoolSeasonStandingsQuery", "CentralCurrentUsersEntriesQuery"],
    awaitRefetchQueries: true,
    update: () => {
      const baseUrl = props.poolData.segmentForArea?.baseUrl
      const url = baseUrl ? withoutDomain(baseUrl) : "/"
      props.history.push(url)
    },
  })

  const leavePoolConfirm = () => {
    const variables: LeavePoolsVariables = {
      poolIds: [poolData.poolId],
      userId: poolData.allCurrentEntries?.[0].userId ?? "current",
    }
    leavePools({ variables })
  }

  const isLocked = centralBracketState?.bracketState?.isLocked || false
  const isPreTournament = centralBracketState?.bracketState?.isPreTournament || false

  const { currentEntriesForArea: myEntries, alreadyHasChallengeEntry } = myEntriesForArea()
  const {
    entriesCount,
    canAddEntry,
    url,
    poolSettings,
    id,
    hasSeenManagerWelcomeBackModal,
    inviteAllEmailSent,
    parentPoolId,
    hasSeenNewSettingPopover,
  } = poolDetail || emptyObject
  const maxEntriesPerUser = poolSettings.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolSettings.maxEntriesPerUser
  const { canLeavePool } = detailedEntry || emptyObject

  const [showPoolInviteEmailModal, setShowPoolInviteEmailModal] = React.useState<boolean>(false)
  const [showManagerWelcomeBackModal, setShowManagerWelcomeBackModal] = React.useState<boolean>(
    hasManagerRole && Boolean(parentPoolId) && !hasSeenManagerWelcomeBackModal && !inviteAllEmailSent,
  )
  const [showNewSettingPopover, setShowNewSettingPopover] = React.useState<boolean>(false)
  const settingsIconRef = useRef(null)
  const navMenuRef = useRef(null)
  const [emailSentToast, setEmailSendToast] = React.useState<ReactText>("")

  const menu = useNavMenu(props, false)
  const myEntriesForPool = myEntries.filter((x) => x.pool?.id === id)

  const poolUsesOpenInvites =
    (constants.ENABLE_OPEN_INVITES && poolDetail?.poolSettings.__typename === "PoolSettings" && poolDetail.poolSettings.openInvites) || false

  const handleShowNewSettingPopoverForManager = () => {
    if (!hasSeenNewSettingPopover && !isLocked && !poolUsesOpenInvites) {
      // only show if manager hasn't seen it, game isn't locked and pool invites aren't enabled
      setShowNewSettingPopover(true)
    }
  }
  const handleShowNewSettingPopoverForParticipant = useCallback(() => {
    if (!hasSeenNewSettingPopover && !isLocked) {
      setShowNewSettingPopover(true)
    }
  }, [hasSeenNewSettingPopover, isLocked])
  useEffect(() => {
    if (!hasManagerRole && poolUsesOpenInvites) {
      if (navMenuRef.current) {
        handleShowNewSettingPopoverForParticipant()
      }
    }
  }, [hasManagerRole, handleShowNewSettingPopoverForParticipant, poolUsesOpenInvites])

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsElement(event.currentTarget)
  }

  const handleSettingsClose = () => {
    setSettingsElement(null)
  }

  // const handleManageAlerts = React.useCallback(() => {
  //   handleSettingsClose()
  //   setShowManageAlertsModal(true)
  // }, [])

  const closeManageAlertsModal = React.useCallback(() => {
    setShowManageAlertsModal(false)
  }, [])

  const handleEditPersonalInfo = React.useCallback(() => {
    handleSettingsClose()
    setShowEditPersonalInfo(true)
  }, [])

  const closeEditPersonalInfo = React.useCallback(() => {
    setShowEditPersonalInfo(false)
  }, [])

  const handleLeaveGroup = React.useCallback(() => {
    handleSettingsClose()
    setShowLeavePoolConfirmationModal(true)
  }, [])

  const openPoolSettingsModal = React.useCallback(() => {
    handleSettingsClose()
    setShowPoolSettingsModal(true)
  }, [])

  const closePoolSettingsModal = React.useCallback(() => setShowPoolSettingsModal(false), [])

  const handleCloseWelcomeBackModal = () => {
    setShowManagerWelcomeBackModal(false)
    handleShowNewSettingPopoverForManager()
  }
  const handleCloseInviteAllModal = () => {
    setShowPoolInviteEmailModal(false)
    handleShowNewSettingPopoverForManager()
  }

  const onInvitePoolClick = () => {
    setShowManagerWelcomeBackModal(false)
    if (showPoolSettingsSavedModal) {
      setShowPoolSettingsSavedModal(false)
    }
    setShowPoolInviteEmailModal(true)
  }
  const handleCloseSettingsSavedModal = () => {
    setShowPoolSettingsSavedModal(false)
  }
  const goToPoolSettings = () => {
    Analytics.trackAction("brackets welcome", "picking options", "pool settings")
    const returnUrl = `returnUrl=${props.location.pathname}`
    const goTo = withoutDomain(`${url}/settings?${returnUrl}`)
    props.history.push(goTo)
  }

  const onInviteAllEmailSent = () => {
    if (!emailSentToast) {
      const toastId = toast.snackbar("Email invites sent", { position: "bottom-center", onClose: () => setEmailSendToast("") })
      setEmailSendToast(toastId)
    }
  }

  const handlePopoverClose = () => {
    setShowNewSettingPopover(false)
  }
  const leavePoolConfirmationModal = (
    <LeavePoolConfirmation
      isOpen={showLeavePoolConfirmationModal}
      onClose={closeLeavePoolConfirmationModal}
      onConfirm={leavePoolConfirm}
      loading={isLeavingPool}
    />
  )

  const isLoggedIn = isCurrentUserLoggedIn(currentUser)
  const entryWithAutogeneratedName = hasAutogeneratedName && myEntries.find((e) => e.hasAutogeneratedName)
  const seasonType = productSeason?.season ?? "regular"
  const seasonYear = productSeason?.year ?? new Date().getFullYear()
  const isWomenBracket = isNCAAWTournamentMatcher.test(productSeason?.gameInstance.uid ?? "")
  const canShowWelcomeBackModal = !showEditPersonalInfo && !showPoolSettingsModal && !showPoolSettingsSavedModal

  const newSettingPopoverBodyText = hasManagerRole
    ? "Managers can now allow participants in their pool to have access to invite options. A great way to grow your pool."
    : "Your pool manager has enabled the open invites option for your pool. Invite your friends today."
  return (
    <PoolPageHeaderWrap>
      {!alreadyHasChallengeEntry && !isLocked && !isPreTournament && (
        <AddBracketComponent
          routeProps={props}
          variant={isWomenBracket ? "wbpc" : "bpc"}
          purpose="createBracket"
          useAsComponent={true}
          goToBracket={!isPreTournament}
        />
      )}
      <div className={`pool-tile-wrapper margin-bottom--${!showNavigation && isMobileView}`}>
        <div className="pool-tile-info">
          <div className="pool-avatar-wrap">
            {/* NOTE LL: Want to show the same avatar component for challenge since we're setting the avatarUrl */}
            <Avatar
              src={poolDetail?.avatarUrl || undefined}
              name={poolDetail?.name}
              alt={poolDetail?.name}
              size={60}
              backgroundColor={"transparent"}
            />
            {hasManagerRole && (
              <div className="pool-manager-info">
                <ListItemIcon size="lg">
                  <StarSVG />
                </ListItemIcon>
              </div>
            )}
          </div>
          <div className="pool-tile-data">
            {gameHasDisplayMemberCountOption && <span className="members-info">{formatMembersCount(entriesCount, "Bracket")}</span>}
            <span className="pool-name">{poolDetail?.name || <Nbsp />}</span>
            {poolDetail?.slogan && <span className="pool-slogan">{poolDetail?.slogan}</span>}
          </div>
        </div>
        <div className="pool-tile-actions">
          <Button variant="clear" onClick={handleSettingsClick} withoutText ref={settingsIconRef}>
            <ListItemIcon size="lg">
              <SettingsSvg />
            </ListItemIcon>
          </Button>
          <Menu anchorEl={settingsElement} onClose={handleSettingsClose} open={Boolean(settingsElement)} origin="left" anchor={ANCHORS.BOTTOM_RIGHT}>
            {!isMobileView && (
              <MenuItem data-cy="edit-pool-settings">
                {hasManagerRole ? (
                  <MenuLink onClick={goToPoolSettings}>
                    <ListItemIcon alignment="left">
                      <PoolSettingsSvg />
                    </ListItemIcon>
                    Edit pool settings
                  </MenuLink>
                ) : (
                  <MenuLink onClick={openPoolSettingsModal}>
                    <ListItemIcon alignment="left">
                      <PoolSettingsSvg />
                    </ListItemIcon>
                    Pool settings
                  </MenuLink>
                )}
              </MenuItem>
            )}
            <MenuItem disabled={!myEntriesForPool.length} data-cy="personal-info">
              <MenuLink onClick={handleEditPersonalInfo}>
                <ListItemIcon alignment="left">
                  <PersonalSvg />
                </ListItemIcon>
                Personal info
              </MenuLink>
            </MenuItem>
            {/* Not ready for 2021 season */}
            {/* <MenuItem>
              <MenuLink onClick={handleManageAlerts}>
                <ListItemIcon alignment="left">
                  <AlertSVG />
                </ListItemIcon>
                Manage alerts
              </MenuLink>
            </MenuItem> */}
            {!isMobileView && !isChallengePool && (
              <>
                <HorizontalSeparator />
                <MenuItem disabled={!canLeavePool} data-cy="leave-pool">
                  <MenuLink onClick={handleLeaveGroup}>
                    <ListItemIcon alignment="left">
                      <LeaveSvg />
                    </ListItemIcon>
                    Leave pool
                  </MenuLink>
                </MenuItem>
              </>
            )}
          </Menu>
          {!isMobileView && constants.ENABLE_OPEN_INVITES && (
            <NewSettingPopover
              anchorEl={hasManagerRole ? settingsIconRef.current : navMenuRef.current}
              onClose={handlePopoverClose}
              open={showNewSettingPopover}
              origin="left"
              anchor={hasManagerRole ? ANCHORS.BOTTOM_RIGHT : ANCHORS.BOTTOM_CENTER}
              ctaUrl={withoutDomain(`${url}/settings`)}
              poolId={poolId}
              title="Open Invites"
              bodyText={newSettingPopoverBodyText}
              variant={hasManagerRole ? "manager" : "participant"}
            />
          )}
        </div>
        {showManageAlertsModal && <ManageAlertsModal isOpen={showManageAlertsModal} onClose={closeManageAlertsModal} />}
        {detailedEntry && (
          <EditEntryModal
            userName={currentUser.preferredEntryName ?? ""}
            userEmail={currentUser.email ?? ""}
            isOpen={showEditPersonalInfo}
            entry={detailedEntry}
            member={detailedMember}
            upsertEntryMutation={upsertEntryMutation}
            onClose={closeEditPersonalInfo}
            onUpdateSuccess={closeEditPersonalInfo}
          />
        )}
      </div>
      {!isLocked && canAddEntry && showAddBracketControl && (
        <AddBracketComponent
          routeProps={props}
          variant="bpm"
          purpose={"createBracket"}
          upsellText={`Add bracket to pool (${1 + (myEntriesForPool?.length || 0)} of ${maxEntriesPerUser}).`}
          pool={poolDetail}
        />
      )}
      {!hasManagerRole && (
        <PoolRulesModal
          isOpen={showPoolSettingsModal}
          pool={poolDetails?.pool}
          close={closePoolSettingsModal}
          poolRulesContentProps={POOL_RULES_CONTENT_PROPS}
          title="Pool Settings"
        />
      )}
      {showNavigation && (
        <PoolMarqueeWrapper>
          <MenuBase inline>
            {menu.map((item) => {
              return (
                <div ref={item.label === `Invite Friends` ? navMenuRef : null}>
                  <DropDownMenu {...item} key={item.id} />
                </div>
              )
            })}
          </MenuBase>
        </PoolMarqueeWrapper>
      )}
      {leavePoolConfirmationModal}
      {showManagerWelcomeBackModal && canShowWelcomeBackModal && !isMobileView && (
        <ManagerWelcomeBackModal
          isOpen={showManagerWelcomeBackModal}
          onClose={handleCloseWelcomeBackModal}
          year={productSeason?.year}
          poolId={id}
          poolUrl={url}
          onInvitePoolClick={onInvitePoolClick}
          isWomenBracket={isWomenBracket}
        />
      )}
      {showPoolSettingsSavedModal && (
        <SettingsSavedModal
          isOpen={showPoolSettingsSavedModal}
          onClose={handleCloseSettingsSavedModal}
          showInviteAllOption={showInviteAllOption}
          onInvitePoolClick={onInvitePoolClick}
        />
      )}

      <InviteAllPreviousPoolMembersModal
        {...props}
        isOpen={showPoolInviteEmailModal}
        onClose={handleCloseInviteAllModal}
        onInviteSent={onInviteAllEmailSent}
      />
      {isLoggedIn && hasAutogeneratedName && entryWithAutogeneratedName && !isLocked && (
        <UpsertEntryNameModal
          title="Name Your Bracket"
          isOpen={hasAutogeneratedName}
          close={() => {
            /* do nothing*/
          }}
          gameInstanceUid={entryWithAutogeneratedName.gameInstanceUid}
          isCommingSoon={isInComingSoon}
          isCreate={false}
          existingEntryDetails={{ id: entryWithAutogeneratedName.id, name: entryWithAutogeneratedName.name }}
          mode={/manager/.test(entryWithAutogeneratedName.gameInstanceUid) ? "bpm" : "bpc"}
          productAbbrev={/manager/.test(entryWithAutogeneratedName.gameInstanceUid) ? "bpm" : "bpc"}
          seasonType={seasonType}
          year={seasonYear}
        />
      )}
    </PoolPageHeaderWrap>
  )
}

export default PoolPageHeader
