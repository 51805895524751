import settings from "../settings"
import { AnalyticsChannelEnum, PoolRoleEnum, SportTypesEnum } from "./enums"

interface ISportTypeCategories {
  sportTypes: SportTypesEnum[]
  analyticsChannel: AnalyticsChannelEnum
}
interface ISportTypeCategoriesObj {
  [key: string]: ISportTypeCategories
}

// TODO qac: lets move away from this and into something more data driven
export const sportTypeCategories = {
  football: {
    sportTypes: [SportTypesEnum.NFL],
    analyticsChannel: "nfl",
  },
  "college-basketball": {
    sportTypes: [SportTypesEnum.NCAAB, SportTypesEnum.NCAAW],
    analyticsChannel: "collegebasketball",
  },
  "college-football": {
    sportTypes: [SportTypesEnum.NCAAF],
    analyticsChannel: "collegefootball",
  },
} as ISportTypeCategoriesObj

export const layoutIds = {
  default: "default",
  "football-parlay": "football-parlay",
  "football-pickem": "football-pickem",
  "football-pickem-post": "football-pickem-post",
  "college-basketball-ncaa-tournament-bracket": "college-basketball-ncaa-tournament-bracket",
  "college-basketball-conference-tournament-bracket": "college-basketball-conference-tournament-bracket",
  "college-football-pickem": "college-football-pickem",
  "college-football-pickem-post": "college-football-pickem-post",
}

export const SPORT_TYPE_LOGOS = {
  NCAAB: "https://sports.cbsimg.net/fly/images/league-logos/55.svg",
  NCAAW: "https://sports.cbsimg.net/fly/images/league-logos/55.svg",
}

export const logoReplacementPath: Record<string, string> = {
  "/static/game-assets/ncaaf-pickem/logo.svg": "https://sports.cbsimg.net/images/collegefootball/picks/logos/ncaaf-pickem.svg",
  "/static/game-assets/ncaaf-bowl-pickem/logo.svg": "https://sports.cbsimg.net/images/collegefootball/picks/logos/ncaaf-pickem-bowl.svg",
  "/static/game-assets/nfl-pickem/logo.svg": "https://sports.cbsimg.net/images/football/picks/logos/nfl-pickem.svg",
  "/static/game-assets/nfl-pickem-post/logo.svg": "https://sports.cbsimg.net/images/football/picks/logos/nfl-pickem-post.svg",
  "/static/game-assets/cbs-ncaab-tournament/logo.svg": "https://sports.cbsimg.net/images/collegebasketball/picks/logos/ncaab-tournament.svg",
  "/static/game-assets/cbs-ncaaw-tournament/logo.svg": "https://sports.cbsimg.net/images/collegebasketball/picks/logos/ncaaw-tournament.svg",
  "/static/game-assets/cbs-ncaab-confbracket/logo.svg": "https://sports.cbsimg.net/images/collegebasketball/picks/logos/ncaab-confbracket.svg",
  "/static/game-assets/nfl-parlay/logo.svg": "https://sports.cbsimg.net/images/football/picks/logos/nfl-parlay.svg",
}

// Spoke w/ SL team and they said it's ok to always hit prod from any env
const sportslineIframes = {
  NFL: `https://www.sportsline.com/iframes/football-iframe/`,
  NCAAF: `https://www.sportsline.com/iframes/college-football-iframe/`,
  NCAAB: `https://www.sportsline.com/iframes/college-basketball-iframe/`,
}

const UNSUPORTED_APP_GAME_INSTANCE_UIDS = ["nissan-ncaab-tournament-challenge"]

export const hostFor = (appEnv: string) =>
  `picks-${settings.TEST_ENV_PREFIX ? settings.TEST_ENV_PREFIX : appEnv}.cbssports.com${
    ["local", "test"].includes(appEnv) ? `:${settings.PORT}` : ""
  }`.replace("-prod", "")

export const sportslineAnalysisComingSoonText = "Sportsline analysis coming soon..."
export const NCAAB_BRACKET_LOCKS_AT_TIMESTAMP = 1742487300000 // Thu Mar 20 2025 12:15:00 GMT-0400 (Eastern Daylight Time)
export const NCAAW_BRACKET_LOCKS_AT_TIMESTAMP = 1742571000000 // Fri Mar 21 2025 11:30:00 GMT-0400 (Eastern Daylight Time)
export const NCAAB_BRACKET_SELECTION_SUNDAY_TIMESTAMP = 1710712800000 // Sun Mar 17 2024 18:00:00 GMT-0400 (Eastern Daylight Time)
export const NCAAW_BRACKET_SELECTION_MONDAY_TIMESTAMP = 1710720000000 // Sun Mar 17 2024 20:00:00 GMT-0400 (Eastern Daylight Time)

export const NCAAW_MANAGER_IMPRESSION_URL_SRC =
  "https://ad.doubleclick.net/ddm/trackimp/N424004.149036TURNERSPORTSANDENT/B31363617.386070327;dc_trk_aid=577063356;dc_trk_cid=208959213;u=__AP1_np_ia_[InsertCachebusterHere]PA__;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?"
export const NCAAB_MANAGER_IMPRESSION_URL_SRC =
  "https://ad.doubleclick.net/ddm/trackimp/N424004.149036TURNERSPORTSANDENT/B31363617.385916695;dc_trk_aid=577064253;dc_trk_cid=208312969;u=__AP1_np_ia_[InsertCachebusterHere]PA__;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1?"

export const NCAAW_CHALLENGE_IMPRESSION_URL_SRC =
  "https://ad.doubleclick.net/ddm/trackimp/N3340.149036.TURNERSPORTSANDENTE/B31274983.386630846;dc_trk_aid=579731332;dc_trk_cid=209549059;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1?"

export const NCAAB_CHALLENGE_IMPRESSION_URL_SRC =
  "https://ad.doubleclick.net/ddm/trackimp/N3340.149036.TURNERSPORTSANDENTE/B31274983.386922423;dc_trk_aid=579733801;dc_trk_cid=209549059;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1?"

export const matchupAnalysisKeys = {
  teamComparison: {
    vsTop25: "vsTop25",
    netRank: "netRank",
    apRank: "apRank",
    strengthOfSchedule: "strengthOfSchedule",
    ptsPerGame: "ptsPerGame",
    oppPtsPerGame: "oppPtsPerGame",
    yardsPerGame: "yardsPerGame",
    oppYardsPerGame: "oppYardsPerGame",
  },
  teamStats: {
    fieldGoalPct: "fieldGoalPct",
    threePointFGPct: "threePointFGPct",
    freeThrowPct: "freeThrowPct",
  },
  situationalStats: {
    homeRecord: "homeRecord",
    awayRecord: "awayRecord",
    lastGamesRecord: "lastGamesRecord",
    conferenceRecord: "conferenceRecord",
  },
}

export enum PicksWorkerJobTypes {
  OnPoolJoin = "OnPoolJoin",
  PoolUpdate = "PoolUpdate",
  EntryUpdate = "EntryUpdate",
  RegisterUserWithMembers = "RegisterUserWithMembers",
  SendPoolIsBackEmails = "SendPoolIsBackEmails",
  SendPoolMessageEmail = "SendPoolMessageEmail",
  SendPoolInvitation = "SendPoolInvitation",
  PicksUpdated = "PicksUpdated",
}

export const registeredTrademark = `\u00AE`

export const managerModeHeaderName = `X-isManagerModeEnabled`

export const managerModeEnabledCookieName = "managerModeEnabled"

const HOSTNAME = hostFor(settings.APP_ENV)
const PROTOCOL = settings.PROTOCOL
const constants = {
  DEFAULT_CHALLENGE_POOL_ID: "challenge",
  ENTRY_NAME_MAX_LENGTH: 200,
  ENTRY_NAME_MIN_LENGTH: 4,
  POOL_NAME_MAX_LENGTH: 200,
  POOL_SLOGAN_MAX_LENGTH: 60,
  POOL_NAME_MIN_LENGTH: 4,
  POOL_PASSWORD_MIN_LENGTH: 4,
  POOL_DEFAULT_EVENTS_PER_PERIOD: 15,
  // this value is to separate an NFL week amount of games from a NCAA week (which is over 100)
  POOL_ALLOWABLE_EVENTS_PER_PERIOD: 50,
  POOL_MIN_EVENTS_PER_PERIOD: 1,
  POOL_MAX_EVENTS_PER_PERIOD: 100,
  // dont allow pools to let more than x entries (more for programming)
  POOL_MAX_ENTRIES_PER_USER: 10,
  POOL_CONSTITUTION_MAX_LENGTH: 2500,
  DEFAULT_RANK: 999999999,
  DEFAULT_PAGINATION: 25,
  MESSAGE_BOARD_DEFAULT_PAGINATION: 50,
  sportTypeCategories,
  layoutIds,
  SSL: PROTOCOL === "https:",
  HOSTNAME,
  PROTOCOL,
  DOMAIN: `${PROTOCOL}//${HOSTNAME}`,
  WS_API_ENDPOINT: `${PROTOCOL === "https:" ? "wss:" : "ws:"}//${HOSTNAME}/graphql`,
  CLIENT_API_ENDPOINT: settings.IS_LOCAL ? "http://picks-local.cbssports.com:9000/graphql" : "/graphql", // putting this in temporarily - it is only being used in createApolloClient.ts
  FB_APP_ID: "297742330311988",
  AUTOGENERATED_ENTRY_PREFIX: `entry-`,
  POOL_ROLES: PoolRoleEnum,
  // for frontend exposure:
  PUBLIC_SENTRY_DSN: settings.PUBLIC_SENTRY_DSN,
  GIT_REVISION: settings.GIT_REVISION,
  DOCKER_BUILD: settings.DOCKER_BUILD,
  // NOTE qac: use this flag with understanding that the setting should be consistent with prod ALWAYS
  IS_PRODISH: settings.IS_PRODISH,
  IS_PROD: settings.IS_PROD,
  IS_LOCAL: settings.IS_LOCAL,
  APP_ENV: settings.APP_ENV,
  // flags:
  PUBLIC_SMTP_ENABLED: settings.PUBLIC_SMTP_ENABLED,
  PUBLIC_MESSAGE_BOARD_ENABLED: settings.PUBLIC_MESSAGE_BOARD_ENABLED,
  PUBLIC_USES_OLD_SAPI_PARAMS: true,
  PUBLIC_SAPI_AUTH_DOMAIN: settings.SAPI_AUTH_DOMAIN,
  sportslineIframes,
  GAMBLING_AGE_YEAR: 21,
  BRAND_BAR_PLACEMENT: "brand_bar",
  BET_SLIP_PLACEMENT_SUFFIX: "betslip",
  NCAAB_CHALLENGE_MAX_ENTRIES_PER_USER: settings.NCAAB_CHALLENGE_MAX_ENTRIES_PER_USER,
  NCAAB_S16_CHALLENGE_MAX_ENTRIES_PER_USER: settings.NCAAB_S16_CHALLENGE_MAX_ENTRIES_PER_USER,
  UNSUPORTED_APP_GAME_INSTANCE_UIDS,
  BRACKET_LOCAL_STORAGE_KEY: "picks_localStorage_key",
  BRACKET_LOBBY_ROUTE: "/:sportType/:subsection/:gameType",
  MESSAGE_BOARD_ROUTE: "/:sportType/:subsection/:gameType/pools/:poolId/message-board",
  TESTING_CHALLENGE_POOL_ROUTE: "/:sportType/:subsection/:gameType/testing-challenge",
  ALLOW_NON_MANAGERS_TO_REACTIVATE_POOLS: false,
  SWEET_SIXTEEN_PRODUCT_ABBREV: "ssbpc",
  NCAAW_CHALLENGE_PRODUCT_ABBREV: "wbpc",
  NCAAW_MANAGER_PRODUCT_ABBREV: "wbpm",
  NCAAB_CHALLENGE_PRODUCT_ABBREV: "bpc",
  NCAAB_MANAGER_PRODUCT_ABBREV: "bpm",
  ENABLE_FANTASY_CONTACT_LIST: settings.ENABLE_FANTASY_CONTACT_LIST,
  SHOW_GAMBLING_PARTNER_BRANDING: settings.SHOW_GAMBLING_PARTNER_BRANDING,
  HELP_CENTER_URL: "https://cbsi.secure.force.com/CBSi/cbssports",
  OPC_HELP_CENTER_URL: "https://help.fpc.cbssports.com/",
  FFBOPC_HELP_CENTER_URL: "https://help.cfpc.cbssports.com/",
  FFBOPM_HELP_CENTER_URL: "https://help.cfpm.cbssports.com/",
  NEW_HELP_CENTER_URL: "https://cbsic.force.com",
  DEFAULT_MESSAGE_BOOARD_NOTIFICATION: false,
  EXACT_TARGET_JOB_CACHE_KEY: "EXACT_TARGET_JOB_CACHE_KEY",
  NCAAW_CHALLENGE_GAME_INSTANCE_UID: "cbs-ncaaw-tournament-challenge",
  NCAAB_CHALLENGE_GAME_INSTANCE_UID: "cbs-ncaab-tournament-challenge",
  NCAAB_MANAGER_GAME_INSTANCE_UID: "cbs-ncaab-tournament-manager",
  NCAAW_MANAGER_GAME_INSTANCE_UID: "cbs-ncaaw-tournament-manager",
  NCAAB_SWEET_SIXTEEN_GAME_INSTANCE_UID: "cbs-ncaab-tournament-sweet-sixteen-challenge",
  // NCAAW_SWEET_SIXTEEN_GAME_INSTANCE_UID: "cbs-ncaaw-tournament-sweet-sixteen-challenge",
  NISSAN_NCAAB_CHALLENGE_GAME_INSTANCE_UID: "nissan-ncaab-tournament-challenge",
  NISSAN_NCAAW_CHALLENGE_GAME_INSTANCE_UID: "nissan-ncaaw-tournament-challenge",
  SWEET_16_ENABLED: settings.SWEET_16_ENABLED,
  MAX_CHARACTERS_PER_MESSAGE_BOARD_MESSAGE: settings.MAX_CHARACTERS_PER_MESSAGE_BOARD_MESSAGE,
  CHALLENGE_POOL_MAX_RETRY: 4,
  MANAGER_POOL_MAX_RETRY: 2,
  EMAIL_REGEX: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
  ENABLE_BUDDY_GROUPS: false,
  CAN_USE_NICKNAMES: true,
  MARCH_MADNESS_SPORT_YEAR: settings.MARCH_MADNESS_SPORT_YEAR,
  APP_CONFIG_BUCKET: settings.APP_CONFIG_BUCKET,
  IS_OPC_SPONSORED: false,
  IS_POPC_SPONSORED: true,
  ADMIN_BASE_URL: settings.ADMIN_BASE_URL,
  ENABLE_OPEN_INVITES: settings.ENABLE_OPEN_INVITES,
  ENABLE_STELLATE: settings.ENABLE_STELLATE,
  CREATE_POOL_ROAD_BLOCK_PATH: "create-pool/pool/{POOL_ID}/roadblock",
}

export default constants
