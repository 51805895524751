import * as React from "react"
import styled from "styled-components/macro"
import { getStringParam } from "../../common/url-utils"
import { TSlotId } from "../App/Layout/constants/adDefinitions"
import { TAd } from "../App/Layout/typings"
import { palette } from "../utils/style-utils"

const StyledDCLK = styled.div`
  height: 12px;
  width: 100%;
`

interface IDCLKProps {
  adType?: TAd
  adSlot?: TSlotId
}

const DCLK = (props: IDCLKProps) => {
  if (typeof window !== "undefined") {
    const showDCLK = getStringParam("DCLK", window.location.search)
    if (showDCLK) {
      return (
        <StyledDCLK>
          <div style={{ backgroundColor: palette.white, display: "flex" }}>{`Ad Slot = ${props.adSlot || "unknown"} | Ad Type = ${
            props.adType || "unknown"
          }`}</div>
        </StyledDCLK>
      )
    }
  }
  return null
}

export default DCLK
