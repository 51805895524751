import { useMutation, useQuery } from "@apollo/client"
import { History, Location } from "history"
import * as React from "react"
import { useHistory, useLocation } from "react-router"
import { GetLocalState } from "../../__generated__/GetLocalState"
import { UpdateLocalState, UpdateLocalStateVariables } from "../../__generated__/UpdateLocalState"
import { EnflatedLocalState, GET_LOCAL_STATE_QUERY, getStateFromQuery, StorageTypes, UPDATE_LOCAL_STATE_MUTATION } from "../utils/local-state"
import { getParam } from "../utils/url-utils"

export interface IInjectedILocalKeyProps {
  value: any
  update(value: any, storageType?: StorageTypes): PromiseLike<any>
}
interface ILocalKeyProps {
  keyName: string
  debug?: string
  children: (props: IInjectedILocalKeyProps) => React.ReactNode | any
}

const getValueFor = (keyName: string, location: Location, localStateQueryProps: EnflatedLocalState) => {
  const currentPathname = location.pathname
  // order by trump
  let value = null as any | null
  // query
  const queryValue = getParam(keyName, location.search)
  if (queryValue) {
    value = queryValue
  }
  // hash
  const hashValue = getParam(keyName, (location.hash || "").replace("#", ""))
  if (hashValue) {
    value = hashValue
  }
  // history
  if (location.state && location.state[keyName]) {
    value = location.state[keyName]
  }
  // persisted
  if (localStateQueryProps.persisted && localStateQueryProps.persisted[keyName]) {
    value = localStateQueryProps.persisted[keyName]
  }
  // memory (only if the same pathname!)
  if (localStateQueryProps.memory && localStateQueryProps.memory[keyName] && localStateQueryProps.pathname === currentPathname) {
    value = localStateQueryProps.memory[keyName]
  }
  return value
}

export function useLocalKey(keyName: string, debug = "") {
  const locationRef = React.useRef<Location<any>>()
  const historyRef = React.useRef<History<any>>()
  const _location = useLocation()
  const _history = useHistory()
  const queryProps = useQuery<GetLocalState>(GET_LOCAL_STATE_QUERY)
  const [mutation] = useMutation<UpdateLocalState, UpdateLocalStateVariables>(UPDATE_LOCAL_STATE_MUTATION)
  const value = getValueFor(keyName, _location, getStateFromQuery(queryProps))
  React.useEffect(() => {
    locationRef.current = _location
    historyRef.current = _history
  })
  const update = React.useCallback(
    (newValue: any, storageType: StorageTypes = "memory", pushInsteadOfReplace = false) => {
      const history = historyRef.current!
      const location = locationRef.current!
      const currentPathname = location.pathname
      const { pathname, search, hash, state } = location
      const { push, replace } = history
      if (/memory|persisted/.test(storageType)) {
        const mergeItems = { [storageType]: { [keyName]: newValue } }
        const variables = { mergeItems, currentPathname }
        return mutation({ variables }).then((result) => {
          // clear route based trumps
          const searchParams = new URLSearchParams(search)
          const hashParams = new URLSearchParams((hash || "").replace("#", ""))
          const newHistory = Object.assign({}, state || {})
          const hasChange = searchParams.has(keyName) || hashParams.has(keyName) || newHistory.hasOwnProperty(keyName)
          if (hasChange) {
            if (searchParams.has(keyName)) {
              searchParams.delete(keyName)
            }
            if (hashParams.has(keyName)) {
              hashParams.delete(keyName)
            }
            if (newHistory.hasOwnProperty(keyName)) {
              delete newHistory[keyName]
            }
            let newPath = pathname
            if (searchParams.toString()) {
              newPath += `?${searchParams.toString()}`
            }
            if (hashParams.toString()) {
              newPath += `#${hashParams.toString()}`
            }
            replace(newPath, newHistory)
          }
          return result
        })
      } else {
        const searchParams = new URLSearchParams(search)
        const hashParams = new URLSearchParams((hash || "").replace("#", ""))
        const newHistory = Object.assign({}, state || {})
        // cleanup trumps
        if (searchParams.has(keyName)) {
          searchParams.delete(keyName)
        }
        if (hashParams.has(keyName)) {
          hashParams.delete(keyName)
        }
        if (newHistory.hasOwnProperty(keyName)) {
          delete newHistory[keyName]
        }
        // set key
        const isRemoveValue = newValue === undefined || newValue === null
        if (storageType === "queryParam") {
          if (isRemoveValue) {
            searchParams.delete(keyName)
          } else {
            searchParams.set(keyName, newValue)
          }
        }
        if (storageType === "hashParam") {
          if (isRemoveValue) {
            hashParams.delete(keyName)
          } else {
            hashParams.set(keyName, newValue)
          }
        }
        if (storageType === "historyState") {
          if (isRemoveValue) {
            if (newHistory.hasOwnProperty(keyName)) {
              delete newHistory[keyName]
            }
          } else {
            newHistory[keyName] = newValue
          }
        }
        // construct new pathname
        let newPath = pathname
        if (searchParams.toString()) {
          newPath += `?${searchParams.toString()}`
        }
        if (hashParams.toString()) {
          newPath += `#${hashParams.toString()}`
        }
        if (pushInsteadOfReplace) {
          push(newPath, newHistory)
        } else {
          replace(newPath, newHistory)
        }
        return Promise.resolve()
      }
    },
    [historyRef, locationRef],
  )
  return { update, value }
}

function LocalKey(props: ILocalKeyProps) {
  const { keyName, debug, children } = props
  // const shouldUpdate = this.props.value !== nextProps.value || this.props.currentPathname !== nextProps.currentPathname || this.props.children !== nextProps.children;
  const { update, value } = useLocalKey(keyName, debug)
  return children({ update, value })
}
export default LocalKey
