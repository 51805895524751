import palette from "./palette"

export interface ITheme {
  primary: string
  secondary: string
  btnBgColor: string
  btnHighlightBgColor: string
  btnBorderColor: string
  btnHighlightBorderColor: string
  disabled: string
  altActive: string
  altHover: string
  // NOTE qac: primary == btn/action color
  isCircularLogo: boolean
  uiNav: string
  // new 2020:
  primaryShadow: string
  primaryHighlight: string
  darkBgColor: string
  lightBgColor: string
  isDarkBtnBg: boolean
  isDarkSplashBg: boolean
}

const themes = Object.freeze({
  default: {
    primary: palette.blue2,
    secondary: palette.gray4,
    btnBgColor: palette.blue2,
    btnHighlightBgColor: palette.blueActive,
    btnBorderColor: palette.blue2,
    btnHighlightBorderColor: palette.blueActive,
    disabled: `${palette.blue2}61`,
    altActive: `${palette.blue2}21`,
    altHover: `${palette.blue2}12`,
    isDarkSecondary: false,
    isDarkBtnBg: false,
    isDarkSplashBg: false,
    isCircularLogo: true,
    uiNav: palette.uiNavBlue1,
    primaryShadow: palette.blueActive,
    primaryHighlight: palette.blue3,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
  "college-basketball-ncaa-tournament-bracket": {
    primary: palette.ncaabTournamentPrimary,
    secondary: palette.ncaabTournamentSecondary,
    btnBgColor: palette.ncaabTournamentSecondary,
    btnHighlightBgColor: `${palette.ncaabTournamentSecondary}61`,
    btnBorderColor: palette.ncaabTournamentSecondary,
    btnHighlightBorderColor: `${palette.ncaabTournamentSecondary}61`,
    disabled: `${palette.ncaabConfPrimary}61`,
    altActive: `${palette.ncaabConfPrimary}61`,
    altHover: `${palette.ncaabConfPrimary}4f`,
    isDarkBtnBg: true,
    isDarkSplashBg: false,
    isCircularLogo: true,
    uiNav: palette.uiNavBlue1,
    primaryShadow: palette.blueActive,
    primaryHighlight: palette.blue3,
    darkBgColor: palette.defaultDarkBgColor,
    lightBgColor: palette.defaultLightBgColor,
  } as ITheme,
})

export default themes
