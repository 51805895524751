import { NCAAB_S16_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils"
import { TDeviceType } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import React from "react"
import constants from "../../common/constants"
import Analytics from "../utils/analytics"
import { cancelIdleCallback, requestIdleCallback } from "../utils/misc-utils"

interface Props {
  feature?: string
  subfeature?: string
  title: string
  isModal?: boolean
  productLine?: string
  deviceType?: TDeviceType
  gameInstanceUid?: string
  extra?: Record<string, any>
}

const initialValue = "unset"

const PRODUCT_LINE_LOOKUP = {
  [constants.NCAAB_MANAGER_GAME_INSTANCE_UID]: constants.NCAAB_MANAGER_PRODUCT_ABBREV,
  [constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID]: constants.NCAAB_CHALLENGE_PRODUCT_ABBREV,
  [NCAAB_S16_GAME_INSTANCE_UID]: constants.SWEET_SIXTEEN_PRODUCT_ABBREV,
  [constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID]: constants.NCAAW_CHALLENGE_PRODUCT_ABBREV,
  [constants.NCAAW_MANAGER_GAME_INSTANCE_UID]: constants.NCAAW_MANAGER_PRODUCT_ABBREV,
}

const isBpmOrBpcNcaabBracket = /cbs-ncaab-tournament/
const isWbpmOrWbpcNcaawBracket = /cbs-ncaaw-tournament/

class AnalyticScreen extends React.PureComponent<Props> {
  public timeoutId: NodeJS.Timer | number | null = null
  public previousTitle: string = initialValue
  public previousFeature: string = initialValue
  public previousSubfeature: string = initialValue
  public previousProductLine: string = initialValue
  public trackScreen = () => {
    this.timeoutId = null
    const currentState = Analytics.currentState
    this.previousTitle = currentState.title
    this.previousFeature = currentState.feature
    this.previousSubfeature = currentState.subfeature
    this.previousProductLine = currentState.productLine
    const { feature, subfeature, title, isModal, productLine: prodLineProp, deviceType, gameInstanceUid, extra } = this.props
    // console.log("this.props:");
    // console.dir(this.props);
    const derrivedTitle = isModal && title && !title.includes(" - ") ? `${this.previousTitle} - ${title}` : title

    let productLine = prodLineProp
    if (gameInstanceUid) {
      if (subfeature === "lobby") {
        if (isBpmOrBpcNcaabBracket.test(gameInstanceUid)) {
          productLine = constants.NCAAB_MANAGER_PRODUCT_ABBREV
        } else if (isWbpmOrWbpcNcaawBracket.test(gameInstanceUid)) {
          productLine = constants.NCAAW_MANAGER_PRODUCT_ABBREV
        } else if (PRODUCT_LINE_LOOKUP[gameInstanceUid]) {
          // should never get here
          productLine = PRODUCT_LINE_LOOKUP[gameInstanceUid]
        }
      } else if (PRODUCT_LINE_LOOKUP[gameInstanceUid]) {
        productLine = PRODUCT_LINE_LOOKUP[gameInstanceUid]
      }
    }
    Analytics.trackScreen(
      feature || this.previousFeature,
      subfeature || this.previousSubfeature,
      derrivedTitle,
      productLine || this.previousProductLine,
      Boolean(isModal),
      deviceType === "handheld",
      extra,
    )
  }

  public componentDidMount() {
    // delay so that other onmounts can cancel the pageview
    this.timeoutId = requestIdleCallback(this.trackScreen)
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.gameInstanceUid !== this.props.gameInstanceUid) {
      this.timeoutId = requestIdleCallback(this.trackScreen)
    }
  }
  public componentWillUnmount() {
    if (!this.timeoutId && this.props.isModal) {
      Analytics.trackScreen(
        this.previousFeature,
        this.previousSubfeature,
        this.previousTitle,
        this.previousProductLine,
        this.props.isModal,
        this.props.deviceType === "handheld",
        this.props.extra,
      )
    }
    cancelIdleCallback(this.timeoutId)
  }

  public render() {
    return null
  }
}

export default AnalyticScreen
