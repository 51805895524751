import React, { FC } from "react"
import { useMutation } from "@apollo/client"
import { Formik, Field, FormikErrors } from "formik"
import styled from "styled-components"
import Select, { ISelectOption } from "@cbs-sports/sports-shared-client/build/cjs/components/Select"
import Checkbox from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { pxToRem, newPalette, fontWeights } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import GenericEntryModal, { IGenericEntryModalProps, ModalActions } from "./GenericEntryModal"
import { useDeviceType } from "../../../../Base/DeviceType"
import useNewsletterSubscriptionOptions, {
  NewsletterSubscriptionOption,
  Newsletter247SiteType,
} from "../../../../hooks/useNewsletterSubscriptionOptions"
import { UPDATE_NEWSLETTER_SUBSCRIPTION_STATUS_MUTATION, UPDATE_USER_NEWSLETTER_SUBSCRIPTIONS } from "../../../queries"
import {
  UpdateNewsletterSubscriptionStatus,
  UpdateNewsletterSubscriptionStatusVariables,
} from "../../../../../__generated__/UpdateNewsletterSubscriptionStatus"
import {
  UpdateUserNewsletterSubscriptions,
  UpdateUserNewsletterSubscriptionsVariables,
} from "../../../../../__generated__/UpdateUserNewsletterSubscriptions"

const DEFAULT_TITLE = "WELCOME TO MARCH MADNESS"

const ActionsWrapper = styled(ModalActions)`
  margin-top: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const NoPaddingHeader = styled.div`
  padding: 0;
  position: relative;
  min-height: 80px;
  background: ${newPalette.brandPrimaryBlue};
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${newPalette.white};
  letter-spacing: -0.25px;
  font-weight: ${fontWeights.xxbold};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/static/sport-assets/newsletter-background.svg);
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    opacity: 0.5;
  }

  .header__logo {
    width: ${pxToRem(104)};
    height: ${pxToRem(12)};
    padding-bottom: 0.5rem;
  }
`

const NewsletterModalRoot = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? "100%" : `calc(${pxToRem(608)} - ${pxToRem(80)})`)};
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: ${newPalette.gray20};

  .root__options-item {
    padding-top: 0.75rem;

    &:not(:last-of-type) {
      padding-bottom: 0.75rem;
    }
  }

  .root__text--section-title {
    font-size: ${pxToRem(18)};
    font-weight: ${fontWeights.bold};
    line-height: 1.5rem;
    letter-spacing: -0.1px;
  }

  .root__text--body1 {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(14)};
    letter-spacing: -0.1px;
    padding-top: ${pxToRem(20)};
    padding-bottom: ${pxToRem(12)};
  }

  .root__text--updates-title {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(14)};
    font-weight: ${fontWeights.regular};
    letter-spacing: -0.1px;
    padding-bottom: 0.75rem;
  }

  .label-content {
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    margin-left: 2rem;
    font-size: ${pxToRem(14)};
    font-weight: ${fontWeights.regular};
  }

  ul.option-container {
    max-height: ${pxToRem(90)};
  }
`

interface INewsletterModalFieldProps extends IGenericEntryModalProps {
  title?: string
  close?: () => void
  newsletterSubscriptionOptions?: Array<NewsletterSubscriptionOption>
  newsletterSubscriptionSites?: Array<Newsletter247SiteType>
  onConfirm?: () => void
  onCancel?: () => void
  isValid: boolean
  isSubmitting: boolean
}

const NewsletterModalField: FC<INewsletterModalFieldProps> = ({
  close,
  onConfirm,
  onCancel,
  title = DEFAULT_TITLE,
  newsletterSubscriptionOptions = [],
  newsletterSubscriptionSites = [],
  isValid,
  isSubmitting,
  ...rest
}) => {
  const deviceType = useDeviceType()
  const isMobile = deviceType === "handheld"
  const headerWrapperProps = { as: NoPaddingHeader }
  const newsletterSiteOptions: ISelectOption[] = newsletterSubscriptionSites.map((x) => ({
    label: x.name,
    value: x.siteKey,
  }))

  const footer = (
    <ActionsWrapper>
      <Button variant="secondary" onClick={onCancel} disabled={isSubmitting} data-cy="news-modal-no-thanks-btn">
        No Thanks
      </Button>
      <Button variant="primary" onClick={onConfirm} disabled={!isValid} withLoading loading={isSubmitting} data-cy="news-modal-submit-btn">
        Submit
      </Button>
    </ActionsWrapper>
  )
  const actionProps = { dense: true }
  const header = (
    <>
      <div className="header__logo">
        <img src="/static/sport-assets/cbs-sports-logo-white.svg" alt="CBS Sports Logo" />
      </div>
      <div>{title}</div>
    </>
  )

  return (
    <GenericEntryModal
      afterClose={close}
      modalType={isMobile ? "fullscreen" : "modal"}
      title={header}
      modalHeaderWrapperProps={headerWrapperProps}
      actionProps={actionProps}
      footer={footer}
      {...rest}
    >
      <NewsletterModalRoot isMobile={isMobile}>
        <div>
          <div className="root__text--section-title root__options-item" data-cy="news-modal-header-label">
            Get the latest news, picks and analysis straight to your inbox!
          </div>
          {newsletterSubscriptionOptions.map(({ mCode, text }) => (
            <div className="root__options-item" key={mCode}>
              <Field name="subscriptions">
                {({ field }) => {
                  const isChecked = field.value.includes(mCode)
                  return (
                    <Checkbox
                      isChecked={isChecked}
                      name={field.name}
                      id={mCode}
                      defaultValue={mCode}
                      label={text}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                  )
                }}
              </Field>
            </div>
          ))}
          <div className="root__text--body1" data-cy="news-modal-text-label">
            Email me exclusive team and recruiting updates for my favorite school:
          </div>
          <Field name="siteKey">
            {({ field, form }) => {
              return (
                <Select
                  id={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  placeholder="Choose a School to Sign Up"
                  options={newsletterSiteOptions}
                  onSelect={(opt) => {
                    form.setFieldValue(field.name, opt.value)
                  }}
                  data-cy="news-modal-select"
                />
              )
            }}
          </Field>
        </div>
      </NewsletterModalRoot>
    </GenericEntryModal>
  )
}

type INewsletterModalProps = Omit<INewsletterModalFieldProps, "isValid" | "isSubmitting">

const NewsletterModal: FC<INewsletterModalProps> = ({ isOpen, ...props }) => {
  const newsletterSubscriptionOptions = useNewsletterSubscriptionOptions()
  const [updateNewsletterSubscriptionStatus] = useMutation<UpdateNewsletterSubscriptionStatus, UpdateNewsletterSubscriptionStatusVariables>(
    UPDATE_NEWSLETTER_SUBSCRIPTION_STATUS_MUTATION,
  )
  const [updateUserNewsletterSubscriptions] = useMutation<UpdateUserNewsletterSubscriptions, UpdateUserNewsletterSubscriptionsVariables>(
    UPDATE_USER_NEWSLETTER_SUBSCRIPTIONS,
  )

  const showSubscriptionModal =
    isOpen &&
    newsletterSubscriptionOptions.showModal &&
    (newsletterSubscriptionOptions.options.length > 0 || newsletterSubscriptionOptions.sites.length > 0)

  const initialValues = {
    subscriptions: newsletterSubscriptionOptions.options.map((x) => x.mCode),
    siteKey: undefined,
  }

  const validate = (values) => {
    const errors: FormikErrors<{ subscriptions: Array<string> }> = {}
    if (values.subscriptions.length <= 0 && !values.siteKey) {
      errors.subscriptions = "No subscriptions selected"
    }
    return errors
  }

  const markAsSeen = () =>
    updateNewsletterSubscriptionStatus({
      variables: {
        sportType: newsletterSubscriptionOptions.sportType,
        sportYear: newsletterSubscriptionOptions.sportYear,
      },
    })

  const handleSubmit = (values) => {
    const subscriptions = values?.subscriptions ?? []
    const mapping = subscriptions.reduce((obj, v) => {
      obj[v] = true
      return obj
    }, {} as Record<string, boolean>)
    const siteKey = values.siteKey
    return updateUserNewsletterSubscriptions({ variables: { mapping, siteKey } }).then(markAsSeen)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} enableReinitialize>
      {({ submitForm, isValid, isSubmitting }) => (
        <NewsletterModalField
          isOpen={showSubscriptionModal}
          title={newsletterSubscriptionOptions.title ?? ""}
          newsletterSubscriptionOptions={newsletterSubscriptionOptions.options}
          newsletterSubscriptionSites={newsletterSubscriptionOptions.sites}
          onConfirm={submitForm}
          onCancel={markAsSeen}
          isValid={isValid}
          isSubmitting={isSubmitting}
          {...props}
        />
      )}
    </Formik>
  )
}

export default NewsletterModal
