import styled from "styled-components/macro"

export const FlexRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const FlexCol = styled(FlexRow)`
  flex: 0;
  min-width: 2.5em;
`
export const FlexColGrow = styled(FlexRow)`
  flex: 1;
  justify-content: flex-start;
`
export const FlexCols = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const FillScreen = styled(FlexCols)`
  min-height: 80vh;
  overflow: auto;
  @media (max-height: 800px) {
    min-height: 55vh;
  }
  .use-body-scroll--true & {
    min-height: 100vh !important;
  }
  .has-skybox & {
    min-height: 70vh;
  }
`
