import React from "react"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import { Checkbox } from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import Toggle from "@cbs-sports/sports-shared-client/build/cjs/components/Toggle"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import styled from "styled-components/macro"
import { fontWeight, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import palette from "../../../../common/palette"
import { DayOfTheWeek } from "../../../../common/common-utils-types"

const ModalContent = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${pxToRem(24)} ${pxToRem(40)};
  color: ${palette.gray20};
  & .modal-title {
    font-weight: ${fontWeight.bold};
    font-size: ${pxToRem(20)};
    width: 100%;
    text-align: left;
    padding-bottom: ${pxToRem(25)};
    line-height: ${pxToRem(24)};
  }
  & .toggle {
    & label {
      font-size: ${pxToRem(14)};
      font-weight: ${fontWeight.medium};
      display: flex;
      justify-content: space-between;
      margin-right: 0;
      & > div {
        margin-right: 0;
      }
    }
  }

  & .days-selection {
    margin-top: ${pxToRem(24)};
    width: ${pxToRem(280)};
    & .days-selection-title {
      font-size: ${pxToRem(14)};
      text-align: left;
      margin-bottom: ${pxToRem(12)};
    }
    & .days-selection-checkboxes {
      padding: ${pxToRem(10)} ${pxToRem(15)};
      border: 1px solid ${palette.gray3};
      border-radius: ${pxToRem(4)};
      display: grid;
      grid-template-columns: 35% 35%;
      grid-template-rows: repeat(4, 1fr);
      grid-auto-flow: column;
      grid-column-gap: 25%;
      & label {
        font-size: ${pxToRem(14)};
        text-decoration: capitalize;
        margin: ${pxToRem(8)} 0;
        & > span.label-content {
          font-weight: ${fontWeight.medium};
          margin-left: ${pxToRem(28)};
        }
      }
    }
  }
  & .text-disabled--true {
    color: ${palette.gray75};
  }
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: ${pxToRem(24)};
    & > :first-child {
      margin-right: ${pxToRem(16)};
    }
  }
`

type CheckedDays = {
  [key in DayOfTheWeek]: boolean
}
interface IModalProps {
  isOpen: boolean
  onClose: () => void
}

const defaultChecks = {
  Sunday: true,
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: true,
  Friday: true,
  Saturday: true,
}

const ManageAlertsModal: React.FC<IModalProps> = ({ isOpen, onClose }) => {
  const defaultUpdateEmailsSetting = false
  const [isSendUpdateEmailsOn, setIsSendUpdateEmailsOn] = React.useState<boolean>(defaultUpdateEmailsSetting)
  const [checkedDays, setCheckedDays] = React.useState<CheckedDays>(defaultChecks)
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = React.useState<boolean>(false)
  const handleCheckedDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedDays({ ...checkedDays, [event.target.name]: !checkedDays[event.target.name] })
    if (!isSaveButtonEnabled) {
      setIsSaveButtonEnabled(true)
    }
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log("Submit changes to Manage Alerts")
  }
  const handleToggleSendEmails = (newValue: boolean) => {
    setIsSendUpdateEmailsOn(newValue)
    if (!isSaveButtonEnabled) {
      setIsSaveButtonEnabled(true)
    }
  }
  const resetModalState = () => {
    setIsSendUpdateEmailsOn(defaultUpdateEmailsSetting)
    setCheckedDays(defaultChecks)
    setIsSaveButtonEnabled(false)
  }
  const keepModalOpen = () => undefined
  return (
    <Modal isOpen={isOpen} onBackgroundClick={keepModalOpen} onEscapeKeydown={keepModalOpen} afterClose={resetModalState} padded={false}>
      <ModalContent>
        <span className="modal-title">MANAGE ALERTS</span>
        <form onSubmit={handleSubmit}>
          <div className="toggle">
            <Toggle onChange={handleToggleSendEmails} checked={isSendUpdateEmailsOn} label={"Overall Standings Updates"} />
          </div>
          <div className="days-selection">
            <p className={`days-selection-title text-disabled--${!isSendUpdateEmailsOn}`}>I want to receive emails on these days:</p>
            <div className="days-selection-checkboxes">
              {Object.keys(defaultChecks).map((day, index) => {
                return (
                  <Checkbox
                    name={day}
                    label={day}
                    key={`${day}-${index}`}
                    isChecked={checkedDays[day]}
                    disabled={!isSendUpdateEmailsOn}
                    size={"sm"}
                    onChange={handleCheckedDaysChange}
                  />
                )
              })}
            </div>
          </div>
          <div className="action-buttons">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button withLoading type="submit" disabled={!isSaveButtonEnabled}>
              Save
            </Button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ManageAlertsModal)
