import { fontWeight, palette, gridBreakPoints, fontFamily, fontSizes } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
const { queries } = gridBreakPoints

export const InviteRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${palette.white};
  box-shadow: none;
  overflow: hidden;
  box-sizing: border-box;
  padding: 1rem;

  &.mode--view {
    height: 7.875rem;
    border-bottom: 1px solid ${palette.gray90};
    & > div.left-side {
      & > div.logo {
        height: 2.5rem;
        width: 2.5rem;
      }
      & > div.pool-information {
        & > span.members-count {
          display: none;
        }
      }
    }
  }

  & > div.left-side {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    padding-right: 1rem;

    & > div.logo {
      display: flex;
      position: relative;
      vertical-align: middle;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: auto;
        max-width: 100%;
        opacity: 1;
        transition: opacity 0.5s ease-in 0.5s;
        vertical-align: top;
      }
    }
    & > div.pool-information {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex: 1;
      box-sizing: border-box;
      min-width: 0;

      & > span.members-count {
        text-transform: uppercase;
        font-family: ${fontFamily.condensed};
        font-weight: ${fontWeight.medium};
        font-size: 10px;
        line-height: 0.75rem;
        letter-spacing: 1.5px;
        color: ${palette.gray40};
      }
      & > span.invited-by {
        font-weight: ${fontWeight.regular};
        font-size: ${fontSizes.small};
        line-height: 0.75rem;
        letter-spacing: -0.1px;
        color: ${palette.gray40};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &.mode--join {
    height: 8.5rem;
    border-top: 1px solid ${palette.gray90};
    border-bottom: 1px solid ${palette.gray90};

    & > div.left-side {
      & > div.logo {
        height: 3rem;
        width: 3rem;
      }

      & > div.pool-information {
        & > span.members-count {
          display: flex;
        }
        & > span.invited-by {
          display: none;
        }
      }
    }
  }

  & > div.invites-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    & > * {
      width: calc(50% - 0.5rem);
    }
  }

  ${queries.sm} {
    flex-direction: row;
    border: 1px solid ${palette.gray90};
    border-radius: 0.5rem;

    &.mode--join {
      height: 5.875rem;
      padding: 1rem 1.5rem;
      margin-top: 0;

      & > div.left-side {
        min-width: 0;
        padding-right: 0;
        & > div.logo {
          height: 3.75rem;
          width: 3.75rem;
        }
        & > div.pool-information {
          flex: 0% 1 1;
        }
      }
    }
    &.mode--view {
      height: 4.625rem;
      & > div.left-side {
        & > div.pool-information {
          flex: 1;
        }
      }
    }

    &.mode--view ~ .mode--view {
      margin-top: 0.75rem;
    }

    & > div.left-side {
      & > div.pool-information {
        & > span.invited-by {
          display: flex;
        }
        & > span.pool-members-count {
          display: flex;
        }
      }
    }

    & > div.invites-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0;
      margin-left: 1.5rem;
      & > :first-child {
        margin-right: 1rem;
      }

      & > * {
        width: auto;
      }
    }
  }
`
export const PasswordModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${palette.white};
  box-shadow: none;
  box-sizing: border-box;
  width: calc(100vw - 4rem);
  max-width: 19.5rem;

  & > div.modal-actions {
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 0 0;

    & > * {
      width: calc(50% - 0.5rem);
    }
  }

  ${queries.sm} {
    width: 23rem;

    & > div.modal-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0;
      padding: 2.5rem 0 0;

      & > * {
        width: auto;
      }

      & > :first-child {
        margin-right: 1rem;
      }
    }
  }
`
export const PoolNameWrap = styled.span`
  font-weight: ${fontWeight.bold};
  font-size: 1rem;
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${palette.gray20};
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: flex-start;
  max-width: 100%;

  &.mode--view {
    margin-bottom: 0.25rem;
  }

  &.mode--join {
    font-size: 1.125rem;
    font-weight: ${fontWeight.bold};
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: left;
    margin: 0.25rem 0;
    pointer-events: none;
  }

  ${queries.sm} {
    &.mode--join {
      font-size: 1.25rem;
      line-height: 1;
    }
  }

  @media (hover: hover) {
    &:hover {
      color: ${palette.lightBlue3};
    }
  }
`
