import { matchPath } from "react-router-dom"
import { AsyncRouteableComponent, AsyncRouteComponentType, AsyncRouteProps, IGameRootRouteMatch, IRoute } from "../../routes.d"

export function isAsyncComponent(Component: AsyncRouteableComponent): Component is AsyncRouteComponentType<any> {
  return (Component as AsyncRouteComponentType<any>).getInitialProps !== undefined
}

function isLoadableComponent(Component: AsyncRouteableComponent): Component is AsyncRouteComponentType<any> {
  return (Component as AsyncRouteComponentType<any>).load !== undefined
}

export function findMatchedRoute(routes: IRoute[], pathname: string) {
  const matchedRoute =
    routes.find((route) => !!route.hasOwnProperty("path") && !!matchPath(pathname, route)) ||
    routes.find(
      // if no route is found, attempt to find a fallback (not-found) route
      (route) => !route.hasOwnProperty("path"),
    )

  // if this is the fallback (not-found) route, `matchPath` errors out if there is no `path`, so we use "*"
  const match =
    (matchedRoute && matchPath<IGameRootRouteMatch>(pathname, matchedRoute.hasOwnProperty("path") ? matchedRoute : { path: "**" })) ||
    (undefined as never)

  return {
    match,
    route: matchedRoute,
  }
}

export function loadRouteComponent(route: AsyncRouteProps) {
  if (route.component && isLoadableComponent(route.component) && route.component.load) {
    return route.component.load()
  } else {
    return Promise.resolve(route.component || null)
  }
}
