import palette from "../../common/palette"
import { pxToRem, gridBreakPoints, fontWeight } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

export { palette, pxToRem, fontWeight }
export const rootFontSize = 16
export const skyboxH = "7.2vw"

export const fontFaces = [
  {
    family: "FS Industrie",
    style: "regular",
    url: "//sports.cbsimg.net/fonts/fs-industrie/variable/standard/FSIndustrieVFStd.woff2",
    format: "woff2",
    weight: "100 900",
    display: "swap",
  },
  {
    family: "FS Industrie",
    style: "italic",
    url: "//sports.cbsimg.net/fonts/fs-industrie/variable/standard/FSIndustrieVFStd-Italic.woff2",
    format: "woff2",
    weight: "100 900",
    display: "swap",
  },
  {
    family: "FS Industrie Nw",
    style: "regular",
    url: "//sports.cbsimg.net/fonts/fs-industrie/variable/narrow/FSIndustrieVFNw.woff2",
    format: "woff2",
    weight: "100 900",
    display: "swap",
  },
  {
    family: "FS Industrie Nw",
    style: "italic",
    url: "//sports.cbsimg.net/fonts/fs-industrie/variable/narrow/FSIndustrieVFNw-Italic.woff2",
    format: "woff2",
    weight: "100 900",
    display: "swap",
  },
]

const skyboxZIndex = 5999999
export const zIndexes = {
  ui0: 0,
  ui1: 1,
  ui2: 2,
  ui3: 3,
  ui4: 4,
  ui5: 5,
  mobileBotTabs: 3,
  siteNav: skyboxZIndex + 5,
  subModal0: skyboxZIndex + 100,
  subModal1: skyboxZIndex + 101,
  subModal2: skyboxZIndex + 102,
  subModal3: skyboxZIndex + 103,
  mobileNavModal: skyboxZIndex + 104,
  siteNavDropdown: skyboxZIndex + 105,
  modalBg: skyboxZIndex + 201,
  modalContent: skyboxZIndex + 202,
  modalHeader: skyboxZIndex + 203,
  tooltip: 10000,
}

const poolNavH = 34
// padding + PageTitleH
const pageTitleH = 40
const poolTitleH = 16 + pageTitleH
const poolTitleEntryNameH = 19
const gridMaxWMargin = 48
const tvBreakpoint = 1920

export const layout = {
  shrinkFontMaxW: 350,
  wrapperWidth: 1100,
  useMobileNavW: gridBreakPoints.breakpoints.sm,
  matchupAnalysisBP: 700,
  fullscreenHideNavW: 600,
  formHiddenLabelsW: 600,
  dialogMaxWidth: 450,
  accountNavW: 340,
  dialogMaxHeight: 200,
  modalHeaderHeight: 52,
  siteNavH: 50,
  siteSubNavH: 30,
  mobileBottomAdH: 50,
  pageRightSideColW: 300,
  shrinkSpoeW: 1230,
  gridMaxWMargin,
  pageContentMaxW: gridBreakPoints.breakpoints.xl,
  pageTitleH,
  poolNavH,
  poolTitleH,
  poolTitleEntryNameH,
  pageMarqueeMinH: 162,
  pageMarqueeCompactMinH: 110,
  pageGutterW: 16,
  nexusTogglesWrapW: 450,
  picksPageLeftColW: 320,
  picksPageMaxW: 1400,
  picksPageBracketLeftColW: 250,
  maxBracketH: 800,
}
// xs: 0,
// sm: 768,
// md: 1024,
// lg: 1278,
// xl: 1536, -> 48 * 2 + 1440 = 1536
export type TDeviceType = "desktop" | "handheld" | "tablet"
export const breakpoints = Object.assign(
  {
    handheld: layout.useMobileNavW, // 800
    tablet: layout.wrapperWidth, // 1400
    tv: tvBreakpoint, // 1920
  },
  gridBreakPoints.breakpoints,
)
