import { Location } from "history"
import * as React from "react"
import constants from "../../common/constants"
import Helmet from "../shared/react-helmet-async/Helmet"

const prefix = `${constants.APP_ENV.toUpperCase()} - `.replace("PROD - ", "")

class MetaTags extends React.Component<Location> {
  public shouldComponentUpdate(nextProps) {
    return this.props.pathname != nextProps.pathname
  }

  public render() {
    const { pathname, search } = this.props
    const origin = constants.DOMAIN
    const ogUrl = origin + `/${pathname}`.replace("//", "/") + search
    const canonicalUrl = ogUrl.replace("/join", "")
    const htmlAttributes = {
      lang: "en",
      itemScope: true,
      itemType: "http://schema.org/WebPage",
    }
    return (
      <Helmet htmlAttributes={htmlAttributes} defaultTitle="Fantasy Games" titleTemplate={`${prefix}CBS Sports - %s`}>
        {/*
        <title>Fantasy Games</title>
        <meta property="og:title" content="CBS Sports - Fantasy Games." />
        <meta property="og:description" content="CBS Sports features live scoring, news, stats, and player info for NFL football, MLB baseball, NBA basketball, NHL hockey, college basketball and football." />
        <meta property="og:image" content="https://sportsfly.cbsistatic.com/fly-900/bundles/sportsmediacss/images/fantasy/default-article-image-large.gif" />
        <meta name="msapplication-navbutton-color" content="#1483b2" />
        <meta name="msapplication-starturl" content={origin} />
        <meta name="msapplication-tooltip" content="CBSSports.com features live scoring and news for NFL football, MLB baseball, NBA basketball, NHL hockey, college basketball and football. CBSSports.com is your source for fantasy sports news." />
        <meta name="description" content="CBS Sports features live scoring, news, stats, and player info for NFL football, MLB baseball, NBA basketball, NHL hockey, college basketball and football." />
        */}

        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="x5-orientation" content="portrait" />
        <meta name="screen-orientation" content="portrait" />
        <meta name="x5-page-mode" content="app" />
        <meta name="browsermode" content="application" />
        <meta content="en" httpEquiv="Content-Language" />
        <meta name="google" content="notranslate" />
        <link rel="preconnect dns-prefetch" href="https://sportshub.cbsistatic.com" />
        <link rel="preconnect dns-prefetch" href="https://sportsfly.cbsistatic.com" />
        <link rel="preconnect dns-prefetch" href="https://sports.cbsimg.net" />

        <meta property="og:site_name" content="CBSSports.com" />
        <meta property="og:type" content="website" />
        <meta property="fb:pages" content="25902406772" />
        <meta property="fb:app_id" content="297742330311988" />
        <meta property="fb:admins" content="100002967552623" />
        <meta property="twitter:account_id" content="14885860" />
        <meta property="twitter:site" content="CBSSports.com" />
        <meta name="msapplication-window" content="width=1024;height=768" />
        <meta name="google-site-verification" content="EdjXVFTZ6BgUzS3Void8cMiXXzE6bXDvNg-Yjl3VZj8" />
        <meta name="robots" content="NOODP" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://www.cbssports.com/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://www.cbssports.com/favicon-16x16.png" />
        <link rel="icon" type="image/x-icon" href="https://www.cbssports.com/favicon.ico" />
        <link
          rel="apple-touch-icon"
          href="https://sportsfly.cbsistatic.com/fly-900/bundles/sportsmediacss/images/core/webclips/touch-icon-iphone.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="https://sportsfly.cbsistatic.com/fly-900/bundles/sportsmediacss/images/core/webclips/touch-icon-ipad.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://sportsfly.cbsistatic.com/fly-900/bundles/sportsmediacss/images/core/webclips/touch-icon-iphone-retina.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://sportsfly.cbsistatic.com/fly-900/bundles/sportsmediacss/images/core/webclips/touch-icon-ipad-retina.png"
        />
        <link rel="alternate" href={`android-app://com.handmark.sportcaster/cbssportsapp/${canonicalUrl}`} />
        <meta name="apple-itunes-app" content="app-id=307184892" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    )
  }
}

export default MetaTags
