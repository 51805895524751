import { gql } from "@apollo/client"

export const POOL_SETTINGS_FRAGMENT = gql`
  fragment PoolSettingsFragment on PoolSettings {
    id
    picksDeadlineType
    periodLength
    maxEntriesPerUser
    mainTiebreaker
    gamesPerPeriod
    pickCountRangeOption
    gameWeightType
    roundBonusType
    roundBonuses
    roundModifiers
    roundModifiersOption
    tournamentIds
    includeMessageBoard
    openInvites
  }
`
export const CENTRAL_SEASON_FRAGMENT = gql`
  fragment CentralSeasonFragment on Season {
    id
    season
    year
    masterProductId
    challengePoolSlug
    challengePoolId
    productAbbrev
    isArchived
    isCurrent
    svgLogoFullPath
    customCopy
    appPromoUrl
    rulesUrl
    hasPrizes
    seasonSignupSponsorDataForm {
      id
      name
      inputFields {
        id
        type
        label
        name
        extraHtml
      }
    }
    whenToWatch {
      id
      periodName
      dates
    }
    gameInstance {
      id
      uid
      client
    }
  }
`
export const CENTRAL_TEAMS_QUERY = gql`
  query CentralTeamsQuery($sportTypes: [GameSportTypeEnumType!], $subsection: GameSubsectionEnumType) {
    teams(sportTypes: $sportTypes, subsection: $subsection) {
      id
      abbrev
      location
      nickName
      shortName
      sportType
      colorHexDex
      colorPrimaryHex
      colorSecondaryHex
      conferenceAbbrev
      cbsTeamId
      wins
      losses
      ties
      imageUrl
      mediumName
    }
  }
`
export const CENTRAL_BRACKET_STATE_QUERY = gql`
  query CentralBracketsState {
    mensBracketState: bracketState(gameInstanceUid: "cbs-ncaab-tournament-challenge") {
      id
      isPreTournament
      locksAt
      isLocked
    }
    womensBracketState: bracketState(gameInstanceUid: "cbs-ncaaw-tournament-challenge") {
      id
      isPreTournament
      locksAt
      isLocked
    }
  }
`
export const CENTRAL_GAME_INSTANCES_QUERY = gql`
  query CentralGameInstancesQuery($gameType: GameGameTypeEnumType!, $sportTypes: [GameSportTypeEnumType!]!, $subsection: GameSubsectionEnumType) {
    allSegments(gameType: $gameType, sportTypes: $sportTypes, subsection: $subsection) {
      id
      gameType
      sportType
      seasonType
      subsection
      analyticsChannel
      landingUrl
      baseUrl
      isInComingSoon
      hasStarted
      hasEnded
      poolSettings {
        ...PoolSettingsFragment
      }
      season {
        ...CentralSeasonFragment
      }
      tournaments {
        id
        cbsId
        name
        abbrev
      }
      season {
        gameInstance {
          id
          uid
          client
          poolType
        }
      }
    }
    gameInstances(gameType: $gameType, sportTypes: $sportTypes, subsection: $subsection) {
      edges {
        node {
          client
          poolType
          id
          uid
          customCopy
          currentPeriod {
            id
            description
            order
            isCurrent
            isPickable
            locksAt
            startsAt
            notificationBannerHtml
            notificationBannerBackgroundColor
            notificationBannerColor
            globalNotificationHtml
            globalNotificationBackgroundColor
            globalNotificationColor
            segment {
              id
              season {
                id
                season
                year
                productAbbrev
                challengePool {
                  poolSettings {
                    ... on PoolSettings {
                      maxEntriesPerUser
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${POOL_SETTINGS_FRAGMENT}
  ${CENTRAL_SEASON_FRAGMENT}
`
export const CENTRAL_POOL_DETAILS_FRAGMENT = gql`
  fragment CentralPoolDetailsFragment on Pool {
    id
    name
    slogan
    url
    password
    poolSettings {
      ...PoolSettingsFragment
    }
    gameInstanceUid
    currentSeasonPoolId
    currentSeasonPoolUrl
    parentPoolId
    entriesCount
    membersCount
    isPublic
    usesMagicLink
    avatarUrl
    canAddEntry
    inviteUrl
    season {
      ...CentralSeasonFragment
    }
    hasSeenManagerWelcomeBackModal
    inviteAllEmailSent
    helpDeskUrl
    canViewEarly
    hasSeenNewSettingPopover
  }
  ${CENTRAL_SEASON_FRAGMENT}
  ${POOL_SETTINGS_FRAGMENT}
`
export const CENTRAL_POOL_DETAILS_QUERY = gql`
  query CentralPoolDetailsQuery($poolId: ID!) {
    pool(id: $poolId) {
      ...CentralPoolDetailsFragment
    }
  }
  ${CENTRAL_POOL_DETAILS_FRAGMENT}
`

export const POOL_BY_SLUG_QUERY = gql`
  query PoolBySlug($slug: String!) {
    pool: poolBySlug(slug: $slug) {
      ...CentralPoolDetailsFragment
    }
  }
  ${CENTRAL_POOL_DETAILS_FRAGMENT}
`
export const CENTRAL_ENTRY_FRAGMENT = gql`
  fragment CentralEntryFragment on Entry {
    id
    name
    email
    hideEmail
    avatarUrl
    gameInstanceUid
    needsToMakePicks
    hasMadeAPick
    userId
    url
    roles
    poolPassword
    totalPicksCount
    poolRank
    fantasyPoints
    maxPoints
    reinvitedPassword
    currentSeasonEntryId
    hasAutogeneratedName
    createdAt
    championTeam {
      id
      cbsTeamId
      sportType
      abbrev
    }
    isVisible
    maxPicksCount
    canDeleteEntry
    canLeavePool
    periodPoint {
      id
      poolRank
      fantasyPoints
    }
    pool {
      ...CentralPoolDetailsFragment
    }
  }
  ${CENTRAL_POOL_DETAILS_FRAGMENT}
`

export const CENTRAL_CURRENT_USERS_PENDING_INVITATIONS_QUERY = gql`
  query CurrentUserPendingInvites($gameInstanceUid: String!) {
    pendingInvitations(gameInstanceUid: $gameInstanceUid) {
      pool {
        id
        name
        entriesCount
        membersCount
        slogan
        avatarUrl
        url
        usesMagicLink
        inviteUrl
        poolSettings {
          ...PoolSettingsFragment
        }
      }
      pendingInvitationId
      invitedBy
    }
    ${POOL_SETTINGS_FRAGMENT}
  }
`
export const CENTRAL_CURRENT_USERS_ENTRIES_QUERY = gql`
  query CentralCurrentUsersEntriesQuery(
    $gameType: GameGameTypeEnumType!
    $sportTypes: [GameSportTypeEnumType!]!
    $subsection: GameSubsectionEnumType
  ) {
    canSeeDebugInfo
    isInDebugMode
    isCbsAppWebview
    isIosBrowser
    isAndroidBrowser
    currentUser {
      id
      picksDbId
      custId
      hasCbsAccount
      hasFantasyUser
      preferredEntryName
      email
      avatarUrl
      isCbsAdmin
      firstName
      lastName
      entries(first: 200, gameType: $gameType, sportTypes: $sportTypes, subsection: $subsection) {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            ...CentralEntryFragment
          }
        }
      }
    }
  }
  ${CENTRAL_ENTRY_FRAGMENT}
`

export const ASSET_SRC_QUERY = gql`
  query AssetSrcQuery($fullPath: String!) {
    asset(fullPath: $fullPath) {
      id
      src
      contentType
    }
  }
`
