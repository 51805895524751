import React, { Component } from "react"
import { HelmetData, IHelmetInstance, IProviderHelmetD, ProviderProps } from "./react-helmet-async-types.d"
import mapStateOnServer from "./server"

// const defaultValue = {} as IProviderHelmetD;

export const Context = React.createContext<IProviderHelmetD>(undefined as any)
Context.displayName = "ReactHelmetAsync"

const canUseDOM = typeof document !== "undefined"

export default class Provider extends Component<ProviderProps> {
  public static canUseDOM = canUseDOM

  public static defaultProps = {
    context: {
      helmet: null as HelmetData | null,
    },
  }

  public static displayName = "HelmetProvider"

  public instances = [] as IHelmetInstance[]

  public value = {
    setHelmet: (serverState: HelmetData | null) => {
      // eslint-disable-next-line react/prop-types
      this.props.context.helmet = serverState
    },
    helmetInstances: {
      get: () => this.instances,
      add: (instance: IHelmetInstance) => {
        this.instances.push(instance)
      },
      remove: (instance: IHelmetInstance) => {
        const index = this.instances.indexOf(instance)
        this.instances.splice(index, 1)
      },
    },
  } as IProviderHelmetD

  constructor(props) {
    super(props)

    if (!Provider.canUseDOM) {
      // eslint-disable-next-line no-param-reassign
      props.context.helmet = mapStateOnServer({
        baseTag: [] as never[],
        bodyAttributes: {} as never,
        encodeSpecialCharacters: true,
        htmlAttributes: {} as never,
        linkTags: [] as never[],
        metaTags: [] as never[],
        noscriptTags: [] as never[],
        scriptTags: [] as never[],
        styleTags: [] as never[],
        title: "",
        titleAttributes: {} as never,
      })
    }
  }

  public render() {
    return <Context.Provider value={this.value}>{this.props.children}</Context.Provider>
  }
}
