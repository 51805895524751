import React, { FC, ReactNode, HTMLAttributes } from "react"
import styled, { StyledComponentProps, DefaultTheme } from "styled-components"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import { IModalProps } from "@cbs-sports/sports-shared-client/build/cjs/components/Modal/Modal.types"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { pxToRem, gridBreakPoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
const { queries } = gridBreakPoints

export const noop = () => Promise.resolve(undefined)
const px14 = pxToRem(14)

const ModalContentWrapper = styled.div`
  padding: 0 1rem 1rem;

  .modal__text--title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: ${theme.fonts.weights.bold};
    color: ${theme.colors.overLight.white20};
  }

  .modal__text--body1 {
    font-size: ${px14};
    line-height: ${px14};
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.white20};
  }

  .modal__text--body2 {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.white40};
  }

  .modal__text--error {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: -0.1px;
    color: ${theme.colors.overLight.red};
  }

  .modal__text--overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${queries.sm} {
    padding: 0 2.5rem 1.5rem;
  }
`

const ModalFooterWrapper = styled.div`
  padding: 1rem;
  border-top: solid 1px ${theme.colors.overLight.white90};

  ${queries.sm} {
    padding: 1rem 1.5rem;
  }
`

const ModalTitle = styled.div`
  font-size: ${pxToRem(18)};
  line-height: 1.25rem;
  text-transform: uppercase;
  font-weight: ${theme.fonts.weights.bold};
  color: ${theme.colors.overLight.white20};
  text-align: left;
  padding: 1rem;

  ${queries.sm} {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 1.5rem 2.5rem;
  }
`

interface ModalActionsProps extends HTMLAttributes<HTMLDivElement> {
  dense?: boolean
}

export const ModalActions = styled.div<ModalActionsProps>`
  display: flex;
  margin-top: ${({ dense }) => `${!!dense ? 1.5 : 2}rem`};
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-left: 1rem;

    &:first-of-type {
      margin-left: 0;
    }
  }
`
export type GenericModalComponentProps = HTMLAttributes<HTMLDivElement> &
  StyledComponentProps<"div", DefaultTheme, Record<string, unknown>, never> & {
    as?: string | React.ComponentType
    forwardedAs?: string | React.ComponentType
  }

export interface IGenericEntryModalProps extends IModalProps {
  title?: ReactNode
  actions?: ReactNode
  actionProps?: Partial<ModalActionsProps>
  modalContentWrapperProps?: GenericModalComponentProps
  modalHeaderWrapperProps?: GenericModalComponentProps
  modalFooterWrapperProps?: GenericModalComponentProps
}

const GenericEntryModal: FC<IGenericEntryModalProps> = ({
  title,
  children,
  actions,
  actionProps,
  noBorder = true,
  modalContentWrapperProps,
  modalHeaderWrapperProps,
  modalFooterWrapperProps,
  footer = null,
  ...rest
}) => {
  const header = !!title ? <ModalTitle {...modalHeaderWrapperProps}>{title}</ModalTitle> : undefined
  return (
    <Modal {...rest} padded={false} variant="white" header={header} noBorder={noBorder}>
      <ModalContentWrapper {...modalContentWrapperProps}>
        {children}
        {actions && <ModalActions {...actionProps}>{actions}</ModalActions>}
      </ModalContentWrapper>
      {footer && <ModalFooterWrapper {...modalFooterWrapperProps}>{footer}</ModalFooterWrapper>}
    </Modal>
  )
}

export default GenericEntryModal
