import { getThemeValues } from "./theme-helpers"
// import confBracketBg from "../../../assets/conf-bracket-bg2.jpg"
// import golfBgImg from "../../../assets/golf-bg.svg";
// import ncaafPostImg from "../../../assets/ncaaf-post-bg.jpg"
// import ncaafImg from "../../../assets/ncaafBg.jpg"
// import defaultLighBgImg from "../../../assets/default-bg-light.jpg"
// import nflPostBg from "../../../assets/nfl-post-bg.jpg"
// import parlayImg from "../../../assets/parlayBg.jpg"
// import { useContext } from "react";
import palette from "./palette"
import { IThemePassin } from "./theme-helpers"
import constants from "./constants"

const staticRoot = `${constants.DOMAIN}/static`

// background: url(${onboardingBgBase}) #00006b no-repeat center;
// linear-gradient(to bottom, #0080FF 0%, #0C357C 100%) was old
// #F6B010 #59B9EB I have 4 blue filters with a gradient going from top left to bottom right: #33bfde to #1e7bd6.
// 4 Blending layers used from top to bottom: Overlay 100%, Overlay 100%, Normal 28%, Multiply 100%.
// circular gradient from center: 250/254/255 to 188/234/245. I stacked 2 layers: Normal blend mode 45 opacity
// on bottom/Screen blend mode 76 opacity on top.
const footballLightBgImage = `url(${staticRoot}/sport-assets/football/default-bg-light.jpg), radial-gradient(85% 50%, ${palette.lightBgGradiantStopColor} 0%, ${palette.lightBgGradiant} 100%)`
export const layoutBackgroundImageStyles = {
  // "black": "none",
  "college-basketball-ncaa-tournament-bracket": `radial-gradient(85% 50%, ${palette.ncaabTournamentTersery} 0%, ${palette.ncaabTournamentSecondary} 100%)`,
  // "football-parlay": `url(${parlayImg}), radial-gradient(85% 50%, #084179 0%, #040814 100%)`,
  "football-parlay": footballLightBgImage,
  "football-pickem": footballLightBgImage,
  // "football-pickem": `url(${golfBgImg}), linear-gradient(to bottom,rgba(0, 0, 0, 0.78) 0%,rgba(0, 0, 0, 0) 100%)`,
  "football-pickem-post": footballLightBgImage,
  "college-football-pickem": footballLightBgImage, // `radial-gradient(50% 100%, #005CB3 0%, #004482 100%)`,
  "college-basketball-conference-tournament-bracket": `url(${staticRoot}/game-assets/cbs-ncaab-confbracket/splash-bg.jpg), radial-gradient(85% 50%, ${palette.ncaabConfTersery} 0%, ${palette.ncaabConfSecondary} 100%)`,
  "college-football-pickem-post": `url(${staticRoot}/game-assets/ncaaf-bowl-pickem/splash-bg.jpg), linear-gradient(to bottom right, ${palette.ncaafPostTersery} 0%, #fff 100%)`, // radial-gradient(85% 50%, ${palette.ncaafPostTersery} 0%, #000 100%)
  default: "none",
}
export const layoutBackgroundColorStyles = {
  // "black": "#000",
  // "football-pickem": pallete,
  "football-pickem-post": palette.nflPostTersery,
  "football-pickem": "transparent",
  "football-parlay": "transparent",
  "college-basketball-ncaa-tournament-bracket": palette.ncaabTournamentTersery,
  "college-basketball-conference-tournament-bracket": palette.ncaabConfTersery, // "#C5DADF",
  "college-football-pickem": palette.ncaafTersery,
  "college-football-pickem-post": palette.ncaafPostTersery,
  default: palette.defaultLightBgColor,
}
export const layoutBackgroundImageStylesSize = {
  default: "cover",
  // "football-pickem": "auto",
  // "football-parlay": "cover",
  // "football-pickem": "cover",
  // "college-football-pickem": "cover",
  // "college-football-pickem-post": "cover",
  // "college-basketball-conference-tournament-bracket": "cover",
  // "football-pickem-post": "cover",
}
export const layoutBackgroundImageStylesBlendMode = {
  default: "overlay",
  // "football-parlay": "inherit",
  "college-football-pickem": "inherit",
  "college-football-pickem-post": "inherit",
  "college-basketball-conference-tournament-bracket": "inherit",
  "football-pickem-post": "inherit",
}
export const layoutBackgroundImageStylesPosition = {
  default: "top center",
  "college-basketball-conference-tournament-bracket": "left center",
  "college-football-pickem-post": "top left",
  "football-pickem-post": "top left",
}
export const layoutSoloBackgroundColorStyles = {
  "college-football-pickem-post": palette.ncaafPostSoloBgColor,
  // "football-parlay": palette.lightBgGradiant,
  default: palette.defaultLightBgColor,
}
export const layoutSoloBackgroundColorStopStyles = {
  "college-football-pickem-post": palette.ncaafPostSoloBgColor,
  // "football-parlay": palette.nflParlaySoloStopColor,
  default: palette.defaultLightBgColor,
}
export const layoutSoloLowerSectionColor = {
  "college-football-pickem-post": "#ffffff17",
  default: "inherit",
}
export const layoutFixedBgColor = {
  "college-football-pickem-post": "transparent",
  default: "inherit",
}

export const soloBgColor = ({ theme }: IThemePassin) => {
  if (layoutSoloBackgroundColorStyles[theme.id]) {
    return layoutSoloBackgroundColorStyles[theme.id]
  }
  const vals = getThemeValues(theme)
  return vals.isDarkSplashBg ? vals.darkBgColor : vals.lightBgColor
  // return layoutSoloBackgroundColorStyles.default;
}
export const bgColorOff = ({ theme }: IThemePassin) => {
  if (layoutSoloBackgroundColorStopStyles[theme.id]) {
    return layoutSoloBackgroundColorStopStyles[theme.id]
  }
  const vals = getThemeValues(theme)
  return vals.isDarkSplashBg ? vals.darkBgColor : vals.lightBgColor
}
export const lowerSection2Bg = ({ theme }: IThemePassin) => {
  if (layoutSoloLowerSectionColor[theme.id]) {
    return layoutSoloLowerSectionColor[theme.id]
  }
  return layoutSoloLowerSectionColor.default
}
export const lowerSectionSvgColor = ({ theme }: IThemePassin) => {
  const vals = getThemeValues(theme)
  return vals.isDarkSplashBg ? "#71C8F0" : vals.primaryShadow
}
export const fixedBgBg = ({ theme }: IThemePassin) => {
  if (layoutFixedBgColor[theme.id]) {
    return layoutFixedBgColor[theme.id]
  }
  return layoutFixedBgColor.default
}

export const bgColor = ({ theme }: IThemePassin) => {
  if (layoutBackgroundColorStyles[theme.id]) {
    return layoutBackgroundColorStyles[theme.id]
  }
  return layoutBackgroundColorStyles.default
}
export const bgImage = ({ theme }: IThemePassin) => {
  if (layoutBackgroundImageStyles[theme.id]) {
    return layoutBackgroundImageStyles[theme.id]
  }
  return layoutBackgroundImageStyles.default
}
export const bgSize = ({ theme }: IThemePassin) => {
  if (layoutBackgroundImageStylesSize[theme.id]) {
    return layoutBackgroundImageStylesSize[theme.id]
  }
  return layoutBackgroundImageStylesSize.default
}
export const bBlendMode = ({ theme }: IThemePassin) => {
  if (layoutBackgroundImageStylesBlendMode[theme.id]) {
    return layoutBackgroundImageStylesBlendMode[theme.id]
  }
  return layoutBackgroundImageStylesBlendMode.default
}
export const bgPosition = ({ theme }: IThemePassin) => {
  if (layoutBackgroundImageStylesPosition[theme.id]) {
    return layoutBackgroundImageStylesPosition[theme.id]
  }
  return layoutBackgroundImageStylesPosition.default
}
