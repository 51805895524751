import React from "react"
import styled from "styled-components/macro"
import { palette, pxToRem, gridBreakPoints } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { fontWeight } from "../../../utils/style-utils"

const { breakpoints } = gridBreakPoints

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  description?: React.ReactNode | null
  imageUrl?: string | null
  imageAlt?: string | null
  imageNode?: React.ReactNode | null
  h1: React.ReactNode | null
  imageBgColor?: string | null
  variant?: "standard" | "compact"
  squareImage?: boolean
  topTitleText?: React.ReactNode | null
  titleAd?: React.ReactNode | null
  sponsor?: React.ReactNode | null
}

const PageTitleStyles = styled.header`
  --diameter: 3.75rem;
  --margin-top: 1.5rem;
  --margin-bottom: 1.5rem;
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  max-width: 100%;
  font-size: ${pxToRem(18)};

  &.page-title__variant--standard {
    margin: 0;
    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
    align-items: center;
  }

  &.page-title__square-image--true {
    & > div.image-container {
      border-radius: 0;
      padding: 0;
    }
  }

  & > div.image-container {
    padding: ${pxToRem(1)};
    border-radius: 50%;
    height: var(--diameter);
    flex: 0 0 var(--diameter);
    max-width: var(--diameter);
    overflow: hidden;
    pointer-events: none;

    &.page-title__variant--compact {
      --diameter: ${pxToRem(40)};
    }
    &.page-title__has-image-node--false {
      background-color: ${palette.gray90};
    }
    &.page-title__has-image-node--true {
      display: flex;
      & > span {
        display: flex;
      }
    }
    & img {
      width: 100%;
    }
  }

  & > div.title-content-container {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    & > * {
      padding-left: ${pxToRem(12)};
      text-overflow: ellipsis;
      overflow: hidden;
    }
    & h1 {
      color: ${palette.gray20};
      font-size: 1.25rem;
      font-weight: ${fontWeight.black};
      letter-spacing: -0.5px;
      white-space: nowrap;
    }
    & aside {
      color: ${palette.gray50};
    }
    &.page-title__is-long--true {
      font-size: 0.75em;
    }
    &.page-title__has-description--false {
      align-items: center;
    }
    .title-container {
      display: flex;
      flex-direction: column;
      min-width: ${pxToRem(75)};
      & sup {
        font-size: ${pxToRem(16)};
      }

      .top-title-text {
        line-height: ${pxToRem(14)};
        font-size: ${pxToRem(14)};
        color: ${palette.gray20};
        display: flex;

        & span {
          line-height: ${pxToRem(14)};
          font-size: ${pxToRem(14)};
          color: ${palette.gray20};
        }

        & sup {
          font-size: ${pxToRem(7)};
        }
      }
    }
    .h1-title-text {
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .h1-title-text.has-ad--true {
      margin-bottom: 0.25rem;
    }
  }

  // 768 Plus.
  @media (min-width: ${breakpoints.sm}px) {
    --diameter: 5rem;
    padding-right: 3rem;
    & > div.title-content-container {
      & > * {
        padding-left: 1rem;
      }
      & h1 {
        font-size: ${pxToRem(24)};
        font-weight: ${fontWeight.black};
      }
    }
  }

  // 768 -> 1023 Plus.
  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px) {
    --margin-top: 1.5rem;
    --margin-bottom: 1rem;
  }

  // 1024 Plus.
  @media (min-width: ${breakpoints.md}px) {
    --margin-top: 2rem;
    --margin-bottom: 1.5rem;
    max-width: 70%;
    & > div.title-content-container {
      & h1 {
        font-size: 2rem;
      }
    }
  }
  // 1278 Plus.
  @media (min-width: ${breakpoints.lg}px) {
    max-width: 85%;
  }
`

const emptyImg = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

function PageTitle(props: IProps) {
  const {
    h1,
    description,
    imageUrl,
    imageAlt,
    imageNode,
    className,
    variant = "standard",
    ref,
    topTitleText,
    squareImage,
    titleAd,
    sponsor,
    ...rest
  } = props
  const isLongTitle = typeof h1 === "string" && h1.length > 16
  const classNames = [
    `page-title__has-description--${!!description}`,
    `page-title__has-image-node--${!!imageNode}`,
    `page-title__has-is-long--${isLongTitle}`,
    `page-title__variant--${variant}`,
    `page-title__square-image--${!!squareImage}`,
  ]
  const sharedClassName = classNames.join(" ")
  if (className) {
    classNames.push(className)
  }
  return (
    <PageTitleStyles className={classNames.join(" ")} {...rest}>
      <div className={`image-container ${sharedClassName}`}>
        {imageNode || <img alt={imageAlt || undefined} src={imageUrl || emptyImg} loading="lazy" />}
      </div>
      <div className={`title-content-container ${sharedClassName}`}>
        {(topTitleText && (
          <div className="title-container">
            {(topTitleText && (
              <div data-cy="MM-Header-label" className="top-title-text">
                {topTitleText}
              </div>
            )) ||
              null}
            <h1 className={`h1-title-text has-ad--${!!titleAd}`} data-cy="NCAA-header-label">
              {h1}
            </h1>
            {titleAd && titleAd}
          </div>
        )) || <h1>{h1}</h1>}
        {(description && <aside>{description}</aside>) || null}
      </div>
      {sponsor}
    </PageTitleStyles>
  )
}

export default PageTitle
