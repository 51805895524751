import React, { FC, HTMLAttributes } from "react"
import { TypographyWrapper } from "./Typography.styles"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

type Capitalization = "REGULAR" | "UPPERCASE"
type Weight = "REGULAR" | "BOLD"
type Spacing = "REGULAR" | "COMPRESSED" | "EXPANDED"
type TypographyVariant = "REGULAR" | "HEADING" | "SUBHEADING" | "TABLE_HEAD" | "BODY1"

interface ITypographyProps {
  className?: string
  capitalization?: Capitalization
  weight?: Weight
  spacing?: Spacing
  variant?: TypographyVariant
  muted?: boolean
}

const Typography: FC<ITypographyProps & HTMLAttributes<HTMLDivElement>> = ({
  capitalization = "REGULAR",
  weight = "REGULAR",
  variant = "REGULAR",
  spacing = "REGULAR",
  muted = false,
  className = "",
  children,
  ...rest
}) => {
  const componentClassName = cx({
    [className]: Boolean(className),
    //CAPITALIZATION
    "capitalization--uppercase": capitalization === "UPPERCASE",
    //COLORS
    "color--muted": muted,
    //SPACING
    "spacing--compressed": spacing === "COMPRESSED",
    "spacing--expanded": spacing === "EXPANDED",
    //WEIGHT
    "weight--bold": weight === "BOLD",
    //VARIANTS
    "variant--regular": variant === "REGULAR",
    "variant--heading": variant === "HEADING",
    "variant--subheading": variant === "SUBHEADING",
    "variant--table-head": variant === "TABLE_HEAD",
    "variant--body1": variant === "BODY1",
  })

  return (
    <TypographyWrapper className={componentClassName} {...rest}>
      {children}
    </TypographyWrapper>
  )
}

export default Typography
