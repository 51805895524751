const purpose = "maskable any"
const pngType = "image/png"
export const defaultIcons = [
  {
    src: "/icon-72x72.png",
    sizes: "72x72",
    type: pngType,
    purpose,
  },
  {
    src: "/icon-96x96.png",
    sizes: "96x96",
    type: pngType,
    purpose,
  },
  {
    src: "/icon-128x128.png",
    sizes: "128x128",
    type: pngType,
    purpose,
  },
  {
    src: "/icon-144x144.png",
    sizes: "144x144",
    type: pngType,
    purpose,
  },
  {
    src: "/icon-152x152.png",
    sizes: "152x152",
    type: pngType,
    purpose,
  },
  {
    src: "/icon-192x192.png",
    sizes: "192x192",
    type: pngType,
    purpose,
  },
  {
    src: "/icon-384x384.png",
    sizes: "384x384",
    type: pngType,
    purpose,
  },
  {
    src: "/icon-512x512.png",
    sizes: "512x512",
    type: pngType,
    purpose,
  },
]
export const otherDynamicIcons = [
  {
    src: "/apple-touch-icon.png",
    sizes: "180x180",
    type: pngType,
    purpose,
  },
  {
    src: "/android-chrome-192x192.png",
    sizes: "192x192",
    type: pngType,
    purpose,
  },
  {
    src: "/android-chrome-512x512.png",
    sizes: "512x512",
    type: pngType,
    purpose,
  },
]
export const miscDynamicIcons = [
  {
    src: "/mstile-150x150.png",
    sizes: "150x150",
    type: pngType,
    purpose: "transparent",
  },
  {
    src: "/logo-transparent-512x512.png",
    sizes: "512x512",
    type: pngType,
    purpose: "transparent",
  },
  {
    src: "/safari-pinned-tab.svg",
    sizes: "512x512",
    type: "image/svg+xml",
    purpose: "svg",
  },
  {
    src: "/emailDesktopHeaderImageUrl.jpg",
    sizes: "1103x275",
    type: "image/jpeg",
    purpose: "email",
  },
  {
    src: "/emailMobileHeaderImageUrl.jpg",
    sizes: "750x300",
    type: "image/jpeg",
    purpose: "email",
  },
]

export const allDynamicLogos = [...defaultIcons, ...miscDynamicIcons, ...otherDynamicIcons]
