import React, { Component } from "react"
import constants from "../../../../common/constants"

const PLAYER_INSTANCE_ID = "right_rail_video_player"

const components = [
  `player_id=${PLAYER_INSTANCE_ID}`,
  `channel=collegebasketball`,
  `width=300`,
  `height=169`,
  `suppressAds=true`,
  `resizable=1`,
  `domain=cbssports.com`,
  `watchAndRead=0`,
  `autoplay=false`,
  `responsive=1`,
  // `silent=true`,
  `env=prod`,
  `autoplay_live=false`,
]
// if (constants.USE_NEW_IFRAME_VIDEO) {
//   components.push("data-da-tracking-pmi=cbssports_embed_site")
// }
const encodedComponents = encodeURIComponent(components.join("&"))
const src = constants.IS_PROD
  ? `https://embed.cbssports.com/player/embed?args=${encodedComponents}`
  : `https://embed-qa.cbssports.com/player/embed?args=${encodedComponents}`

class VideoPlayer extends Component {
  render() {
    return (
      <div className="EmbedVideoPlayer" id={`embedVideoContainer_${PLAYER_INSTANCE_ID}`}>
        <iframe
          title="Right rail video player"
          name={`embedVideo_${PLAYER_INSTANCE_ID}`}
          id={`embedVideo_${PLAYER_INSTANCE_ID}`}
          src={src}
          allowFullScreen
          frameBorder="0"
          scrolling="no"
          width={300}
          height={169}
          marginHeight={0}
          marginWidth={0}
          data-da-tracking-pmi="cbssports_embed_site"
        ></iframe>
      </div>
    )
  }
}
export default VideoPlayer

// SAMPLE VIDEO IFRAME SRC
// const src = `https://www.cbssports.com/player/embed/?args=player_id%3Dright_rail_video_player%26env%3Dprod%26channel%3Dteam%253ANFL%253AIND%252Cteam%253ANFL%253ABUF%26resizable%3D1%26autoplay%3Dtrue%26comp_ads_enabled%3Dfalse%26responsive%3Dtrue%26watchAndRead%3D0%26ids%3D45e9d222-a121-4b98-ba25-e732b5f53cc6%26domain%3Dcbssports.com`
