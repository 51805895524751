import styled from "styled-components/macro"
import { themeBtnBgColor, themeBtnBorderColor, themeBtnHighlightBgColor, themeBtnHighlightBorderColor } from "../../common/theme-helpers"
import { fontWeight, palette, pxToRem } from "../utils/style-utils"

const buttonBaseW = 343
const buttonSmallBaseW = 115

const ButtonBaseBase = styled.button`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(2)};
  border: ${pxToRem(1)} solid ${themeBtnBorderColor};
  padding: ${pxToRem(buttonBaseW / 22)} ${pxToRem(buttonBaseW / 8.75)};
  width: ${pxToRem(buttonBaseW)};
  min-height: ${pxToRem(buttonBaseW / 8.575)};
  max-width: 80%;
  font-size: ${pxToRem(14)};
  font-weight: ${fontWeight.semiBold};
  transition: all 0.2s ease-out;
  &:not(.is-form-spinner) > .spinner {
    /* give spinner no padding */
    margin: -0.6em 0;
    height: 2em;
    width: 2em;
  }
`
export const ButtonBase = styled(ButtonBaseBase)`
  background-color: ${themeBtnBgColor};
  color: ${palette.white};
  &:active {
    border-color: ${themeBtnHighlightBorderColor};
    background-color: ${themeBtnHighlightBgColor};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      border-color: ${themeBtnHighlightBorderColor};
      background-color: ${themeBtnHighlightBgColor};
    }
  }
`
export const ButtonSmallBase = styled(ButtonBase)`
  padding: ${pxToRem(buttonSmallBaseW / 14)} ${pxToRem(buttonSmallBaseW / 8)};
  min-width: ${pxToRem(buttonSmallBaseW)};
  width: auto;
  flex: 0 1 auto;
  min-height: ${pxToRem(buttonSmallBaseW / 3.5)};
  font-size: ${pxToRem(12)};
`

export const IconButton = styled.button`
  transition: all 0.2s ease-in-out;
  color: currentColor;
  display: flex;
  flex-flow: column;
  align-items: center;
  will-change: opacity;
  padding: 0.5em;
  & svg {
    width: 1.5em;
    height: 1.5em;
    margin-bottom: 0.25em;
  }
  &.is-inline--true {
    flex-flow: row nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: ${pxToRem(40)};
    padding-right: 0.25em;
    & > * {
      margin-right: 0.25em;
    }
    & svg {
      margin-bottom: 0;
    }
  }
  @media (hover: hover) {
    :hover:not(:active) {
      opacity: 0.8;
    }
  }
  :active {
    opacity: 0.7;
  }
`

export const ButtonWithAfterContentInteraction = styled.button`
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.15);
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.2s ease-out;
  }
  &.is-light-bg--true:after {
    background: rgba(0, 0, 0, 0.08);
  }
  @media (hover: hover) {
    &:hover:not(:active):after {
      opacity: 0.75;
    }
  }
  &:active:after {
    opacity: 1;
  }
`
