import * as React from "react"
import styled from "styled-components/macro"

const SVG = styled.svg`
  &.is-icon--true {
    height: 1.5em;
    width: 1.5em;
  }
`

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string
  icon?: boolean
}

const SvgComponent = React.forwardRef<SVGSVGElement, IProps>(({ color = "currentColor", icon = false, className, ...props }, ref) => {
  const classNames = [`checkmark`]
  if (className) {
    classNames.push(className)
  }
  if (icon) {
    classNames.push("is-icon--true")
  }
  return (
    <SVG viewBox="0 0 512 512" className={classNames.join(" ")} {...props} ref={ref}>
      <path
        fill={color}
        d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"
      />
    </SVG>
  )
})

const MemoizedComponent = React.memo(SvgComponent)
export default MemoizedComponent
