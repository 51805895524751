import React, { FC, useCallback, useState } from "react"
import PoolRulesModal from "../../../PoolPages/components/EntryModals/PoolRulesModal/PoolRulesModal"
import { PoolDetailsFragment } from "../../../../../__generated__/PoolDetailsFragment"
import PoolRulesContent from "../../../PoolPages/components/EntryModals/PoolRulesModal/RulesContent"
import GenericEntryModal from "../../../PoolPages/components/EntryModals/GenericEntryModal"
import { PoolRulesModalContent } from "../../../PoolPages/components/EntryModals/PoolRulesModal/PoolRulesModal.styles"
import BonusesContent from "../../../PoolPages/components/EntryModals/PoolRulesModal/BonusesContent"
interface BracketPoolSettingsViewProps {
  pool: PoolDetailsFragment
  variant?: "modal" | "page"
  isOpen?: boolean
  isLimbo?: boolean
}

const BracketPoolSettingsContent: FC<BracketPoolSettingsViewProps> = ({ pool, isLimbo }) => {
  const [showModal, setShowModal] = useState(false)

  const hide = useCallback(() => {
    setShowModal(false)
  }, [])
  const show = useCallback(() => {
    setShowModal(true)
  }, [])

  return (
    <>
      <PoolRulesContent pool={pool} mode="full" onShowBonuses={show} noShowBonuses={isLimbo} />
      <GenericEntryModal title="BONUSES" onEscapeKeydown={hide} onBackgroundClick={hide} closeBtnAction={hide} isOpen={showModal}>
        <PoolRulesModalContent>
          <BonusesContent />
        </PoolRulesModalContent>
      </GenericEntryModal>
    </>
  )
}

const BracketPoolSettingsView: FC<BracketPoolSettingsViewProps> = (props) => {
  const { pool, variant = "modal", isOpen = false } = props
  if (variant === "modal") {
    return <PoolRulesModal pool={pool} isOpen={isOpen} />
  }
  return <BracketPoolSettingsContent {...props} />
}

export default BracketPoolSettingsView
