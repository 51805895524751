import styled from "styled-components/macro"
import { palette, fontSizes, gridBreakPoints, pxToRem, fontWeight, fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { HorizontalOverflowContainer } from "../../../../components/OverflowContainer"

const { queries } = gridBreakPoints
export const PoolMarqueeWrapper = styled(HorizontalOverflowContainer)`
  white-space: nowrap;
  overflow-y: hidden;
  font-size: ${fontSizes.regular};
  border-radius: 0.5rem 0.5rem 0 0;
  margin-bottom: 0;
  border: 1px solid ${palette.gray90};
  padding: 2rem 1.5rem 0 1.5rem;
  ${queries.md} {
    background-image: none;
  }
`
export const PoolPageHeaderWrap = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto);
  grid-auto-rows: auto;
  grid-gap: var(--grid-gap);

  & > div.pool-tile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${palette.white};
    box-shadow: none;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid ${palette.gray90};
    border-radius: 0.5rem;
    height: 5.75rem;

    & > div.pool-tile-info {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      flex-direction: row;
      box-sizing: border-box;
      min-width: 0;

      & > div.pool-avatar-wrap {
        display: flex;
        position: relative;
        width: ${pxToRem(60)};
        height: ${pxToRem(60)};
        box-sizing: border-box;
        & > div.pool-manager-info {
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          top: ${pxToRem(-4)};
          right: ${pxToRem(-2)};
          color: ${palette.yellow};
        }
      }

      & > div.pool-tile-data {
        display: flex;
        margin-left: 1rem;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        min-width: 0;
        & > span {
          font-style: normal;
        }

        & > span.members-info {
          text-transform: uppercase;
          font-family: ${fontFamily.condensed};
          font-weight: ${fontWeight.medium};
          font-size: 10px;
          line-height: 0.75rem;
          letter-spacing: 1.5px;
          color: ${palette.gray40};
        }
        & > span.pool-name {
          font-weight: ${fontWeight.bold};
          font-size: ${fontSizes.large};
          line-height: 1.25rem;
          letter-spacing: -0.1px;
          color: ${palette.gray20};
          margin: 0.25rem 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        & > span.pool-slogan {
          font-weight: ${fontWeight.regular};
          font-size: ${fontSizes.small};
          line-height: 0.75rem;
          letter-spacing: -0.1px;
          color: ${palette.gray40};
        }
      }
    }

    & > div.pool-tile-actions {
      display: flex;
      height: 100%;
      flex-direction: row;
      align-items: center;
      margin-left: 1rem;
    }
  }
  & > div.margin-bottom--true {
    margin-bottom: 1rem;
  }
`
