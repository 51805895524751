import { fontFamily, fontWeight } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { toggleableToggledClassname } from "../../../components/Dropdown"
import { FlexCol, FlexColGrow, FlexRow } from "../../../components/FlexComponents"
import { layout, palette, pxToRem, skyboxH, zIndexes } from "../../../utils/style-utils"

const navSideDropdownW = layout.accountNavW

const DropdownDD = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0px ${pxToRem(10)} ${pxToRem(20)} ${pxToRem(-5)} rgba(0, 0, 0, 0.2);
  color: #232323;
  font-size: ${pxToRem(16)};
  top: 100%;
  z-index: ${zIndexes.siteNavDropdown};
`

export const NavSideDropdown = styled(DropdownDD)`
  border-top: ${pxToRem(4)} solid ${palette.uiNavLinkHighlighted};
  min-width: ${pxToRem(navSideDropdownW)};
  right: 0;
  margin-top: ${pxToRem(-4)};
`

export const Container = styled(FlexRow)`
  overflow: visible;
  flex: 0 1 auto;
  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    display: none;
  }
`

export const SpoeDropdownNoLeagueBtn = styled.button`
  color: ${palette.white};
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  border: 1px solid ${palette.malibuBlue};
  padding: ${pxToRem(6)} ${pxToRem(8)};
  font-size: ${pxToRem(13)};
  font-weight: ${fontWeight.semiBold};
  margin-right: ${pxToRem(8)};
  text-transform: uppercase;
  border-radius: ${pxToRem(2)};
  letter-spacing: ${pxToRem(2)};
  transition: all 0.2s ease-out;
  & svg {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    margin-right: ${pxToRem(8)};
  }
  @media (hover: hover) {
    &:hover {
      color: ${palette.brandNavLinkHover};
    }
  }
  &:active {
    color: ${palette.brandNavLinkHover};
  }
`

export const SpoeDropdownWrapper = styled.div`
  height: 100%;
  margin-right: ${pxToRem(-8)};
  &:after {
    content: "";
    height: 0;
    position: absolute;
    width: 0;
    z-index: ${zIndexes.siteNavDropdown};
    border-bottom: ${pxToRem(9)} solid ${palette.uiNavLinkHighlighted};
    border-left: ${pxToRem(10)} solid transparent;
    border-right: ${pxToRem(10)} solid transparent;
    bottom: ${pxToRem(1)};
    display: none;
    left: ${pxToRem(16)};
  }
  &.${toggleableToggledClassname}:after {
    display: block;
  }
  &.has-league--false:after {
    left: 40%;
  }
  @media (max-width: ${pxToRem(layout.useMobileNavW)}) {
    display: none;
  }
`
export const SpoeDropdownToggler = styled.button`
  display: flex;
  flex-flow: row nowrap;
  padding: ${pxToRem(8)};
`
export const SpoeDropdownTogglerAvatar = styled.img`
  border-radius: 50%;
  border: ${pxToRem(2)} solid ${palette.gray6};
  background-color: ${palette.gray4};
  flex: 0 0 auto;
  min-width: ${pxToRem(36)};
  height: ${pxToRem(36)};
`
export const SpoeDropdownTogglerCurrentLeagueInfo = styled.div`
  flex: 1;
  font-size: ${pxToRem(15)};
  font-weight: ${fontWeight.bold};
  color: #fff;
  padding-left: ${pxToRem(18)};
  text-align: left;
  & > * {
    max-width: ${pxToRem(140)};
    min-width: ${pxToRem(120)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.1em;
  }
  & > *:last-child {
    font-size: ${pxToRem(13)};
  }
  @media (max-width: ${pxToRem(layout.shrinkSpoeW)}) {
    & {
      display: none;
    }
  }
`

export const NavSideItemAccount = styled(FlexRow)`
  color: rgba(255, 255, 255, 0.8);
  font-family: ${fontFamily.condensed};
  font-size: ${pxToRem(13)};
  letter-spacing: ${pxToRem(2)};
  text-transform: uppercase;
  transition: all 0.2s ease;
  border: ${pxToRem(1)} solid rgba(255, 255, 255, 0.2);
  border-radius: ${pxToRem(2)};
  margin: ${pxToRem(7)} auto;
  padding: ${pxToRem(10)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(9)};
  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
    }
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.15);
    color: #fff;
  }
`

export const MyTeamsComponentBottom = styled.div`
  text-transform: uppercase;
  background-color: ${palette.gray95};
  padding: ${pxToRem(10)} ${pxToRem(15)};
`
export const MyTeamsComponentBottomRow = styled(FlexRow)`
  font-size: ${pxToRem(12)};
  font-weight: ${fontWeight.semiBold};
  text-transform: uppercase;
  letter-spacing: ${pxToRem(0.5)};
  line-height: 1.5;
  color: #94a1a9;
  border-bottom: ${pxToRem(1)} solid #e6e7e9;
  margin-bottom: ${pxToRem(10)};
  padding-bottom: ${pxToRem(10)};
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

const ScrollWindow = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`

export const MyTeamsContainer = styled(ScrollWindow)`
  max-height: calc(100vh - ${pxToRem(250)} - ${skyboxH});
`
export const MyTeamsSport = styled.div`
  color: #000;
  background-color: #fff;
`
export const MyTeamsSportTitle = styled.div`
  color: #232323;
  font-weight: ${fontWeight.bold};
  font-size: ${pxToRem(12)};
  letter-spacing: ${pxToRem(0.2)};
  background-color: ${palette.gray95};
  padding: ${pxToRem(9)} ${pxToRem(14)};
  text-transform: uppercase;
`

const ARow = styled(FlexRow)`
  background-color: rgba(0, 0, 0, 0);
  & .row-info {
    transition: all 0.2s ease-in-out;
  }
  @media (hover: hover) {
    :hover:not(:active) {
      background-color: rgba(0, 0, 0, 0.02);
      & .row-info {
        transform: translateX(${pxToRem(7)});
      }
    }
  }
  :active {
    background-color: rgba(0, 0, 0, 0.06);
    & .row-info {
      transform: translateX(${pxToRem(5)});
    }
  }
`

export const MyTeamsRow = styled(ARow)`
  padding: ${pxToRem(10)} ${pxToRem(15)};
  max-width: ${pxToRem(navSideDropdownW)};
  border-bottom: ${pxToRem(1)} solid #e6e7e9;
`
export const MyTeamsRowLogo = styled(FlexCol)`
  min-width: ${pxToRem(36)};
  height: ${pxToRem(36)};
  border-radius: ${pxToRem(50)};
  border: ${pxToRem(1)} solid #263a4c;
  background-color: rgba(0, 0, 0, 0.02);
`
export const MyTeamsRowInfo = styled(FlexColGrow)`
  padding-left: ${pxToRem(10)};
  display: block;
`
export const MyTeamsRowName = styled.p`
  font-size: ${pxToRem(14)};
  margin-bottom: ${pxToRem(4)};
  letter-spacing: ${pxToRem(0.2)};
  color: #232323;
  font-weight: ${fontWeight.bold};
`
export const MyTeamsRowLeague = styled.p`
  font-size: ${pxToRem(12)};
  font-weight: ${fontWeight.semiBold};
  letter-spacing: ${pxToRem(0.5)};
  color: #94a1a9;
`
