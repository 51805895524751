import { useQuery } from "@apollo/client"
import React, { useEffect } from "react"
import { emptyObject } from "../../../../../../common/misc-utils"
import { TPoolRouteProps } from "../../../../../../routes.d"
import { PoolDetailsQuery, PoolDetailsQueryVariables } from "../../../../../../__generated__/PoolDetailsQuery"
import LoadingView from "../../../../../components/LoadingView"
import { IPageLayoutProps } from "../../../../../shared/molecules/PageLayout/PageLayout"
import { isCurrentUserLoggedIn } from "../../../../../utils/data-utils"
import BracketPoolSettingsView from "../../../../PoolSetupPages/components/BracketPoolSettings/BracketPoolSettingsView"
import { TUpsertEntryMutation } from "../../../../PoolSetupPages/PoolSetupTypes"
import { POOL_DETAILS_QUERY } from "../../../../queries"
import InviteRow from "../../../components/InviteRow/InviteRow"
import { StandardPoolPageContainer } from "../../../components/PoolPage"
import { LimboPageWrap } from "./LimboPage.styles"
interface LimboPageProps extends TPoolRouteProps, IPageLayoutProps {
  title?: string
  redirectOnMount?: () => void
  mutation: TUpsertEntryMutation
  encryptedPassword: string | null
}

const LimboPage = (props: LimboPageProps) => {
  const { poolData, redirectOnMount, mutation } = props
  const { currentUser, currentUserPendingInvites = [], poolId, poolDetail, isCbsAppWebview } = poolData || emptyObject
  const invite = currentUserPendingInvites.find((x) => x.pool?.id === poolId)
  const isLoggedIn = isCurrentUserLoggedIn(currentUser)

  useEffect(() => {
    if (typeof redirectOnMount === "function") {
      redirectOnMount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, loading } = useQuery<PoolDetailsQuery, PoolDetailsQueryVariables>(POOL_DETAILS_QUERY, {
    skip: !poolId,
    variables: { poolId },
  })
  const pool = data?.pool

  return (
    <StandardPoolPageContainer {...props} analyticsFeature="brackets" analyticsSubfeature={`join`} analyticsTitle={`Join Pool`}>
      {loading && <LoadingView />}
      {pool && (
        <LimboPageWrap>
          <InviteRow pool={pool} mode="join" invite={invite} mutation={mutation} isLoggedIn={isLoggedIn} isCbsAppWebview={isCbsAppWebview} />
          {poolDetail && (
            <div className="pool-settings">
              <span className="pool-settings-title">Pool Settings</span>
              <BracketPoolSettingsView pool={pool} variant="page" isLimbo />
            </div>
          )}
        </LimboPageWrap>
      )}
    </StandardPoolPageContainer>
  )
}

export default LimboPage
