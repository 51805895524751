import React, { FC, ReactNode, useCallback } from "react"
import styled from "styled-components"
import ClassicTooltip from "@cbs-sports/sports-shared-client/build/cjs/components/ClassicTooltip"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { pxToRem, buildClassNames as cx, palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import SortSVG from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Sort"
import { ColumnDataType, ColumnAlignType } from "./StandingsTable.common"

interface IStandingsTableHeaderRootProps {
  size?: number
  align?: ColumnAlignType
}

const StandingsTableHeaderRoot = styled.div<IStandingsTableHeaderRootProps>`
  grid-column-end: span ${({ size }) => size ?? 1};
  text-align: ${({ align }) => align ?? "left"};
  font-family: ${theme.fonts.family.condensed};
  font-weight: ${theme.fonts.weights.semireg};
  color: ${theme.colors.overLight.white50};
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 1.25px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &:first-of-type {
    padding-left: 1.5rem;
  }

  &:last-of-type {
    padding-right: 1.5rem;
  }

  .header__content {
    &.header__content--interactive {
      cursor: pointer;
    }

    &:not(.header__content--interactive) {
      * {
        cursor: default;
      }
    }

    * {
      outline: none;
    }

    .label__wrapper,
    .tooltip__wrapper {
      padding: 0.5rem 0;
    }

    .column__sort-indicator {
      display: inline-block;
      max-width: ${pxToRem(6)};
      color: ${theme.colors.overLight.white50};
    }

    &.header__content--interactive:hover {
      color: ${palette.lightBlue3};
    }
  }
  &.champion-column {
    display: flex;
    justify-content: flex-end;
    margin-right: ${pxToRem(10)};
  }
`

const TooltipBody = styled.div`
  border-radius: ${pxToRem(4)};
  background: ${theme.colors.overDark.darkGray2};
  padding: 0.25rem 0.5rem;
  color: ${theme.colors.overLight.white};
  font-size: ${pxToRem(12)};
  line-height: 1rem;
`

const renderBody = (body: ReactNode) => <TooltipBody>{body}</TooltipBody>

interface IProps {
  columnData: ColumnDataType
  onSort?: (col: string) => void
}

const StandingsTableHeader: FC<IProps> = ({
  columnData: { key, label, align, size, description, descriptionTooltipProps, sortable = false, customClass = "" },
  onSort,
}) => {
  const content = (
    <>
      {label}
      {sortable ? (
        <div className="column__sort-indicator">
          <SortSVG />
        </div>
      ) : null}
    </>
  )

  const handleClick = useCallback(() => {
    if (sortable) {
      onSort?.(key)
    }
  }, [sortable, onSort, key])

  return (
    <StandingsTableHeaderRoot size={size} align={align} onClick={handleClick} className={customClass}>
      <div className={cx({ header__content: true, "header__content--interactive": sortable })}>
        {!!description ? (
          <ClassicTooltip content={description} renderBody={renderBody} {...descriptionTooltipProps}>
            {content}
          </ClassicTooltip>
        ) : (
          <div className="label__wrapper" data-cy="standing-header">
            {content}
          </div>
        )}
      </div>
    </StandingsTableHeaderRoot>
  )
}

export default StandingsTableHeader
