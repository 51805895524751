import React, { FC } from "react"
import styled from "styled-components"
import { buildClassNames as cx, palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import Typography from "../../../../../shared/atoms/Typography"
import { BracketStatusType } from "./StandingsTable.common"

const BracketStatusRoot = styled(Typography)`
  &.status--coming-soon {
    color: ${palette.lightBlue3};
  }

  &.status--complete {
    color: ${palette.darkGreen};
  }

  &.status--incomplete {
    color: ${palette.darkRed};
  }
`

interface IProps {
  status: BracketStatusType
  picksCount: number
  maxPicksCount: number
  needsToMakePicks: boolean
  detailedMessage?: boolean
}

const BracketStatus: FC<IProps> = ({ needsToMakePicks, maxPicksCount, picksCount, status, detailedMessage = false }) => {
  if (status === "COMING_SOON") {
    return (
      <BracketStatusRoot className="status--coming-soon" weight="BOLD" spacing="COMPRESSED">
        Coming Soon
      </BracketStatusRoot>
    )
  } else if (status === "PICKABLE") {
    const isComplete = !needsToMakePicks
    const arePicksComplete = picksCount >= maxPicksCount

    const message = isComplete
      ? "Complete"
      : !detailedMessage
      ? "Incomplete"
      : !arePicksComplete
      ? `${picksCount}/${maxPicksCount} Picks Made`
      : "Tiebreaker Missing"

    return (
      <BracketStatusRoot className={cx({ "status--complete": isComplete, "status--incomplete": !isComplete })} weight="BOLD" spacing="COMPRESSED">
        {message}
      </BracketStatusRoot>
    )
  }
  return null
}

export default BracketStatus
