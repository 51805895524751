export const TAG_PROPERTIES = {
  CHARSET: "charset",
  CSS_TEXT: "cssText",
  HREF: "href",
  HTTPEQUIV: "http-equiv",
  INNER_HTML: "innerHTML",
  ITEM_PROP: "itemprop",
  NAME: "name",
  PROPERTY: "property",
  REL: "rel",
  SRC: "src",
}

export const ATTRIBUTE_NAMES = {
  BODY: "bodyAttributes",
  HTML: "htmlAttributes",
  TITLE: "titleAttributes",
}

export const TAG_NAMES = {
  BASE: "base",
  BODY: "body",
  HEAD: "head",
  HTML: "html",
  LINK: "link",
  META: "meta",
  NOSCRIPT: "noscript",
  SCRIPT: "script",
  STYLE: "style",
  TITLE: "title",
  FRAGMENT: "Symbol(react.fragment)",
}

export const VALID_TAG_NAMES = Object.keys(TAG_NAMES).map((name) => TAG_NAMES[name])

export const REACT_TAG_MAP = {
  accesskey: "accessKey",
  charset: "charSet",
  class: "className",
  contenteditable: "contentEditable",
  contextmenu: "contextMenu",
  "http-equiv": "httpEquiv",
  itemprop: "itemProp",
  tabindex: "tabIndex",
}

export const HTML_TAG_MAP = Object.keys(REACT_TAG_MAP).reduce((obj, key) => {
  // eslint-disable-next-line no-param-reassign
  obj[REACT_TAG_MAP[key]] = key
  return obj
}, {})

export const HELMET_ATTRIBUTE = "data-rh"
