import { useMutation } from "@apollo/client"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { MenuBase } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import Popover, { ANCHORS, OriginType } from "@cbs-sports/sports-shared-client/build/cjs/components/Popover"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"

import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { UPDATE_NEW_SETTING_POPOVER_VIEWED_STATUS_MUTATION } from "../../queries"
import {
  UpdateNewSettingPopoverViewedStatus,
  UpdateNewSettingPopoverViewedStatusVariables,
} from "../../../../__generated__/UpdateNewSettingPopoverViewedStatus"
import Analytics from "../../../utils/analytics"

const defaultPaperProps = {
  style: {
    paddingTop: "0.25rem",
    paddingBottom: "0.5rem",
    minWidth: "10rem",
  },
}
interface INewSettingPopover extends React.HTMLAttributes<HTMLUListElement> {
  anchorEl: React.ReactNode
  origin?: OriginType
  anchor?: ANCHORS
  open: boolean
  onClose?: (event: React.SyntheticEvent) => void
  closeOnScape?: boolean
  closeOnBkgClick?: boolean
  zIndex?: number
  ctaUrl: string
  poolId: string
  title: string
  bodyText: string
  variant: "manager" | "participant"
}

const Container = styled.div`
  padding: 0.5rem 0.75rem 0.25rem 0.75rem;

  & .new-tag {
    color: ${bracketTheme.colors.overLight.blue2};
    font-weight: ${bracketTheme.fonts.weights.bold};
    text-transform: uppercase;
    margin-right: 0.25rem;
  }
  & .title {
    font-weight: ${bracketTheme.fonts.weights.bold};
  }
  & .body {
    font-size: 0.75rem;
    font-weight: ${bracketTheme.fonts.weights.semireg};
    color: ${bracketTheme.colors.overLight.white50};
    margin: 0.25rem 0;
  }

  & > .cta-wrapper {
    width: 100%;
    display: flex;
    margin-top: 0.5rem;
    & > :first-child {
      margin-right: 0.75rem;
    }
  }

  & > .variant--participant {
    justify-content: end;
  }
`

const Content = ({ title, body, onClose, ctaUrl, variant }) => {
  const onPrimaryActionTrackClick = () => Analytics.trackAction("pool home", "mgr open invites modal", "review settings")
  return (
    <MenuBase>
      <Container>
        <div>
          <span className="new-tag">New!</span>
          <span className="title">{title}</span>
        </div>
        <div className="body">{body}</div>
        <div className={`cta-wrapper variant--${variant}`}>
          {variant === "manager" ? (
            <>
              <Button variant="secondary" onClick={onClose}>
                Close
              </Button>
              <StyledButton as={Link} to={ctaUrl} className="variant--primary cta" onClick={onPrimaryActionTrackClick}>
                Review Settings
              </StyledButton>
            </>
          ) : (
            <Button variant="primary" onClick={onClose}>
              Got it
            </Button>
          )}
        </div>
      </Container>
    </MenuBase>
  )
}

const NewSettingPopover = ({
  anchorEl,
  open,
  anchor,
  onClose,
  origin,
  closeOnScape = true,
  closeOnBkgClick = true,
  zIndex,
  ctaUrl,
  poolId,
  title,
  bodyText,
  variant,
}: INewSettingPopover) => {
  const [updateNewSettingPopoverViewedStatus] = useMutation<UpdateNewSettingPopoverViewedStatus, UpdateNewSettingPopoverViewedStatusVariables>(
    UPDATE_NEW_SETTING_POPOVER_VIEWED_STATUS_MUTATION,
  )
  const handleClose = (event) => {
    if (typeof onClose === "function") {
      onClose(event)
    }
  }
  useEffect(() => {
    if (open) {
      // trigger mutation to marke popover as seen
      updateNewSettingPopoverViewedStatus({ variables: { poolId } })
    }
  }, [open, poolId, updateNewSettingPopoverViewedStatus])
  return (
    <Popover
      anchor={anchor}
      bodyProps={variant === "manager" ? defaultPaperProps : { style: { marginTop: "-6px", width: "18.75rem", ...defaultPaperProps.style } }}
      showAnchor={true}
      active={open}
      target={anchorEl}
      origin={origin}
      content={<Content title={title} body={bodyText} onClose={onClose} ctaUrl={ctaUrl} variant={variant} />}
      padded={variant === "manager" ? true : false}
      onClose={handleClose}
      onEscapeKeydown={closeOnScape ? handleClose : undefined}
      onBackgroundClick={closeOnBkgClick ? handleClose : undefined}
      variant="menu"
      zIndex={zIndex}
      closeOnScroll={false}
    />
  )
}

export default React.memo(NewSettingPopover)
