import { SeasonEnumType } from "../__generated__/globalTypes"
import { IGameRootRouteMatch } from "../routes.d"
import constants from "./constants"
import { filterNulls } from "./misc-utils"
import Og, { IGameOg } from "./og"
import themes, { ITheme } from "./themes"
import { isMarchMadnessMatcher, isOPMMatcher } from "./common-utils-helpers"
// import invariant from "invariant"
// import { makeFullUrl } from '../server/aws-file-uploads';
interface IEntryWithUrl {
  url: string
}
export type IGameUrlParams = IGameRootRouteMatch
export const poolJoinRoute = "join"
export const poolStandingsRoutesRegexp = /\/standings|\/all-picks/
export const ungatedPoolSubroutesRegexp = /^(picks|join|settings|groups)$/
export const roadblockedNativeAppSubroutesRegexp = /^(picks)$/
export const poolCreateRegexp = /\/create-pool/
export const adminMatcher = /^\/admin\//

const emptyString = ""
const nativeAppExternalPrefix = "ext://"
const hasNativeAppDeeplink = /app\:\/\//
export const hasProtocol = /\:\/\//
const httpsMatcher = /^https?\:\/\//
const nativeAppExternalMatcher = /^ext\:\/\//
const https2Matcher = /^https?\/\//
const httpMatcher = /^http\/\//
const doubleSlashMatcher = /^\/\//
export const isNumbersOnlyRegexp = /^\d+$/
export const getStringParam = (key: string, searchString: string) => {
  // console.debug("(new URLSearchParams(searchString)).get(key)")
  return new URLSearchParams(searchString).get(key)
}
export const getParam = (key: string, searchString: string): string | number | null => {
  // console.debug("(new URLSearchParams(searchString)).get(key)")
  const value = getStringParam(key, searchString)
  if (value && typeof value === "string" && isNumbersOnlyRegexp.test(value)) {
    return parseInt(value)
  }
  return value
}

export function convertSportArenaToApiSportArena(sportArena: string) {
  return sportArena.replace("college-basketball", "basketball").replace("college-", "")
}

export function findOgByMetaPath(pathname: string): IGameOg | undefined {
  // NOTE qac: we want to add the "/" so that regular season doesnt conflict with post (which adds -post)... dumb on my part in design
  const options = Og.filter((ogs) => ogs.metaPathPrefix && pathname.includes(ogs.metaPathPrefix + "/"))
  // NOTE qac: its ok for us to have multiple results, we CAN share assets (ncaa tourney)
  return (options.length && options[0]) || undefined
}

export function findOgBy(gameInstanceUid: string, seasonType?: string): IGameOg | null {
  if (isOPMMatcher.test(gameInstanceUid)) {
    return Og.find((ogs) => ogs.uidPrefix && gameInstanceUid.includes(ogs.uidPrefix)) || null
  }
  const options = Og.filter((ogs) => ogs.uidPrefix && gameInstanceUid.includes(ogs.uidPrefix))
  // NOTE qac: pre almost always is "regular" type (mainly for testing)
  const values =
    (seasonType && options.find((ogs) => ogs.seasonType === seasonType.replace("pre", "regular"))) || (options.length && options[0]) || null
  return values
}

export const getLayoutId = (params: IGameUrlParams, seasonType?: string): string =>
  constants.layoutIds[[params.sportType, params.subsection, params.gameType, seasonType].filter(filterNulls).join("-")] ||
  constants.layoutIds[[params.sportType, params.subsection, params.gameType].filter(filterNulls).join("-")] ||
  constants.layoutIds.default

export function getThemeByOg(gameOg: IGameOg): ITheme {
  const parts = gameOg.frontendPathPrefix.split("/")
  const params = {
    sportType: emptyString,
    gameType: undefined,
    subsection: undefined,
  } as IGameRootRouteMatch
  params.sportType = parts.shift() || emptyString
  if (parts.length > 1) {
    params.subsection = parts.shift()
  }
  params.gameType = parts.shift()
  const layoutId = getLayoutId(params, gameOg.seasonType || undefined)
  // console.dir(params);
  return themes[layoutId]
}

export const parseParams = (searchString: string) => {
  const params = new URLSearchParams(searchString)
  return params
}

export const setParam = (searchString: string, key: string, value?: string | number | null) => {
  const params = parseParams(searchString)
  if (value) {
    params.set(key, value.toString())
  } else {
    params.delete(key)
  }
  return params.toString()
}

export function addTagToUrl(url: string, tagValue?: string, isFtag = false) {
  if (tagValue) {
    const parts = url.split("?")
    if (parts.length < 2) {
      parts.push("")
    }
    let search = parts[1]!
    search = setParam(search, isFtag ? "ftag" : "ttag", tagValue)
    return parts.map((val, i) => (i === 1 ? search : val)).join("?")
  } else {
    return url
  }
}

export function hasOurDomain(hrefOrPath) {
  return hrefOrPath.includes(constants.DOMAIN)
}
export function isExternalHref(hrefOrPath) {
  return (hasProtocol.test(hrefOrPath) && !hasOurDomain(hrefOrPath)) || nativeAppExternalMatcher.test(hrefOrPath)
}

export const processHref = (hrefOrPath: string, isCbsAppWebview = false) => {
  let fullUrl = hrefOrPath
  if (hasNativeAppDeeplink.test(hrefOrPath)) {
    return hrefOrPath
  }
  if (!httpsMatcher.test(hrefOrPath) && !nativeAppExternalMatcher.test(hrefOrPath)) {
    fullUrl = `https://www.cbssports.com${hrefOrPath}`
  }
  if (isCbsAppWebview && !nativeAppExternalMatcher.test(fullUrl)) {
    fullUrl = nativeAppExternalPrefix + fullUrl
  }
  // console.log(`processHref: ${hrefOrPath} --> ${fullUrl} (${isCbsAppWebview})`)
  return fullUrl
}
const feIdRegexp = new RegExp("([a-z0-9=]+).*")
const feIdRegexpSol = "$1"
export function extractFeId(id?: string | null) {
  if (id) {
    // NOTE qac: we also are getting ids from bad spoe spots on cbs like: ivxhi4tzhiytcmzs/ownerlogo?size=36x36 - lets remove this:
    // https://sentry.io/share/issue/50ac41cb60e6432aae8b6fb40d7795cd/
    // another: ivxhi4tzhi2tmojygmyq===%3'[0]
    // https://sentry.io/share/issue/e56940f38c234c1bba6aa9481069e267/
    return id.replace(feIdRegexp, feIdRegexpSol)
  }
  return null
}
export const urlFromSpoeAttr = (attr: string) =>
  `https://${attr.replace(httpsMatcher, emptyString).replace(httpMatcher, emptyString).replace(doubleSlashMatcher, emptyString)}`
export const urlToSpoeAttr = (attr: string) => attr.replace(https2Matcher, emptyString)
export const withoutDomain = (url: string): string => {
  const parts = url.split(constants.DOMAIN)
  if (parts.length > 1) {
    return parts[parts.length - 1]
  } else {
    // NOTE qac: this is probably because we are proxying to dev or something...
    const parts2 = url.split(".com")
    return parts2[parts2.length - 1]
  }
}
export const entryPicksUrl = ({ url }: IEntryWithUrl, order?: number) => `${url}${order ? `&order=${order}` : ""}`

export const spoeImgFor = (gameInstanceUid: string, seasonType: SeasonEnumType) => {
  if (isMarchMadnessMatcher.test(gameInstanceUid)) {
    return `https://freebracketchallenge-1.mayhem.cbssports.com/ownerlogo?size=36x36`
  } else {
    throw new Error(`unhandled spoe for ${gameInstanceUid}`)
  }
}

export const objToQueryParams = (obj: any, startingParams: boolean) => {
  const query = Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join("&")
  return query ? (startingParams ? `?${query}` : `&${query}`) : ""
}
