import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { FlexCols, FlexRow } from "../../../components/FlexComponents"
import { palette, pxToRem } from "../../../utils/style-utils"

export const DropdownInner = styled(FlexCols)`
  padding: ${pxToRem(25)} ${pxToRem(25)} 0;
  align-items: flex-start;
`
export const StyledNavDropdownItem = styled(FlexRow)`
  color: ${palette.gray5};
  font-size: ${pxToRem(14)};
  font-weight: 600;
  letter-spacing: ${pxToRem(1.5)};
  text-transform: uppercase;
  margin-bottom: ${pxToRem(15)};
  @media (min-width: ${pxToRem(1190)}) {
    font-size: ${pxToRem(16)};
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      color: ${palette.blueActive};
    }
  }
  &:active {
    color: ${palette.blueActive};
  }
`

export const DropdownCta = styled(FlexRow)`
  text-transform: uppercase;
  transition: background-color 0.2s ease;
  letter-spacing: ${pxToRem(1)};
  background-color: #15b842;
  color: #fff;
  font-size: ${pxToRem(12)};
  font-weight: 600;
  line-height: 1;
  padding: ${pxToRem(12)} ${pxToRem(50)};
  margin: ${pxToRem(5)};
  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: #0d7329;
    }
  }
  &:active {
    background-color: #0d7329;
  }
`
export const DropdownRow = styled(FlexRow)`
  margin-bottom: ${pxToRem(15)};
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: ${pxToRem(1)} solid #e6e7e9;
`

export const StyledGameItem = styled(FlexCols)`
  margin-bottom: ${pxToRem(20)};
  max-width: 48%;
  color: #94a1a9;
  & > * {
    width: 100%;
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      color: #147cd1;
      & > div {
        background-color: #0d7329;
      }
    }
  }
  &:active {
    color: #147cd1;
    & > div {
      background-color: #0d7329;
    }
  }
`
export const StyledGameItemImg = styled.img`
  width: ${pxToRem(180)};
`
export const StyledGameItemBtn = styled(FlexRow)`
  background-color: #15b842;
  color: #fff;
  font-family: ${fontFamily.base};
  font-weight: 600;
  letter-spacing: ${pxToRem(1)};
  padding: ${pxToRem(12)};
  text-transform: uppercase;
  transition: background-color 0.2s ease;
`
export const StyledGameItemText = styled.div`
  margin-top: ${pxToRem(6)};
  font-size: ${pxToRem(12)};
  font-weight: 400;
  letter-spacing: ${pxToRem(1)};
  line-height: 1.2em;
  white-space: normal;
  text-transform: uppercase;
  text-align: center;
  background-color: transparent !important;
  transition: color 0.2s ease;
`
export const NavDropdownItemAlt = styled(FlexRow)`
  width: 100%;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  letter-spacing: ${pxToRem(1)};
  line-height: 1;
  text-transform: uppercase;
  color: #15b842;
  margin-bottom: ${pxToRem(20)};
  text-align: center;
  @media (hover: hover) {
    &:hover:not(:active) {
      color: #0d7329;
    }
  }
  &:active {
    color: #0d7329;
  }
`
