import { canUseDom, getScrollContainerEl } from "../utils/misc-utils"

export function scrollToTop() {
  canUseDom && getScrollContainerEl()?.scroll({ top: 0, left: 0, behavior: "instant" as any })
}

export function scrollToTopAfterTimeout(ms = 500) {
  setTimeout(() => {
    scrollToTop()
  }, ms)
}
