import * as React from "react"

const Globe = ({ color = "currentColor" }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        fill={color}
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0ZM2 10c0-.61.08-1.21.21-1.78L6.99 13v1c0 1.1.9 2 2 2v1.93C5.06 17.43 2 14.07 2 10Zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6V8h2c.55 0 1-.45 1-1V5h2c1.1 0 2-.9 2-2v-.41C15.92 3.77 18 6.65 18 10c0 2.08-.81 3.98-2.11 5.4Z"
      />
    </svg>
  )
}

export default Globe
