import React from "react"
import { MultipleEntriesPoolInviteMailCenterModal } from "../containers/MultipleEntriesArea/MultipleEntriesPoolInviteMailCenter"
import { TPoolRouteProps } from "../../../../routes.d"
import { getGameText } from "../../../../common/game-text"

interface IProps extends TPoolRouteProps {
  isOpen: boolean
  onClose: () => void
  onInviteSent: () => void
}
const POOL_NAME_REPLACE = "POOL_NAME_REPLACE"
const InviteAllPreviousPoolMembersModal: React.FC<IProps> = (props) => {
  const { poolData } = props
  const { poolDetail, segmentsForArea, productSeason, gameInstanceForArea, gameInstancesForArea } = poolData
  const poolName = poolDetail?.name || ""

  const segmentToUse =
    segmentsForArea &&
    productSeason &&
    segmentsForArea.find((seg) => (productSeason ? seg.season.id === productSeason.id : !!seg.season.challengePoolSlug))
  if (!segmentToUse) {
    return null
  }
  const gameInstance = gameInstanceForArea || gameInstancesForArea.find((gi) => gi.id === segmentToUse.season.gameInstance.id)
  if (!gameInstance) {
    return null
  }

  const gameInstanceUid = gameInstance.uid
  const { inviteBackModalMessage } = getGameText(gameInstanceUid, segmentToUse)
  const defaultMessage = inviteBackModalMessage?.replace(POOL_NAME_REPLACE, poolName ? `, ${poolName},` : "") || ""

  return (
    <MultipleEntriesPoolInviteMailCenterModal
      {...props}
      defaultMessage={defaultMessage}
      // defaultSubject={`${formattedName} Bracket Pool is Back and You're Invited!`}
      onNotificationSent={props.onInviteSent}
      variant={"welcome-back-invitation"}
    />
  )
}

export default React.memo(InviteAllPreviousPoolMembersModal)
