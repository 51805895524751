import constants from "../../common/constants"
import {
  EmailSenderType,
  encryptedPoolPasswordParamKey,
  getPoolInvitationUrl,
  invitationIdSearchParamKey,
  invitedBySearchParamKey,
  invitedJoinKeySearchParamKey,
  invitedViaSearchParamKey,
} from "../../common/email-invites"
import { getLayoutId, getParam, getStringParam, setParam, withoutDomain, poolJoinRoute } from "../../common/url-utils"
import { canUseDom } from "./misc-utils"
import { matchPath } from "react-router"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { buildTtag } from "../../common/game-text"

// re-export
export { getStringParam, getParam, setParam, withoutDomain, getLayoutId }

export function buildJoinUrl({ poolRoot, search }) {
  // const currentSearch = search ? `?${search.replace("?", "")}` : "";
  const searchParts = (search || "")
    .replace("?", "")
    .split("&")
    .filter((s: string) => !!s)
  const returnUrlSearch = searchParts.length ? `?${searchParts.join("&")}` : ""
  return (poolRoot && `${poolRoot}/${poolJoinRoute}${returnUrlSearch}`) || ""
}
const formMatcher = /^\/.*\/create-pool\/pool\/(.*)\/step\/(.*)/

export function extractPoolRoutePart(poolRoot: string, pathname: string) {
  const parts = pathname.split(poolRoot).filter(filterNulls)
  if (parts.length) {
    const rest = parts[0]
    const pathParts = rest.split("/").filter(filterNulls)
    const firstOne = pathParts.shift() || "picks"
    return firstOne
  } else {
    return "picks"
  }
  // const matcher = new RegExp(`/${poolRoot}/(\w)`)
  // const matches = matcher.exec(pathname)
}

const createPoolFinishedSetupSearchKey = "finished-setup"
export const getCreatePoolParams = (pathname: string, search: string) => {
  const matchRegex = formMatcher.exec(pathname)
  const poolId = matchRegex && matchRegex[1]
  const formStep = matchRegex && matchRegex[2]
  const hasFinalizedSetupFlag = getParam(createPoolFinishedSetupSearchKey, search)
  return {
    poolId,
    formStep,
    hasFinalizedSetupFlag,
    createPoolFinishedSetupSearchKey,
  }
}

export const getAuthSearch = (returnUrl: string, productAbbrev?: string, masterProductId?: number, isCbsAppWebview?: boolean) => {
  const parts = [`returnUrl=${encodeURIComponent(returnUrl)}`]
  if (productAbbrev) {
    parts.push(`productAbbrev=${productAbbrev}`)
  }
  if (masterProductId) {
    parts.push(`masterProductId=${masterProductId}`)
  }
  if (isCbsAppWebview) {
    parts.push(`isNativeApp=true`)
  }
  return parts.join("&")
}

export const getInvitationInfo = (search: string) => {
  const encryptedPassword = getStringParam(encryptedPoolPasswordParamKey, search)
  return {
    invitedByEntryId: getParam(invitedBySearchParamKey, search),
    invitedVia: getParam(invitedViaSearchParamKey, search),
    joinKey: getParam(invitedJoinKeySearchParamKey, search),
    invitationId: getParam(invitationIdSearchParamKey, search),
    encryptedPassword,
  }
}

export const getInvitationUrl = (args) => {
  const { pool, medium, entryId, hasManagerRole } = args
  const { season, url, poolPassword, inviteUrl } = pool ?? emptyObject
  const { year, productAbbrev } = season
  const senderRole: EmailSenderType | undefined = typeof hasManagerRole === "undefined" ? undefined : hasManagerRole ? "manager" : "participant"
  const ttagSegmentPrefix = senderRole === "participant" ? "NM" : ""
  const ttag = buildTtag(productAbbrev, year, "FF", `${ttagSegmentPrefix}${medium}SetupInvite`)
  return getPoolInvitationUrl({ url: inviteUrl || url, password: poolPassword }, entryId, medium, ttag, poolPassword, undefined, senderRole)
}

const fallbackCopyToClipboard = (value: string) => {
  if (canUseDom && document && navigator) {
    const isIos = navigator.userAgent.match(/ipad|iphone/i)
    const el = document.createElement("textarea") // Create a <textarea> element
    el.value = value // Set its value to the string that you want copied
    el.setAttribute("readonly", "") // Make it readonly to be tamper-proof
    el.style.position = "absolute"
    el.style.left = "-9999px" // Move outside the screen to make it invisible
    document.body.appendChild(el) // Append the <textarea> element to the HTML document
    const prevSelection = document.getSelection()
    const prevSelected =
      prevSelection && prevSelection.rangeCount > 0 // Check if there is any content selected previously
        ? prevSelection.getRangeAt(0) // Store selection if found
        : false // Mark as false to know no selection existed before
    if (isIos) {
      const range = document.createRange()
      range.selectNodeContents(el)
      const selection = window.getSelection()
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(range)
      }
      el.setSelectionRange(0, 999999)
    } else {
      el.select() // Select the <textarea> content
    }
    document.execCommand("copy") // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el) // Remove the <textarea> element
    if (prevSelected) {
      // If a selection existed before copying
      const newSelection = document.getSelection()
      if (newSelection) {
        newSelection.removeAllRanges() // Unselect everything on the HTML document
        newSelection.addRange(prevSelected) // Restore the original selection
      }
    }
  }
}

export const copyToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).catch((err) => {
    if (!constants.IS_PROD) {
      console.error("Could not copy text to clipboard: ", err)
    }
    fallbackCopyToClipboard(text)
  })
}

export const filterNulls = <T>(i?: T | null): i is T => !!i

export const urlMatch = (pathname: string, to: string): boolean => {
  const match = matchPath(to, {
    path: pathname,
    exact: true,
    strict: false,
  })
  return match?.isExact || false
}
