import styled from "styled-components"
import {
  palette,
  fontFamily,
  fontSizes,
  gridBreakPoints,
  fontWeight,
  pxToRem,
  fontWeights,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const { queries, breakpoints } = gridBreakPoints

export const Spacer = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${palette.gray90};
  margin: 0 ${pxToRem(30)};
`
export const BigStyledAddBracketComponent = styled.div`
  background-color: ${palette.white};
  background-image: url(/static/sport-assets/basketball/pool-setup-bg.svg);
  background-repeat: repeat-x;
  display: flex;
  flex-direction: column;
  width: 100%;

  & div.logosContainer {
    align-items: center;
    display: flex;
    flex-flow: row;
    height: ${pxToRem(100)};
    justify-content: center;
    margin-bottom: 20px;
  }

  & div.finalFourLogo {
    img {
      height: ${pxToRem(100)};
      width: ${pxToRem(130)};
    }
  }

  & div.sponsorLogoCont {
    img {
      height: ${pxToRem(100)};
      width: ${pxToRem(180)};
    }
  }

  & div.promoText1 {
    color: ${palette.gray20};
    font-size: ${pxToRem(18)};
    font-weight: ${fontWeights.xxbold};
    letter-spacing: -0.1px;
    text-align: center;
    margin-bottom: 4px;
  }

  & div.promoText2 {
    color: ${palette.gray40};
    font-size: ${pxToRem(14)};
    letter-spacing: -0.1px;
    line-height: 20px;
    text-align: center;
    padding: 0 2rem;
  }

  & .cta {
    margin: 1rem 0 0 0;
  }

  &.use-as-component--true {
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid ${palette.gray90};
  }

  ${queries.sm} {
    flex-direction: column;
    align-items: center;
    div {
      padding: 5px 0;
    }
    &.use-as-component--true {
      padding: 1.5rem;
      border-radius: 0.5rem;
    }
    & div.promoText1 {
      font-size: ${pxToRem(32)};
      margin-bottom: 0;
    }
    & div.promotText2 {
      font-size: ${pxToRem(20)};
      line-height: 28px;
      padding: 0;
    }
    & .cta {
      margin: 10px 0;
    }
  }
`
export const StyledAddBracketComponent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
  width: 100%;

  &.use-as-component--true {
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid ${palette.gray90};
  }

  & > div.information {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 1rem;
    color: ${palette.gray40};

    & > div.icon-container {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      margin-right: 1rem;

      & > svg {
        color: ${palette.lightBlue3};
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    & > div.icon-container--bpc,
    & > div.icon-container--wbpc {
      & > svg {
        height: auto;
        width: auto;
      }
    }

    & > .content-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      & > .title {
        font-style: normal;
        font-size: ${fontSizes.regular};
        font-family: ${fontFamily.base};
        font-weight: ${fontWeight.bold};
        line-height: 1rem;
        letter-spacing: -0.1px;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${palette.gray20};
        margin-bottom: 0.25rem;
      }
      & > .description {
        font-style: normal;
        font-weight: normal;
        font-size: ${fontSizes.regular};
        line-height: 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: -0.1px;
      }
      & > .description--bpc,
      & > .description--wbpc {
        padding-right: 1rem;
      }
    }
  }
  & > div.information.no-margin {
    margin-bottom: 0;
  }
  & > .button-container--bpc,
  & > .button-container--wbpc {
    ${queries.sm} {
      top: ${pxToRem(13)};
    }
    top: auto;
    position: relative;
  }

  ${queries.sm} {
    flex-direction: row;
    align-items: center;
    &.use-as-component--true {
      padding: 1.5rem;
      border-radius: 0.5rem;
    }

    & > div.information {
      margin-bottom: 0;

      & > .content-wrap {
        & > .title {
          font-size: ${fontSizes.medium};
        }
        & > .description {
          font-size: ${fontSizes.medium};
        }
      }
    }

    & .cta {
      order: 2;
    }
  }

  @media (max-width: ${breakpoints.sm - 1}px) {
    &.variant--bpc,
    &.variant--wbpc {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      border-radius: 0;
      & > .information {
        flex-direction: row;
        display: flex;
        & svg {
          display: flex;
        }
      }
    }
  }
`

export const DisclaimerWrapper = styled.div`
  &.bpc,
  &.wbpc {
    ${queries.sm} {
      padding-top: 5px;
    }
    padding-top: 0;
    text-align: center;
  }
  display: flex;
  justify-content: center;
  font-size: ${fontSizes.small};
  color: ${palette.gray40};
  line-height: 0.75rem;
  margin-top: 1rem;
  & > span {
    line-height: 0.75rem;
    & > a {
      text-decoration: underline;
      &:hover {
        color: ${palette.lightBlue3};
      }
    }
  }

  ${queries.sm} {
    order: 1;
    flex-direction: column;
    text-align: right;
    font-size: 0.625rem;
    margin: 0 1rem;
  }
`
