import React, { FC, useCallback, useState, useContext } from "react"
import styled from "styled-components"
import { Formik, Form, Field, ErrorMessage } from "formik"
import GenericEntryModal, { IGenericEntryModalProps, ModalActions, noop } from "./GenericEntryModal"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import Input from "@cbs-sports/sports-shared-client/build/cjs/components/Form/Input"
import Select from "@cbs-sports/sports-shared-client/build/cjs/components/Select"
import { fontFamily, fontWeights, palette, pxToRem, buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import bracketTheme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { extractValidationError } from "../../../../components/Form"
import constants, { NCAAB_CHALLENGE_IMPRESSION_URL_SRC, NCAAW_CHALLENGE_IMPRESSION_URL_SRC } from "../../../../../common/constants"
import { useHistory, useRouteMatch } from "react-router-dom"
import NissanTMSvg from "../../../../components/icons/NissanTM"
import Checkbox from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import { buildDisclaimer, getRulesLink } from "../../../../../common/game-text"
import { emptyObject, NCAAB_S16_GAME_INSTANCE_UID, NCAAW_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import { IPoolData } from "../../../../../routes.d"
import { useDeviceType } from "../../../../Base/DeviceType"
import AnalyticScreen from "../../../../components/AnalyticsScreen"
import Analytics from "../../../../utils/analytics"
import OneByOneAd from "../OneByOneAd"
import {
  ANYTHING_BUT_NUMBERS_REGEX,
  DEFAULT_TITLE,
  HELPER_MESSAGE,
  NISSAN_VEHICLE_OPTIONS,
  REMOVE_INVALID_CHARS_REGEX,
  US_PHONE_NUMBER_REGEX,
} from "./Common/modalData"
import { IHaveSponsorData } from "./Common/modalTypes"
import { validateEntryName, validatePhoneNumber } from "./Common/modalValidations"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import WomensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/WomensBracket"
import MensBracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/MensBracket"

const ENTRY_NAME_TAKEN_MESSAGE = "Entry name is already in use. Please select a different name"

const Modal = styled.div<{ isMobile?: boolean }>`
  width: ${(props) => (props.isMobile ? "100%" : `calc(${pxToRem(488)} - ${pxToRem(80)})`)};
  display: flex;
  flex-direction: column;
  color: ${bracketTheme.colors.overLight.white20};

  .modal__input-helper {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.white50};
  }

  .modal__text--label {
    padding-left: 1rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.1px;
    color: ${palette.gray20};
    font-weight: ${fontWeights.bold};
    margin-bottom: 0.25rem;
  }

  .modal__error-message {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.1px;
    color: ${bracketTheme.colors.overLight.red};
  }

  & input.has-errors {
    border-color: ${bracketTheme.colors.overLight.red};
    & + label.input-label {
      border-color: ${bracketTheme.colors.overLight.red};
    }
  }

  .modal__wrapper--select {
    max-width: ${pxToRem(159)};
  }

  .modal__wrapper--input {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .modal__wrapper-title {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-size: ${(props) => (props.isMobile ? pxToRem(18) : "1.25rem")};
  }

  & > .news-and-rules {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background: ${palette.gray97};
    border: solid 1px ${palette.gray90};
    border-radius: 0.25rem;

    .modal__text--disclaimer {
      font-size: ${pxToRem(10)};
      line-height: ${pxToRem(14)};
      color: ${palette.gray40};
    }

    & > .news-wrap {
      .label-content {
        font-family: ${fontFamily.base};
        font-size: ${pxToRem(14)};
        font-style: normal;
        font-weight: ${fontWeights.regular};
        line-height: 1.25rem;
        letter-spacing: -0.1px;
        text-align: left;
        margin-left: ${pxToRem(28)};
        color: ${palette.gray50};
      }
    }
    & > .rules-wrap {
      font-size: ${pxToRem(10)};
      line-height: ${pxToRem(14)};
      color: ${palette.gray40};

      a {
        text-decoration: underline;

        &:hover,
        &:active {
          color: ${palette.lightBlue3};
        }
      }
    }
  }
`
const BpcUpsertEntryNameModalHeaderWrap = styled.div<{ isMobile?: boolean }>`
  margin-top: ${(props) => (props.isMobile ? "2rem" : "1.5rem")};
  margin-bottom: ${(props) => (props.isMobile ? "2rem" : "1.5rem")};

  & > .logos-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > .logo-content {
      height: 5rem;
      width: 5rem;
    }
    & > .logo-content.nissan-logo {
      height: 4rem;
    }

    & > .divider {
      height: 4rem;
      width: 1px;
      margin: 0 1.5rem;
      background-color: ${palette.gray90};
    }
  }
`

const ModalFooter = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  background: ${palette.white};
  width: ${(props) => (props.isMobile ? "100%" : `calc(${pxToRem(488)} - ${pxToRem(48)})`)};
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};

  .footer__disclaimer {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: -0.1px;
    color: ${palette.gray20};
    flex: 1;
    padding-right: 1.5rem;
    margin-top: ${(props) => (props.isMobile ? "0.5rem" : 0)};
    margin-bottom: ${(props) => (props.isMobile ? "1.5rem" : 0)};

    a {
      text-decoration: underline;

      &:hover,
      &:active {
        color: ${palette.lightBlue3};
      }
    }
  }

  ${ModalActions} {
    margin: 0;
    width: ${(props) => (props.isMobile ? "100%" : "auto")};
  }
`

const BpcDisclaimerWrapper = styled.div`
  font-size: ${pxToRem(10)};
  line-height: ${pxToRem(14)};
  color: ${palette.gray40};
`

const BpcUpsertEntryNameModalHeader = ({ isWomenBracket, isMobile }: { isWomenBracket?: boolean; isMobile?: boolean }) => {
  return (
    <BpcUpsertEntryNameModalHeaderWrap isMobile={isMobile}>
      <div className="logos-container">
        <div className="logo-content">{isWomenBracket ? <WomensBracketSvg /> : <MensBracketSvg />}</div>
        <div className="divider"></div>
        <div className="logo-content nissan-logo">
          <NissanTMSvg />
        </div>
      </div>
    </BpcUpsertEntryNameModalHeaderWrap>
  )
}

type TExistingEntryDetails = {
  id: string
  name: string
}

type UIOptions = {
  ctaCancel: string
}

interface IUpsertEntryNameModalProps extends IGenericEntryModalProps {
  close: (created?: boolean, entryUrl?: string, entryId?: string) => void
  mode?: "bpc" | "bpm"
  redirectToLobby?: boolean
  gameInstanceUid?: string
  isCommingSoon?: boolean
  isCreate?: boolean
  seasonType?: string
  productAbbrev?: string
  year?: number
  upsertEntryMutation?: IPoolData["upsertEntryMutation"]
  existingEntryDetails?: TExistingEntryDetails
  uiOptions?: Partial<UIOptions>
  entryIdToCopy?: string
}

interface IUpsertEntryFormValues {
  entryId?: string
  gameInstanceUid?: string
  name: string
  entryIdToCopy?: string
}

const BPMUpsertEntryNameModal: FC<IUpsertEntryNameModalProps> = ({
  title = DEFAULT_TITLE,
  redirectToLobby,
  close,
  gameInstanceUid,
  isCommingSoon,
  seasonType,
  productAbbrev,
  year,
  upsertEntryMutation,
  existingEntryDetails,
  entryIdToCopy,
  ...rest
}) => {
  const poolData = useContext(PoolDataContext)
  const { detailedEntry: entry, upsertEntryMutation: poolDataUpsertEntryMutation } = poolData || emptyObject
  const mutation = upsertEntryMutation ?? poolDataUpsertEntryMutation

  const history = useHistory()
  const lobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)
  const [generalError, setGeneralError] = useState<string | undefined>()
  const initialValues: IUpsertEntryFormValues = {
    name: (existingEntryDetails?.name || entry?.name) ?? "",
    entryId: (existingEntryDetails?.id || entry?.id) ?? "",
    gameInstanceUid,
  }

  const handleClose = useCallback(() => {
    close()
    if (redirectToLobby) {
      setTimeout(() => {
        history.push(lobbyMatch?.url || "")
      }, 500)
    }
  }, [close, redirectToLobby, history, lobbyMatch?.url])

  const handleSubmit = (values: IUpsertEntryFormValues) => {
    setGeneralError(undefined)
    const variables = {
      ...values,
      name: values.name.trim(),
    }

    return mutation({ variables })
      .then(() => close(true))
      .catch((err) => {
        const apiErrors = extractValidationError(err)
        if (apiErrors.errors.name) {
          const errorMessage = apiErrors.errors.name.includes("is already taken") ? ENTRY_NAME_TAKEN_MESSAGE : apiErrors.errors.name.join(" ")
          setGeneralError(errorMessage)
        } else {
          setGeneralError(apiErrors.message)
        }
      })
  }

  return (
    <Formik<IUpsertEntryFormValues> initialValues={initialValues} onSubmit={handleSubmit} isInitialValid={!validateEntryName(initialValues.name)}>
      {(formik) => {
        const actions = (
          <>
            <Button variant="secondary" type="button" onClick={handleClose} disabled={formik.isSubmitting} data-cy="upsert-entry-cancel-btn">
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!formik.isValid}
              withLoading
              loading={formik.isSubmitting}
              type="button"
              onClick={formik.submitForm}
              data-cy="upsert-entry-save-btn"
            >
              Save
            </Button>
          </>
        )

        return (
          <GenericEntryModal {...rest} title={title} actions={actions} onBackgroundClick={noop} onEscapeKeydown={noop}>
            <Form>
              <Modal>
                <Field name="name" validate={validateEntryName}>
                  {({ field, _, meta }) => {
                    return (
                      <>
                        <Input
                          className={cx({ "has-errors": Boolean(meta.touched && meta.error) })}
                          label="Bracket Name"
                          autoComplete="off"
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="modal__error-message">{meta.error}</div>
                        ) : (
                          <div className="modal__input-helper">{HELPER_MESSAGE}</div>
                        )}
                      </>
                    )
                  }}
                </Field>
                {generalError && <div className="modal__error-message">{generalError}</div>}
              </Modal>
            </Form>
          </GenericEntryModal>
        )
      }}
    </Formik>
  )
}

const BPCUpsertEntryNameModal: FC<IUpsertEntryNameModalProps> = ({
  title = DEFAULT_TITLE,
  redirectToLobby,
  close,
  gameInstanceUid,
  isCommingSoon,
  seasonType,
  isCreate,
  productAbbrev,
  year,
  upsertEntryMutation,
  existingEntryDetails,
  uiOptions,
  entryIdToCopy,
  ...rest
}) => {
  const deviceType = useDeviceType()
  const isMobile = deviceType === "handheld"
  const modalContentWrapperProps: Record<string, unknown> = { style: { paddingBottom: isMobile ? "1.5rem" : "1rem" } }
  const modalFooterProps: Record<string, unknown> | undefined = isMobile
    ? undefined
    : { style: { position: "sticky", bottom: 0, background: "white" } }
  const poolData = useContext(PoolDataContext)
  const { detailedEntry: entry, upsertEntryMutation: poolDataUpsertEntryMutation } = poolData || emptyObject
  const mutation = upsertEntryMutation ?? poolDataUpsertEntryMutation

  const history = useHistory()
  const lobbyMatch = useRouteMatch(constants.BRACKET_LOBBY_ROUTE)
  const [generalError, setGeneralError] = useState<string | undefined>()
  const initialValues: IUpsertEntryFormValues & IHaveSponsorData = {
    name: isCreate ? "" : (existingEntryDetails?.name || entry?.name) ?? "",
    entryId: isCreate ? "" : (existingEntryDetails?.id || entry?.id) ?? "",
    gameInstanceUid,
    sponsorData: { name: "Nissan", optin: false, data: { vehicle: "" } },
  }

  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid || "")

  const handleClose = useCallback(() => {
    close()
    if (redirectToLobby) {
      setTimeout(() => {
        history.push(lobbyMatch?.url || "")
      }, 500)
    }
  }, [close, redirectToLobby, history, lobbyMatch?.url])

  const handleSubmit = (data: IUpsertEntryFormValues & IHaveSponsorData) => {
    setGeneralError(undefined)
    Analytics.trackAction("challenge entry", "bracket pool", "save")
    const variables: IUpsertEntryFormValues & IHaveSponsorData = {
      ...data,
      entryIdToCopy,
      sponsorData: {
        ...data.sponsorData,
        data: {
          phoneNumber: data.sponsorData.data.phoneNumber || undefined,
          vehicle: data.sponsorData.data.vehicle || undefined,
        },
      },
    }
    return mutation({ variables })
      .then((res) => {
        close(true, res.data?.upsertEntry?.entry?.url, res.data?.upsertEntry?.entry?.id)
      })
      .catch((err) => {
        const apiErrors = extractValidationError(err)
        setGeneralError(apiErrors.message)
      })
  }

  const cancelText = uiOptions?.ctaCancel || isCommingSoon ? "Cancel" : "Discard Bracket"
  // buildDisclaimer brings text + atag inside of the text, so we need to set the html
  const disclaimer = (
    <BpcDisclaimerWrapper
      dangerouslySetInnerHTML={{
        __html: buildDisclaimer(gameInstanceUid || "", seasonType || "", productAbbrev || "", year || new Date().getFullYear()),
      }}
    />
  )
  const rulesUrlForDisclaimer =
    getRulesLink(gameInstanceUid || "", seasonType || "", productAbbrev || "", year || new Date().getFullYear(), "disclaimer") || "#"

  return (
    <Formik<IUpsertEntryFormValues & IHaveSponsorData>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isInitialValid={!validateEntryName(initialValues.name)}
    >
      {(formik) => {
        const cancelAction = () => {
          formik.resetForm()
          handleClose()
        }

        const modalActions = (
          <ModalFooter isMobile={isMobile}>
            <div className="footer__disclaimer">
              By clicking SAVE you agree to the{" "}
              <a href={rulesUrlForDisclaimer} target="_blank" rel="noreferrer noopener nofollow">
                official rules
              </a>
              .
            </div>
            <ModalActions>
              <Button
                variant="secondary"
                type="button"
                fullWidth={isMobile}
                onClick={cancelAction}
                disabled={formik.isSubmitting}
                data-cy="upsert-entry-cancel-btn"
              >
                {cancelText}
              </Button>
              <Button
                variant="primary"
                fullWidth={isMobile}
                disabled={!formik.isValid}
                withLoading
                loading={formik.isSubmitting}
                type="button"
                onClick={formik.submitForm}
                data-cy="upsert-entry-save-btn"
              >
                Save
              </Button>
            </ModalActions>
          </ModalFooter>
        )

        const footer = isMobile ? disclaimer : modalActions
        const bodyNode = isMobile ? modalActions : disclaimer

        const impressionUrl = gameInstanceUid === NCAAW_GAME_INSTANCE_UID ? NCAAW_CHALLENGE_IMPRESSION_URL_SRC : NCAAB_CHALLENGE_IMPRESSION_URL_SRC
        const impressionTrackingExtra = { sponsorName: "nissan logo", nissanLogoImpression: 1 }
        return (
          <GenericEntryModal
            {...rest}
            footer={footer}
            onBackgroundClick={noop}
            onEscapeKeydown={noop}
            modalType={isMobile ? "fullscreen" : "modal"}
            modalContentWrapperProps={modalContentWrapperProps}
            modalFooterWrapperProps={modalFooterProps}
          >
            <AnalyticScreen
              feature="brackets"
              subfeature={`bracket-challenge-entry`}
              title={`Create Entry`}
              isModal={true}
              gameInstanceUid={gameInstanceUid}
              deviceType={deviceType}
              extra={impressionTrackingExtra}
            />
            <OneByOneAd impressionUrl={impressionUrl} />
            <Form>
              <Modal isMobile={isMobile}>
                <BpcUpsertEntryNameModalHeader isWomenBracket={isWomenBracket} isMobile={isMobile} />
                <div className="modal__wrapper-title modal__text--title">{title}</div>
                <Field name="name" validate={validateEntryName}>
                  {({ field, _, meta }) => {
                    return (
                      <>
                        <Input
                          label="Bracket Name"
                          maxLength={constants.ENTRY_NAME_MAX_LENGTH}
                          autoComplete="off"
                          className={cx({ "has-errors": Boolean(meta.touched && meta.error) })}
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="modal__error-message">{meta.error}</div>
                        ) : (
                          <div className="modal__input-helper">{HELPER_MESSAGE}</div>
                        )}
                      </>
                    )
                  }}
                </Field>
                <div className="news-and-rules">
                  <div className="news-wrap">
                    <Field name="sponsorData.optin">
                      {({ field }) => (
                        <Checkbox
                          label="I would like to receive future product news and announcements from NISSAN.*"
                          name={field.name}
                          onChange={field.onChange}
                          isChecked={field.value}
                          className="news-check-box"
                        />
                      )}
                    </Field>
                  </div>
                  {formik.values.sponsorData.optin && (
                    <>
                      <Field name="sponsorData.data.vehicle">
                        {({ field: { value, name }, form: { setFieldValue, setTouched } }) => {
                          const selectedValue = NISSAN_VEHICLE_OPTIONS.find((opt) => opt.value === value)
                          return (
                            <div className="modal__wrapper--input modal__wrapper--select">
                              <Select
                                options={NISSAN_VEHICLE_OPTIONS}
                                selectedValue={selectedValue}
                                onSelect={(item) => setFieldValue(name, item.value)}
                                onBlur={() => {
                                  setTouched({ ...formik.touched, [name]: true })
                                }}
                                id={name}
                              />
                            </div>
                          )
                        }}
                      </Field>
                      <Field name="sponsorData.data.phoneNumber" validate={validatePhoneNumber}>
                        {({ field: { onBlur, onChange, value, ...rest }, form: { setFieldValue, setTouched }, meta }) => {
                          return (
                            <div className="modal__wrapper--input">
                              <div className="modal__text--label">Phone Number</div>
                              <Input
                                placeholder="(XXX) XXX-XXXX"
                                {...rest}
                                value={value}
                                className={cx({ "has-errors": Boolean(meta.touched && meta.error) })}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const { value: currentValue } = e.target
                                  const match = currentValue.replace(ANYTHING_BUT_NUMBERS_REGEX, "").match(US_PHONE_NUMBER_REGEX)
                                  const newValue = match
                                    ? `(${match[1]}) ${match[2]}-${match[3]}`
                                    : currentValue.replace(REMOVE_INVALID_CHARS_REGEX, "")
                                  setFieldValue(rest.name, newValue)
                                }}
                                onBlur={() => {
                                  const currentValue: string = value ?? ""
                                  const match = currentValue.replace(ANYTHING_BUT_NUMBERS_REGEX, "").match(US_PHONE_NUMBER_REGEX)
                                  if (match) {
                                    const newValue = `(${match[1]}) ${match[2]}-${match[3]}`
                                    setFieldValue(rest.name, newValue)
                                  }
                                  setTouched({
                                    ...formik.touched,
                                    sponsorData: { ...formik.touched.sponsorData, data: { ...formik.touched.sponsorData?.data, phoneNumber: true } },
                                  })
                                }}
                              />
                              <ErrorMessage name="sponsorData.data.phoneNumber" component="div" className="modal__error-message" />
                            </div>
                          )
                        }}
                      </Field>
                      <div className="rules-wrap">
                        *By checking the box above, you expressly consent that CBSI can provide Nissan North America Inc, your information and agree
                        that Nissan North America Inc. may contact you about your inquiry via email, by text message or phone call (including
                        automatic telephone dialing system or an artificial or prerecorded voice) to the residential or cellular telephone number you
                        have provided, even if that telephone number is on a corporate, state, or national Do Not Call Registry. Data rates may apply.
                        You do not have to agree to receive such calls or messages as a condition of entering the Challenge. CBS Interactive, Inc.
                        assumes no responsibility for use of such information by Nissan North America, Inc. and advises you to review the{" "}
                        <a href="https://www.nissanusa.com/privacy.html" target="_blank">
                          Nissan Legal Privacy Policy | Nissan USA
                        </a>
                        .
                      </div>
                    </>
                  )}
                </div>
                {bodyNode}
                {generalError && <div className="modal__error-message">{generalError}</div>}
              </Modal>
            </Form>
          </GenericEntryModal>
        )
      }}
    </Formik>
  )
}

const BPCS16UpsertEntryNameModal: FC<IUpsertEntryNameModalProps> = (props) => {
  const { entryIdToCopy, ...rest } = props
  const uiOptions: UIOptions = {
    ctaCancel: "Cancel",
  }

  return (
    <BPCUpsertEntryNameModal
      {...rest}
      gameInstanceUid={NCAAB_S16_GAME_INSTANCE_UID}
      productAbbrev={constants.SWEET_SIXTEEN_PRODUCT_ABBREV}
      uiOptions={uiOptions}
    />
  )
}

const UpsertEntryNameModal = ({ mode = "bpm", ...rest }: IUpsertEntryNameModalProps) => {
  const poolData = useContext<PoolDataContextType>(PoolDataContext)
  const isBpcSweet16Variant = poolData?.gameInstanceUid === NCAAB_S16_GAME_INSTANCE_UID

  if (mode === "bpm") {
    return <BPMUpsertEntryNameModal {...rest} />
  }
  if (isBpcSweet16Variant) {
    return <BPCS16UpsertEntryNameModal {...rest} />
  }
  return <BPCUpsertEntryNameModal {...rest} />
}

UpsertEntryNameModal.modalKey = "upsert-entry-modal-key"
export default UpsertEntryNameModal
