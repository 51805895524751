import { GamesPerPeriodEnumType, GameWeightEnumType } from "@cbs-sports/sports-shared-client/build/cjs/types/globalTypes"
import { PeriodLengthEnum, PicksDeadlineTypeEnum, SportTypesEnum, SpreadTypeEnum } from "@cbs-sports/sports-shared-client/build/cjs/utils/enums"
import { PoolSettingsTypesEnum } from "./enums"
import { TRoundTimeframe } from "./pool-settings"

export type TPlayoffsSettingsOPM = {
  includePlayoffs: boolean // by default do not include playoffs
}

export type TTiebreakerSettingsOPM = {
  totalScore: boolean // total MNF score
}

// One time / Per Week
export enum FinancesFeesFrequencyOPM {
  ONE_TIME = "ONE_TIME",
  PER_WEEK = "PER_WEEK",
  UNKNOWN = "UNKNOWN",
}

export type TFinancesFeesOPM = {
  amount: number
  frequency: FinancesFeesFrequencyOPM
}

export type TFinancesSettingsOPM = {
  managerPlaysForFree: boolean
  fees: TFinancesFeesOPM
}

export enum MissedPeriodsOPMEnum {
  ZERO = "ZERO",
  LOWEST_MINUS_ONE = "LOWEST_MINUS_ONE",
  UNKNOWN = "UNKNOWN",
}

export type TPoolSettingsOPM = {
  sportTypes: SportTypesEnum[]
  periodLength: PeriodLengthEnum
  spreadType: SpreadTypeEnum
  includeMessageBoard: boolean
  picksDeadlineType: PicksDeadlineTypeEnum
  maxEntriesPerUser: number
  gamesPerPeriod: GamesPerPeriodEnumType // if this is ALL | CBS, we do not need to look at numPicksPerPeriodCount
  showPickPercentages: boolean
  numPicksPerPeriodCount?: number
  gameWeightType: GameWeightEnumType
  missedPeriods: MissedPeriodsOPMEnum
  finances: TFinancesSettingsOPM
  tiebreaker: TTiebreakerSettingsOPM
  playoffs: TPlayoffsSettingsOPM
  rounds: Record<number, TRoundTimeframe>
  enableAutoPilot: boolean
  __typename: PoolSettingsTypesEnum
}
