import { BracketUtils as BracketUtilsBase } from "@cbs-sports/sports-shared-client/build/cjs/utils/bracket-utils"
import { TIdToPropagation } from "@cbs-sports/sports-shared-client/build/types/utils/common-utils-types"
import { IBracketUtilMatchup } from "./common-utils-types.d"

export class BracketUtils extends BracketUtilsBase {
  constructor(public allMatchups: IBracketUtilMatchup[]) {
    super(allMatchups)
  }

  public getTeamPropagationForMatchUp(matchup: IBracketUtilMatchup): TIdToPropagation {
    const { topItemId, bottomItemId, advancementId, slotIds } = matchup
    const [top, bottom] = slotIds || (matchup as any)?.slotIdsKey?.split("-") || []
    let nextM = this.getNextMatchupFor(matchup)
    const list: number[] = []
    if (advancementId) {
      list.push(advancementId)
    }
    while (nextM && nextM.advancementId) {
      list.push(nextM.advancementId)
      nextM = this.getNextMatchupFor(nextM)
    }
    const result: TIdToPropagation = {}
    if (topItemId) {
      result[topItemId] = top ? [Number(top), ...list] : list
    }
    if (bottomItemId) {
      result[bottomItemId] = bottom ? [Number(bottom), ...list] : list
    }
    return result
  }
}
export default BracketUtils
