import * as React from "react"
import Footer from "@cbs-sports/sports-shared-client/build/cjs/components/SiteNav/Footer"
import { LayoutProps } from "../typings"
import { BottomBannerAdContainer, FooterContainer } from "../styles/SiteFooter.styles"
import Ad from "../../../components/Ad"
import BidBarrel from "../../../utils/bid-barrel"

const SiteFooter = React.memo((props: LayoutProps) => {
  const { deviceType, pathname, isCbsAppWebview } = props
  const isHandheld = deviceType === "handheld"
  const bottomBannerAdProps = BidBarrel.getDomValues({
    adLayoutProps: props,
    adSlot: "bracket_bottom",
    requestedPos: "bottom",
  })
  // NOTE LL: Only want to show for Brackets for now
  const showBottomBannerAd = !isHandheld && /college-basketball\/(ncaa|ncaaw)-tournament/.test(pathname)
  const bottomBannerAd = showBottomBannerAd ? (
    <Ad adSlot={`bracket_bottom`} useShSkybox={false} {...BidBarrel.extractProps(bottomBannerAdProps)} />
  ) : undefined
  React.useEffect(() => {
    return () => {
      if (bottomBannerAdProps) {
        BidBarrel.teardown(bottomBannerAdProps)
      }
    }
  }, [])

  if (isCbsAppWebview) {
    return null
  }

  return (
    <>
      {bottomBannerAd && <BottomBannerAdContainer>{bottomBannerAd}</BottomBannerAdContainer>}
      <FooterContainer id="picks-site-footer">
        <Footer />
      </FooterContainer>
    </>
  )
})

export default SiteFooter
