import { useContext } from "react"
import PoolDataContext, { PoolDataContextType } from "../Contexts/PoolDataContext"
import constants from "../../common/constants"
export enum PageHeaderOptions {
  None = 0, //Represents an empty set of options
  DisplayMemberCount = 1 << 0, //When to show the pagination control in the for the overall standings page
}

type OptionsType = number
type OptionCheckerType = (opt: PageHeaderOptions) => boolean

function checkOption(alloptions: OptionsType): OptionCheckerType {
  return (opt: PageHeaderOptions) => {
    return !!(alloptions & opt)
  }
}

const DEFAULT_STANDING_OPTIONS: number = PageHeaderOptions.None

function usePageHeaderOptions(): [OptionsType, OptionCheckerType] {
  const context = useContext<PoolDataContextType>(PoolDataContext)
  const gameInstanceUid = context?.gameInstanceUid ?? ""

  let optionsForGameInstance: number = PageHeaderOptions.None

  switch (gameInstanceUid) {
    case constants.NCAAB_MANAGER_GAME_INSTANCE_UID:
    case constants.NISSAN_NCAAB_CHALLENGE_GAME_INSTANCE_UID:
    case constants.NCAAW_MANAGER_GAME_INSTANCE_UID:
    case constants.NISSAN_NCAAW_CHALLENGE_GAME_INSTANCE_UID:
      optionsForGameInstance = PageHeaderOptions.DisplayMemberCount
      break
    default:
      optionsForGameInstance = DEFAULT_STANDING_OPTIONS
      break
  }

  const hasOption = checkOption(optionsForGameInstance)

  return [optionsForGameInstance, hasOption]
}

export default usePageHeaderOptions
