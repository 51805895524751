import * as React from "react"
import { SeasonEnumType } from "../../__generated__/globalTypes"
import constants from "../../common/constants"
import buildMappingFor from "../../common/game-text"
import Og from "../../common/og"
import { findOgBy, getStringParam } from "../../common/url-utils"
import Helmet from "../shared/react-helmet-async/Helmet"
import { otherDynamicIcons } from "../../common/meta-icon-list"
import { useLocation } from "react-router-dom"
import { ENUM_NCAAW } from "../../common/enums"
import { isMarchMadnessMatcher } from "../../common/common-utils-helpers"
import { EmailSenderType, senderRoleSearchParamKey } from "../../common/email-invites"
import { pseudoDecrypt } from "../../common/string-utils"

interface IGameOgMetaProps {
  gameInstanceUid?: string
  seasonType?: SeasonEnumType
  year?: number
  productAbbrev?: string
  multiGameInstances?: boolean
  poolName?: string
  entryName?: string
}

const getGameInstanceUidBasedOnUrl = (location) => {
  // /college-basketball/ncaa-tournament/bracket
  if (/\/college-basketball\/ncaa-tournament/.test(location.pathname)) {
    if (/nissan/.test(location.pathname)) {
      return `nissan-ncaab-tournament-challenge`
    } else if (/challenge/.test(location.pathname)) {
      return `cbs-ncaab-tournament-challenge`
    }
    return `cbs-ncaab-tournament-manager`
  } else if (/\/college-basketball\/ncaaw-tournament/.test(location.pathname)) {
    if (/nissan/.test(location.pathname)) {
      return `nissan-ncaaw-tournament-challenge`
    } else if (/challenge/.test(location.pathname)) {
      return `cbs-ncaaw-tournament-challenge`
    }
    return `cbs-ncaaw-tournament-manager`
  }
  return ""
}

const getProductAbbrevBasedOnUrl = (location) => {
  // /college-basketball/ncaa-tournament/bracket
  if (/\/college-basketball\/ncaa-tournament/.test(location.pathname)) {
    if (/nissan/.test(location.pathname)) {
      return `wlnbpc`
    } else if (/challenge/.test(location.pathname)) {
      return `bpc`
    }
    return `bpm`
  } else if (/\/college-basketball\/ncaaw-tournament/.test(location.pathname)) {
    if (/nissan/.test(location.pathname)) {
      return `wlnwbpc`
    } else if (/challenge/.test(location.pathname)) {
      return `wbpc`
    }
    return `wbpm`
  }
  return ""
}

const REGULAR = "regular"
const POST = "post"
const GameOgMeta: React.FC<IGameOgMetaProps> = ({
  gameInstanceUid = "",
  seasonType = REGULAR,
  year = 0,
  productAbbrev = "",
  multiGameInstances = false,
  poolName = "",
  entryName = "",
  children,
}) => {
  const location = useLocation()
  const championAbbrev = getStringParam("championAbbrev", location.search) || ""
  const championTeamName = decodeURIComponent(getStringParam("champion", location.search) || "")
  const sportType = getStringParam("sportType", location.search) || ""
  const origin = constants.DOMAIN
  const gameInstanceUidToUse = gameInstanceUid !== "" ? gameInstanceUid : getGameInstanceUidBasedOnUrl(location)
  const productAbbrevToUse = productAbbrev !== "" ? productAbbrev : getProductAbbrevBasedOnUrl(location)
  const seasonTypeToUse = isMarchMadnessMatcher.test(gameInstanceUidToUse) ? POST : seasonType
  const yearToUse = isMarchMadnessMatcher.test(gameInstanceUidToUse) ? constants.MARCH_MADNESS_SPORT_YEAR + 1 : year
  const values = findOgBy(gameInstanceUidToUse, seasonTypeToUse)
  if (!values || (!multiGameInstances && !seasonTypeToUse)) {
    const defaultOg = Og[0]
    const des =
      "Play Pick'em Fantasy Sport games with CBS Sports and win cash prizes.  Play against your friends and or compete for prizes in various formats and game modes."
    return (
      <Helmet>
        <title>CBS Sports Fantasy Games</title>
        <meta name="msapplication-tooltip" content={des} />
        <meta name="description" content={des} />
        <link rel="manifest" href={`${origin}/site.webmanifest`} />
        <meta property="og:title" content="CBS Sports - Fantasy Games." />
        <meta property="og:description" content={des} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${origin}${defaultOg.metaPathPrefix}/apple-touch-icon.png`} />
        <meta name="msapplication-TileColor" content={defaultOg.themeColor} />
        <meta name="theme-color" content={defaultOg.themeColor} />
        <meta property="og:image" content={`${origin}${defaultOg.metaPathPrefix}/og-image.jpg`} />
        <meta property="og:image:height" content={defaultOg.ogImgH} />
        <meta property="og:image:width" content={defaultOg.ogImgW} />
        <meta name="msapplication-starturl" content={`${origin}/app-shell`} />
        {children}
      </Helmet>
    )
  }
  const isChallengePool = /challenge/.test(gameInstanceUidToUse)
  const gameTexts = buildMappingFor(gameInstanceUidToUse, seasonTypeToUse, yearToUse, productAbbrevToUse)
  const title = gameTexts.metaTitle
  let ogTitle = gameTexts.titleWithClient
  // TODO qac: more switching
  // console.log(`productSeason: ${gameInstanceUid} ${year}`);
  // console.dir(values)
  // console.dir(gameTexts)
  const description = gameTexts.metaDescription
  let ogDescription =
    (entryName && `'${entryName}' is my ${gameTexts.title} Entry on CBS Sports`) ||
    (isChallengePool && `I'm playing ${gameTexts.titleWithSuffix} on CBS Sports.  Join it today!`) ||
    (poolName && `'${poolName}' is my ${gameTexts.title} Pool on CBS Sports. Join it today!`) ||
    `Play ${gameTexts.titleWithSuffix} on CBS Sports.`

  // if (!values) {
  //   console.warn(`no values for ${frontendPathPrefix}`)
  // }
  // <link rel="icon" type="image/png" sizes="32x32" href={`${origin}/nfl-pickem/favicon-32x32.png`} />
  // <link rel="icon" type="image/png" sizes="16x16" href={`${origin}/nfl-pickem/favicon-16x16.png`} />
  // console.dir(values)
  const metaUrlPrefix = origin + values.metaPathPrefix
  // NOTE qac: to ensure whenever we generate new images that we have a  chance for FB to refresh:
  const queryParamBuster = yearToUse ? `?year=${yearToUse}&v=10` : "?v=10"
  let ogImage = `${metaUrlPrefix}${values.ogImageAssetName}${queryParamBuster}`
  // nissan champion share
  if (championAbbrev && championTeamName && sportType) {
    const gender = sportType.toUpperCase() === ENUM_NCAAW ? "women's" : "men's"
    ogDescription = "Fill out your March Madness® Bracket"
    ogTitle = `My ${gender} picks are in! Now it's your turn.`
    ogImage = `${origin}/static/game-assets/nissan-champion-share.png${queryParamBuster}`
  }
  // open invites share
  const encryptedInviteSenderRole: EmailSenderType | "" = (getStringParam(senderRoleSearchParamKey, location.search) as EmailSenderType) || ""
  const inviteSenderRole = pseudoDecrypt(encryptedInviteSenderRole)
  if (inviteSenderRole === "participant") {
    ogDescription = `My friend is running a ${gameTexts.title} Pool on CBS Sports and I want you to join the fun. Join '${poolName}' today.`
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="msapplication-tooltip" content={ogDescription} />
      <meta name="msapplication-TileColor" content={values.themeColor} />
      <meta name="theme-color" content={values.themeColor} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:height" content={values.ogImgH} />
      <meta property="og:image:width" content={values.ogImgW} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={ogDescription} />
      <meta name="twitter:image" content={ogImage} />

      <link rel="mask-icon" href={`${metaUrlPrefix}/safari-pinned-tab.svg`} color={values.themeColor} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${metaUrlPrefix}/apple-touch-icon.png`} />
      {otherDynamicIcons.map((icon) => (
        <link key={icon.src} rel="icon" type={icon.type} sizes={icon.sizes} href={`${metaUrlPrefix}${icon.src}`} />
      ))}
      {values.ogVideoAssetName && <meta property="og:video:url" content={`${metaUrlPrefix}/${values.ogVideoAssetName}`} />}
      {values.ogVideoAssetName && <meta property="og:video:secure_url" content={`${metaUrlPrefix}/${values.ogVideoAssetName}`} />}
      {values.ogVideoW && <meta property="og:video:width" content={values.ogVideoW} />}
      {values.ogVideoH && <meta property="og:video:height" content={values.ogVideoH} />}
      {children}
    </Helmet>
  )
}

export default GameOgMeta
