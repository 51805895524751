import { GraphQLError } from "graphql"
import { capitalize } from "./misc-utils"

const prefix = "ValidationError:"
const apiErrorPrefix = "GraphQL error: "

export interface IValidationErrorBody {
  [key: string]: string[]
}
export interface IValidationErrors extends Error {
  errors: IValidationErrorBody
}
export interface IGraphqlErrors {
  graphQLErrors: GraphQLError[]
}
/*
Samples:

function clientExtractErrorMessage(apiErrorString: string): string {
  const parts = apiErrorString.split("ValidationError:")
  if (parts.length > 1) {
    try {
      const parsed = JSON.parse(parts[1]) as IValidationErrors
      return parsed.message
    } catch (error) {
      // failed to parse JSON
    }
  }
  // could not parse error:
  return null
}

function clientExtractErrorMessages(apiErrorString: string): string[] {
  const parts = apiErrorString.split("ValidationError:")
  if (parts.length > 1) {
    try {
      const parsed = JSON.parse(parts[1]) as IValidationErrors
      const errors = parsed.errors
      const messages = [] as string[]
      for (const key of Object.keys(errors)) {
        const errorMessages = errors[key]
        if (key === "base") {
          // "base" is a key where all errors are put that do not belong to an "input".
          // these messages are human friendly and full sentances (ex: "Brackets are locked")
          errorMessages.forEach((msg) => messages.push(msg))
        } else {
          // "key" is the name of the <input> with the error.  This usually converts to something human freindly:
          messages.push(`${capitalize(key)} ${errorMessages.join(" - ")}`)
        }
      }
    } catch (error) {
      // failed to parse JSON
    }
  }
  // could not parse error:
  return null
}
*/

// NOTE qac: we dont want to polute logs / sentry for these:
export const acceptableErrorNameRegexp = new RegExp(`PersistedQueryNotFound|${prefix}`)

// TODO qac: create a ValidationError Class

export const throwValidationError = (key: string, errorMessage: string) => {
  // NOTE qac: for future, may be useful to have unique ids per error:
  const hash = `${key}:${errorMessage.replace(/\n/g, "")}`
  const errors = {
    name: `Fantasy Picks Error - ${hash}`,
    message: humanizeErrorMsg(key, [errorMessage]),
    errors: { [key]: [errorMessage] },
  } as IValidationErrors
  throw new Error(prefix + JSON.stringify(errors))
}

export const humanizeErrorMsg = (fieldName: string, messages: string[]) => {
  if (fieldName === "base" || fieldName === "general") {
    return messages.join(".  ")
  } else {
    return `${capitalize(fieldName)} ${messages.join(" - ")}`
  }
}

export const extractValidationError = (error: GraphQLError | Error | ErrorEvent | IValidationErrors): IValidationErrors => {
  // TODO: Not returning nice errors. Example: Pool.tsx - line 119

  if (error && error.hasOwnProperty("errors") && error.hasOwnProperty("name")) {
    return error as IValidationErrors
  }
  const parts = error.message.split(prefix)
  if (parts.length > 1) {
    const parsed = JSON.parse(parts[1])
    return parsed as IValidationErrors
  } else {
    return {
      name: (error.hasOwnProperty("name") && (error as Error).name) || prefix,
      message: error.message,
      errors: {
        base: [error.message.replace(apiErrorPrefix, "")],
      },
    }
  }
}
