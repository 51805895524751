import React, { FC } from "react"
import { impressionCacheBusterRegex, replaceWithVguid } from "../../../../common/common-utils-helpers"

interface IOneByOneProps {
  impressionUrl: string
}

const OneByOneAd: FC<IOneByOneProps> = ({ impressionUrl }) => {
  React.useEffect(() => {
    const src = replaceWithVguid(impressionUrl).replace(impressionCacheBusterRegex, Date.now().toString())
    const oneByOneImage = new Image()
    oneByOneImage.src = src
    oneByOneImage.height = 1
    oneByOneImage.width = 1
  }, [impressionUrl])

  return null
}

export default React.memo(OneByOneAd)
