import camelCase from "lodash/camelCase"
import kebabCase from "lodash/kebabCase"
import snakeCase from "lodash/snakeCase"
import startCase from "lodash/startCase"
import upperFirst from "lodash/upperFirst"

const parameterize = (str: string) => snakeCase(str).replace(/_/g, "-")
const upperSnakeCase = (str: string) => snakeCase(str).toUpperCase()

function getRandomChar(amount = 1) {
  return Math.random().toString(36).substr(2, amount)
}
function getRandomString(length = 12) {
  const result: string[] = []
  for (let i = 0; i < length; i++) {
    result.push(getRandomChar())
  }
  return result.join("")
}
function pseudoEncrypt(src: string) {
  let final = ""
  for (let i = 0; i < src.length; i++) {
    final += src.charAt(i) + getRandomChar()
  }
  return final
}
function pseudoDecrypt(str: string) {
  let final = ""
  for (let i = 0; i < str.length; i++) {
    final += str.charAt(i)
    i++
  }
  return final
}

function humanize(str: string) {
  return upperFirst(snakeCase(str).replace(/_/gi, " "))
}

export const humanizeNumber = (val: number) => {
  return (val || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const pascalCase = (val: string) => {
  return startCase(camelCase(val)).replace(/ /g, "")
}

// re-export:
export { camelCase, snakeCase, kebabCase, upperFirst, parameterize, pseudoEncrypt, pseudoDecrypt, humanize, upperSnakeCase, getRandomString }
