import { TSportType } from "../../common/db-typings"
import { mapToId, toRank, unique, uniqueNonNull } from "../../common/misc-utils"

declare global {
  // tslint:disable-next-line
  interface Window {
    // requestIdleCallback: (func: any) => number;
    // cancelIdleCallback: (id: number) => number;
    // MutationObserver: any;
    WebKitMutationObserver: any
    MozMutationObserver: any
  }
}

const bodyDatasetHoldsKey = "modalholds"
export const modalOpenAttr = "sh-modal-open"

export { unique, uniqueNonNull, mapToId, toRank }

interface IEdge<T> {
  node: T
}
interface IEdges<T> {
  edges?: Array<IEdge<T>>
}

export const canUseDom = typeof document !== "undefined" && typeof window !== "undefined" && typeof navigator !== "undefined"
export const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
// export const emailReg = /^(?!.*\.\.)(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/ // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
export const edgeToNode = <T>(edge: IEdge<T>): T => edge.node

export const emptyNodes = [] as never[]
export const toNodes = <T>(obj?: IEdges<T>): T[] => {
  if (obj && obj.edges) {
    return obj.edges.map(edgeToNode)
  } else {
    return emptyNodes
  }
}

export const requestIdleCallback = (cb: () => void) => {
  if (canUseDom) {
    if (window.requestIdleCallback) {
      return window.requestIdleCallback(cb)
    } else {
      return window.setTimeout(cb, 0)
    }
  }
  return null
}
export const cancelIdleCallback = (id?: any) => {
  if (id && canUseDom) {
    if (window.cancelIdleCallback) {
      return window.cancelIdleCallback(id)
    } else {
      return window.clearTimeout(id)
    }
  }
}

export const cancelAnimationFrame = (id: number) => {
  if (id && canUseDom) {
    if (window.cancelAnimationFrame) {
      return window.cancelAnimationFrame(id)
    } else {
      return window.clearTimeout(id)
    }
  }
}

export function isIos() {
  return canUseDom && /ios|iphone|ipad|ipod/i.test(navigator.userAgent || "")
}

export function onModalOpen() {
  if (canUseDom) {
    const el = document.body
    const holds = parseInt((el.dataset && el.dataset[bodyDatasetHoldsKey]) || "0") + 1
    el.setAttribute(modalOpenAttr, isIos() ? "ios" : "default")
    if (el.dataset) {
      el.dataset[bodyDatasetHoldsKey] = holds.toString()
    }
    return holds
  } else {
    return 0
  }
}
export function onModalClose(escapeKeyCallback?: (event: Event) => void) {
  if (canUseDom) {
    const el = document.body
    const holds = parseInt((el.dataset && el.dataset[bodyDatasetHoldsKey]) || "0") - 1
    // console.debug(`cleanUp holds: ${holds}`);
    if (el.dataset) {
      el.dataset[bodyDatasetHoldsKey] = holds.toString()
    }
    if (holds < 1) {
      el.removeAttribute(modalOpenAttr)
    }
    return holds
  } else {
    return 0
  }
}

export function getScrollContainerEl() {
  if (canUseDom) {
    return document.getElementById("sh-scroll-container") || document.body || null
  }
  return null
}

export const onDOMReady = () => {
  return new Promise((res) => {
    if (canUseDom) {
      if (document.readyState !== "loading") {
        res(0)
      } else {
        document.addEventListener("DOMContentLoaded", res, false)
        document.addEventListener("onload", res, false)
      }
    }
  })
}

export const loadScript = (src: string, defer = true, async = true) => {
  return onDOMReady().then(() => {
    return new Promise((res, rej) => {
      if (canUseDom) {
        const el = document.createElement("script")
        if (async) {
          el.setAttribute("async", "async")
        }
        if (defer) {
          el.setAttribute("defer", "defer")
        }
        // https://tags.tiqcdn.com/utag/cbsi/cbsnewssite/qa/utag.js
        el.setAttribute("src", src)
        el.addEventListener("load", () => requestIdleCallback(res as any))
        el.addEventListener("onerror", rej)
        document.body.appendChild(el)
      }
    })
  })
}

let guuid = ""
let cnt = 0
let lastKnownUrl = ""

export const getVGUID = (newPathname?: string, forceNewVGUID?: boolean) => {
  // NOTE qac: the biz rules for vguid are best summed up in this confluence:
  // https://atlassian.cbsi.com/confluence/x/PuEyB
  // * must be unique for each pageview
  // * must be synced up with: ads, analytics (adobe), and attribution (william hill)
  if (canUseDom) {
    if (!guuid) {
      guuid = window.__STI_DEBUG__.guuid || "" + Math.random() * 10000000
    }
    if (newPathname && lastKnownUrl !== newPathname) {
      lastKnownUrl = newPathname
      guuid = guuid + cnt++
    } else if (forceNewVGUID) {
      guuid = guuid + cnt++
    }
  }

  return guuid
}

export const getQueryWithError = (queries: any[]) => {
  const queryWithError = queries.filter((q) => !!q && !!q.error)
  return queryWithError.length ? queryWithError[0] : undefined
}

export const isEventInHalftime = (gamePeriod: number, sportType?: TSportType, timeRemaining?: string | null) => {
  switch (sportType) {
    case "NCAAW":
      if (gamePeriod === 2) {
        if (timeRemaining === "0:00") {
          return true
        }
      }
      return false
    case "NCAAB":
      if (gamePeriod === 1) {
        if (timeRemaining === "0:00") {
          return true
        }
      }
      return false
    default:
      return false
  }
}
