import * as React from "react"

const Import = ({ color = "currentColor" }) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M13.833 8.833V13c0 .417-.416.833-.833.833H3A.835.835 0 012.167 13V8.833c0-.5-.334-.833-.834-.833-.5 0-.833.333-.833.833v5c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667v-5c0-.5-.417-.833-.833-.833a.835.835 0 00-.834.833zm-5-.25L10.417 7a.805.805 0 011.166 0 .805.805 0 010 1.167l-3 3a.806.806 0 01-1.166 0l-3-3a.806.806 0 010-1.167.806.806 0 011.166 0l1.584 1.583v-7.25C7.167.917 7.5.5 8 .5c.5 0 .833.417.833.833v7.25z"
        fill={color}
      />
    </svg>
  )
}
export default Import
