import {
  PoolDetailsFragment,
  PoolDetailsFragment_season_segments_edges_node,
  // PoolDetailsFragment_season_segments_edges_node_poolSettings,
} from "../__generated__/PoolDetailsFragment"
import { ENUM_POST } from "./enums"

export const stepToPath = ({ path }): string => path
export const tournamentRBLabels = ["Preliminary Rounds", "Quarterfinals", "Semifinals", "Championship"]

export const useWeightsAndBonusesStep = false
const confBIgnored = ["periodLength", "spreadType", "mainTiebreaker", "secondaryTiebreaker", "maxPicksPerPeriodCount", "includeChampionshipRound"]
const bracketDefaults = ["periodLength", "picksDeadlineType", "tournamentIds", "maxPicksPerPeriodCount", "includeChampionshipRound"]
const ncaafBowlPickemIgnored = ["periodLength", "roundBonusType", "includeChampionshipRound"]
const ncaafPickemIgnored = ["periodLength", "picksDeadlineType"]
const nflPlayoffPickemIgnored = ["maxPicksPerPeriodCount", "includeChampionshipRound"]
const nflpickemIgnored = ["includeChampionshipRound"]
export const ignoredSettingsFor = (gameInstanceUid?: string, seasonType?: string, gameType?: string) => {
  if (gameInstanceUid === undefined) {
    return []
  }
  if (/cbs-football-pickem/.test(gameInstanceUid) || /cbs-nfl-survivor/.test(gameInstanceUid)) {
    return [...confBIgnored, ...ncaafBowlPickemIgnored, ...ncaafPickemIgnored, ...nflpickemIgnored]
  }
  if (/ncaab-confbracket/.test(gameInstanceUid)) {
    return confBIgnored
  }
  if (/nfl-pickem/.test(gameInstanceUid) && seasonType === ENUM_POST) {
    return nflPlayoffPickemIgnored
  }
  if (/nfl-pickem/.test(gameInstanceUid)) {
    return nflpickemIgnored
  }
  if (/ncaaf-pickem/.test(gameInstanceUid) && seasonType === ENUM_POST) {
    return ncaafBowlPickemIgnored
  }
  if (/ncaaf-pickem/.test(gameInstanceUid)) {
    return ncaafPickemIgnored
  }
  if (/nfl-parlay/.test(gameInstanceUid)) {
    return ncaafPickemIgnored
  }
  if (/ncaab-tournament/.test(gameInstanceUid)) {
    return bracketDefaults
  }
  throw new Error(`unhandled gameInstanceUid: ${gameInstanceUid}`)
}

export const getSegment = (pool: PoolDetailsFragment): PoolDetailsFragment_season_segments_edges_node => {
  const segments = pool.season.segments.edges
  return segments[0]!.node
}

export const getFullPoolSettings = (pool: PoolDetailsFragment, defaultOnly = false) => {
  const segmentPoolSettings = getSegment(pool).poolSettings
  const currentPoolSettings = Object.assign({}, segmentPoolSettings, defaultOnly ? {} : pool.poolSettings)
  if (currentPoolSettings.__typename !== "PoolSettings") {
    throw new Error(`Invalid pool settings type ${currentPoolSettings.__typename}`)
  }
  // stringify so we dont modify anything by mistake (deep objects)
  return JSON.parse(JSON.stringify(currentPoolSettings))
}

export const arraysAreDiff = (first: any[], second: any[]): boolean => {
  if (first?.length !== second?.length) {
    return true
  }

  for (let index = 0; index < first.length; index++) {
    if (first[index] !== second[index]) {
      return true
    }
  }
  return false
}
