import React, { useState, useEffect } from "react"
import { MenuBase } from "@cbs-sports/sports-shared-client/build/cjs/components/Menu"
import { CountdownClock } from "@cbs-sports/sports-shared-client/build/cjs/components/CountdownClock"
import DropDownMenu from "../../../components/DropDownMenu/DropDownMenu"
import PageTabsNav from "../../../shared/atoms/Page/PageTabsNav"
import PageMarquee from "../../../shared/atoms/Page/PageMarquee"
import PoolPageSponsor from "../components/PoolPageSponsor"
import PageTitle from "../../../shared/atoms/Page/PageTitle"
import { Nbsp, GameMarqeeWrapper, GameMarqeeRightCol } from "../components/PoolPage"
import { getSponsorKeyFor, getGameText } from "../../../../common/game-text"
import { spoeImgFor } from "../../../../common/url-utils"
import { Logo } from "../../../components/Logos"
import useNavMenu, { INavMenuProps } from "../../../hooks/useNavMenu"
import { NCAAB_BRACKET_SELECTION_SUNDAY_TIMESTAMP, NCAAW_BRACKET_SELECTION_MONDAY_TIMESTAMP, registeredTrademark } from "../../../../common/constants"
import { SponsorLogoAd } from "../../../components/LogoWithAd"
import { isNCAAWTournamentMatcher } from "../../../../common/common-utils-helpers"

const addTradeMarkToParts = (parts?: string[], noSup = false) => {
  return parts?.map((part, index) => {
    return (
      (index + 1 < parts.length && (
        <React.Fragment key={index}>
          <span>{part}</span>
          {!noSup && <sup>{registeredTrademark}</sup>}
          {noSup && <span>{registeredTrademark}</span>}
        </React.Fragment>
      )) ||
      (part && <span key={index}>{part}</span>) ||
      null
    )
  })
}
const getShowCountDownClock = (info: { locksAt: number | null; isInComingSoon: boolean; gameInstanceUid: string }): boolean => {
  const { locksAt, isInComingSoon, gameInstanceUid } = info
  const now = new Date().getTime()
  if (isInComingSoon) {
    const timeStamp = isNCAAWTournamentMatcher.test(gameInstanceUid)
      ? NCAAW_BRACKET_SELECTION_MONDAY_TIMESTAMP
      : NCAAB_BRACKET_SELECTION_SUNDAY_TIMESTAMP
    return now < timeStamp
  }
  return locksAt ? now < locksAt : false
}

const menuStyles: React.CSSProperties = {
  position: "absolute",
}

const GameMarquee: React.FC<INavMenuProps & { isCompactMarquee: boolean }> = (props) => {
  const { poolData, isCompactMarquee } = props
  const {
    productSeason,
    usersFirstCentralEntryInPool,
    periodLocksAt,
    centralBracketState,
    isCbsAppWebview,
    segmentsForArea,
    gameInstanceForArea,
    gameInstancesForArea,
    isPoolCreate,
    isInComingSoon,
    gameInstanceUid: pdGameInstanceUid,
  } = poolData
  const seasonType = productSeason?.season
  const locksAt = centralBracketState?.bracketState?.locksAt || periodLocksAt
  const menu = useNavMenu(props, true)
  const [showCountDownClock, setShowCountDownClock] = useState<boolean>(false)
  useEffect(() => {
    setShowCountDownClock(
      getShowCountDownClock({
        isInComingSoon,
        locksAt,
        gameInstanceUid: pdGameInstanceUid,
      }),
    )
  }, [isInComingSoon, locksAt, pdGameInstanceUid])

  const hideCountDownClock = () => {
    setTimeout(() => {
      setShowCountDownClock(false)
    }, 500)
  }

  const segmentToUse =
    segmentsForArea &&
    productSeason &&
    segmentsForArea.find((seg) => (productSeason ? seg.season.id === productSeason.id : !!seg.season.challengePoolSlug))
  if (!segmentToUse) {
    return null
  }
  const gameInstance = gameInstanceForArea || gameInstancesForArea.find((gi) => gi.id === segmentToUse.season.gameInstance.id)
  if (!gameInstance) {
    return null
  }
  const gameInstanceUid = gameInstance.uid
  const sponsorKey = productSeason && getSponsorKeyFor(productSeason)
  const variant = isCompactMarquee ? "compact" : "standard"
  const classNames = [
    `page-marquee__has-right-el--false`,
    `page-marquee__has-sponsor--${!!sponsorKey}`,
    `page-marquee__has-multiple-entries-per-user--true`,
    `page-marquee__has-secondary-nav--true`,
    `page-marquee__variant--${variant}`,
  ]
  const imageNode = (productSeason && <Logo gameInstanceUid={gameInstanceUid} season={productSeason} />) || null
  const { titleWithSuffix, title, topTitleText, sponsorLogoUrl } = getGameText(gameInstanceUid, segmentToUse)
  const poolName = title || (segmentToUse && titleWithSuffix) || ""
  const entryOrPoolAvatarUrl =
    usersFirstCentralEntryInPool.avatarUrl || (!imageNode && gameInstanceUid && seasonType && spoeImgFor(gameInstanceUid, seasonType)) || ""

  if (isPoolCreate) {
    return null
  }

  const poolNameParts = (poolName && poolName.split(registeredTrademark)) || undefined
  const topTitleTextParts = topTitleText?.split(registeredTrademark)
  let titleAd: React.ReactNode | null = null

  if (sponsorKey === "nissanOrHertz") {
    titleAd = <SponsorLogoAd />
  }

  const timeStamp = isNCAAWTournamentMatcher.test(gameInstanceUid)
    ? NCAAW_BRACKET_SELECTION_MONDAY_TIMESTAMP
    : NCAAB_BRACKET_SELECTION_SUNDAY_TIMESTAMP

  return (
    <PageMarquee variant={variant}>
      <GameMarqeeWrapper className={classNames.join(" ")}>
        <PageTitle
          variant={variant}
          className="page-marquee__page-title"
          imageAlt={poolName}
          imageUrl={entryOrPoolAvatarUrl}
          description={null}
          h1={addTradeMarkToParts(poolNameParts) || <Nbsp />}
          imageNode={imageNode}
          squareImage
          topTitleText={addTradeMarkToParts(topTitleTextParts, true) || null}
          titleAd={titleAd}
          sponsor={
            (productSeason && sponsorKey && sponsorKey !== "nissanOrHertz" && (
              <div className="page-marquee__sponsor">
                <PoolPageSponsor season={productSeason} sponsorLogoUrl={sponsorLogoUrl ? sponsorLogoUrl : undefined} />
              </div>
            )) ||
            null
          }
        />
        {showCountDownClock && (
          <GameMarqeeRightCol className={classNames.join(" ")}>
            <CountdownClock
              label={isInComingSoon ? "Selection Show Starts In" : "Brackets Lock In"}
              timestamp={isInComingSoon ? timeStamp : locksAt || undefined}
              onExpire={hideCountDownClock}
            />
          </GameMarqeeRightCol>
        )}
      </GameMarqeeWrapper>

      <PageTabsNav className={`multiple-entries--true`}>
        <MenuBase inline style={menuStyles}>
          {menu.map((item) => (
            <DropDownMenu {...item} key={item.id} isCbsAppWebview={isCbsAppWebview} useNavItem={false} />
          ))}
        </MenuBase>
      </PageTabsNav>
    </PageMarquee>
  )
}

export default GameMarquee
