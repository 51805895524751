import React, { useEffect } from "react"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
// import { ModalContent } from "./ManagerWelcomeBackModal"
import { FlexRow } from "../../../components/FlexComponents"
import BracketSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Bracket"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { useHistory } from "react-router-dom"
import { fontSizes, fontWeight, palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"

const ModalContent = styled.div`
  padding: 1.4rem ${pxToRem(40)};
  & .brackets-icon {
    width: ${pxToRem(80)};
    height: ${pxToRem(80)};
  }
  & .title-and-description {
    margin-left: 1rem;
    & .modal-title {
      font-weight: ${fontWeight.bold};
      font-size: ${pxToRem(18)};
      line-height: 1.5rem;
      letter-spacing: -0.1px;
      color: ${palette.gray20};
    }
    & .modal-description {
      width: ${pxToRem(272)};
      line-height: 1.25rem;
      color: ${palette.gray40};
      font-size: ${fontSizes.regular};
      margin-top: 0.25rem;
    }
  }
  & .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: ${pxToRem(22)};
    & > :first-child {
      margin-right: ${pxToRem(16)};
    }
  }
`
interface ISettingsSavedModalProps {
  isOpen: boolean
  onClose: () => void
  onInvitePoolClick: () => void
  showInviteAllOption?: boolean
}
const SettingsSavedModal = ({ isOpen, onClose, onInvitePoolClick, showInviteAllOption }: ISettingsSavedModalProps) => {
  //TODO: once we have final design of the simple Settings Saved modal, we can use "showInviteAllOption" to determine if we want to show the Alert Players content.
  const history = useHistory()
  const {
    location: { state },
  } = history
  // clean up navigation history state so this modal doesn't show again on page refresh
  useEffect(() => {
    if (state?.["actions"]) {
      delete state["actions"]
      history.replace({ ...history.location, state })
    }
  }, [history, state])

  return (
    <Modal isOpen={isOpen} padded={false}>
      <ModalContent>
        <FlexRow>
          <div className="brackets-icon">
            <BracketSvg />
          </div>
          <div className="title-and-description">
            <span className="modal-title">Your Settings are Saved!</span>
            <p className="modal-description">Now alert your previous pool members and let everyone know your pool is back.</p>
          </div>
        </FlexRow>
        <div className="action-buttons">
          <Button variant="secondary" onClick={onClose}>
            No Thanks
          </Button>
          <Button onClick={onInvitePoolClick}>Alert Players</Button>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default SettingsSavedModal
