import palette from "./palette"
import themes from "./themes"
import { DefaultTheme } from "styled-components/macro"

export interface IThemePassin {
  theme: DefaultTheme
}

interface IGetTheme extends Partial<DefaultTheme> {
  id: string
}

export function getThemeValues(theme: IGetTheme, bypassNexusCheck = false) {
  return ((theme.isNexusStyles === false || bypassNexusCheck) && theme.id && themes[theme.id]) || themes.default
}
export const themeBtnHighlightBgColor = ({ theme }: IThemePassin) => getThemeValues(theme).btnHighlightBgColor
export const themeBtnBgColor = ({ theme }: IThemePassin) => {
  // console.log('theme:')
  // console.dir(theme)
  return getThemeValues(theme).btnBgColor
}
export const themeBtnColor = ({ theme }: IThemePassin) => {
  // opposite of colorFor
  return getThemeValues(theme).isDarkSplashBg ? palette.grayUnknownText : palette.white
}
export const themeBtnHighlightBorderColor = ({ theme }: IThemePassin) => getThemeValues(theme).btnHighlightBorderColor
export const themeBtnBorderColor = ({ theme }: IThemePassin) => getThemeValues(theme).btnBorderColor
export const themeBtnDisabledColor = ({ theme }: IThemePassin) => getThemeValues(theme).disabled
export const altActiveBgFor = ({ theme }: IThemePassin) => getThemeValues(theme).altActive
export const altHoverBgFor = ({ theme }: IThemePassin) => getThemeValues(theme).altHover
export const colorFor = ({ theme }: IThemePassin) => {
  // console.log('colorFor')
  // console.dir(theme)
  // console.dir(getTheme(theme))
  return getThemeValues(theme).isDarkSplashBg ? palette.white : palette.grayUnknownText
}
export const secondaryFor = ({ theme }: IThemePassin) => getThemeValues(theme).secondary
export const primaryFor = ({ theme }: IThemePassin) => getThemeValues(theme).primary
