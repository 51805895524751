import React, { FC, useMemo } from "react"
import Select, { ISelectOption } from "@cbs-sports/sports-shared-client/build/cjs/components/Select"

export interface IDisplayPoolSelectOption extends ISelectOption {
  year?: number
  isSelected?: boolean
}

const wrapperStyle = {
  width: "11rem",
}

interface IPoolHistoryDropdownProps {
  options: Array<IDisplayPoolSelectOption>
  onSelect: (option: IDisplayPoolSelectOption) => void
}

const PoolHistoryDropdown: FC<IPoolHistoryDropdownProps> = ({ options, onSelect }) => {
  const selectedValue = useMemo(() => {
    return options.find((o) => o.isSelected)
  }, [options])

  if (options.length <= 1) {
    return null
  }
  return (
    <div style={wrapperStyle}>
      <Select options={options} selectedValue={selectedValue} onSelect={onSelect} />
    </div>
  )
}

export default PoolHistoryDropdown
