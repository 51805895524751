import constants from "../../../../../../common/constants"
import { FORMATTED_US_NUMBER_REGEX } from "./modalData"

export const validateEntryName = (value?: string) => {
  if (!value?.trim()) {
    return "Bracket Name is required"
  }

  if (value.length < constants.ENTRY_NAME_MIN_LENGTH) {
    return `Bracket Name must be at least ${constants.ENTRY_NAME_MIN_LENGTH} characters`
  }

  if (value.length > constants.ENTRY_NAME_MAX_LENGTH) {
    return `Bracket Name must be less than ${constants.ENTRY_NAME_MAX_LENGTH} characters`
  }

  return undefined
}

export const validatePhoneNumber = (value?: string) => {
  if (value) {
    if (!FORMATTED_US_NUMBER_REGEX.test(value)) {
      return `Invalid phone number. It must match the pattern: (XXX) XXX-XXXX`
    }
  }
  return undefined
}
