import styled from "styled-components"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"

export const ColumnLink = styled.button`
  transition: color 0.2s ease-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  &:hover,
  &:active {
    color: ${theme.colors.overLight.blue2};
  }
`
