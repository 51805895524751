import { get as getCookie, set as setCookie } from "es-cookie"
import constants from "../../common/constants"
import { getStringParam } from "../../common/url-utils"
import { AdTypes, TSlotId } from "../App/Layout/constants/adDefinitions"
import { TAd } from "../App/Layout/typings"
import { canUseDom, getVGUID, loadScript } from "./misc-utils"
import { breakpoints } from "./style-utils"

// NOTE qac: this was very insightful: view-source:http://www.qa.cbssports.com/ads/ads-example-with-skybox?adTargeting_campaign=skybox2_v2_1_11_test&adNetwork=7336&skyboxCollapse=false

declare global {
  // tslint:disable-next-line
  interface Window {
    xfpSlot: any
    xfpFirstPage: any
    xfpSession: any
    xfpPageType: any
    xfpSubsession: any
    surround: any
    xfpGetArgs: () => void
    __SH_Ad: any
    BidBarrel: any
  }
}

interface ISharedProps {
  sizes: string
  name: TSlotId
  pos: string
  // xfpDomain: string;
  // xfpNetwork: string;
  xfpInstance: string
  xfpArena: string
  xfpFeature: string
  xfpPageType: string
  ptype: string
}
export interface IAdProps extends ISharedProps {
  prefix: string
  classNameSuffix: string
  skyboxHeight: number | undefined
  adType?: TAd
}

export interface IAdSlot extends ISharedProps {
  ptype: string
}

interface ISlot extends IAdSlot {
  render: boolean
  slot: any
}
interface ISlots {
  [key: string]: ISlot
}

const slots = {} as ISlots
const useIdSwitching = false
// const backoffMs = 200;
let timeoutId: number | null = null

// const extraLoading = false;
// const sdkLoading = false;
let isInitialRender = true

interface IContext {
  isCbsAppWebview: boolean
}
const context = {
  isCbsAppWebview: false,
} as IContext

function setContext(newContext: IContext) {
  Object.assign(context, newContext)
}

// Some variables that need to be set on the page for XFP ad serving:
// xfpInstance (7336|8264) => 7336 for dev and 8264 on QA and production
// xfpArena (collegebasketball|collegefootball|mlb|nba|other|nfl|nhl) => The arena (section) that the content is relevant to
// xfpFeature (home|picksheet) => The feature value for the page
//
// Note that your specific logic for xfpSlot will vary based on whatever requirements the ad folk give you. This was based on code that we
// gave to our partner, WorkState when they were initially building out SportsLine.com.

// ?adTargeting_campaign=skybox_test_latest&adNetwork=8264&adTargeting_test=999999
// ?adNetwork=8264&adTargeting_campaign=Test_Skybox_Leaders&adTargeting_test=999999

const xfpDomain = ".cbssports.com" // xfpDomain   : Set this appropriately for your domain.
const PROD_AD_NETWORK = "8264"
const DEV_AD_NETWORK = "8264"
let xfpNetwork = constants.APP_ENV === "prod" ? PROD_AD_NETWORK : DEV_AD_NETWORK // xfpNetwork  : (7336|8264) => 7336 for dev and 8264 on QA and production
if (canUseDom) {
  const adNetworkToUse = getStringParam("adNetwork", window.location.search)
  if (adNetworkToUse) {
    xfpNetwork = adNetworkToUse
  }
}
// let xfpInstance = "aw-cbssports";    // xfpInstance : (aw- for desktop. maw- for mobile)
// let xfpArena    = "other";           // xfpArena    : (collegebasketball|collegefootball|mlb|nba|other|nfl|nhl)
// let xfpFeature  = "home";            // xfpFeature  : (home|picksheet) => The feature value for the page
// let xfpPageType = "home|post";       // xfpPageType : Your Ad PM can provide more info about pageType values for your site.
// let xfpSlot     = '/' + xfpNetwork + '/' + xfpInstance + '/' + xfpArena + '/' + xfpFeature;
// xfpSlot='/7336/aw-cbssports/other/media/home'; // JUST FOR TESTING ONLY

let activeList: TSlotId[] = []

const forceAdsLocally = true

const incrementalAdUnits = ["gambling_partner", "gambling_partner_modal"]
export type TAdLayoutProps = {
  deviceType: string
  pathname: string
  poolRootPathname?: string
  productAbbrev?: string
  adType?: TAd
  isInvite?: boolean
  isCbsAppWebview?: boolean
}

let myLayoutProps: TAdLayoutProps = {} as never

let myDfpPath = ""

const initialize = async (initActiveList?: TSlotId[]) => {
  const xfpValues = getValuesForXfp(myLayoutProps)
  const dfpPath = buildXFPPath(xfpValues)
  myDfpPath = dfpPath
  if (isInitialRender) {
    setInitialTargettingValues(xfpValues.ptype)
    window.BidBarrel.initialize({
      dfpPath,
      collapseAllEmptyDivs: true,
      targeting: {
        auto: true,
        cookie: {
          consolidate: true,
        },
      },
      pageTargeting: {
        vguid: surround.vguid,
        srvclvl: "free", // we only have free
        env: xfpNetwork === PROD_AD_NETWORK ? `prod` : `dev`,
        ptype: xfpValues.ptype,
        // firstpg: window.xfpFirstPage,
        session: window.xfpSession,
        subses: window.xfpSubsession,
        // st: window.sp_blocked
      },
    })

    if (initActiveList) {
      setActiveList(initActiveList)
      window.BidBarrel.auction(activeList)
    }
  }
}

const setActiveList: (newActiveList: TSlotId[]) => void = (newActiveList: TSlotId[]) => {
  activeList = newActiveList
}

// {
//   prefix, = const prefix = `${classNameSuffix}-`;
//   classNameSuffix, const classNameSuffix = requestedPos || skyboxHeight && "skybox-collapsed" || isHandheld && "bottom" || "in-page";
//   name, // requestedPos && "mpu_top" || skyboxHeight && "skybox_leader_sticky" || isHandheld && "mobile_banner_top" || "top"
//   ptype, // const lastPathParam = pathname === layoutProps.poolRootPathname ? "picks" : parts[parts.length - 1];
// const ptype = lastPathParam === "picks" ? isBracket ? "matchups" : "picks and odds" : lastPathParam;
//   sizes, // const sizes = requestedPos && "[300,250]" || skyboxHeight && "[[5,5],[728,90],[970,66]]" || isHandheld && "[320,50]" || "[[728,90],[970,66],[970,90]]";
//   pos, // const pos = "top"; // requestedPos || skyboxHeight && "top" || isHandheld && "top" || "top";
//   // id,
//   xfpPageType, // see below
//   xfpInstance, // see below
//   xfpArena, // see below
//   xfpFeature, // see below
//   skyboxHeight, // const skyboxHeight = requestedPos ? undefined : (deviceType === "desktop" && 100 || deviceType === "tablet" && 84 || undefined);
//   adType // see below
// };

// const xfpInstance = `${isHandheld ? "m" : ""}aw-cbssports`;
// const xfpArena = /college-basketball/.test(pathname) && "collegebasketball" || /college-football/.test(pathname) && "collegefootball" || `nfl`;
// const xfpFeature = `fantasy`;
// const pageTypeParts = [`${gameType}_${lastPathParam.replace("picks", "makepicks")}`];
// // https://owl.cbsi.com/jira/browse/ADPM-975
// const productAbbrevTarget = (layoutProps.productAbbrev || "unknown").replace(/cnf/, "");
// pageTypeParts.unshift(productAbbrevTarget);
// if (/conference-tournament/.test(pathname)) {
//   pageTypeParts.unshift("conf_tourney");
// }
// const xfpPageType = pageTypeParts.join("/");
// // xfpSlot='/7336/aw-cbssports/other/media/home'; // JUST FOR TESTING ONLY
// const adType = layoutProps.adType;

interface IXFPValues {
  xfpArena: string
  xfpFeature: string
  xfpInstance: string
  xfpPageType: string
  ptype: string
  adType?: TAd
}

const marchMadnessAdMappings = {
  bracket: "lobby",
  standings: "standings",
  players: "players",
  "manager-players": "manager-players",
  "invite-center": "invites",
  challenge: "make-picks",
  picks: "make-picks",
  "create-pool": "create-pool",
  "game-weights": "create-scoring",
  invite: "create-invite",
  settings: "edit-settings",
  join: "join",
  roadblock: "road-block",
}

const getValuesForXfp = (adLayoutProps: TAdLayoutProps): IXFPValues => {
  const { deviceType, pathname, poolRootPathname, productAbbrev: initialProductAbbrev, adType, isInvite, isCbsAppWebview } = adLayoutProps
  const isHandheld = deviceType === "handheld"
  // split and remove empty strings caused by a trailing slash at the end ex: ncaa-tournament/bracket/
  const parts = pathname.split("/").filter((part) => part)
  const isBracket = /bracket/.test(pathname)
  const isMarchMadness = /ncaa(w?)-tournament/.test(pathname)
  const isGroups = /groups/.test(pathname)
  const gameType = (isBracket && "bracket") || "pickem"
  const lastPathParam = pathname === poolRootPathname ? "picks" : parts[parts.length - 1] || "picks"
  const lastPathParamPtype = lastPathParam === "picks" ? (isBracket ? "matchups" : "picks_and_odds") : lastPathParam
  let ptype = lastPathParamPtype
  if (isMarchMadness && marchMadnessAdMappings[lastPathParam]) {
    ptype = marchMadnessAdMappings[lastPathParam]
  } else if (isGroups) {
    ptype = "groups"
    if (isInvite) {
      ptype = "groups_splash"
    }
  }
  // const isMobile = !!slot.sizes && slot.sizes[0] === 320;
  // console.debug(`poolRootPathname: ${poolRootPathname}, pathname: ${pathname} (lastPathParam: ${lastPathParam} ptype: ${ptype})`)
  // https://owl.cbsi.com/jira/browse/ADPM-939
  const xfpInstance = `${isCbsAppWebview ? "app" : isHandheld ? "m" : ""}aw-cbssports`
  const xfpArena = (/college-basketball/.test(pathname) && "collegebasketball") || (/college-football/.test(pathname) && "collegefootball") || `nfl`
  const xfpFeature = `fantasy`
  let productAbbrev = initialProductAbbrev
  let pageTypeParts: string[] = []
  if (isMarchMadness) {
    pageTypeParts = [`${gameType}_${marchMadnessAdMappings[lastPathParam] || lastPathParam}`]
    if (marchMadnessAdMappings[lastPathParam] === "lobby") {
      // The Ads team is always expecting bpm as productAbbrev from the lobby page
      if (/ncaaw-tournament/.test(pathname)) {
        productAbbrev = "wbpm"
      } else {
        productAbbrev = "bpm"
      }
    }
  } else if (isGroups) {
    if (isInvite) {
      pageTypeParts = [`${gameType}_groups_splash`]
    } else {
      pageTypeParts = [`${gameType}_groups`]
    }
  } else {
    pageTypeParts = [`${gameType}_${lastPathParam.replace("picks", "makepicks")}`]
  }
  // https://owl.cbsi.com/jira/browse/ADPM-975
  const productAbbrevTarget = (productAbbrev || "unknown").replace(/cnf/, "")
  pageTypeParts.unshift(productAbbrevTarget)
  if (/conference-tournament/.test(pathname)) {
    pageTypeParts.unshift("conf_tourney")
  }
  const xfpPageType = pageTypeParts.join("/")
  // xfpSlot='/7336/aw-cbssports/other/media/home'; // JUST FOR TESTING ONLY
  // const skyboxHeight = adType.name.match(/skybox/) ? 100 : undefined; // (deviceType === "desktop" && 100) || (deviceType === "tablet" && 84) || undefined;
  return {
    // name: adType.name,
    // sizes: adType.sizes,
    // pos: adType.position,
    xfpArena,
    xfpFeature,
    xfpInstance,
    xfpPageType,
    ptype,
    adType,
    // skyboxHeight,
  } as IXFPValues
}

interface IXFPValues {
  xfpArena: string
  xfpFeature: string
  xfpInstance: string
  xfpPageType: string
}

const buildXFPPath = (xfpValues: IXFPValues): string => {
  return [
    "", // need this one for first / in the path
    xfpNetwork,
    xfpValues.xfpInstance,
    xfpValues.xfpArena,
    xfpValues.xfpFeature,
    xfpValues.xfpPageType, // if we have a pageSlot, use it, if not, use the page type
  ].join("/")
}

interface IGetDomValuesParams {
  adLayoutProps: TAdLayoutProps
  requestedPos?: string // only used in Picks, and it's set to "sidebar" // goal is to remove this and pass in which adSlot
  routeKey?: string // this isn't used
  adSlot?: TSlotId // only used in Picks, and it's set to "sidebar"
  // adPos?: string;
}

const getDomValues = (params: IGetDomValuesParams): IAdProps => {
  const { adSlot, adLayoutProps, requestedPos, routeKey } = params
  myLayoutProps = adLayoutProps || {}
  const { deviceType } = adLayoutProps
  const isDefined = canUseDom
  const key = useIdSwitching ? routeKey : undefined
  const isHandheld = deviceType === "handheld"
  const skyboxHeight = requestedPos ? undefined : (deviceType === "desktop" && 100) || (deviceType === "tablet" && 84) || undefined
  const adData = adSlot ? AdTypes[adSlot] : undefined
  const pos = adData?.position || "top"
  const sizes = adData?.sizes || "[[5,5],[728,90],[970,66]]" // that is the default that is set for skybox_leader_sticky
  const classNameSuffix = requestedPos || (skyboxHeight && "skybox-collapsed") || (isHandheld && "bottom") || "in-page"
  const prefix = `${classNameSuffix}-`
  const isAfterFirstClientSideLoad = isDefined && !!window.__SH_Ad
  const keyWithBackNavFix = (isAfterFirstClientSideLoad && (window.__SH_Ad.firstKey === key ? "load" : key)) || key
  const suffix = (isAfterFirstClientSideLoad && keyWithBackNavFix) || "load"
  const name: TSlotId = useIdSwitching
    ? (isAfterFirstClientSideLoad && window.__SH_Ad[prefix + suffix]) || prefix + suffix
    : // use defined adSlot if it is there, else, default to existing logic
      adSlot || (requestedPos && "mpu_top") || (skyboxHeight && "skybox_leader_sticky") || (isHandheld && "mobile_banner_top") || "top"
  if (isDefined && !window.__SH_Ad) {
    window.__SH_Ad = { firstKey: key }
    // console.log(`window.__SH_Ad: '${name}'  ${JSON.stringify(window.__SH_Ad)}`)
  }

  const { adType, ptype, xfpPageType, xfpInstance, xfpArena, xfpFeature } = getValuesForXfp(adLayoutProps)

  return {
    prefix,
    classNameSuffix,
    name,
    ptype,
    sizes,
    pos,
    // id,
    xfpPageType,
    xfpInstance,
    xfpArena,
    xfpFeature,
    skyboxHeight,
    adType,
  }
}

const extractProps = (props: IAdProps): IAdProps => {
  const {
    prefix,
    classNameSuffix,
    name,
    ptype,
    sizes,
    pos,
    // id,
    xfpPageType,
    xfpInstance,
    xfpArena,
    xfpFeature,
    skyboxHeight,
    adType,
  } = props
  return {
    prefix,
    classNameSuffix,
    name,
    ptype,
    sizes,
    pos,
    // id,
    xfpPageType,
    xfpInstance,
    xfpArena,
    xfpFeature,
    skyboxHeight,
    adType,
  }
}

const currentTargeting = [
  { key: "ptype", value: "" }, // xfpPageType : Your Ad PM can provide more info about pageType values for your site.
  // { key: "firstpg", value: "" }, // Is this the first pageview of the visit.
  { key: "session", value: "" }, // Sessions a -f.
  { key: "subses", value: "" }, // SubSessions 1-4
  { key: "vguid", value: "" }, // VGuid is a unique identifier for each visitor/pageview. It is passed into ads and analytics so that they may be linked together.
]

const surround = {
  cat: ["a", "b", "c", "d", "e", "f"],
} as any

// Note LL: We can clean this up later since this is now handled by BidBarrel
// we will still need to manage vguid's
const updateSurroundCookie = () => {
  surround.cookie = getCookie("surround")
  surround.vguid = getVGUID()
  if (surround.cookie === false || surround.cookie === undefined) {
    surround.pick = Math.floor(Math.random() * surround.cat.length)
    surround.subsession = Math.floor(Math.random() * 4) + 1 /*subsessions 1-4*/
    surround.cookie = surround.cat[surround.pick] + "|" + surround.subsession
    setCookie("surround", surround.cookie, {
      path: "/",
      domain: xfpDomain,
      secure: true,
      sameSite: "none",
    })
  }
  if (surround.cookie) {
    surround.sessions = surround.cookie.split("|")
  }
}

const isBrave = () => {
  if ((window.navigator as any).brave != undefined) {
    if ((window.navigator as any).brave.isBrave?.name == "isBrave") {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const init = async () => {
  const isBraveBrowser = isBrave()
  if (canUseDom && !isBraveBrowser && !context.isCbsAppWebview && (forceAdsLocally || constants.APP_ENV !== "local")) {
    if (!window.BidBarrel) {
      const immediateUseBidBarrelFunctions = ["auction", "initialize", "setTargeting", "destroySlots", "setConfig", "on", "clearSlots"]
      // tslint:disable-next-line
      ;(function (a) {
        const w = window
        const b = "BidBarrel"
        const q = "queue"
        w[b] = w[b] ? w[b] : {}
        w[b][q] = w[b][q] ? w[b][q] : []
        a.forEach((z) => {
          w[b][z] =
            w[b][z] ||
            function () {
              // eslint-disable-next-line prefer-rest-params
              const c = arguments // put this back into non-arrow function because `arguments` gets lost n arrow functions
              w[b][q].push((r) => {
                // eslint-disable-next-line prefer-spread
                w[b][z].apply(w[b], c)
                r()
              })
            }
        })
      })(immediateUseBidBarrelFunctions)
      // Prod: `bidbarrel_path: 'https://at.cbsi.com/lib/dist/prod/bidbarrel-cbssports.min.js'`
      // Dev/QA: `bidbarrel_path: 'https://at.cbsi.com/lib/dist/stage/bidbarrel-cbssports.min.js'`
      // Latest: `bidbarrel_path: 'https://at.cbsi.com/lib/dist/latest/bidbarrel-cbssports.min.js'`

      const bidBarrelPath =
        xfpNetwork === PROD_AD_NETWORK
          ? "https://at.cbsi.com/lib/dist/prod/bidbarrel-cbssports.min.js"
          : "https://at.cbsi.com/lib/dist/stage/bidbarrel-cbssports.min.js"
      await loadScript(bidBarrelPath)
      if (isInitialRender) {
        enqueueRender(0)
      }
    }
  }
}

const setInitialTargettingValues = (pageType?: string) => {
  // This code block will test for and generate, if needed, the surround cookie which includes the session and subsession.
  // It will also store the page's vguid in the surroud object for use in ads and analytics tagging.
  // This is recommended to be added to the HEAD section of the page.
  updateSurroundCookie()
  // tracks if this is the first page the user has visited in this session
  // const hasVisitedCookie = getCookie("XFP_FIRSTPAGE")
  // const xfpFirstPage = hasVisitedCookie ? "0" : "1" // if cookie doesn't exist, this is the first page, so firstPage = 1
  const xfpSession = surround.cookie ? surround.sessions[0] : "off"
  const xfpSubsession = surround.cookie ? surround.sessions[1] : "off"
  // get number of milliseconds until 12:45am ET
  const exp = new Date()
  const month = exp.getUTCMonth() // get month in UTC
  const date = exp.getUTCDate() // get month in UTC
  const day = exp.getUTCDay() // get month in UTC
  let isEDT = false
  if (month > 2 && month < 10) {
    isEDT = true
  } else if (month < 2 || month > 10) {
    isEDT = false
  } else if (month === 10) {
    isEDT = true
    if (date > 7) {
      // after first week, not in EDT
      isEDT = false
    }
    if (day === 0 && exp.getUTCHours() >= 6) {
      // Sunday of first week, check time before 6am UTC, in EDT
      isEDT = false
    }
    if (date > day) {
      // Sunday has passed, not in EDT
      isEDT = false
    }
    // Sunday has not passed, in EDT
  } else if (date < 8) {
    // still first week, not in EDT
    isEDT = false
  } else if (date > 14) {
    // after second week, in EDT
    isEDT = false
  } else if (date === 0 && exp.getUTCHours() > 6) {
    // 7am UTC or later, in EDT
    isEDT = true
  } else if (date === 0) {
    isEDT = false
  } else if (date - day > 7) {
    isEDT = true
  }
  const etExpireHour = 0.75
  const etUtcOffset = isEDT ? 4 : 5 // if in daylight savings time
  const utcExpire = (etExpireHour + etUtcOffset) % 24 // UTC expire time in hours
  const utcTime = 60 * exp.getUTCHours() + exp.getUTCMinutes() // UTC time in mins
  let minsToExpiration = 60 * utcExpire - utcTime // time to expiration
  if (minsToExpiration < 0) {
    minsToExpiration = 24 * 60 + minsToExpiration
  }
  const xfpTimeToExpiration = 60000 * minsToExpiration // in milliseconds
  exp.setTime(exp.getTime() + xfpTimeToExpiration) // get number of milliseconds until 12:45am ET
  setCookie("XFP_FIRSTPAGE", "1", {
    path: "/",
    domain: xfpDomain,
    expires: exp,
    secure: true,
    sameSite: "none",
  })

  // populate currentTargeting
  if (typeof window !== "undefined") {
    // window.xfpFirstPage = xfpFirstPage
    window.xfpSession = xfpSession
    window.xfpSubsession = xfpSubsession
    window.surround = surround
    window.xfpPageType = pageType || "unknown"
    setCurrentTargetting()
  }
}

const setCurrentTargetting = () => {
  updateSurroundCookie()
  if (typeof window !== "undefined") {
    currentTargeting.forEach((pair) => {
      // if (pair.key === "firstpg") {
      //   pair.value = window.xfpFirstPage
      // }
      if (pair.key === "session") {
        pair.value = window.xfpSession
      }
      if (pair.key === "subses") {
        pair.value = window.xfpSubsession
      }
      if (pair.key === "vguid") {
        pair.value = window.surround.vguid
      }
      if (pair.key === "ptype") {
        pair.value = window.xfpPageType
      }
    })
  }
}

const updateCurrentTargetting = (pageType?: string) => {
  updateSurroundCookie()
  if (typeof window !== "undefined") {
    // always unset FirstPage since its no longer the "first page"
    // window.xfpFirstPage = "0"
    window.xfpPageType = pageType || window.xfpPageType
    setCurrentTargetting()
  }
}

const xfpGetArgs = () => {
  let newFtag = false
  if (typeof window !== "undefined") {
    const query = window.location.search.substring(1) // get query string
    const pairs = query.split("&")
    pairs.forEach((pair) => {
      const pos = pair.indexOf("=") // Look for name "name=value"
      if (pos !== -1) {
        // If not found, skip
        let argname = pair.substring(0, pos) // Extract the name
        const value = decodeURIComponent(pair.substring(pos + 1)) // Extract the value Decode it, if needed
        const adTarg = argname.match(/adTargeting_/) // Do we have a match on adTargeting_<key>
        // Set Targeting for spec'd out query parms
        if (
          argname === "env" ||
          argname === "session" ||
          argname === "subses" ||
          argname === "adRegion" ||
          argname === "ftag" ||
          argname === "ttag" ||
          adTarg
        ) {
          // args[argname] = value;
          if (adTarg) {
            // strip off adTargeting_ from the name if present
            argname = argname.substring(12)
          }
          window.BidBarrel.setTargeting({ argname: value })
          if (argname === "ftag" || argname === "ttag") {
            setCookie("xfpFtag", value, {
              path: "/",
              domain: xfpDomain,
              secure: true,
              sameSite: "none",
            })
            newFtag = true
          }
        }
      }
    })
    const xfpFtagVal = getCookie("xfpFtag") // check if we have an ftag cookie
    if (xfpFtagVal && !newFtag) {
      // If no new setting for ftag but we had an old one then
      window.BidBarrel.setTargeting({ ftag: xfpFtagVal })
    }
  }
}

const processQueue = () => {
  if (canUseDom && window.BidBarrel) {
    // console.log("🚀 DEBUG -> processQueue -> slots = ", slots)
    // console.debug(`executing!`, slots);

    // display slots that were just created AFTER setting the target n such
    const slotsArray = Object.values(slots)
    // console.log("🚀 DEBUG -> processQueue -> slotsArray = ", slotsArray)
    const slotsToCreate = slotsArray.filter((slot) => !!slot.render && !slot.slot)
    // console.log("🚀 DEBUG -> processQueue -> slotsToCreate = ", slotsToCreate)
    const newSlot = (slotsToCreate.length && slotsToCreate[0]) || (slotsArray.length && slotsArray[0]) || undefined
    // console.log("🚀 DEBUG -> processQueue -> newSlot = ", newSlot)
    let needToDestroySlots = false
    // NOTE QAC: we put this in here so we ensure we only call this function once per change
    timeoutId = null
    // console.log("🚀 DEBUG -> processQueue -> isInitialRender = ", isInitialRender)
    if (isInitialRender) {
      initialize()
      isInitialRender = false
    } else {
      const xfpValues = getValuesForXfp(myLayoutProps)
      const dfpPath = buildXFPPath(xfpValues)
      needToDestroySlots = myDfpPath !== dfpPath
      myDfpPath = dfpPath
      window.BidBarrel.setConfig("dfpPath", dfpPath)
    }

    // create slot.slot for neeeded
    slotsToCreate.forEach((slot) => {
      const slotName = [xfpNetwork, slot.xfpInstance, slot.xfpArena, slot.xfpFeature, slot.xfpPageType].join("/")
      slot.slot = [`${slotName}`, slot.sizes, slot.name].join("|")
      if (!activeList.includes(slot.name)) {
        const newActiveList = activeList
        newActiveList.push(slot.name)
        setActiveList(newActiveList)
      }
    })

    // Note - Leo - We can auction all slots in the activeList like this, but going with the route up top to set pos values
    // window.BidBarrel.auction(activeList)

    if (isInitialRender) {
      setInitialTargettingValues(newSlot?.ptype || "")
    } else {
      window.BidBarrel.queue.push((resolve) => {
        if (window.BidBarrel.pageTargeting.firstpg == 1) {
          // needs to be == because 1 could be string or number
          window.BidBarrel.setTargeting({ firstpg: 0 })
        }
        resolve()
      })
      updateCurrentTargetting(newSlot?.ptype)
    }

    slotsToCreate.forEach((slot) => {
      if (slot.slot) {
        // console.debug(`Creating slot: '${slotName}' (ptype: ${slot.ptype}, pos: ${slot.pos})`)
        // commenting out to debug - window.BidBarrel.setTargeting({ pos: slot.pos, ptype: slot.ptype }, [slot.name])
      } else {
        console.warn(`failed to create slot: '${slot.name}'`)
      }
    })

    xfpGetArgs() // to set/override targeting for env, session, subses, adRegion, ftag, ttag, and adTargeting_<key> if set by query string
    // refresh and after the slot div is in the page.

    // teardown slot.slot for neeeded
    const slotsToDestroy = needToDestroySlots ? slotsArray : slotsArray.filter((slot) => !slot.render && !!slot.slot)
    // console.log("🚀 DEBUG -> processQueue -> slotsToDestroy:", slotsToDestroy)
    const slotsToRefresh = slotsArray.filter((slot) => !slotsToDestroy.includes(slot) && !slotsToCreate.includes(slot))
    // console.log("🚀 DEBUG -> processQueue -> slotsToRefresh:", slotsToRefresh)
    if (slotsToDestroy.length) {
      // console.log(`'destroying slot ${slotsToDestroy[0].name}'`)
      const slotNames = slotsToDestroy.map((slot) => slot.name)
      try {
        window.BidBarrel.destroySlots(slotNames)
        window.BidBarrel.clearSlots(slotNames)
      } catch (error) {
        console.error(`Error destroying slots: `, error)
      }
    }
    slotsToDestroy.forEach((slot) => (slot.slot = null))
    if (slotsToRefresh.length) {
      // console.log(`'clearing slot ${slotsToRefresh[0].name}'`)
      window.BidBarrel.clearSlots(slotsToRefresh.map((slot) => slot.name))
      // window.BidBarrel.auction(slotsToRefresh.map((slot) => slot.name));
    }

    // these values should be the same for every ad on a page...
    // This next block includes the ad definitions relevant to each page.
    // Please note that there is a section for desktop visitors and another section specific to mobile visitors.
    for (const currentTargetingOption of currentTargeting) {
      const key = currentTargetingOption.key
      const value = currentTargetingOption.value
      window.BidBarrel.setTargeting({ [key]: value })
    }

    if (slotsToCreate.length) {
      window.BidBarrel.auction(
        slotsToCreate.map((slot) => {
          const incrementalUnit = incrementalAdUnits.includes(slot?.name) ? 1 : null
          return [slot.name, incrementalUnit, { pos: slot.pos }]
        }),
      )
    }

    // window.shSlots = currentSlots;

    // only apply styles to inline ad if it's not a blank ad
    window.BidBarrel.on("identified", function ({ type, code }, _unitConfig) {
      if (code === "gambling_partner" && type !== "blank") {
        const adDiv = document?.querySelector<HTMLElement>(".gambling-partner-inline-ad")
        if (adDiv) {
          adDiv.classList.add("gambling-partner-inline-ad-container")
        }
      }
    })
  } else {
    init()
    timeoutId = null
  }
}

const enqueueRender = (ms: number) => {
  // console.log("🚀 DEBUG -> enqueueRender -> ms:", ms)
  // console.debug(`enqueueRender - timeoutId: '${timeoutId}'`);
  // console.log("🚀 DEBUG -> enqueueRender -> timeoutId:", timeoutId)
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  if (Object.keys(slots).length) {
    timeoutId = setTimeout(processQueue, ms)
    // console.log("🚀 DEBUG -> enqueueRender -> SETTING timeoutId:", timeoutId)
  } else {
    timeoutId = null
    // console.log("🚀 DEBUG -> enqueueRender -> UN-SETTING timeoutId:", timeoutId)
  }
}

const getKeyForAdSlot = (name, pos, sizes) => {
  return [name, sizes, pos].join("|")
}

const register = ({ name, pos, sizes, ptype, xfpInstance, xfpArena, xfpFeature, xfpPageType }: IAdSlot) => {
  // console.log("🚀 DEBUG -> register -> values = ", { name, pos, sizes, ptype, xfpInstance, xfpArena, xfpFeature, xfpPageType })
  const key = getKeyForAdSlot(name, pos, sizes)
  // console.log("🚀 DEBUG -> register -> key = ", key)
  // dont render "[300,250]" if its not large enough screen!
  // https://owl.cbsi.com/jira/browse/ADPM-966
  if (sizes === "[300,250]" && window.innerWidth < breakpoints.tablet) {
    // console.debug(`ignoring 300,250 register`)
    return
  }
  // const slot = slots[key];
  // console.debug(`register: ${key}`);
  if (slots[key] && slots[key].slot) {
    // already rendered
    if (!useIdSwitching) {
      // console.log("🚀 DEBUG -> register -> slot DOES exists")
      enqueueRender(200)
    }
  } else {
    // console.log("🚀 DEBUG -> register -> slot DOES NOT exists")
    slots[key] = {
      name,
      pos,
      ptype,
      xfpInstance,
      xfpArena,
      xfpFeature,
      xfpPageType,
      render: true,
      sizes: JSON.parse(sizes),
      slot: null,
    }
    enqueueRender(200)
  }
}

const teardown = ({ name, pos, sizes }: IAdSlot) => {
  const key = getKeyForAdSlot(name, pos, sizes)
  const slot = slots[key]
  if (useIdSwitching) {
    // console.debug(`teardown: ${key}`);
    if (slot && slot.render) {
      slot.render = false
      // enqueueRender();
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }
      window.BidBarrel.destroySlots([slot])
      // processQueue()
    }
    if (useIdSwitching && typeof window !== "undefined" && window.__SH_Ad) {
      window.__SH_Ad[name] = `${name}-${Date.now()}`
    }
  }
  if (window.BidBarrel) {
    try {
      window.BidBarrel.destroySlots([name])
      if (slot) {
        delete slots[key]
      }
    } catch (error) {
      console.error(`Error destroying slots: `, error)
    }
  }
}

const refresh = ({ name, pos, sizes, ptype, xfpInstance, xfpArena, xfpFeature, xfpPageType }: IAdSlot) => {
  // console.log("🚀 DEBUG -> refresh -> values = ", { name, pos, sizes, ptype, xfpInstance, xfpArena, xfpFeature, xfpPageType })

  if (!useIdSwitching) {
    const key = getKeyForAdSlot(name, pos, sizes)
    // console.log("🚀 DEBUG -> refresh -> key = ", key)
    slots[key] = {
      name,
      pos,
      ptype,
      xfpInstance,
      xfpArena,
      xfpFeature,
      xfpPageType,
      render: true,
      sizes: JSON.parse(sizes),
      slot: null,
    }
    enqueueRender(200)
  }
}

// Refreshes a list of ads that you pass in.
// Example call: refreshBids(["leader_plus_top","sky_top"]);

// For ads that have bidder parameters, if you call BidBarrel.refresh they need to be re-auctioned.
// BidBarrel.auction itself has an internal check for units without bidders and will short circuit the header bidding process
// and go straight to refresh though. So we use .auction instead of .refresh.

// const refreshBids = (units: TSlotId[]) => {
//   if (window.BidBarrel) {
//     window.BidBarrel.auction(units)
//   }
// }

const BidBarrel = {
  teardown,
  register,
  refresh,
  getDomValues,
  extractProps,
  useIdSwitching,
  setContext,
}

export default BidBarrel
