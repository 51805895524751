import { DbRow, IGameInstanceCopy } from "./db-typings"
import { dateToEpoch, emptyArray } from "./misc-utils"
import settings from "../settings"
import { CompetitionFragment_odds } from "../server/api/datasources/UIGateway/__generated__/CompetitionFragment"

export const periodExtraDisabledEventTitlesKey = "disabledEventTitles"
export const periodExtraTournamentWinnerPlayInTeamIdsKey = "tournamentWinnerPlayInTeamIds"
export const periodExtraTiebreakerQuestionTitleKey = "tiebreakerQuestionTitle"
export const periodNotificationBannerHtmlKey = "notificationBannerHtml"
export const periodNotificationBannerBackgroundColorKey = "notificationBannerBackgroundColor"
export const periodNotificationBannerColorKey = "notificationBannerColor"
export const periodGlobalNotificationHtmlKey = "globalNotificationHtml"
export const periodGlobalNotificationBackgroundColorKey = "globalNotificationBackgroundColor"
export const periodGlobalNotificationColorKey = "globalNotificationColor"
export const periodOverrideStartsAtAttr = "overrideStartsAt"
export const lastDismissedReactivateSeasonIdKey = "lastDismissedReactivateSeasonId"
export const noMessageBoardNotificationKey = "noMessageBoardNotification"
export const tournamentPickPercentKey = "tournamentPickPercent"

export const dupMatchupHackKey = "isDupMatchup"

export type IEventGameOdds = {
  source: string
  bookId: string
  type: string
  side: string
  outcomeOdds: string
  openingOutcomeOdds: string
  bookName: string
  bookDisplayName: string
  teamId?: number
  value?: string
  openingValue?: string
  selectionId?: string
}

export interface IEventWithGameOdds extends DbRow {
  gameOdds: IEventGameOdds | null
  gameOddsChangelog: IEventGameOddsChangelog | null
}

export interface IEventGameOddsChange {
  marketsLastUpdatedAt: number
  detectedAt: number
  changeType: "INITIAL" | "CHANGED" | "REMOVED"
  lineType?: IEventOddsMarketConsensusLineTypes
  teamId?: number | "DRAW"
  choice?: string
  bookName?: string
  attributeName?: string
  previousValue?: string
  newValue?: string
}

export interface IEventGameOddsChangelog {
  changes: IEventGameOddsChange[]
}

export interface IEventOddsMarketShared {
  id: string
  odds: string
  openingOdds: string
  oddsTrend?: "NEUTRAL" | "UP" | "DOWN"
  teamId?: number | "DRAW"
  choice?: "OVER" | "UNDER"

  selectionId?: string
}

export interface IEventOddsMarketBook {
  id: string
  name: string
  displayName: string
}

export interface IEventOddsMarketSpread extends IEventOddsMarketShared {
  teamId: number
  spread: string
  openingSpread: string
  side: string
}

export interface IEventOddsMarket2WayOr3Way extends IEventOddsMarketShared {
  teamId: number | "DRAW"
  isDrawVersion: boolean
}

export interface IEventOddsMarketTotal extends IEventOddsMarketShared {
  choice: "OVER" | "UNDER"
  total: string
  openingTotal: string
}

export interface IEventOddsMarket {
  id: string
  bookUsed: IEventOddsMarketBook
  spreads: IEventOddsMarketSpread[]
  moneyLines: IEventOddsMarket2WayOr3Way[]
  totals: IEventOddsMarketTotal[]
}

export type IEventOddsMarketLineTypes = "spread" | "2way" | "3way" | "total" | "asian_handicap"
export type IEventOddsMarketConsensusLineTypes = "spread" | "moneyline" | "total"

function getOddsFromUIGateway(gameOdds: CompetitionFragment_odds[], book?: string) {
  const TEAM_MARKET = "TEAM_MARKET"
  const CONSENSUS = "CONSENSUS"
  if (book && gameOdds.some((x) => x.source === TEAM_MARKET && x.bookName === book)) {
    return gameOdds.filter((x) => x.source === TEAM_MARKET && x.bookName === book)
  } else if (gameOdds.some((x) => x.source === CONSENSUS)) {
    return gameOdds.filter((x) => x.source === CONSENSUS)
  } else {
    return null
  }
}

function getOddsFromPicks(gameOdds: IEventGameOdds[], book?: string) {
  const TEAM_MARKET = "TEAM_MARKET"
  const TEAM_CONSENSUS = "TEAM_CONSENSUS"
  if (book && gameOdds.some((x) => x.source === TEAM_MARKET && x.bookName === book)) {
    return gameOdds.filter((x) => x.source === TEAM_MARKET && x.bookName === book)
  } else if (gameOdds.some((x) => x.source === TEAM_CONSENSUS)) {
    return gameOdds.filter((x) => x.source === TEAM_CONSENSUS)
  } else {
    return null
  }

  // // NOTE LL: If we want to check for a specific book, lets not check to make sure we have consensus odds since we're not going to use them
  // if (book && book !== ENUM_CONSENSUS && gameOdds?.teamMarkets?.find((tm) => tm.teamBooks.find((tmb) => tmb.name === book))) {
  //   return gameOdds
  // }
  // // NOTE LL: we were getting team consensus `bet_percentage_outcomes` but no lines for some events. Let's make sure we have lines that we can use
  // else if (gameOdds.teamConsensus && gameOdds.teamConsensus.find((tc) => tc.name === "lines")) {
  //   return gameOdds
  // } else {
  //   return null
  // }
}

export function getGameOddsFromEvent(event: DbRow, book?: string) {
  if (!settings.USE_UI_GATEWAY_COMPETITIONS) {
    const gameOdds = getExtraColumn(event, "gameOdds", emptyArray)
    return getOddsFromPicks(gameOdds, book)
  }
  const gameOdds = getExtraColumn(event, "gameOdds", [])
  return getOddsFromUIGateway(gameOdds, book)
}

export const oddsGroupColumnNamesWithValues = ["spread", "openingSpread", "total", "openingTotal", "odds", "openingOdds", "oddsTrend"]
export const trackedOddsGroupColumnNamesWithValues = ["spread"]

export function getDisabledPeriodEventTitlesFor(period: DbRow) {
  const extra = getExtraColumn(period)
  return (extra[periodExtraDisabledEventTitlesKey] || []) as string[]
}

export const getGameInstanceCopy = (gameInstance: DbRow): IGameInstanceCopy => {
  const copy = getExtraColumn<IGameInstanceCopy>(gameInstance, "copy")
  return copy
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getExtraColumn = <T = any>(row: any, columnName = "extra", fallback = {} as T): T => {
  // NOTE qac: account for knex not inflating this
  const extra = row[columnName] || fallback
  if (typeof extra === "string") {
    return JSON.parse(row[columnName])
  }
  return extra
}
export const serializeExtraColumn = (extra: any) => {
  if (Object.keys(extra || {}).length) {
    return JSON.stringify(extra)
  } else {
    return null
  }
}

export function getStartsAtForPeriod(per: DbRow): number {
  // NOTE qac: this is for convincing a season to rollover before it's time...
  return getExtraColumn(per)[periodOverrideStartsAtAttr] || dateToEpoch(per.startsAt)
}
