import * as React from "react"

const BracketPlaceHolder = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 16c0 .5-.5 1-1 1h-5v5c0 .5-.5 1-1 1h-5v2c0 .5-.5 1-1 1H9c-.5 0-1-.5-1-1s.5-1 1-1h4v-4H9c-.5 0-1-.5-1-1s.5-1 1-1h5c.5 0 1 .5 1 1v2h4V11h-4v2c0 .5-.5 1-1 1H9c-.5 0-1-.5-1-1s.5-1 1-1h4V8H9c-.5 0-1-.5-1-1s.5-1 1-1h5c.5 0 1 .5 1 1v2h5c.5 0 1 .5 1 1v5h5c.5 0 1 .5 1 1z"
      fill="currentColor"
    />
  </svg>
)

export default BracketPlaceHolder
