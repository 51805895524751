import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ChevRightSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ChevRight"
import ChevLeftSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/ChevLeft"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import { palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import * as React from "react"
import styled from "styled-components/macro"

const PaginationContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  & > button.variant--secondary {
    color: ${palette.gray20};
    background-color: ${palette.white};
    padding-right: 0.5rem;
    &:hover:not(:active) {
      background-color: ${theme.colors.overLight.blue1};
    }
  }
  & > button:first-child {
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
`

const PaginationNew = ({
  hasPreviousPage,
  hasNextPage,
  endCursor,
  goToPage,
  isLoading,
  pageNumber,
  goToPageNumber,
  stylesProps,
}: {
  hasPreviousPage: boolean
  hasNextPage: boolean
  startCursor?: string | null
  endCursor?: string | null
  goToPage: (cursor: any) => void
  isLoading?: boolean
  pageNumber?: number
  goToPageNumber?: (page: number) => void
  stylesProps?: React.CSSProperties
}) => {
  const handleGoToNextPage = () => {
    goToPage(endCursor)
    if (typeof goToPageNumber === "function") {
      goToPageNumber(pageNumber || 0 + 1)
    }
  }
  const handleGoToFirstPage = () => {
    goToPage(null)
    if (typeof goToPageNumber === "function") {
      goToPageNumber(0)
    }
  }

  if (!hasPreviousPage && !hasNextPage) {
    return null
  }

  return (
    <PaginationContainer style={stylesProps}>
      <Button variant="secondary" disabled={isLoading || !hasPreviousPage} onClick={handleGoToFirstPage}>
        <ListItemIcon alignment="left">
          <ChevLeftSvg />
        </ListItemIcon>
        First
      </Button>
      <Button variant="secondary" disabled={isLoading || !hasNextPage} onClick={handleGoToNextPage}>
        Next
        <ListItemIcon alignment="right">
          <ChevRightSvg />
        </ListItemIcon>
      </Button>
    </PaginationContainer>
  )
}
export default PaginationNew
