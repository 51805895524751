import * as React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components/macro"
import LocalKey, { IInjectedILocalKeyProps } from "../Base/LocalKey"
import { FlexRow } from "../components/FlexComponents"
import { zIndexes } from "../utils/style-utils"

const toggleableClassname = "toggleable"
export const toggleableToggledClassname = "toggled"

const StyledDropdown = styled.div`
  position: absolute;
  top: 100%;
  z-index: ${zIndexes.siteNavDropdown};
`

const Container = styled(FlexRow)`
  height: 100%;
  overflow: visible;
  flex: 0 1 auto;
  position: relative;
`

const Toggler = styled(FlexRow)`
  transition: all 0.2s ease;
`

interface IDropdownEls {
  container?: React.Component | React.FC
  toggler?: React.Component | React.FC
  dropdown?: React.Component | React.FC
  disableHover?: boolean
  onClick?: (event?: any) => void
  className?: string
}

interface IDropdown extends IInjectedILocalKeyProps, IDropdownEls {
  valueName: string
}

const canHover = () => window.matchMedia("(hover: hover)").matches

class Dropdown extends React.Component<IDropdown> {
  public timeoutId: number | null = null
  public mounted = false
  public bound = false
  public node: any = null

  public onPointerEnter = (_event) => {
    // console.log('onPointerEnter')
    if (!this.props.disableHover && canHover()) {
      this.clearTimeout()
      this.props.update(this.props.valueName)
    }
  }

  public onClick = (event, onClickCallback?: () => void) => {
    // console.log('onclick')
    event.preventDefault()
    // console.log(evt.target)
    // if (evt.target && evt.target.classList.contains(toggleableClassname)) {
    this.props.update(this.props.value === this.props.valueName ? null : this.props.valueName)
    onClickCallback?.()
    // }
  }

  public onPointerLeave = () => {
    // console.log('onPointerLeave')
    if (!this.props.disableHover && canHover()) {
      this.clearTimeout()
      this.timeoutId = setTimeout(this.close, 200)
    }
  }

  public clearTimeout = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  }

  public close = () => {
    this.clearTimeout()
    if (this.props.value === this.props.valueName) {
      this.props.update(null)
    }
  }

  public onClickOutside = (event) => {
    // console.log(event)
    // console.dir(this.node)
    // console.log(`contains? ${this.node.contains(event.target)}`)
    if (this.mounted && this.node && !!this.node.contains && !this.node.contains(event.target)) {
      // console.log(`close!`)
      this.close()
    }
  }

  public componentDidMount() {
    // console.log('componentDidMount')
    this.mounted = true
  }

  public componentWillUnmount() {
    // console.log('componentWillUnmount')
    this.mounted = false
    this.node = undefined
  }

  public componentDidUpdate() {
    // console.log(`componentDidUpdate: ${this.props.valueName}`)
    this.updateClickAway()
  }

  public updateClickAway = () => {
    const {
      mounted,
      bound,
      node,
      props: { value, valueName },
    } = this
    if (mounted && !bound && value === valueName) {
      // console.log(`bound: ${value} ${valueName}`)
      if (!node) {
        // defer this lookup until now so we dont do this on mount for every dropdown
        this.node = ReactDOM.findDOMNode(this)
      }
      this.bound = true
      document.addEventListener("click", this.onClickOutside)
    }
    if (mounted && bound && value !== valueName) {
      // console.log(`unbound: ${value} ${valueName}`)
      this.bound = false
      document.removeEventListener("click", this.onClickOutside)
    }
  }

  public render() {
    const { value, container, toggler, dropdown, valueName, className, onClick: onClickCallback, update, ...rest } = this.props
    // console.log(`value: ${value}, valueName: ${valueName}`)
    const toggled = value === valueName
    return (
      <Container
        as={(container || "div") as any}
        className={`${toggleableClassname} ${toggled ? toggleableToggledClassname : ""} ${className || ""}`}
        onPointerEnter={this.onPointerEnter}
        onPointerLeave={this.onPointerLeave}
      >
        <Toggler
          isToggled={toggled}
          as={(toggler || "button") as any}
          type="button"
          onClick={(event) => this.onClick(event, onClickCallback)}
          {...rest}
        />
        {toggled && <StyledDropdown as={(dropdown || "div") as any} {...rest} />}
      </Container>
    )
  }
}

interface IConnectedDropdown extends IDropdownEls {
  keyName?: string
  valueName: string
  className?: string
  toggler?: any
  dropdown?: (props: any) => JSX.Element
}

class ConnectedDropdown extends React.Component<IConnectedDropdown> {
  public render() {
    const { keyName, valueName, ...rest } = this.props
    const newKeyName = this.props.keyName || "dropdown"
    // console.log(`valueName: ${valueName}, newKeyName: ${newKeyName}`)
    return <LocalKey keyName={newKeyName}>{(props) => <Dropdown {...props} {...rest} valueName={valueName} />}</LocalKey>
  }
}

export default ConnectedDropdown
