import * as React from "react"
import {
  DropdownCta,
  DropdownInner,
  DropdownRow,
  NavDropdownItemAlt,
  StyledGameItem,
  StyledGameItemBtn,
  StyledGameItemImg,
  StyledGameItemText,
  StyledNavDropdownItem,
} from "../styles/SiteNavSidePlayItem.styles"
import constants from "../../../../common/constants"

const genFantasyLink = (sportId: string) => ({
  href: `/fantasy/games/${sportId}?ttag=FF16_os_drpdwn_lk`,
  title: `Fantasy ${sportId}`,
})
const DropdownLinks = {
  play: ["football", "baseball", "basketball", "hockey"].map(genFantasyLink),
}

const NavDropdownItem = ({ href, title }) => (
  <StyledNavDropdownItem
    as="a"
    title={title}
    className={isExternalHref(href) ? "external-link" : ""}
    href={processHref(href)}
    target="_blank"
    rel="noopener nofollow"
  >
    {title}
  </StyledNavDropdownItem>
)

const hasHttp = /https?:/
const isExternalHref = (hrefOrPath: string) => hasHttp.test(hrefOrPath)
const processHref = (hrefOrPath: string) => {
  if (hasHttp.test(hrefOrPath)) {
    return hrefOrPath
  } else {
    const prefix = constants.IS_PROD ? "www" : "qa"
    return `https://${prefix}.cbssports.com${hrefOrPath}`
  }
}

const GameItem = ({ title, href, src }) => (
  <StyledGameItem as="a" title={title} href={processHref(href)} target="_blank" rel="noopener nofollow">
    <StyledGameItemImg src={src} alt={`Fantasy ${title}`} />
    <StyledGameItemBtn>Play Now</StyledGameItemBtn>
    <StyledGameItemText>
      Fantasy
      <br />
      {title}
    </StyledGameItemText>
  </StyledGameItem>
)

export const SiteNavSidePlayItemContents = () => {
  return (
    <React.Fragment>
      <DropdownInner>
        <NavDropdownItem title="Games" href="/fantasy/games/?ftag=SPM-16-10acb5f" />
        <DropdownRow>
          <GameItem
            title="Basketball"
            href="/fantasy/games/basketball/?ftag=SPM-16-10acg6d"
            src="https://sportshub.cbsistatic.com/i/r/2018/09/20/18b9a8ac-4b7f-4996-8042-8b216fa7b974/thumbnail/180x100/f146b588afaaa5769051f43a7746d7b7/fhoops-launch-180x1002x.jpg"
          />
          <GameItem
            title="Hockey"
            href="/fantasy/games/hockey/?ftag=SPM-16-10acg6j"
            src="https://sportshub.cbsistatic.com/i/r/2018/09/20/4e42a951-99e4-4732-92a0-417c8688c999/thumbnail/180x100/ccd92d8f01228a8485ab091e2bd1ec06/fhockey-launch-180x100.jpg"
          />
        </DropdownRow>
        <NavDropdownItemAlt as="a" href={processHref("/fantasy/games/my-teams")} target="_blank" rel="noopener nofollow">
          View your Leagues
        </NavDropdownItemAlt>
        {DropdownLinks.play.map((item) => (
          <NavDropdownItem key={item.href} {...item} />
        ))}
      </DropdownInner>
      <DropdownCta as="a" href={processHref(`/fantasy/games`)} rel="noopener nofollow">
        See All Sports Games
      </DropdownCta>
    </React.Fragment>
  )
}
