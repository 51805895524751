import * as React from "react"
import { RouteComponentProps } from "react-router"
import { AsyncRouteComponentState, AsyncRouteComponentType, Ctx, Module } from "../../routes.d"

/**
 * Returns a new React component, ready to be instantiated.
 * Note the closure here protecting Component, and providing a unique
 * instance of Component to the static implementation of `load`.
 */

export function asyncComponent<Props extends { [K in keyof Props]?: string | undefined }>({
  loader,
  Placeholder,
}: {
  loader: () => Promise<Module<React.ComponentType<Props>>>
  Placeholder?: React.ComponentType<RouteComponentProps<Props>>
}) {
  // keep Component in a closure to avoid doing this stuff more than once
  let Component: AsyncRouteComponentType<Props> | null = null

  return class AsyncRouteComponent extends React.Component<RouteComponentProps<Props>, AsyncRouteComponentState> {
    /**
     * Static so that you can call load against an uninstantiated version of
     * this component. This should only be called one time outside of the
     * normal render path.
     */
    public static load() {
      return loader().then((ResolvedComponent) => {
        Component = ResolvedComponent!.default || ResolvedComponent
      })
    }

    public static getInitialProps(ctx: Ctx<any>) {
      // Need to call the wrapped components getInitialProps if it exists
      if (Component !== null) {
        return Component.getInitialProps ? Component.getInitialProps(ctx) : Promise.resolve(null)
      }
    }

    constructor(props: RouteComponentProps<Props>) {
      super(props)
      this.updateState = this.updateState.bind(this)
      this.state = {
        Component,
      }
    }

    public UNSAFE_componentWillMount() {
      AsyncRouteComponent.load().then(this.updateState)
    }

    public updateState() {
      // Only update state if we don't already have a reference to the
      // component, this prevent unnecessary renders.
      if (this.state.Component !== Component) {
        this.setState({
          Component,
        })
      }
    }

    public render() {
      const { Component: ComponentFromState } = this.state

      if (ComponentFromState) {
        return <ComponentFromState {...this.props} />
      }

      if (Placeholder) {
        return <Placeholder {...this.props} />
      }

      return null
    }
  }
}
