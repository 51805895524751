import constants from "./constants"
import { SpreadEnumType } from "./enums"
import { nonSpreadSpreadTypes } from "./pool-settings"
export const isAutogeneratedEntryName = (name: string) => name.indexOf(constants.AUTOGENERATED_ENTRY_PREFIX) === 0

export const oneSecond = 1000
export const oneMinute = 60 * oneSecond
export const oneHour = 60 * oneMinute
export const oneDay = 24 * oneHour
export const oneWeek = 7 * oneDay
export const oneYear = 365 * oneDay

export const emptyArray = Object.freeze([]) as never[]
export const emptyObject = Object.freeze({}) as never
export const emptyVariable = Object.freeze("") as never

export const filterNulls = <T>(i?: T | null): i is T => !!i
export const onlyUnique = (value, index, self) => self.indexOf(value) === index
export const isEmptyArray = (value) => !value || value.length <= 0
export const filterNonFalsy = <T>(i?: T | null): boolean => !i
export const unique = <T>(arr: readonly T[]) => Array.from(new Set<T>(arr))
export const uniqueNonNull = <T>(arr: T[]) => arr.filter(filterNulls).filter(onlyUnique)
export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const copyArray = <T>(arr: T[]) => ([] as T[]).concat(arr)
export const compareArrays = <T = any>(arr1: Array<T>, arr2: Array<T>) =>
  arr1.length === arr2.length && arr1.every((item, index) => item === arr2[index])

export const isNumber = (n: any) => typeof n === "number"
export const tryToCastToInteger = (n: any) =>
  (isNumber(n) && Number.isInteger(Number(n))) || (typeof n === "string" && Number.isInteger(Number(n))) ? Number(n) : n

interface IMapToIdObj {
  id: string | number
}
export const mapToId = (obj: IMapToIdObj) => obj.id
export const times = (multiplier: number) => (val: number | null | undefined, decimalPlaces = 0) => {
  const numericValue = Number(val)
  if (typeof val === "undefined" || val === null || isNaN(numericValue)) {
    return null
  }
  const result = multiplier * numericValue

  return decimalPlaces > 0 ? result.toFixed(decimalPlaces) : result
}
export const toPercentile = (num?: number | null) => (isNumber(num) ? `${num}%` : null)
const _types = ["th", "st", "nd", "rd"]

export const toTruncatedNumber = (n?: number | null) => toRank(n, false, true)
export const toRank = (n?: number | null, omitNumber = false, omitOrdinal = false) => {
  if (!n) {
    return null
  }
  if (n >= 1000000) {
    // 1 million +
    return omitNumber ? "" : `${toTenth(n / 1000000)}m`
  } else if (n >= 1000) {
    // 1k + (will split into 100k and 10k intervals)
    return omitNumber ? "" : `${toTenth(n / 1000)}k`
  }
  const v = n % 100
  const res = _types[(v - 20) % 10] || _types[v] || _types[0]
  if (omitOrdinal) {
    return n.toString()
  }
  return omitNumber ? res : `${n}${res}`
}

// 9999999999
// 1544564691 is too short, needs to be * 1000
const epochDetector = 9999999999

export const intoSlices = <T>(array: T[], size: number) => {
  const chunks = [] as T[][]
  let index = 0
  while (index < array.length) {
    chunks.push(array.slice(index, size + index))
    index += size
  }
  return chunks
}

export const dateToEpoch = (date: number | Date | string): number => {
  if (typeof date == "number") {
    // 9999999999
    // 1544564691 is too short, needs to be * 1000
    return date < epochDetector ? date * 1000 : date
  } else if (typeof date === "string") {
    const parsed = Date.parse(date)
    return parsed < epochDetector ? parsed * 1000 : parsed
  } else {
    return date.getTime()
  }
}

export function clearAllKeys(obj: any) {
  if (obj) {
    try {
      const keys = Object.keys(obj)
      for (const key of keys) {
        delete obj[key]
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export const toTenth = (num) => Math.round(num * 10) / 10

export function getSpreadValue(formattedSpread?: string | null) {
  return parseFloat((formattedSpread || "0").replace("+", "").replace("PK", "0"))
}

export function adjustedSpread(spread: number | null, isHomeTeam = true) {
  if (spread === null) {
    return null
  }
  if ((spread * 10.0) % 10 === 0) {
    // subtract 0.5 to ensure there is always a winner
    return spread + (isHomeTeam ? -0.5 : 0.5)
  } else {
    return spread
  }
}

export function usesSpread(spreadType: SpreadEnumType) {
  return !nonSpreadSpreadTypes.includes(spreadType)
}

export function formatSpread(spread: number | string | null, isHomeTeam = true, reconvert = false, adjustForTies = false): string | null {
  const to = typeof spread
  if (to === "string" && reconvert && spread) {
    const spreadWithPkFix = (["PK"].includes(spread.toString().toUpperCase()) && "0") || (spread as string)
    return formatSpread(parseFloat(spreadWithPkFix), isHomeTeam, false, adjustForTies)
  }
  // already been converted
  if (typeof spread !== "number") {
    return spread
  }
  const mult = isHomeTeam ? 1 : -1
  const contextualSpread = spread * mult
  const rounded = toTenth(adjustForTies ? adjustedSpread(contextualSpread, isHomeTeam) : contextualSpread).toString()
  return (contextualSpread > 0 ? "+" : "") + (rounded.includes(".") ? rounded : rounded + ".0")
}

//Given a name, returns the name component: firstName and lastName
export const breakName = (name?: string): { firstName?: string; lastName?: string } => {
  if (!name) return {}
  const runes = name.split(" ")
  if (runes.length === 1) {
    //only one name, assume is firstName
    return { firstName: runes[0] }
  }
  return {
    firstName: runes[0],
    lastName: runes.slice(1).join(" "),
  }
}

// http://api.cbssports.com/swagger/index.html?url=http%3A%2F%2Fapi.cbssports.com%2Fprimpy%2Fapi-docs#/
export const dateFromIso = (isoStr?: string | null) => {
  return (isoStr && new Date(isoStr)) || undefined
}
export const isNumberOrString = (str?: number | string | null) => {
  return ["string", "number"].includes(typeof str)
}

export function epochToLocalDatetimeInputValue(epoch: number) {
  // <input type="local-datetime"> requires format:
  const tzoffset = new Date().getTimezoneOffset() * 60000 // offset in milliseconds
  const localISOTime = new Date(epoch - tzoffset).toISOString().slice(0, -5)
  // => '2015-01-26T06:40:36'
  return localISOTime
}

const outsideDatetimeMatcher = /\.\d{3}\-\d{2}\:\d{2}$/
// prevent: {"startsAt":["2019-11-30T16:11:00.000Z","2019-11-29T11:11:00.000-05:00"]}
// "2019-11-29T11:11:00.000-05:00" is a user's input, but the DB doesnt tell us what TZ its in, we need to assume all tzs in are converted to utc
export const toDbDate = (dateOrString: string | Date, isLocalDate = false) => {
  let date = typeof dateOrString === "string" ? new Date(Date.parse(dateOrString)) : dateOrString
  // convert to server DB time (which has no ts)
  if (typeof dateOrString === "string" && (/Z/.test(dateOrString) || outsideDatetimeMatcher.test(dateOrString))) {
    // const serverOffset = (new Date()).getTimezoneOffset()
    const inputOffset = date.getTimezoneOffset()
    date = new Date(date.getTime() - inputOffset * 60 * 1000)
  } else if (isLocalDate && dateOrString instanceof Date) {
    // NOTE qac: we need to use UTC hours + 4 which is DST
    const offset = date.getTimezoneOffset() / 60
    const etHours = date.getHours()
    date.setHours(etHours - offset, 0, 0, 0)
  }
  const splits = date.toISOString().split(":")
  if (splits.length > 1 && /Z/.test(splits[splits.length - 1])) {
    splits.pop()
  }
  return splits.join(":")
}

export function deepDup<T>(o: T): T {
  return JSON.parse(JSON.stringify(o))
}

export function normalizeValue(value: number, min: number, max: number, inverted: boolean) {
  const dividend = value - min
  const divisor = max - min === 0 ? 1 : max - min
  const computed = 100 * (dividend / divisor)
  return Math.round(inverted ? 100 - computed : computed)
}

export function winAvg(wins: number, losses: number) {
  const divisor = wins + losses === 0 ? 1 : wins + losses
  return wins / divisor
}

export function getLeagueTypeFor(sportType: string) {
  const lowered = sportType.toLowerCase()
  return lowered.includes("ncaa") ? "ncaa" : lowered
}

export function getLeagueTypeForTeamLogos(sportType: string) {
  const lowered = sportType.toLowerCase()
  if (lowered === "ncaaw") {
    // Womens League
    return "ncaaw"
  }
  return lowered.includes("ncaa") ? "ncaa" : lowered
}

export const recordFor = (team: { wins?: number | string; losses?: number | string; ties?: number | string }) => {
  if (team) {
    const record = [team.wins || 0, team.losses || 0]
    if (team.ties) {
      record.push(team.ties)
    }
    return record.join("-")
  }
  return "0-0"
}

export const getWeatherIconToUse = (weatherIcon?: string) => {
  const dataMappingToIcon = {
    mostly_sunny: {
      icon: "mostlysunny",
      description: "Mostly Sunny",
    },
    sunny: {
      icon: "sunny",
      description: "Sunny",
    },
    cloudy: {
      icon: "partlycloudy",
      description: "Cloudy",
    },
    mostly_cloudy: {
      icon: "partlycloudy",
      description: "Mostly Cloudy",
    },
    partly_cloudy: {
      icon: "partlycloudy",
      description: "Partly Cloudy",
    },
    high_clouds: {
      icon: "highclouds",
      description: "High Clouds",
    },
    night_high_clouds: {
      icon: "highclouds",
      description: "Night High Clouds",
    },
    night_mostly_cloudy: {
      icon: "nightpartlycloudy",
      description: "Night Mostly Cloudy",
    },
    night_partly_cloudy: {
      icon: "nightpartlycloudy",
      description: "Night Partly Cloudy",
    },
    flood: {
      icon: "flood",
      description: "Flood",
    },
    heavy_rain: {
      icon: "heavyrain",
      description: "Heavy Rain",
    },
    rain: {
      icon: "rain",
      description: "Rain",
    },
    tstorms: {
      icon: "tstorms",
      description: "Thunder Storms",
    },
    scattered_tstorms: {
      icon: "scatteredtstorms",
      description: "Scattered Thunder Storms",
    },
    severe_tstorms: {
      icon: "severetstorms",
      description: "Severe Thunder Storms",
    },
    showers: {
      icon: "rain",
      description: "Rain",
    },
    sprinkles: {
      icon: "sprinkles",
      description: "Sprinkles",
    },
    night_scat_tstorms: {
      icon: "nightthunderstorms",
      description: "Night Scattered Thunder Storms",
    },
    night_showers: {
      icon: "nightsprinkles",
      description: "Night Showers",
    },
    hurricane: {
      icon: "hurricane",
      description: "Hurricane",
    },
    tornado: {
      icon: "tornado",
      description: "Tornado",
    },
    hail: {
      icon: "snowrainmix",
      description: "Hail",
    },
    heavy_snow: {
      icon: "lightsnow",
      description: "Heavy Snow",
    },
    snow_rain_mix: {
      icon: "snowrainmix",
      description: "Snow/Rain Mix",
    },
    snow: {
      icon: "snow",
      description: "Snow",
    },
    light_snow: {
      icon: "lightsnow",
      description: "Light Snow",
    },
    night_clear: {
      icon: "nightclear",
      description: "Night Clear",
    },
    night_mostly_clear: {
      icon: "nightclear",
      description: "Night Mostly Clear",
    },
    dense_fog: {
      icon: "lightfog",
      description: "Dense Fog",
    },
    early_fog: {
      icon: "earlyfog",
      description: "Early Fog",
    },
    haze: {
      icon: "haze",
      description: "Haze",
    },
    light_fog: {
      icon: "lightfog",
      description: "Light Fog",
    },
    sleet: {
      icon: "snowrainmix",
      description: "Sleet",
    },
    // no icons
    // ice_fog: {
    //   icon: "",
    //   description: "",
    // },
    // sandstorm: {
    //   icon: "",
    //   description: "",
    // },
  }

  const weatherIconToUse = weatherIcon && dataMappingToIcon[weatherIcon]
  if (weatherIconToUse) {
    return Object.assign({ url: `https://sports.cbsimg.net/images/fantasy/weather/weather-${weatherIconToUse.icon}.png` }, weatherIconToUse) as {
      icon: string
      url: string
      description: string
    }
  }

  return undefined
}
