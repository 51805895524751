import React from "react"
import ErrorReport from "./ErrorReport"
import Status from "./Status"

const NotFound = () => (
  <Status code={404}>
    <ErrorReport
      body={
        <>
          Sorry, this page doesn't exist.
          <br />
          If you think this is an error you can report it, or return to CBSSports.com
        </>
      }
    />
  </Status>
)
export default NotFound
