import styled from "styled-components/macro"
import { palette, fontSizes, fontWeight, gridBreakPoints, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
const { queries } = gridBreakPoints
export const EntryRowWrap = styled.div<{ hoverBgColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.variant--standard {
    position: relative;
    width: 100%;
    padding: 0.75rem 1rem;
    margin-top: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid ${palette.gray90};
    box-sizing: border-box;
    height: 3.5rem;
  }

  &.variant--compact {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  & .delete-button {
    display: none;
  }

  &.is-coming-soon {
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: ${(props) => (props.hoverBgColor ? props.hoverBgColor : palette.lightestBlue)};
      cursor: pointer;
    }

    & .delete-button {
      background-color: ${palette.white};
    }
  }

  ${queries.sm} {
    &.variant--standard {
      padding: 0.5rem 1rem;
      height: 3rem;
    }
    & .delete-button {
      display: inline-block;
      padding-top: 3px;
      margin-right: -10px;
    }
  }
`
export const EntryRank = styled.div`
  display: none;
  width: 4rem;
  font-style: normal;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSizes.regular};
  line-height: 1.25rem;
  align-items: center;
  letter-spacing: -0.1px;
  color: ${palette.gray20};

  &.display-flex {
    display: flex;
  }

  ${queries.sm} {
    display: flex;
    &.mobile-only {
      display: none;
    }
  }
`
export const EntryStat = styled.span`
  display: none;

  ${queries.sm} {
    display: flex;
    width: 2rem;
    margin-left: 1.5rem;
    font-style: normal;
    font-weight: ${fontWeight.regular};
    font-size: ${fontSizes.regular};
    line-height: 1.25rem;
    align-items: center;
    letter-spacing: -0.1px;
    color: ${palette.gray20};
    justify-content: flex-end;
  }
`
export const EntryChampion = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  & > svg {
    width: 2rem;
    height: 2rem;
  }

  &.variant--compact {
    margin: 0 0.5rem;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
  &.variant--standard {
    & > .entry-logo {
      min-width: 1.5rem;
      min-height: 1.5rem;
      max-width: 1.5rem;
      max-height: 1.5rem;
      margin-left: 0;
    }
  }

  ${queries.sm} {
    &.variant--standard {
      margin-right: 1.5rem;
      padding-left: 1rem;
      width: 5rem;
    }
  }
`
export const EntryName = styled.div`
  display: inline;
  font-style: normal;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSizes.regular};
  line-height: 1;
  letter-spacing: -0.1px;
  color: ${palette.gray20};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 0.5rem;

  &.variant--standard {
    flex: 1;
  }

  &.entry-name--hover {
    color: ${palette.lightBlue3};
  }

  ${queries.sm} {
    line-height: 1rem;
  }

  & .entry-name {
    @media (hover: hover) {
      &:hover:not(:active) {
        color: ${palette.lightBlue3};
      }
    }
  }
`
export const EntryStatus = styled.span`
  display: none;
  flex: 1;
  font-style: normal;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSizes.regular};
  line-height: 1;
  align-items: center;
  letter-spacing: -0.1px;
  color: ${palette.darkRed};
  margin-left: 0.125rem;
  &.is-coming-soon {
    color: ${palette.lightBlue3};
  }
  &.is-complete {
    color: ${palette.darkGreen};
  }

  &.is-locked {
    display: flex;
  }

  ${queries.sm} {
    display: flex;
    line-height: 1rem;
    margin-left: 0;
    &.align-status-column {
      margin-left: -2.7rem;
    }
  }
`
export const Information = styled.div`
  display: flex;
  flex-direction: column;
  flex: 6;
  overflow: hidden;

  ${queries.sm} {
    align-items: center;
    flex-direction: row;
  }
`
export const InformationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 0.25rem;

  ${queries.sm} {
    margin-top: 0;
  }
`
export const ChevronContainer = styled.div`
  display: flex;
  position: absolute;
  width: 2rem;
  right: 0;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${palette.gray50};
  }

  ${queries.sm} {
    display: none;
  }
`
export const Title = styled.span`
  font-style: normal;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSizes.regular};
  line-height: 1;
  display: flex;
  align-items: center;
  letter-spacing: -0.1px;
  color: ${palette.gray40};
  &:after {
    content: ":";
  }

  ${queries.sm} {
    display: none;
  }
`
export const PoolName = styled.span`
  font-size: 14px;
  line-height: 1.25rem;
  color: ${palette.gray20};
  letter-spacing: -0.1px;
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  display: flex;
`
export const TooltipBody = styled.div`
  border-radius: ${pxToRem(4)};
  background: ${theme.colors.overDark.darkGray2};
  padding: 0.25rem 0.5rem;
  color: ${theme.colors.overLight.white};
  font-size: ${pxToRem(12)};
  line-height: 1rem;
  margin-right: 4px;
  margin-top: -30px;
`

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
`
