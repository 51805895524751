import { ISelectOption } from "@cbs-sports/sports-shared-client/build/types/components/Select/Select"
import constants from "../../../../../../common/constants"

export const ANYTHING_BUT_NUMBERS_REGEX = /\D/g
export const REMOVE_INVALID_CHARS_REGEX = /[^0-9 ()-]/g
export const US_PHONE_NUMBER_REGEX = /(\d{3})(\d{3})(\d{4})/
export const FORMATTED_US_NUMBER_REGEX = /\((\d{3})\) (\d{3})-(\d{4})/
export const DEFAULT_TITLE = "Edit Bracket Name"
export const HELPER_MESSAGE = `Must be at least ${constants.ENTRY_NAME_MIN_LENGTH} characters`

export const NISSAN_VEHICLE_OPTIONS: ISelectOption[] = [
  { label: "Choose a vehicle", value: "" },
  { label: "Altima", value: "ALTIMA" },
  { label: "ARIYA", value: "ARIYA" },
  { label: "Armada", value: "ARMADA" },
  { label: "Frontier", value: "FRONTIER" },
  { label: "GT-R", value: "GT-R" },
  { label: "Kicks", value: "KICKS" },
  { label: "Leaf", value: "LEAF" },
  { label: "Maxima", value: "MAXIMA" },
  { label: "Pathfinder", value: "PATHFINDER" },
  { label: "Rogue", value: "ROGUE" },
  { label: "Sentra", value: "SENTRA" },
  { label: "Titan / Titan XD", value: "TITAN/TITAN XD" },
  { label: "Versa", value: "VERSA" },
  { label: "Z", value: "Z" },
]
