import * as React from "react"

const TricoSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 934.07 200.05">
    <path
      fill="#231f20"
      d="M448.53,597.24h49.84V403.82H448.53ZM37.58,443.06h64.91V597.24h49.84V443.06h64.9V403.82H37.58Zm389,25.14v-.55c0-18.24-6.79-33.43-19.41-44.21-14.89-12.71-37.22-19.62-66-19.62H237.54V597.24h49.85v-155H336.9c24.28,0,39.16,9.4,39.16,27.63v.55c0,16.3-13.91,27.36-38.19,27.36h-41l80.74,99.47h55.75L378,528.16C406.81,519,426.55,499.42,426.55,468.2ZM834,400.5c-69.9,0-120.71,45.05-120.71,100v.55c0,55,50.16,99.47,120.07,99.47s120.72-45,120.72-100V500C954,445,903.86,400.5,834,400.5Zm68,100.58c0,33.16-27.83,60.24-68,60.24s-68.61-27.63-68.61-60.79V500c0-33.16,27.83-60.24,68-60.24s68.61,27.63,68.61,60.79ZM633.74,561.32c-38.83,0-65.7-27.63-65.7-60.79V500c0-33.16,27.52-60.24,65.7-60.24,22.58,0,40.33,8.23,57.75,21.69l27.84-34.38C699,411.17,674.08,400.5,634.07,400.5c-69.59,0-118.13,45.05-118.13,100v.55c0,55.54,49.51,99.47,116.18,99.47,39.22,0,64.09-10.68,85.62-28.26L691.58,540C674.46,553,658.8,561.32,633.74,561.32Z"
      transform="translate(-37.58 -400.5)"
    />
    <path
      fill="#231f20"
      d="M941.77,420.35a14.94,14.94,0,1,1,15,14.78A14.73,14.73,0,0,1,941.77,420.35Zm15,12.33c6.67,0,11.94-5.23,11.94-12.33a12,12,0,1,0-11.94,12.33Zm-3.12-3.79h-2.57v-17h6.47c4,0,6,1.49,6,4.84,0,3-1.91,4.37-4.41,4.68l4.84,7.49h-2.89l-4.48-7.37h-3Zm3.08-9.56c2.19,0,4.14-.15,4.14-2.76,0-2.11-1.91-2.5-3.71-2.5h-3.51v5.26Z"
      transform="translate(-37.58 -400.5)"
    />
  </svg>
)

export default TricoSvg
