import React, { FC } from "react"
import styled from "styled-components"
import { emptyArray } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import StandingsTableRow from "./StandingsTableRow"
import Typography from "../../../../../shared/atoms/Typography"
import StandingsTableHeaderCell from "./StandingsTableHeaderCell"
import StandingsTableDataCell from "./StandingTableDataCell"
import { ColumnDataType, StandingsTableSortOptionsType, PoolDataEntryType } from "./StandingsTable.common"
import { CentralCurrentUsersEntriesQuery_currentUser } from "../../../../../../__generated__/CentralCurrentUsersEntriesQuery"

const DISABLED_SORTING: StandingsTableSortOptionsType = {
  enabled: false,
}

const StandingsTableRoot = styled.div`
  .standings__nodata {
    padding: 2rem;
    text-align: center;
  }
`
interface IStandingsTableProps {
  entries: Array<PoolDataEntryType>
  columns: Array<ColumnDataType>
  sortOptions?: StandingsTableSortOptionsType
  noDataMessage?: string
  className?: string
  onSort?: (col: string) => void
  currentUser?: CentralCurrentUsersEntriesQuery_currentUser
}

const StandingsTable: FC<IStandingsTableProps> = ({
  sortOptions = DISABLED_SORTING,
  entries = emptyArray,
  columns = [],
  noDataMessage = "No records found!",
  onSort,
  className = "",
  currentUser,
}) => {
  const columnsHeaders = (
    <StandingsTableRow>
      {columns.map((c) => (
        <StandingsTableHeaderCell key={c.key} columnData={c} onSort={onSort} />
      ))}
    </StandingsTableRow>
  )

  const hasData = entries.length > 0

  const currentUsersEntryIds = new Set<string>()
  currentUser?.entries?.edges?.map(({ node }) => currentUsersEntryIds.add(node.id))

  const tableContent = hasData ? (
    entries.map((entry) => (
      <StandingsTableRow key={entry.id} hoverable isMine={currentUsersEntryIds.has(entry.id)}>
        {columns.map((column) => (
          <StandingsTableDataCell key={column.key} columnData={column} entry={entry} sortOptions={sortOptions} />
        ))}
      </StandingsTableRow>
    ))
  ) : (
    <div className="standings__nodata">
      <Typography weight="BOLD" spacing="COMPRESSED">
        {noDataMessage}
      </Typography>
    </div>
  )

  return (
    <StandingsTableRoot className={className}>
      {columnsHeaders}
      {tableContent}
    </StandingsTableRoot>
  )
}

export default StandingsTable
