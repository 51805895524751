import React, { FC, useCallback, useRef, useReducer, Reducer } from "react"
import { useMutation } from "@apollo/client"
import { Formik, FormikHelpers, Form, Field } from "formik"
import styled from "styled-components"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import Checkbox from "@cbs-sports/sports-shared-client/build/cjs/components/Checkbox"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import { TPoolRouteProps } from "../../../../../routes.d"
import { UpsertEntryMutation } from "../../../../../__generated__/UpsertEntryMutation"
import { EntryDetailsFragment } from "../../../../../__generated__/EntryDetailsFragment"
import { extractValidationError } from "../../../../components/Form"
import { CropInfo, EditEntryAvatarForm, IEditEntryAvatarFormValues, upload } from "./EditEntryAvatarModal"
import {
  GetEntryAvatarSignedUrlMutation,
  GetEntryAvatarSignedUrlMutationVariables,
} from "../../../../../__generated__/GetEntryAvatarSignedUrlMutation"
import { GET_ENTRY_AVATAR_SIGNED_URL_MUTATION } from "../../../queries"
import GenericEntryModal, { IGenericEntryModalProps, noop } from "./GenericEntryModal"
import constants from "../../../../../common/constants"
import PencilSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Pencil"
import palette from "../../../../../common/palette"
import Link from "../../../../components/Link"
import { trackActionByKey } from "../../containers/MessageBoard/message-board-tracking"
import { CurrentMembershipQuery_member } from "../../../../../__generated__/CurrentMembershipQuery"

const px14 = pxToRem(14)
const px28 = pxToRem(28)
const px88 = pxToRem(88)
const DEFAULT_TITLE = "Personal Info"
const HIDE_EMAIL_LABEL = "Don't show my email address to other pool members."
const OPT_OUT_MESSABE_BOARD_LABEL = "I don't want to receive Message Board email notifications."

const ModalWrapper = styled.div`
  width: calc(${pxToRem(448)} - ${pxToRem(80)});

  .form__errorMessage {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: -0.1px;
    padding-left: 1rem;
    padding-right: 1rem;
    color: ${theme.colors.overLight.red};
  }

  .form__checkbox {
    color: ${theme.colors.overLight.white50};
    font-size: ${px14};
    line-height: 1.25rem;

    span.label-content {
      margin-left: ${px28};
    }
  }
  .form__checkbox.wrap {
    span.label-content.no-wrap {
      white-space: normal;
    }
  }

  .spaced__form {
    margin-top: 1rem;
  }

  .form__row--avatar {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  .form__row--info {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: ${theme.colors.overLight.white20};
    font-weight: ${theme.fonts.weights.semireg};
    text-align: center;

    .info__container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info__name {
      font-size: 1.25rem;
      line-height: 1.25rem;
      margin-bottom: 0.5rem;
    }

    .info__email {
      font-size: ${px14};
      line-height: 1rem;
    }

    .info__edit_account {
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .form__avatar-wrapper {
    padding: 0.25rem;
    flex: 1;
    flex-basis: 100%;
  }

  .form__avatar {
    display: block;
    width: ${px88};
    height: ${px88};
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
  }

  .form__editAvatar {
    margin-top: 0.75rem;
  }

  .form__row--input {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

type EditEntryStep = "ENTRY_FORM" | "AVATAR_FORM"

interface IEditEntryModal extends IGenericEntryModalProps {
  entry: EntryDetailsFragment
  member: CurrentMembershipQuery_member
  userName: string
  userEmail?: string
  upsertEntryMutation: TPoolRouteProps["poolData"]["upsertEntryMutation"]
  onUpdateSuccess?: (data: UpsertEntryMutation) => void
  onClose?: () => void
}

interface IEditEntryFormValues extends IEditEntryAvatarFormValues {
  entryId: string
  hideEmail: boolean
  noMessageBoardNotification: boolean
}

type StateType = {
  file: string
  step: EditEntryStep
  isUploading: boolean
  cropInfo?: CropInfo
}

type Action =
  | { type: "FILE_LOADED"; file: string }
  | { type: "FILE_CROPPED"; cropInfo: CropInfo }
  | { type: "FILE_UPLOAD" }
  | { type: "FILE_UPLOAD_SUCCESS"; url: string }
  | { type: "FILE_UPLOAD_ERROR"; err: Error }
  | { type: "GO_TO_STEP"; step: EditEntryStep }

const initialState: StateType = {
  file: "",
  step: "ENTRY_FORM",
  isUploading: false,
}

const reducer: Reducer<StateType, Action> = (state, action) => {
  switch (action.type) {
    case "FILE_LOADED":
      return {
        ...state,
        file: action.file,
        step: "AVATAR_FORM",
        cropInfo: undefined,
      }
    case "FILE_UPLOAD":
      return {
        ...state,
        isUploading: true,
      }
    case "FILE_UPLOAD_SUCCESS":
      return {
        ...state,
        file: "",
        step: "ENTRY_FORM",
        isUploading: false,
      }
    case "FILE_UPLOAD_ERROR":
      return {
        ...state,
        isUploading: false,
      }
    case "FILE_CROPPED":
      return {
        ...state,
        cropInfo: action.cropInfo,
      }
    case "GO_TO_STEP":
      return {
        ...state,
        step: action.step,
      }

    default:
      return state
  }
}

const EditEntryModal: FC<IEditEntryModal> = ({
  isOpen,
  title = DEFAULT_TITLE,
  entry,
  member,
  upsertEntryMutation,
  onClose,
  onUpdateSuccess,
  userName,
  userEmail = "",
  ...rest
}) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const [uploadAvatar] = useMutation<GetEntryAvatarSignedUrlMutation, GetEntryAvatarSignedUrlMutationVariables>(GET_ENTRY_AVATAR_SIGNED_URL_MUTATION)
  const [{ file, step, cropInfo, isUploading }, dispatch] = useReducer(reducer, initialState)

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files && event.currentTarget.files[0]
    if (file) {
      const type = file.type?.split("/")
      if (type?.length > 0) {
        const isImage = type[0] === "image"
        if (isImage) {
          const reader = new FileReader()
          reader.onload = () => {
            if (fileRef.current) {
              fileRef.current.value = "" // reseting the input
            }
            dispatch({ type: "FILE_LOADED", file: String(reader.result) })
          }
          if (file) {
            reader.readAsDataURL(file)
          }
        }
      }
    }
  }

  const initialValues: IEditEntryFormValues = {
    entryId: entry.id,
    hideEmail: member?.hideEmail,
    noMessageBoardNotification: member?.noMessageBoardNotification ?? constants.DEFAULT_MESSAGE_BOOARD_NOTIFICATION,
    avatarUrl: member?.avatarUrl,
  }

  const isManagerPool = /manager/.test(entry.gameInstanceUid)

  const handleSubmit = useCallback(
    (variables: IEditEntryFormValues, actions: FormikHelpers<IEditEntryFormValues>) => {
      return upsertEntryMutation({ variables })
        .then((res) => {
          const result = res.data as UpsertEntryMutation
          onUpdateSuccess?.(result)
        })
        .catch((err) => {
          const apiErrors = extractValidationError(err)
          actions.setErrors(apiErrors.errors)
        })
    },
    [upsertEntryMutation, onUpdateSuccess],
  )

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikBag) => {
        const hasChanged = JSON.stringify(initialValues) !== JSON.stringify(formikBag.values)

        const saveImage = () => {
          upload({
            entryId: entry.id,
            fileContent: file,
            cropInfo: cropInfo,
            uploadAvatar: uploadAvatar,
            onStart: () => {
              dispatch({ type: "FILE_UPLOAD" })
            },
            onUploaded: async ({ url }) => {
              formikBag.setFieldValue("avatarUrl", url)
              dispatch({ type: "FILE_UPLOAD_SUCCESS", url })
            },
            onError: (err) => {
              dispatch({ type: "FILE_UPLOAD_ERROR", err })
            },
          })
        }

        const closeAndClear = () => {
          formikBag.resetForm()
          onClose?.()
        }

        const actions =
          step === "ENTRY_FORM" ? (
            <>
              <Button variant="secondary" type="button" onClick={closeAndClear} disabled={formikBag.isSubmitting}>
                Cancel
              </Button>
              <Button
                variant="primary"
                withLoading
                loading={formikBag.isSubmitting}
                onClick={formikBag.submitForm}
                disabled={!(hasChanged && formikBag.isValid)}
              >
                {formikBag.isSubmitting ? "Saving" : "Save"}
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" type="button" onClick={() => dispatch({ type: "GO_TO_STEP", step: "ENTRY_FORM" })} disabled={isUploading}>
                Cancel
              </Button>
              <Button variant="primary" withLoading loading={isUploading} onClick={saveImage}>
                {isUploading ? "Uploading" : "Apply"}
              </Button>
            </>
          )

        const content =
          step === "ENTRY_FORM" ? (
            <ModalWrapper>
              <Form>
                <div className="form__row--avatar">
                  <div className="form__avatar-wrapper">
                    <img className="form__avatar" src={formikBag.values.avatarUrl} alt="Entry Avatar" />
                  </div>
                  <div className="form__editAvatar">
                    <input
                      id="entry_avatar"
                      type="file"
                      className="input"
                      onChange={onFileChange}
                      accept="image/*"
                      ref={fileRef}
                      style={{ display: "none" }}
                    />
                    <StyledButton as="label" htmlFor="entry_avatar" className="variant--secondary">
                      Edit Avatar
                    </StyledButton>
                  </div>
                </div>
                <div className="form__row--info">
                  <div className="info__container">
                    <div className="info__name_email">
                      <div className="info__name">{userName}</div>
                      <div className="info__email">{userEmail}</div>
                    </div>
                    <div className="info__edit_account">
                      <Link to={`https://www${constants.IS_PROD ? "" : ".qa"}.cbssports.com/settings`}>
                        <PencilSvg color={palette.uiBlue1} />
                      </Link>
                    </div>
                  </div>
                </div>
                {isManagerPool && (
                  <>
                    <Field name="hideEmail">
                      {({ field: { name, value, onChange } }) => (
                        <Checkbox name={name} isChecked={value} label={HIDE_EMAIL_LABEL} className="form__checkbox" noWrap onChange={onChange} />
                      )}
                    </Field>
                    <Field name="noMessageBoardNotification">
                      {({ field: { name, value, onChange } }) => (
                        <Checkbox
                          name={name}
                          isChecked={value}
                          label={OPT_OUT_MESSABE_BOARD_LABEL}
                          className="form__checkbox spaced__form wrap"
                          noWrap
                          onChange={onChange}
                          onClick={() => !value && trackActionByKey("email-opt-out")}
                        />
                      )}
                    </Field>
                  </>
                )}
              </Form>
            </ModalWrapper>
          ) : (
            <EditEntryAvatarForm file={file} setCropInfo={(cropInfo) => dispatch({ type: "FILE_CROPPED", cropInfo })} />
          )

        const modalTitle = step === "ENTRY_FORM" ? title : `Edit Avatar`
        const modalActionProps = { dense: step === "ENTRY_FORM" }

        return (
          <GenericEntryModal
            isOpen={isOpen}
            title={modalTitle}
            onBackgroundClick={noop}
            onEscapeKeydown={noop}
            actions={actions}
            actionProps={modalActionProps}
            {...rest}
          >
            {content}
          </GenericEntryModal>
        )
      }}
    </Formik>
  )
}

export default React.memo(EditEntryModal)
