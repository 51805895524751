export const stripCircular = (from, seen?): any => {
  const to = Array.isArray(from) ? [] : {}
  seen = seen || []
  seen.push(from)
  Object.getOwnPropertyNames(from).forEach((key) => {
    if (!from[key] || (typeof from[key] !== "object" && !Array.isArray(from[key]))) {
      to[key] = from[key]
    } else if (seen.indexOf(from[key]) < 0) {
      to[key] = stripCircular(from[key], seen.slice(0))
    } else {
      to[key] = "[Circular]"
    }
  })
  return to
}

export const styles =
  ' \
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, \
abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, \
small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, \
fieldset, form, label, legend, caption, article, aside, canvas, details, figcaption, figure,  footer, header, hgroup, \
menu, nav, section, summary, time, mark, audio, video { \
    margin:0; \
    padding:0; \
    border:0; \
    outline:0; \
    vertical-align:baseline; \
    background:transparent; \
} \
 \
* { \
    -moz-box-sizing:border-box; \
    -webkit-box-sizing:border-box; \
    box-sizing:border-box; \
} \
 \
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { \
    display: block; \
} \
 \
html { \
    font-size: 16px !important; \
    width:100%; \
    height:100%; \
} \
 \
body { \
    height: calc(100% - 54px) !important; \
    overflow: hidden; \
    padding: 0; \
    margin: 0; \
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; \
    font-weight: normal; \
    line-height: 1.5rem; \
    color: #0a0a0a; \
    background: #fefefe; \
    -webkit-font-smoothing: antialiased; \
    -moz-osx-font-smoothing: grayscale; \
} \
 \
.clearfix { \
    clear:both; \
    zoom: 1; \
} \
.clearfix:before, .clearfix:after { \
  content: ""; \
    display: block; \
    height: 0; \
    visibility: hidden; \
} \
.clearfix:after { \
    clear: both; \
} \
 \
.content-container { \
border-radius: 6px; \
text-align: center; \
box-shadow: 0 0 20px rgba(0,0,0,.2); \
padding:50px; \
background-color: #fff; \
width:100%; \
max-width:525px; \
margin: 20vh auto; \
} \
@media (max-width: 500px) { \
.content-container { \
 padding:20px; \
} \
} \
 \
.head-line { \
transition:color .2s linear; \
font-size:48px; \
line-height:60px; \
letter-spacing: -1px; \
margin-bottom: 5px; \
color:#ccc; \
} \
.subheader { \
transition:color .2s linear; \
font-size: 2rem; \
line-height:46px; \
color:#333; \
} \
.hr { \
height:1px; \
background-color: #ddd; \
width:60%; \
max-width:250px; \
margin:35px auto; \
} \
.context { \
transition:color .2s linear; \
font-size:18px; \
line-height:27px; \
color:#bbb; \
} \
.context p { \
margin:0; \
} \
.context p:nth-child(n+2) { \
margin-top:16px; \
} \
.buttons-container { \
margin-top: 35px; \
overflow: hidden; \
} \
.buttons-container > * { \
transition:text-indent .2s ease-out, color .2s linear, background-color .2s linear; \
text-indent: 0px; \
font-size:14px; \
text-transform: uppercase; \
text-decoration: none; \
color:#fff; \
background-color:#2ecc71; \
border-radius: 99px; \
padding:12px 0 13px; \
text-align: center; \
display:inline-block; \
overflow: hidden; \
position: relative; \
width:45%; \
cursor: pointer; \
line-height: 24px; \
border: none; \
outline: none; \
} \
 \
.buttons-container .fa { \
transition:left .2s ease-out; \
position: absolute; \
left:-50px; \
} \
 \
.buttons-container > *:hover { \
text-indent: 15px; \
} \
 \
.buttons-container > *:nth-child(1) { \
float:left; \
} \
 \
.buttons-container > *:nth-child(2) { \
float:right; \
} \
 \
.buttons-container .fa-home { \
font-size:18px; \
top:15px; \
} \
 \
.buttons-container > *:hover .fa-home { \
left:10px; \
} \
 \
.buttons-container .fa:not(.fa-home) { \
font-size:16px; \
top:17px; \
} \
 \
.buttons-container > *:hover .fa:not(.fa-home) { \
left:5px; \
} \
 \
.buttons-container .fa:not(.fa-home) { \
font-size:16px; \
top:17px; \
} \
 \
.buttons-container > *:hover .fa:not(.fa-home) { \
left:9px; \
} \
 \
 \
.buttons-container.single-button { \
text-align: center; \
} \
 \
.buttons-container.single-button > *  { \
float:none !important; \
min-width: 200px; \
} \
 \
 \
.background-image { \
 background-color: rgba(0,0,0,0.05); \
} \
 \
.primary-text-color { \
 color: #494949 !important; \
} \
 \
.secondary-text-color { \
 color: #AAAAAA !important; \
} \
 \
.sign-text-color { \
 color: #FFBA00 !important; \
} \
 \
.sign-frame-color { \
 color: #343C3F; \
} \
 \
.pane { \
 background-color: #FFFFFF !important; \
} \
 \
.border-button { \
 color: #2ECC71 !important; \
 border-color: #0071D9 !important; \
} \
.button { \
 background-color: #0071D9 !important; \
 color: #FFFFFF !important; \
} \
 \
.flex-wrapper { \
 display: flex; \
 align-items: center; \
 justify-content: center; \
 height: 100%; \
} \
 \
body #nbaGlobalHeader { \
 background-color: #1D428A; \
 min-height: 54px; \
 display: block !important; \
} \
'
