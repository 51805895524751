import React, { FC, useContext, useMemo } from "react"
import { getFullPoolSettings, tournamentRBLabels } from "../../../../../../common/pool-settings-setup"
import {
  PoolDetailsFragment,
  PoolDetailsFragment_season_segments_edges_node,
  PoolDetailsFragment_season_segments_edges_node_periods_edges_node,
} from "../../../../../../__generated__/PoolDetailsFragment"
import { sortByOrder } from "../../../../../../common/sorters"
import { ENUM_CONFERENCE_TOURNAMENT, GameTypeEnum, PoolSettingsTypesEnum, RoundModifierTypeEnum } from "../../../../../../common/enums"
import { getModifierLabel } from "../../../../PoolSetupPages/components/BracketPoolFormComponents"
import { buildClassNames as cx } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { PoolRulesTable, TitleLink } from "./PoolRulesModal.styles"
import PoolDataContext from "../../../../../Contexts/PoolDataContext"
import { CentralGameInstancesQuery_allSegments } from "../../../../../../__generated__/CentralGameInstancesQuery"

const CHAMPIONSHIP_TIEBREAKER_MESSAGE = "Championship Game’s Total Score"
const NONE_TIEBREAKER_MESSAGE = "None"
const LABEL_REPLACEMENT_MAP = new Map<string, string>([
  ["Second", "First Round"],
  ["Third", "Second Round"],
  ["National", "Championship"],
])

interface IPoolRulesTableProps {
  pool?: PoolDetailsFragment
  onShowBonuses: () => void
  noShowBonuses?: boolean
  showBonusesCol?: boolean
  dense?: boolean
}
export interface PoolRulesContentProps extends IPoolRulesTableProps {
  mode?: "full" | "rules"
}

const PoolRules: FC<IPoolRulesTableProps> = ({ pool, onShowBonuses, noShowBonuses, showBonusesCol = true, dense }) => {
  const poolData = useContext(PoolDataContext)
  const { allSegments, detailedPeriod } = poolData || emptyObject

  const fullPoolSettings = useMemo(() => {
    const fullPoolSettings = pool ? getFullPoolSettings(pool) : undefined
    const bonuses = fullPoolSettings?.roundBonuses ?? []
    const modifiers = fullPoolSettings?.roundModifiers ?? []
    let segmentData: PoolDetailsFragment_season_segments_edges_node | undefined = undefined
    let periods: PoolDetailsFragment_season_segments_edges_node_periods_edges_node[] | undefined
    if (pool && (pool.season?.segments?.edges?.length || 0) > 0) {
      segmentData = pool.season.segments.edges[0].node
      periods = segmentData ? segmentData.periods.edges.map((e) => e.node).sort(sortByOrder) : undefined
    } else {
      const firstSegmentForPoolSeason: CentralGameInstancesQuery_allSegments | null =
        (pool && allSegments.find((seg) => seg.season.id === pool?.season?.id)) || null
      console.log(firstSegmentForPoolSeason, detailedPeriod)
    }

    const isBracket = segmentData ? segmentData.gameType === GameTypeEnum.BRACKET : false
    const isConfTourneyBracket = segmentData && isBracket && segmentData.subsection === ENUM_CONFERENCE_TOURNAMENT
    const labels = (isConfTourneyBracket ? tournamentRBLabels : periods && periods.slice(1).map((x) => x.description)) ?? []

    return bonuses.map((e, i) => {
      const label = labels[i] ?? ""
      const transformedLabel = LABEL_REPLACEMENT_MAP.has(label) ? LABEL_REPLACEMENT_MAP.get(label) : label
      return [transformedLabel, e, getModifierLabel(modifiers[i] as RoundModifierTypeEnum)]
    })
  }, [allSegments, detailedPeriod, pool])

  const tableContent = useMemo(
    () =>
      fullPoolSettings.map((arr, i) => {
        const [label, points, bonus] = arr
        return (
          <div className={cx({ table__row: true, "table__row--dense": dense })} key={i}>
            <div className="table__col table__col--label table__col--12">{label}</div>
            <div className="table__col table__col--5 table__col--right">{points}</div>
            {showBonusesCol && <div className="table__col table__col--11 table__col--right">{bonus}</div>}
          </div>
        )
      }),
    [fullPoolSettings, showBonusesCol, dense],
  )

  return (
    <PoolRulesTable>
      <div className={cx({ "table__row table__row--title": true, "table__row--dense": dense })}>
        <div className="table__col table__col--12 table__col--title">Round</div>
        <div className="table__col table__col--5 table__col--title table__col--right">Points</div>
        {showBonusesCol && (
          <div className="table__col table__col--11 table__col--title table__col--right">
            <TitleLink
              onClick={onShowBonuses}
              className={cx({
                "disabled-mobile": noShowBonuses,
              })}
            >
              Bonuses
            </TitleLink>
          </div>
        )}
      </div>
      <div className="table__content">{tableContent}</div>
    </PoolRulesTable>
  )
}

const PoolRulesContent = ({ mode = "rules", ...rest }: PoolRulesContentProps) => {
  const { pool } = rest
  const { poolSettings, isPublic, constitution } = pool || emptyObject
  const mainTiebreaker =
    poolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolSettings?.mainTiebreaker === "TOTAL_SCORE"
      ? CHAMPIONSHIP_TIEBREAKER_MESSAGE
      : NONE_TIEBREAKER_MESSAGE
  const maxEntriesPerUser = (poolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolSettings?.maxEntriesPerUser) ?? 0

  return (
    <>
      {mode === "full" && (
        <div className="modal__row rules-content__row">
          <div className="modal__label modal__label--height-sm rules-content__label">Pool Type</div>
          <div className="modal__content modal__content--height-sm rules-content__content">{isPublic ? "Public" : "Private"}</div>
        </div>
      )}
      <div className="modal__row rules-content__row">
        <div className="modal__label rules-content__label modal__label--height-lg rules-content__scoring_rules">Scoring Rules</div>
        <div className="modal__content rules-content__content">
          <PoolRules {...rest} />
        </div>
      </div>
      <div className="modal__row rules-content__row">
        <div className="modal__label modal__label--height-sm rules-content__label">Tiebreaker</div>
        <div className="modal__content modal__content--height-sm rules-content__content">{mainTiebreaker}</div>
      </div>
      <div className="modal__row rules-content__row">
        <div className="modal__label modal__label--height-sm rules-content__label">Max Brackets Per Player</div>
        <div className="modal__content modal__content--height-sm rules-content__content">{maxEntriesPerUser}</div>
      </div>
      {mode === "full" && (
        <div className="modal__row rules-content__row rules-content__additional_rules_row">
          <div className="modal__label rules-content__label rules-content__additional_rules">Additional Rules</div>
          <div className={`modal__content rules-content__content rules-content__content__constitution_${!!constitution}`}>
            {constitution || "None"}
          </div>
        </div>
      )}
    </>
  )
}

export default PoolRulesContent
