// http://api.cbssports.com/primpy/sportsapp/tournaments?access_token=e82ec81a39c91d6ef6ea5c2cb4129449188a5973

const Tournaments = [
  {
    _id: 489,
    name: "Boca Raton Beach Classic - Hall of Fame",
  },
  {
    _id: 477,
    name: "Las Vegas Holiday Invitational",
  },
  {
    _id: 35,
    name: "West Coast Conference Tourney",
  },
  {
    _id: 334,
    name: "Don Haskins Sun Bowl Invitational",
  },
  {
    _id: 393,
    name: "Global Sports Classic",
  },
  {
    _id: 29,
    name: "Southern Conference Tourney",
  },
  {
    _id: 21,
    name: "Missouri Valley Conference Tourney",
  },
  {
    _id: 469,
    name: "New Orleans Shootout",
  },
  {
    _id: 466,
    name: "Phil Knight Invitational - Motion",
  },
  {
    _id: 147,
    name: "2k Empire Classic",
  },
  {
    _id: 378,
    name: "Las Vegas Invite",
  },
  {
    _id: 445,
    name: "Corpus Christi Coastal Classic",
  },
  {
    _id: 421,
    name: "Cancun Challenge - Mayan Division",
  },
  // NOTE qac: wtf... this is in the data?
  // {
  //   _id: null,
  //   name: null,
  // },
  {
    _id: 385,
    name: "Legends Classic",
  },
  {
    _id: 443,
    name: "Barclays Center Classic",
  },
  {
    _id: 454,
    name: "Emerald Coast Classic",
  },
  {
    _id: 459,
    name: "Savannah Invitational",
  },
  {
    _id: 485,
    name: "NIT Season Tip-Off",
  },
  {
    _id: 350,
    name: "Las Vegas Classic",
  },
  {
    _id: 388,
    name: "Summit League Conference Tourney",
  },
  {
    _id: 1,
    name: "NCAA Tournament",
  },
  {
    _id: 446,
    name: "Corpus Christi Coastal Classic",
  },
  {
    _id: 380,
    name: "Wooden Legacy",
  },
  {
    _id: 347,
    name: "Hall of Fame Classic",
  },
  {
    _id: 342,
    name: "U.S. Virgin Islands Paradise Jam",
  },
  {
    _id: 52,
    name: "Preseason NIT",
  },
  {
    _id: 444,
    name: "Legends Classic-Subregional",
  },
  {
    _id: 481,
    name: "Las Vegas Classic - Regional",
  },
  {
    _id: 368,
    name: "Las Vegas Invitational",
  },
  {
    _id: 430,
    name: "Hall of Fame Tip-Off Springfield",
  },
  {
    _id: 464,
    name: "Cayman Islands Classic",
  },
  {
    _id: 24,
    name: "Patriot League Conference Tourney",
  },
  {
    _id: 457,
    name: "2K Classic - Subregional",
  },
  {
    _id: 451,
    name: "MGM Resorts Main Event Heavyweight",
  },
  {
    _id: 10,
    name: "Big South Conference Tourney",
  },
  {
    _id: 42,
    name: "Big Sky Conference Tourney",
  },
  {
    _id: 453,
    name: "Emerald Coast Classic",
  },
  {
    _id: 5,
    name: "Atlantic Coast Conference Tourney",
  },
  {
    _id: 230,
    name: "Mountain West Conference Tournament",
  },
  {
    _id: 399,
    name: "Charleston Classic",
  },
  {
    _id: 448,
    name: "Barclays Center Classic",
  },
  {
    _id: 23,
    name: "Ohio Valley Conference Tourney",
  },
  {
    _id: 419,
    name: "Hall of Fame Tip-Off Naismith",
  },
  {
    _id: 12,
    name: "Big West Conference Tourney",
  },
  {
    _id: 432,
    name: "Battle 4 Atlantis",
  },
  {
    _id: 480,
    name: "Battle at the Boardwalk",
  },
  {
    _id: 345,
    name: "AdvoCare Invitational",
  },
  {
    _id: 6,
    name: "Big 12 Conference Tourney",
  },
  {
    _id: 365,
    name: "Las Vegas Classic",
  },
  {
    _id: 132,
    name: "Maui Invitational",
  },
  {
    _id: 486,
    name: "NIT Orlando Season Tip-Off",
  },
  {
    _id: 131,
    name: "Great Alaska Shootout",
  },
  {
    _id: 474,
    name: "Fort Myers Tip-Off",
  },
  {
    _id: 433,
    name: "Battle 4 Atlantis Mainland",
  },
  {
    _id: 411,
    name: "Diamond Head Classic",
  },
  {
    _id: 22,
    name: "Northeast Conference Tourney",
  },
  {
    _id: 452,
    name: "MGM Resorts Main Event Middleweight",
  },
  {
    _id: 460,
    name: "Ivy League Conference Tourney",
  },
  {
    _id: 391,
    name: "Cancun Challenge - Riviera Division",
  },
  {
    _id: 36,
    name: "Western Athletic Conference Tourney",
  },
  {
    _id: 276,
    name: "Atlantic Sun Conference Tourney",
  },
  {
    _id: 279,
    name: "Pac-12 Conference Tournament",
  },
  {
    _id: 476,
    name: "St. Pete Shootout",
  },
  {
    _id: 473,
    name: "Myrtle Beach Invitational",
  },
  {
    _id: 447,
    name: "Gulf Coast Showcase",
  },
  {
    _id: 16,
    name: "Metro Atlantic Conference Tourney",
  },
  {
    _id: 277,
    name: "Horizon Conference Tourney",
  },
  {
    _id: 475,
    name: "NIagara Tip-Off",
  },
  {
    _id: 398,
    name: "Puerto Rico Tip-Off",
  },
  {
    _id: 14,
    name: "Colonial Conference Tourney",
  },
  {
    _id: 17,
    name: "Mid-American Conference Tourney",
  },
  {
    _id: 8,
    name: "Big East Conference Tourney",
  },
  {
    _id: 483,
    name: "Battle For The Capital",
  },
  {
    _id: 40,
    name: "Southland Conference Tourney",
  },
  {
    _id: 4,
    name: "Atlantic 10 Conference Tourney",
  },
  {
    _id: 31,
    name: "Southwestern Athletic Conference Tourney",
  },
  {
    _id: 28,
    name: "Southeastern Conference Tourney",
  },
  {
    _id: 394,
    name: "Global Sports Classic",
  },
  {
    _id: 410,
    name: "CollegeInsider.com Postseason Tournament",
  },
  {
    _id: 2,
    name: "NIT",
  },
  {
    _id: 32,
    name: "Sun Belt Conference Tourney",
  },
  {
    _id: 389,
    name: "College Basketball Invitational",
  },
  {
    _id: 15,
    name: "Conference USA Conference Tourney",
  },
  {
    _id: 429,
    name: "Maui Invite",
  },
  {
    _id: 450,
    name: "American Athletic Conference Tournament",
  },
  {
    _id: 462,
    name: "Islands of the Bahamas Showcase",
  },
  {
    _id: 3,
    name: "America East Conference Tourney",
  },
  {
    _id: 461,
    name: "Phil Knight Invitational - Victory",
  },
  {
    _id: 488,
    name: "Boca Raton Beach Classic - Naismith",
  },
  {
    _id: 458,
    name: "Vegas 16",
  },
  {
    _id: 467,
    name: "Basketball Hall of Fame Belfast Classic",
  },
  {
    _id: 463,
    name: "Cayman Islands Classic",
  },
  {
    _id: 7,
    name: "Big Ten Tourney",
  },
  {
    _id: 487,
    name: "Maui on the Mainland",
  },
  {
    _id: 19,
    name: "Mid-Eastern Conference Tourney",
  },
  {
    _id: 468,
    name: "Bulldog Bash",
  },
  {
    _id: 465,
    name: "Cayman Islands Classic - Mainland",
  },
  {
    _id: 482,
    name: "Belfast Basketball Classic - Goliath",
  },
  {
    _id: 484,
    name: "Orlando Invitational",
  },
  {
    _id: 53,
    name: "Women's NCAA Tournament",
  },
]

export default Tournaments
