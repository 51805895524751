import * as Sentry from "@sentry/browser"
import React from "react"
import constants from "../../common/constants"
import { stripCircular } from "../../server/middleware/error-page-shared"
import Code from "../components/Code"
import ErrorReport from "./ErrorReport"

interface State {
  error: any | null
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
declare global {
  // tslint:disable-next-line
  interface Window {
    SH_ERROR_NOTIFY: (error: Error | ErrorEvent | string, extraScope?: string) => void
    QQ?: any
    pickUtils?: any
    __Zone_enable_cross_context_check: boolean
  }
}
function logError(err: any) {
  if (typeof console !== "undefined" && console.error) {
    console.error(err)
  }
}

class ErrorBoundary extends React.Component<IProps, State> {
  public isConfigured = false
  constructor(props) {
    super(props)
    this.state = { error: null }
    this.configure()
  }

  public configure = () => {
    if (!this.isConfigured && constants.PUBLIC_SENTRY_DSN && typeof window !== "undefined") {
      // https://github.com/angular/angular/issues/31723
      // https://sentry.io/share/issue/0d357e0d4d0a486ba1b2833e950519ff/
      window.__Zone_enable_cross_context_check = true
      // https://github.com/getsentry/sentry-javascript/blob/master/packages/browser/examples/app.js
      const ignoreRegexp = /PersistedQueryNotFoundError|cbsi_ads_skyboxKit|__AMP_TOP|dw_anonc|getAdPos|TRCImpl|\(ad|execute code from a freed script|CustomEvent|ads_baseDiv|docs-homescreen-gb-container/i
      const isProd = constants.APP_ENV === "prod"
      const sampleRate = isProd ? 0.3 : 1.0
      Sentry.init({
        dsn: constants.PUBLIC_SENTRY_DSN,
        sampleRate,
        debug: !isProd,
        environment: constants.APP_ENV,
        release: constants.GIT_REVISION,
        whitelistUrls: [constants.DOMAIN],
        integrations(integrations) {
          const allowedIntigrations = integrations.filter(({ name }) => !/Breadcrumbs|GlobalHandlers|TryCatch|FunctionToString|Non-Error/.test(name))
          // console.log('integrations')
          // console.dir(integrations)
          // console.dir(allowedIntigrations)
          return allowedIntigrations
        },
        // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
        ignoreErrors: [ignoreRegexp],
        // beforeBreadcrumb(breadcrumb) {
        //   if (window.hasOwnProperty("QQ")) {
        //     console.log(breadcrumb);
        //   }
        //   return breadcrumb.category === "xhr" ? null : breadcrumb;
        // },
        beforeSend(event, hint) {
          try {
            if (window.hasOwnProperty("QQ")) {
              console.log(event)
              console.log(hint)
            }
            const error = hint && (hint.syntheticException || hint.originalException)
            // https://github.com/getsentry/sentry-javascript/issues/2210#issuecomment-523572038
            if (error && error instanceof Event && event && event.extra && hint && hint.originalException) {
              const exception = hint.originalException
              event.extra.isTrusted = (exception as any).isTrusted
              event.extra.detail = (exception as any).detail
              event.extra.type = (exception as any).type
            }
            const tp = typeof error
            const msg = (error && tp === "object" && error.hasOwnProperty("message") && (error as Error).message) || (tp === "string" && tp) || ""
            if (ignoreRegexp.test(msg.toString())) {
              return null
            }
          } catch (err) {
            logError(err)
          }
          return event
        },
        // logLevel: 3,
      })
      Sentry.configureScope((scope) => {
        scope.setTag("APP_RUN_CONTEXT", "BROWSER")
        scope.setTag("dockerBuild", constants.DOCKER_BUILD)
      })
      this.isConfigured = true
      window.SH_ERROR_NOTIFY = (err, extraScope) => {
        Sentry.withScope((scope) => {
          if (extraScope) {
            scope.setExtra("extraScope", extraScope)
          }
          scope.setExtra("cookies", document.cookie)
          scope.setExtra("href", window.location.href)
          if (err instanceof Error) {
            Sentry.captureException(err)
          } else {
            Sentry.captureMessage(JSON.stringify(err))
          }
        })
        logError(err)
      }
    } else if (typeof window !== "undefined") {
      window.SH_ERROR_NOTIFY = (err) => {
        logError(`Suppressed Sentry error:`)
        logError(err)
      }
    }
  }

  public componentDidCatch(error, errorInfo) {
    if (this.isConfigured) {
      this.setState({ error })
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key])
        })
        Sentry.captureException(error)
      })
      logError(error)
    }
  }

  public render() {
    if (this.state.error) {
      const debugError = ["local", "dev"].includes(constants.APP_ENV)
      if (debugError) {
        const err = this.state.error
        const originalError = err.originalError || err
        const printedError = stripCircular(originalError)
        const stack = err.stack ? err.stack.toString().replace(/[\n]/g, "<br />") : "[nostack]"
        return (
          <>
            <h1>Stack:</h1>
            <Code>{stack}</Code>
            <h1>Error</h1>
            <Code>${JSON.stringify(printedError, null, 2)}</Code>
          </>
        )
      } else {
        return <ErrorReport showRetry={true} />
      }
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

export default ErrorBoundary
