import React from "react"
import styled from "styled-components/macro"
import { palette } from "../utils/style-utils"
import { FlexCols } from "./FlexComponents"
import Spinner from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Spinner"

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  color?: string
  variant?: "base" | "view"
}

const Container = styled(FlexCols)`
  min-height: 10em;
  justify-content: center;
  & svg {
    width: 3em;
    height: 3em;
  }
  &.loading-view--view {
    min-height: 60vh;
  }
`

const LoadingView = React.forwardRef<HTMLDivElement, IProps>(({ color = "", variant = "base", className, ...props }, ref) => {
  const classNames = [`loading-view--${variant}`] as string[]
  if (className) {
    classNames.push(className)
  }
  return (
    <Container className={classNames.join(" ")} {...props} ref={ref}>
      <Spinner color={color || palette.blue1} />
    </Container>
  )
})

const MemoizedComponent = React.memo(LoadingView)
export default MemoizedComponent
