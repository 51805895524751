import React, { FC } from "react"
import styled from "styled-components"
import { palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import theme from "@cbs-sports/sports-shared-client/build/cjs/utils/BracketTheme"
import IncorrectSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Incorrect"
import CheckSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Check"
import Typography from "../../../../../shared/atoms/Typography"
import { CHAMPION_PICK_STATUS, PoolDataEntryType } from "./StandingsTable.common"
import BracketPlaceHolder from "../../../../../components/icons/BracketPlaceHolder"
import { teamImgUrl } from "../../../../../utils/data-utils"

const ChampionPlaceholderWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${theme.colors.overLight.white95};

  & > svg {
    color: ${palette.gray75};
    width: 100%;
    height: 100%;
  }
`

const ChampionLogoRoot = styled.div`
  position: relative;

  .champion__status {
    max-width: 1rem;
    max-height: 1rem;
    position: absolute;
    right: -8px;
    bottom: -2px;
    border-radius: 50%;
    background: ${theme.colors.overLight.white};

    &.champion__status--incorrect {
      color: ${palette.darkRed};
    }

    &.champion__status--correct {
      color: ${palette.darkGreen};
    }
  }
`

const ChampionLogoWrapper = styled.img`
  max-width: 1.5rem;
  max-height: 1.5rem;
  width: 100%;
  height: auto;
`

const TiebreakerNodeRoot = styled.div`
  margin-left: 0.75rem;
`

interface IChampionLogoProps {
  championTeam: PoolDataEntryType["championTeam"]
  tiebreakerAnswers: PoolDataEntryType["tiebreakerAnswers"]
  showTiebreaker: boolean
  pickStatus?: CHAMPION_PICK_STATUS
  showPlaceholder?: boolean
  logoUrl?: string
}

type TiebreakerNodeProps = Pick<IChampionLogoProps, "showTiebreaker" | "tiebreakerAnswers">

const TiebreakerNode: FC<TiebreakerNodeProps> = ({ showTiebreaker, tiebreakerAnswers }) => {
  if (!showTiebreaker) {
    return null
  }
  const [answer] = tiebreakerAnswers.slice(-1)
  return (
    <TiebreakerNodeRoot>
      <Typography variant="REGULAR" spacing="COMPRESSED" muted>
        {`(${answer?.value || "—"})`}
      </Typography>
    </TiebreakerNodeRoot>
  )
}

const ChampionLogo: FC<IChampionLogoProps> = ({
  logoUrl,
  championTeam,
  showTiebreaker,
  tiebreakerAnswers,
  pickStatus = "UNKNOWN",
  showPlaceholder,
}) => {
  const championUrl =
    logoUrl ??
    (championTeam ? teamImgUrl({ sportType: championTeam.sportType, cbsTeamId: championTeam.cbsTeamId, id: championTeam.cbsTeamId }) : undefined)
  return (
    <>
      {championUrl && !showPlaceholder ? (
        <ChampionLogoRoot>
          <ChampionLogoWrapper src={championUrl} alt="Champion Logo" />
          {pickStatus === "CORRECT" && (
            <div className="champion__status champion__status--correct">
              <CheckSvg />
            </div>
          )}
          {pickStatus === "INCORRECT" && (
            <div className="champion__status champion__status--incorrect">
              <IncorrectSvg />
            </div>
          )}
        </ChampionLogoRoot>
      ) : (
        <ChampionPlaceholderWrapper>
          <BracketPlaceHolder />
        </ChampionPlaceholderWrapper>
      )}
      <TiebreakerNode showTiebreaker={showTiebreaker} tiebreakerAnswers={tiebreakerAnswers} />
    </>
  )
}

export default ChampionLogo
