import React, { useCallback } from "react"
import { setParam, getParam } from "@cbs-sports/sports-shared-client/build/cjs/utils/url-utils"
import constants from "../../../../../../common/constants"
import { useHistory, useLocation } from "react-router-dom"
import { COLUMN_MAP } from "./StandingsTable.common"
import { EntryOrderByType, SortingOrderByType } from "../../../../../../__generated__/globalTypes"

const PARAMS = {
  orderBy: "orderBy",
  after: "after",
  sort: "sort",
}

const defaultPagination = constants.DEFAULT_PAGINATION

interface IStandingsPagination {
  pageSize?: number
  orderByColumn?: EntryOrderByType | null
  sort?: SortingOrderByType | null
  first: number
  after?: string
  goToPage: (cursor: string | null) => void
  updateOrderBy: (order: string) => void
  resetOrderBy: (orderByColumn: EntryOrderByType) => void
}

interface UseStandingsPaginationProps {
  pageSize: IStandingsPagination["pageSize"]
  orderByColumn: EntryOrderByType
  sort: SortingOrderByType
}

interface ParamsType {
  key: string
  value: string | null
}

const useStandingsPagination = (props: UseStandingsPaginationProps): IStandingsPagination => {
  const history = useHistory()
  const location = useLocation()
  const { pageSize = defaultPagination, sort = "DESC", orderByColumn } = props

  const currentPage = getParam(PARAMS.after, location.search)
  const paramSort = getParam(PARAMS.sort, location.search)
  const paramOrder = getParam(PARAMS.orderBy, location.search)

  const currentSort: SortingOrderByType = paramSort ? ((paramSort.toString() as any) as SortingOrderByType) : sort
  const currentOrder: EntryOrderByType = paramOrder ? ((COLUMN_MAP[paramOrder] as any) as EntryOrderByType) : orderByColumn

  const applyParams = useCallback(
    (key: string, value: string | null) => {
      const existingSearchParts = (location.search || "").replace("?", "")
      return history.push({ search: `${setParam(existingSearchParts, key, value)}` })
    },
    [location.search, history],
  )

  const applyMultipleParams = useCallback(
    (params: ParamsType[]) => {
      let existingSearchParts = (location.search || "").replace("?", "")
      params.forEach((x) => {
        existingSearchParts = setParam(existingSearchParts, x.key, x.value)
      })
      return history.push({ search: existingSearchParts })
    },
    [location.search, history],
  )

  const updateOrderBy = useCallback(
    (order: string) => {
      //getting: fantasyPoints checking with OVERALL_RANK
      const converted = COLUMN_MAP[order]
      const params: ParamsType[] = []
      if (converted) {
        if (converted === currentOrder) {
          if (currentSort === "ASC") {
            params.push({
              key: PARAMS.sort,
              value: "DESC",
            })
          } else {
            params.push({
              key: PARAMS.sort,
              value: "ASC",
            })
          }
        } else {
          params.push({
            key: PARAMS.sort,
            value: "DESC",
          })
        }
        params.push({
          key: PARAMS.orderBy,
          value: order,
        })
      }
      applyMultipleParams(params)
    },
    [applyMultipleParams, currentOrder, currentSort],
  )

  const resetOrderBy = useCallback(
    (orderByColumn: EntryOrderByType) => {
      const converted = COLUMN_MAP[orderByColumn]
      const params: ParamsType[] = []
      if (converted) {
        params.push({
          key: PARAMS.sort,
          value: "DESC",
        })
        params.push({
          key: PARAMS.orderBy,
          value: converted,
        })
      }
      applyMultipleParams(params)
    },
    [applyMultipleParams],
  )

  const goToPage = React.useCallback(
    (cursor: string | null) => {
      return applyParams(PARAMS.after, cursor)
    },
    [applyParams],
  )

  return {
    first: pageSize,
    after: currentPage ? currentPage.toString() : undefined,
    sort: currentSort,
    orderByColumn: currentOrder,
    goToPage,
    updateOrderBy,
    resetOrderBy,
  }
}

export default useStandingsPagination
